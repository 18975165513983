import React from 'react';
import { connect } from 'react-redux';
import scrollToComponent from 'react-scroll-to-component';
import { hasGoneToBucket, shouldGotoBucket } from '../../../features/tabs';

class BucketWrapNoStyle extends React.Component {
  constructor(props) {
    super(props);
    this.bucketRef = React.createRef();
  }
  componentDidMount() {
    this.gotoIfNessesary();
  }
  componentDidUpdate() {
    this.gotoIfNessesary();
  }
  gotoIfNessesary() {
    const {
      hasGoneTo,
      goto,
    } = this.props;
    if (goto) {
      scrollToComponent(this.bucketRef.current, { align: 'top' });
      hasGoneTo();
    }
  }
  render() {
    const {
      children,
      hasGoneTo,
      goto,
      ...props
    } = this.props;
    return (
      <div {...props} ref={this.bucketRef}>
        {children}
      </div>
    );
  }
}

export default connect(
  state => ({ goto: shouldGotoBucket(state) }),
  { hasGoneTo: hasGoneToBucket },
)(BucketWrapNoStyle);
