import React from 'react';
import Item from '../Item';
import Money from '../Money';
import { sanitizePrice } from '../../../lib/price';

const Display = ({ countPrColli, price }) => (
  <Money money={(sanitizePrice(price)) / countPrColli} />
);

export default ({ varenr }) => (
  <Item display={Display} varenr={varenr} />
);
