import React from 'react';


export default ({ onClick, children, ...restProps }) => {
  const onClickFunction = (evt) => {
    if (evt) {
      evt.preventDefault();
    }
    if (onClick) {
      onClick();
    }
    return false;
  };
  return (
    <a {...restProps} onClick={onClickFunction} href="#">
      {children}
    </a>
  );
};
