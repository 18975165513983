import React from 'react';
import { connect } from 'react-redux';
import { getMoms } from '../../features/commerce';
import { setMoms } from '../../features/commerce/moms';
import { LinkStyled } from '../../components/typography';

export default connect(
  (state, { moms }) => ({
    moms: typeof moms === 'undefined' ? getMoms(state) : moms,
    realMoms: typeof moms === 'undefined',
  }),
  {
    setMoms,
  },
)(({
  realMoms,
  setMoms,
  moms,
}) => {
  const momsText = moms ? 'med' : 'uden';
  return (
    <React.Fragment>
      {realMoms
        ? (
          <LinkStyled action onClick={() => setMoms(!moms)}>{momsText}</LinkStyled>
        )
        : momsText
      }&nbsp;moms
    </React.Fragment>
  );
});
