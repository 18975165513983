import React from 'react';
import styled from 'styled-components/macro';
import { Paragraph, ClearFix } from '../../../typography';
import { Image } from '../../../Image';
import { ScreenSizeDetector } from '../../../../lib/helpers';
import { Row, Col } from '../../../grid';

const Container = styled.div`
  position: relative;
  border: 2px ${({ theme }) => theme.colors.colorGreyLightAlt} solid;
  border-top: 10px;
  padding: 10px;
  color: ${({ theme }) => theme.colors.colorGreyDark} !important;

  &:hover {
    background: ${({ theme }) => theme.colors.colorBlueHighlight};
  }

  @media (max-width: 991px) {
    padding: 5px;
  }
`;
const ImageContainer = styled.div`
  display: block;
  min-height: 40px;
  height: 100%;
  float: left;
  width: 120px;
  text-align: center;

  @media (max-width: 400px) {
    width: 90px;
  }
`;
const DescriptionContainer = styled.div`
  float: right;
  width: calc(100% - 130px);

  @media (max-width: 400px) {
    width: calc(100% - 100px);
  }
`;
const Title = styled(Paragraph)`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;

  @media (max-width: 991px) {
    font-size: 14px;
    margin-bottom: 5px;
  }
`;

export default ({
  name,
  imageSingle,
  expiredMessage,
  expiredSize,
  imagePlural,
  image,
}) => (
  <Container className="resultView">
    <ImageContainer>
      <Row margin="0px">
        {imageSingle && (
          <Col xs={imagePlural ? 4 : 12} padding="0px" textCenter>
            <ScreenSizeDetector imageHeight={[[991, '40px'], '60px']} maxHeight={[[991, '40px'], '60px']}>
              <Image name={imageSingle} />
            </ScreenSizeDetector>
          </Col>
        )}
        {imagePlural && (
          <Col xs={imageSingle ? 8 : 12} padding="0px" textCenter>
            <ScreenSizeDetector imageHeight={[[991, '40px'], '60px']} maxHeight={[[991, '40px'], '60px']}>
              <Image name={imagePlural} />
            </ScreenSizeDetector>
          </Col>
        )}
        {image && (
          <Col xs={12} padding="0px" textCenter>
            <ScreenSizeDetector imageHeight={[[991, '40px'], '60px']} maxHeight={[[991, '40px'], '60px']}>
              <Image name={image} />
            </ScreenSizeDetector>
          </Col>
        )}
      </Row>
    </ImageContainer>
    <DescriptionContainer>
      <Title>
        {name}{expiredSize && (
          <React.Fragment>
            ,&nbsp;{expiredSize}
          </React.Fragment>
        )}
      </Title>
      {expiredMessage && (
        <Paragraph red>
          {expiredMessage()}
        </Paragraph>
      )}
    </DescriptionContainer>
    <ClearFix />
  </Container>
);
