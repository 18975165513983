import React from 'react';
import { connect } from 'react-redux';
import { getMoms } from '../../features/commerce';
import {
  getTotalPant,
  getTotalCostWithoutAnalegTillaeg,
  getBucketItemsWithoutReturPantAndAnlaegTillaeg,
  getTotalCostWithoutPantAndAnlaegTillaeg,
  getTotalWeight,
  getDeliveryCost,
  getExternalDeliveryFeeCost,
  getDeliveryFeesCostWithoutExternalDelivery,
  getDeliveryCostWithoutFees,
  getTotalReturPant,
  getEffectiveZip,
} from '../../features/commerce/selectors';
import { isDelivery } from '../../features/commerce/delivery';

export default connect(
  state => ({
    items: getBucketItemsWithoutReturPantAndAnlaegTillaeg(state),
    totalCostWithoutPant: getTotalCostWithoutPantAndAnlaegTillaeg(state),
    totalCost: getTotalCostWithoutAnalegTillaeg(state),
    totalPant: getTotalPant(state),
    totalReturPant: getTotalReturPant(state),
    isDelivery: isDelivery(state),
    deliveryCost: getDeliveryCost(state),
    deliveryCostWithoutFees: getDeliveryCostWithoutFees(state),
    deliveryFeesCost: getDeliveryFeesCostWithoutExternalDelivery(state),
    externalDeliveryFeeCost: getExternalDeliveryFeeCost(state),
    effectiveZip: getEffectiveZip(state),
    hasMoms: getMoms(state),
    totalWeight: getTotalWeight(state),
  }),
)(
  ({ display: Component, ...otherProps }) => (
    <Component {...otherProps} />
  ),
);
