import FadoelAlternativeView1 from './FadoelAlternativeView1';
import FadoelAlternativeView2 from './FadoelAlternativeView2';
import RelatedView1 from './RelatedView1';
import BuyButtons from './BuyButtons';
import PackImageMultiple from './PackImageMultiple';

export {
  FadoelAlternativeView1,
  FadoelAlternativeView2,
  RelatedView1,
  BuyButtons,
  PackImageMultiple,
};
