import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import OutsideClickHandler from 'react-outside-click-handler';
import { setShowSearchOverlay, getShowSearchOverlay, generalClearSearch } from '../../../../features/commerce/search';
import MenuResultsView from '../../../../components/Search/MenuResultsView';

const Container = styled.div`
  z-index: 1000;
  width: 100%;
  background-color: white;
  transition: top 0.3s ease-in;
  position: fixed;
  bottom: 0;
  
  &.active {
    top: 0;
  }
  &.inactive {
    top: 100%;
  }
`;

class MenuSearch extends React.Component {
  outsideClick = () => {
    const {
      visible,
      hideSearchOverlay,
      clearSearch,
    } = this.props;
    if (!visible) {
      return;
    }
    clearSearch();
    hideSearchOverlay();
  }

  hide = () => {
    const {
      hideSearchOverlay,
    } = this.props;
    hideSearchOverlay();
  }

  render() {
    const {
      visible,
    } = this.props;
    return (
      <OutsideClickHandler onOutsideClick={this.outsideClick}>
        <Container className={visible ? 'active' : 'inactive'}>
          <MenuResultsView hideMenu={this.hide} hideMenuAndClose={this.hide} />
        </Container>
      </OutsideClickHandler>
    );
  }
}

export default connect(
  state => ({
    visible: getShowSearchOverlay(state),
  }),
  dispatch => ({
    hideSearchOverlay: () => dispatch(setShowSearchOverlay(false)),
    clearSearch: () => dispatch(generalClearSearch()),
  }),
)(MenuSearch);
