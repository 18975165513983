import {
  put, select, take, actionChannel, fork,
} from 'redux-saga/effects';
import { buffers } from 'redux-saga';
import {
  // formValueSelector,
  CHANGE,
  getKundenr,
} from '../form';
import { STARTUP_COMPLETE } from '../actions';
import getArg from '../../lib/args';

export const getPlejerListeContents = ({ plejerliste: { plejerListeContents } }) => plejerListeContents;

const actionPrefix = 'SKAFTE/PLEJERLISTE/';
const PLEJER_LISTE_CHANGE = `${actionPrefix}PLEJER_LISTE_CHANGE`;
const PLEJER_LISTE_RESET = `${actionPrefix}PLEJER_LISTE_RESET`;
const SET_OVERRIDE_PLEJERLISTE = `${actionPrefix}SET_OVERRIDE_PLEJERLISTE`;
const SET_OVERRIDE_PLEJERLISTE_INTERNAL = `${actionPrefix}SET_OVERRIDE_PLEJERLISTE_INTERNAL`;

const setOverridePlejerlisteInternal = plejerListe => ({
  type: SET_OVERRIDE_PLEJERLISTE_INTERNAL,
  payload: plejerListe,
});
export const setOverridePlejerliste = plejerListe => ({
  type: SET_OVERRIDE_PLEJERLISTE,
  payload: plejerListe,
});

const changePlejerListe = payload => ({
  type: PLEJER_LISTE_CHANGE,
  payload,
});
const resetPlejerListe = () => ({
  type: PLEJER_LISTE_RESET,
});

const initialState = {
  kundenr: null,
  overridePlejerliste: false,
  plejerListe: [],
  plejerListeContents: {},
};
export function getInitialState() {
  const plejerliste = getArg('plejerliste').split('\n');
  const plejerListeContents = {};
  for (let i = 0; i < plejerliste.length; i += 1) {
    const elements = plejerliste[i].split(',');
    const kundenr = elements[0];
    const kundeliste = [];
    for (let j = 1; j < elements.length; j += 1) {
      const varenr = elements[j].trim();
      if (varenr !== '') {
        kundeliste.push(varenr);
      }
    }
    if (kundeliste.length > 0) {
      plejerListeContents[kundenr] = kundeliste;
    }
  }
  return {
    ...initialState,
    plejerListeContents,
  };
}
export default function plejerListeReducer(state = {}, action) {
  switch (action.type) {
    case PLEJER_LISTE_CHANGE:
      if (state.overridePlejerliste) {
        return state;
      }
      return action.payload;
    case PLEJER_LISTE_RESET:
      if (state.overridePlejerliste) {
        return state;
      }
      return {
        ...initialState,
        plejerListeContents: state.plejerListeContents,
      };
    case SET_OVERRIDE_PLEJERLISTE_INTERNAL:
      return {
        ...state,
        plejerListe: action.payload,
        overridePlejerliste: true,
      };
    default:
      return state;
  }
}
// const formSelector = formValueSelector('invoice-details');

const plejerListeSaga22 = actionChannel => function* plejerListeSaga2() {
  while (true) {
    const { payload } = yield take(actionChannel);
    yield put(setOverridePlejerlisteInternal(payload));
  }
};
export function* plejerlisteSaga() {
  const setOverrideActionChannel = yield actionChannel(SET_OVERRIDE_PLEJERLISTE);
  const changeActionChannel = yield actionChannel(CHANGE, buffers.expanding());
  yield take(STARTUP_COMPLETE);
  const plejerListeContents = yield select(getPlejerListeContents);
  const kundenr = yield select(getKundenr);
  if (kundenr) {
    if (plejerListeContents[kundenr.trim()]) {
      yield put(changePlejerListe({ kundenr: kundenr.trim(), plejerListe: plejerListeContents[kundenr.trim()] }));
    }
  }
  yield fork(plejerListeSaga22(setOverrideActionChannel));
  while (true) {
    const action = yield take(changeActionChannel);
    const { payload: { key: field, value: payload } } = action;
    if (field === 'kundenr') {
      const kundenr = payload.trim();
      if (plejerListeContents[kundenr]) {
        yield put(changePlejerListe({ kundenr, plejerListe: plejerListeContents[kundenr] }));
      } else {
        yield put(resetPlejerListe());
      }
    }
  }
}
