import React from 'react';
import styled from 'styled-components/macro';

const EntryWrapNoStyle = ({
  children,
  ...otherProps
}) => (
  <div {...otherProps}>
    {children}
  </div>
);
export default styled(EntryWrapNoStyle)`
  display: block;
  float: left;
  width: calc(25% - 40px);
  padding: 0px 15px;
  margin: 5px;
  background: #eee;
  height: 120px;

  @media (max-width: 1200px) {
    width: calc(33% - 40px);
  }

  @media (max-width: 992px) {
    height: 135px;
  }

  @media (max-width: 768px) {
    width: calc(50% - 40px);
  }

  @media (max-width: 575px) {
    width: calc(100% - 40px);
    height: 110px;
  }
`;
