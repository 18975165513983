import moment from 'moment';
import { createSelector } from 'reselect';
import { deliveryTypes } from '../shared';
import {
  dateTypes,
  dateModes,
  SET_DATE,
  SET_TIME,
  SET_MODE,
  RESET_DATE,
  VALIDATE_STATE,
  isBefore,
  isToday,
  isSaturday,
  isSunday,
  storeDateFormat,
  timeTranslation,
  getTimeFee as sharedGetTimeFee,
  getDateFee as sharedGetDateFee,
  isChristmas,
  combineFees,
  subtractFees,
  sameDayChoices,
  nightChoices,
  deliverySaturdayChoices,
  deliverySundayChoices,
  createGetAvailableTimes,
} from './shared';
import { closedDates } from '../../../../content/commerce/closedDates';
import getArg from '../../../../lib/args';
import { getSelfText } from '../../../../lib/date';

const getDefaultTime = (mode) => {
  switch (mode) {
    case dateModes.sameDay:
      return sameDayChoices.early;
    case dateModes.night:
      return nightChoices.early;
    case dateModes.deliverySaturday:
      return deliverySaturdayChoices.morning;
    case dateModes.deliverySunday:
      return deliverySundayChoices.afternoon;
    case dateModes.interval: {
      return { fromTime: 8, toTime: 16 };
    }
    default:
      return null;
  }
};
const initialState = {
  date: null,
  mode: dateModes.interval,
  time: getDefaultTime(dateModes.interval),
};


const modeTransition = (state, { dateType, mode, deliveryType }) => {
  if (
    dateType !== state.type
    || state.mode === mode
    || deliveryType !== deliveryTypes.deliveryAndPickup
  ) {
    return state;
  }
  return {
    ...state,
    mode,
    time: getDefaultTime(mode),
  };
};

const specialDays = ['20191223', '20191227', '20191230'];
const dateTransition = (state, { dateType, date }) => {
  if (
    dateType === dateTypes.delivery
    && state.type === dateTypes.pickup
    && state.mode !== dateModes.none
    && state.date !== null
  ) {
    if (
      date === null
      || isBefore(state.date, date)
    ) {
      return {
        ...state,
        date: null,
        time: getDefaultTime(state.mode),
      };
    }
  }
  if (
    dateType !== state.type
    || state.date === date
  ) {
    return state;
  }
  const newState = {
    ...state,
    date,
  };
  if (specialDays.includes(date)) {
    return modeTransition(
      newState,
      {
        dateType,
        mode: dateModes.interval,
        time: { from: 8, to: 13 },
        deliveryType: deliveryTypes.deliveryAndPickup,
      },
    );
  }
  // If today
  // And is delivery
  if (isSunday(date)) {
    return modeTransition(newState, { dateType, mode: dateModes.deliverySunday, deliveryType: deliveryTypes.deliveryAndPickup });
  }
  if (isSaturday(date)) {
    return modeTransition(newState, { dateType, mode: dateModes.deliverySaturday, deliveryType: deliveryTypes.deliveryAndPickup });
  }
  if (isToday(date)) {
    return modeTransition(newState, { dateType, mode: dateModes.sameDay, deliveryType: deliveryTypes.deliveryAndPickup });
  }
  if (
    state.mode === dateModes.sameDay
    || state.mode === dateModes.deliverySaturday
    || state.mode === dateModes.deliverySunday
  ) {
    return modeTransition(newState, { dateType, mode: dateModes.interval, deliveryType: deliveryTypes.deliveryAndPickup });
  }
  return newState;
};

const stateValidation = (state) => {
  if (state[dateTypes.delivery].date !== null) {
    if (isBefore(state[dateTypes.delivery].date, moment().format(storeDateFormat))) {
      return {
        ...state,
        [dateTypes.delivery]: dateTransition(state[dateTypes.delivery], { dateType: dateTypes.delivery, date: null }),
        [dateTypes.pickup]: dateTransition(state[dateTypes.pickup], { dateType: dateTypes.delivery, date: null }),
      };
    }
  }
  return state;
};

const makeReducer = (dateType) => {
  const typeInitialState = {
    ...initialState,
    type: dateType,
  };
  return (state = typeInitialState, action) => {
    switch (action.type) {
      case SET_DATE:
        return dateTransition(state, action.payload);
      case SET_MODE:
        return modeTransition(state, action.payload);
      case SET_TIME:
        if (
          action.payload.deliveryType === deliveryTypes.deliveryAndPickup
          && action.payload.dateType === state.type
        ) {
          return {
            ...state,
            time: action.payload.time,
          };
        }
        return state;
      case RESET_DATE:
        return typeInitialState;
      default:
        return state;
    }
  };
};
const deliveryReducer = makeReducer(dateTypes.delivery);
const pickupReducer = makeReducer(dateTypes.pickup);

const initialTotalState = {
  [dateTypes.delivery]: deliveryReducer(undefined, { type: '' }),
  [dateTypes.pickup]: pickupReducer(undefined, { type: '' }),
};
const runReducerOnKey = (reducer, key) => (state, action) => {
  const newState = reducer(state[key], action);
  if (newState !== state[key]) {
    return {
      ...state,
      [key]: newState,
    };
  }
  return state;
};

export default (state = initialTotalState, action) => {
  switch (action.type) {
    case VALIDATE_STATE:
      return stateValidation(state);
    default: {
      let newState = runReducerOnKey(deliveryReducer, dateTypes.delivery)(state, action);
      newState = runReducerOnKey(pickupReducer, dateTypes.pickup)(newState, action);
      return newState;
    }
  }
};


export const getRealLocalState = state => state.commerce.delivery.dates[deliveryTypes.deliveryAndPickup];
export const getLocalState = dateType => state => getRealLocalState(state)[dateType];

export const hasDateAsPossibility = () => () => true;
export const getDate = dateType => createSelector(
  getLocalState(dateType),
  localState => localState.mode !== dateModes.none && localState.date && moment(localState.date, storeDateFormat),
);


export const getMode = dateType => state => getLocalState(dateType)(state).mode;

export const getDisabledWeekdays = () => () => [];

export const getDisabledDays = () => createSelector(
  () => null,
  () => Object.keys(closedDates),
);

export const getMinDate = dateType => (state) => {
  if (dateType === dateTypes.pickup) {
    const deliveryDate = getDate(dateTypes.delivery)(state);
    if (deliveryDate) {
      return deliveryDate;
    }
  }
  return moment();
};

export const getIsDisabled = dateType => (state) => {
  if (dateType === dateTypes.pickup) {
    if (!getLocalState(dateTypes.delivery)(state).date) {
      return true;
    }
  }
  return false;
};

export const isRequired = () => () => true;

export const validate = dateType => (state) => {
  if (isRequired(dateType)(state)) {
    const {
      date,
    } = getLocalState(dateType)(state);
    return Boolean(date);
  }
  return true;
};

const availableTimeSelectors = {
  [dateModes.sameDay]: {
    [dateTypes.delivery]: createGetAvailableTimes(dateTypes.delivery, [
      sameDayChoices.early,
      sameDayChoices.late,
      nightChoices.early,
      nightChoices.medium,
      nightChoices.late,
    ]),
    [dateTypes.pickup]: createGetAvailableTimes(dateTypes.pickup, [
      sameDayChoices.early,
      sameDayChoices.late,
      nightChoices.early,
      nightChoices.medium,
      nightChoices.late,
    ]),
  },
  [dateModes.night]: {
    [dateTypes.delivery]: createGetAvailableTimes(dateTypes.delivery, [
      nightChoices.early,
      nightChoices.medium,
      nightChoices.late,
      nightChoices.afterMidnight,
      nightChoices.dhlDelivery,
    ]),
    [dateTypes.pickup]: createGetAvailableTimes(dateTypes.pickup, [
      nightChoices.early,
      nightChoices.medium,
      nightChoices.late,
      nightChoices.afterMidnight,
      nightChoices.dhl,
    ]),
  },
  [dateModes.deliverySaturday]: {
    [dateTypes.delivery]: createGetAvailableTimes(dateTypes.delivery, [
      deliverySaturdayChoices.morning,
      deliverySaturdayChoices.afternoon,
      deliverySaturdayChoices.midday,
      deliverySaturdayChoices.evening,
      deliverySaturdayChoices.night,
      deliverySaturdayChoices.afterMidnight,
    ]),
    [dateTypes.pickup]: createGetAvailableTimes(dateTypes.pickup, [
      deliverySaturdayChoices.morning,
      deliverySaturdayChoices.afternoon,
      deliverySaturdayChoices.midday,
      deliverySaturdayChoices.evening,
      deliverySaturdayChoices.night,
      deliverySaturdayChoices.afterMidnight,
      deliverySaturdayChoices.morningNotHome,
    ]),
  },
  [dateModes.deliverySunday]: {
    [dateTypes.delivery]: createGetAvailableTimes(dateTypes.delivery, [
      deliverySundayChoices.afternoon,
      deliverySundayChoices.midday,
      deliverySundayChoices.evening,
      deliverySundayChoices.night,
      deliverySundayChoices.afterMidnight,
    ]),
    [dateTypes.pickup]: createGetAvailableTimes(dateTypes.pickup, [
      deliverySundayChoices.afternoon,
      deliverySundayChoices.midday,
      deliverySundayChoices.evening,
      deliverySundayChoices.night,
      deliverySundayChoices.afterMidnight,
      deliverySundayChoices.afternoonNotHome,
    ]),
  },
};

export const getAvailableTimesInner = dateType => (currentTime = new Date().getTime(), dateMode, date) => {
  switch (dateMode) {
    case dateModes.sameDay:
    case dateModes.night:
    case dateModes.deliverySaturday:
    case dateModes.deliverySunday:
      return availableTimeSelectors[dateMode][dateType](date, currentTime);
    case dateModes.interval:
    case dateModes.none:
    default:
      return null;
  }
};

export const getTime = dateType => (state) => {
  const localState = getLocalState(dateType)(state);
  if (getArg('browser')) {
    const possibleTimes = getAvailableTimesInner(dateType)(undefined, localState.mode, localState.date);
    if (possibleTimes && !possibleTimes.includes(localState.time)) {
      return possibleTimes[0];
    }
  }
  return localState.time;
};

export const getAvailableTimes = dateType => (state, currentTime) => {
  const localState = getLocalState(dateType)(state);
  return getAvailableTimesInner(dateType)(currentTime, localState.mode, localState.date);
};

export const getActualTime = dateType => (state) => {
  const localState = getLocalState(dateType)(state);
  switch (localState.mode) {
    case dateModes.sameDay:
    case dateModes.night:
    case dateModes.deliverySaturday:
    case dateModes.deliverySunday: {
      const time = getTime(dateType)(state);
      return time ? timeTranslation[time].time : null;
    }
    case dateModes.interval:
      if (localState.time && localState.time.fromTime == -10) {
        return getSelfText;
      }
      return localState.time ? `${localState.time.fromTime}:00 - ${localState.time.toTime}:00` : null;
    case dateModes.none:
    default:
      return null;
  }
};

export const getTimeFee = dateType => state => (time) => {
  const fees = sharedGetTimeFee(time);
  const date = getDate(dateType)(state);
  return Object.keys(fees).reduce(
    (acc, feeKey) => {
      if (fees[feeKey].removeFee && fees[feeKey].removeFee(date)) {
        return acc;
      }
      return {
        ...acc,
        [feeKey]: fees[feeKey],
      };
    },
    {},
  );
};

export const getDateFee = () => () => (date) => {
  let fees = sharedGetDateFee(date);
  if (isChristmas(date)) {
    fees = {
      ...fees,
      [622]: {
        amount: 1,
        dontStack: true,
        overrides: [621],
        main: true,
      },
    };
  } else {
    fees = {
      ...fees,
      [621]: {
        amount: 1,
        dontStack: true,
        main: true,
      },
    };
  }
  return fees;
};
const normalDateFees = {
  [621]: {
    amount: 1,
    dontStack: true,
  },
};
export const getNormalDateFees = dateType => (state) => {
  if (dateType === dateTypes.pickup) {
    const deliveryDate = getDate(dateTypes.delivery)(state);
    if (deliveryDate && isChristmas(deliveryDate)) {
      return {
        [622]: {
          amount: 1,
          dontStack: true,
          overrides: [621],
        },
      };
    }
  }
  return normalDateFees;
};

export const getDateExtraFee = dateType => date => createSelector(
  getNormalDateFees(dateType),
  getDateFee(dateType),
  getDate(dateTypes.delivery),
  (normalDateFees, getDF, deliveryDate) => {
    const dateFee = getDF(date);
    if (dateType === dateTypes.pickup) {
      if (deliveryDate && isChristmas(deliveryDate)) {
        const {
          [621]: fake4,
          [622]: fake,
          ...restNormalDateFees
        } = normalDateFees;
        const {
          [621]: fake2,
          [622]: fake3,
          ...restDateFees
        } = dateFee;
        return subtractFees(restDateFees, restNormalDateFees);
      }
    }
    return subtractFees(dateFee, normalDateFees);
  },
);
export const getFees = dateType => createSelector(
  getMode(dateType),
  getTime(dateType),
  getDate(dateType),
  getDateFee(dateType),
  getTimeFee(dateType),
  (mode, time, date, getDF, getTF) => {
    let fees = {};
    if (mode !== dateModes.none) {
      fees = {
        ...fees,
        ...getDF(date),
      };
    }
    if (
      mode === dateModes.sameDay
      || mode === dateModes.night
      || mode === dateModes.deliverySaturday
      || mode === dateModes.deliverySunday
    ) {
      fees = {
        ...fees,
        ...getTF(time),
      };
    }
    return fees;
  },
);

export const getPriceComposition = createSelector(
  getFees(dateTypes.delivery),
  getFees(dateTypes.pickup),
  combineFees,
);
