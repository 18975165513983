
const actionPrefix = 'SKAFTE/TABS/';

export const TAB_TOGGLE = `${actionPrefix}TAB_TOGGLE`;

export const TAB_GOTO = `${actionPrefix}TAB_GOTO`;

export const TAB_HAS_GONE_TO = `${actionPrefix}TAB_HAS_GONE_TO`;

export const VARE_GOTO = `${actionPrefix}VARE_GOTO`;

export const VARE_HAS_GONE_TO = `${actionPrefix}VARE_HAS_GONE_TO`;

export const VARE_HIGHLIGHT = `${actionPrefix}VARE_HIGHLIGHT`;

export const BUCKET_GOTO = `${actionPrefix}BUCKET_GOTO`;

export const BUCKET_HAS_GONE_TO = `${actionPrefix}BUCKET_HAS_GONE_TO`;

export const hasGoneToBucket = () => ({
  type: BUCKET_HAS_GONE_TO,
});
export const gotoBucket = () => ({
  type: BUCKET_GOTO,
});

export const toggleTab = name => ({
  type: TAB_TOGGLE,
  payload: name,
});

export const gotoTab = name => ({
  type: TAB_GOTO,
  payload: name,
});

export const hasGoneToTab = name => ({
  type: TAB_HAS_GONE_TO,
  payload: name,
});

export const gotoVare = ({ varenr, tab }) => ({
  type: VARE_GOTO,
  payload: { varenr, tab },
});

export const hasGoneToVare = varenr => ({
  type: VARE_HAS_GONE_TO,
  payload: varenr,
});

export const highlightVare = varenr => ({
  type: VARE_HIGHLIGHT,
  payload: varenr,
});


const initialState = {
  tabs: {},
  varer: {},
  gotoBucket: false,
};
export default function tabReducer(state = initialState, action) {
  switch (action.type) {
    case BUCKET_GOTO:
      return { ...state, gotoBucket: true };
    case BUCKET_HAS_GONE_TO:
      return { ...state, gotoBucket: false };
    case VARE_HIGHLIGHT: {
      const {
        varer: {
          [action.payload]: existingVare = { goto: false },
        },
      } = state;
      return {
        ...state,
        varer: {
          ...state.varer,
          [action.payload]: {
            ...existingVare,
            highlighted: true,
          },
        },
      };
    }
    case VARE_HAS_GONE_TO: {
      const {
        varer: {
          [action.payload]: existingVare = { highlighted: false },
        },
      } = state;
      return {
        ...state,
        varer: {
          ...state.varer,
          [action.payload]: {
            ...existingVare,
            goto: false,
          },
        },
      };
    }
    case VARE_GOTO: {
      const { tab, varenr } = action.payload;
      const {
        varer: {
          [varenr]: existingVare = { highlighted: false },
        },
      } = state;
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [tab]: {
            expanded: true,
            goto: false,
          },
        },
        varer: {
          [varenr]: {
            ...existingVare,
            goto: true,
            highlighted: true,
          },
        },
      };
    }
    case TAB_HAS_GONE_TO: {
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [action.payload]: {
            ...state.tabs[action.payload],
            goto: false,
          },
        },
      };
    }
    case TAB_GOTO: {
      return {
        ...state,
        tabs: {
          [action.payload]: { expanded: true, goto: true },
        },
      };
    }
    case TAB_TOGGLE: {
      const expanded = typeof state.tabs[action.payload] === 'undefined' || !state.tabs[action.payload].expanded;
      return {
        ...state,
        tabs: {
          [action.payload]: { expanded, goto: false },
        },
      };
    }
    default:
      return state;
  }
}


export const isExpanded = (state, name) => state.tabs.tabs[name] && state.tabs.tabs[name].expanded;

export const anyTabExpanded = state => Object.keys(state.tabs.tabs).some(name => isExpanded(state, name));
export const shouldGotoTab = (state, name) => state.tabs.tabs[name] && state.tabs.tabs[name].goto;

export const isHighlighted = (state, varenr) => state.tabs.varer[varenr] && state.tabs.varer[varenr].highlighted;

export const shouldGotoVare = (state, varenr) => state.tabs.varer[varenr] && state.tabs.varer[varenr].goto;

export const shouldGotoBucket = state => state.tabs.gotoBucket;
