import React from 'react';
import NumberFormat from 'react-number-format';

export default props => (
  <NumberFormat
    decimalSeparator=","
    thousandSeparator="."
    displayType="text"
    {...props}
  />
);
