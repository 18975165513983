import styled from 'styled-components/macro';
import {
  marginTheming,
  paddingTheming,
  elementTheming,
  removePaddingThemingProps,
  removeMarginThemingProps,
  removeElementThemingProps,
  removeProps,
  combine,
  createComponentWithModifiedProps,
} from '../../style/themes/main';


const removeCustomProps = removeProps();
const removeAllProps = combine(
  removePaddingThemingProps,
  removeMarginThemingProps,
  removeElementThemingProps,
  removeCustomProps,
);

const JumbotronNoProps = createComponentWithModifiedProps(removeAllProps, 'div', 'Skafte.com.typography.Jumbotron');

export default styled(JumbotronNoProps)`
  @media screen and (min-width:768px){
    & {
      padding-right:60px;
      padding-left:60px;
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
  background-color: ${({ theme }) => theme.colors.colorGreyLight};
  ${marginTheming}
  ${paddingTheming}
  ${elementTheming}
`;
