import React from 'react';
import { Money } from '../../../containers/commerce';
import { AlcoholPercentage, FadKobling } from '../parts';
import { sanitizeMeasurement, ScreenSizeDetector } from '../../../lib/helpers';
import { sanitizePrice } from '../../../lib/price';
import BuyButtonsFadoel from './BuyButtonsFadoel';
import {
  Col,
  Row,
  Container,
  Div,
} from '../../grid';
import { Paragraph, Heading4, LinkButton } from '../../typography';
import { Table, Td } from '../../tables';
import { Image } from '../../Image';
import LavLiterpris from '../../../containers/commerce/LavLiterpris';

const RenderButtons = ({
  amount,
  actions,
  notice,
  smallDescription,
  lavLiterpris,
  inStock,
  inStockText,
}) => {
  if (inStock === '-1' && !inStockText) {
    return (
      <React.Fragment>
        <Paragraph red>
          Midlertidigt udsolgt fra producenten
        </Paragraph>
        <LinkButton small textCenter to="/bestil/fadoel-og-anlaeg/fadoel" title="Fadølstyper">
          Se vores store sortiment af fadøl
        </LinkButton>
      </React.Fragment>
    );
  }
  if (inStock === '-1' && inStockText) {
    return (
      <React.Fragment>
        <Paragraph red>
          {inStockText()}
        </Paragraph>
        <LinkButton small textCenter to="/bestil/fadoel-og-anlaeg/fadoel" title="Fadølstyper">
          Se vores store sortiment af fadøl
        </LinkButton>
      </React.Fragment>
    );
  }
  if (notice) {
    return (
      <React.Fragment>
        <Paragraph>
          {notice()}
        </Paragraph>
        <Paragraph fontWeight="bold">
          Læg i kurv:
        </Paragraph>
        <Paragraph>
          <BuyButtonsFadoel fullWidth amount={amount} actions={actions} />
        </Paragraph>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {smallDescription && smallDescription()}
      {lavLiterpris && <LavLiterpris lavLiterpris={lavLiterpris} />}
      <Paragraph>
        <BuyButtonsFadoel fullWidth amount={amount} actions={actions} />
      </Paragraph>
    </React.Fragment>
  );
};

const defaultSingleWidth = [[425, '100px'], '100px'];
const RenderSingleImage = ({ imageSingle, singleWidth = defaultSingleWidth }) => {
  if (!imageSingle) {
    return null;
  }
  return (
    <ScreenSizeDetector maxWidth={singleWidth} imageWidth={singleWidth}>
      <Image name={imageSingle} width="100%" maxHeight="120px" className="bucketAnimation" />
    </ScreenSizeDetector>
  );
};

export default React.forwardRef((props) => {
  const {
    price,
    liter,
    name,
    imageSingle,
    amount,
    actions,
    fadoelsType,
    fadKobling,
    alcoholPercentage,
    singleWidth,
    notice,
    small,
    whiteBg,
    smallDescription,
    extraItemRef,
    inStock,
    inStockText,
    lavLiterpris,
  } = props;
  return (
    <React.Fragment>
      <Container grey={amount === 0 && !whiteBg} white={amount === 0 && whiteBg} green={amount > 0}>
        <Div ref={extraItemRef}>
          <Row>
            <Col xs={12} paddingTop="15px" paddingBottom="0">
              <Heading4 marginBottom="0" className="bucketAnimation">{name}</Heading4>
            </Col>
            <Col xs={3} padding="15px 0 15px 15px">
              <RenderSingleImage imageSingle={imageSingle} singleWidth={singleWidth} />
            </Col>
            <Col xs={9}>
              <Table paddingTd="5px" fontSize="12px">
                <tr>
                  <Td>Størrelse</Td>
                  <Td textRight>{sanitizeMeasurement(liter)}l</Td>
                </tr>
                <tr>
                  <Td>Alkohol %</Td>
                  <Td textRight><AlcoholPercentage value={alcoholPercentage} /></Td>
                </tr>
                <tr>
                  <Td>Type</Td>
                  <Td textRight>{fadoelsType}</Td>
                </tr>
                <tr>
                  <Td>Anstikker</Td>
                  <Td textRight><FadKobling value={fadKobling} /></Td>
                </tr>
                <tr>
                  <Td>Literpris</Td>
                  <Td textRight><Money money={sanitizePrice(price) / sanitizeMeasurement(liter)} /></Td>
                </tr>
                <tr>
                  <Td>Pris</Td>
                  <Td textRight><Money money={sanitizePrice(price)} /></Td>
                </tr>
              </Table>
            </Col>
            <Col xs={12} padding="0 15px 15px 15px" margin="-15px 0 0 0">
              <RenderButtons smallDescription={smallDescription} small={small} amount={amount} actions={actions} inStock={inStock} inStockText={inStockText} notice={notice} lavLiterpris={lavLiterpris} />
            </Col>
          </Row>
        </Div>
      </Container>
    </React.Fragment>
  );
});
