import styled from 'styled-components/macro';
import { Div } from '../../../../components/grid';

const HeaderWrap = styled(Div)`
  display: block;
  background-color: ${({ theme }) => theme.colors.colorWhite};

  ${({ responsive, theme }) => responsive && `
    background-color: ${theme.colors.colorWhite};
    -webkit-box-shadow: 0px -5px 10px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px -5px 10px rgba(0,0,0,0.5);
    box-shadow: 0px -5px 10px rgba(0,0,0,0.5);
    position: fixed;
    width: 100%;
    height: 70px;
    z-index: 1000;
    bottom: 0px !important;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
  `}
`;

export default HeaderWrap;
