import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import GodkendTabButton from '../layout/MainBestilLayout/GodkendTabButton';
import TabButtonContainer from '../layout/MainBestilLayout/TabButtonContainer';

const TabButtonWrapNoStyle = ({ children, ...otherProps }) => (
  <div {...otherProps}>
    {children}
  </div>
);
const TabButtonWrap = styled(TabButtonWrapNoStyle)`
  border-bottom: 10px solid ${({ theme }) => theme.colors.colorGreyLightAlt};
  overflow: hidden;
  padding-top: 5px;
  @media (max-width: 991px) {
    border-bottom: 0px !important;
  }
`;

const tabs = [
  {
    id: 'kildevand',
    to: '/bestil/flasker-og-daaser/kildevand',
    title: 'Kildevand',
    mediumTitle: 'Kildevand',
    smallTitle: 'Vand',
    showSmall: true,
    showMedium: true,
  },
  {
    id: 'sodavand',
    to: '/bestil/flasker-og-daaser/sodavand',
    title: 'Sodavand',
    mediumTitle: 'Sodavand',
    smallTitle: 'Soda',
    showSmall: true,
    showMedium: true,
  },
  {
    id: 'juice-og-saft',
    to: '/bestil/flasker-og-daaser/juice-og-saft',
    title: 'Juice & Saft',
    mediumTitle: 'Juice',
    smallTitle: 'Juice',
    showSmall: true,
    showMedium: true,
  },
  {
    id: 'oel-og-alkohol',
    to: '/bestil/flasker-og-daaser/oel-og-alkohol',
    title: 'Øl & Alkohol',
    mediumTitle: 'Alkohol',
    smallTitle: 'Øl',
    showSmall: true,
    showMedium: true,
  },
];

const saedvanligeTab = {
  to: '/bestil/flasker-og-daaser/saedvanlige',
  title: 'Sædvanlige',
  mediumTitle: 'Ofte',
  smallTitle: 'Ofte',
  showSmall: true,
  showMedium: true,
};

const tabsWithSaedvanlige = [
  saedvanligeTab,
  ...tabs,
];

const DefaultTabButtons = connect(({ plejerliste }) => plejerliste)(
  ({ plejerListe, activeTab }) => (
    plejerListe.length > 0
      ? <TabButtonContainer tabs={tabsWithSaedvanlige} activeTab={activeTab} />
      : <TabButtonContainer tabs={tabs} activeTab={activeTab} />
  ),
);

export default ({ activeTab }) => (
  <TabButtonWrap>
    <DefaultTabButtons activeTab={activeTab} />
    <GodkendTabButton to="/bestil/detaljer" title="Gå til kassen" />
  </TabButtonWrap>
);
