import React from 'react';
import { connect } from 'react-redux';
import styled, { keyframes } from 'styled-components/macro';
import OutsideClickHandler from 'react-outside-click-handler';
import { Link, withRouter } from 'react-router-dom';
import { generalChangeSearch, generalClearSearch, getShowSearchOverlay } from '../../features/commerce/search';
import { Item, VareLink } from '../../containers/commerce';
import getItemDefaultInformation from '../../content/commerce/items';
import { getGeneralSearchState } from '../../features/commerce/search/selectors';
import { resultTypes } from '../../features/commerce/search/shared';
import { specialEncodeUriForReactRouter } from '../../lib/helpers';
import { Div } from '../grid';
import GeneralResultView from './GeneralResultView';
import {
  Input, Paragraph, LinkStyled, LinkButton,
} from '../typography';
import { FaCog, FaSearch } from '../Icons';

const GeneralSearchInputWrap = styled(Div)`
  float: right;
`;
const SearchFormNoStyle = ({
  children,
  ...otherProps
}) => (
  <form {...otherProps}>
    {children}
  </form>
);
const SearchForm = styled(SearchFormNoStyle)`
  position: relative;
`;

const SearchInputNoStyle = ({
  ...otherProps
}) => (
  <Input type="text" placeholder="Hvad leder du efter?" {...otherProps} />
);

const SearchInput = styled(SearchInputNoStyle)`
  box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.colors.colorGreyLightAlt};
  border-radius: 15px;
  padding: 16px 15px;
  width: calc(250px - 30px);
  font-size: 14px;
  transition: width 500ms, box-shadow 180ms ease-in;

  @media (max-width: 1599px) {
    width: calc(200px - 30px);
  }
  
  &::placeholder {
    font-style: italic;
  }

  &:focus {
    width: calc(400px - 30px);
    box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.colors.colorBlue}, 0px 0px 10px rgba(0,0,0,0.2);
  }
`;

const SearchSubmitNoStyle = ({
  ...otherProps
}) => (
  <button type="submit" {...otherProps}>
    <FaSearch />
  </button>
);
const SearchSubmit = styled(SearchSubmitNoStyle)`
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  border: none;
  background: none;
  padding: 13.5px 15px;

  & > svg {
    fill: ${({ theme }) => theme.colors.colorBlue};
    font-size: 20px;

    &:hover {
      fill: ${({ theme }) => theme.hoverColors.colorBlue};
      cursor: pointer;
    }
  }
`;

const ResultViewContainer = styled.div`
  width: 100%;
  @media (max-width: 767px) {
    :nth-child(5), :nth-child(6) {
      display: none;
    }
  }
`;
const ResultsContainer = styled.div`
  position: absolute;
  right: 0;
  width: 600px;
  border-radius: 10px;
  opacity: 0;
  pointer-events: none;
  z-index: 10;
  background-color: white;
  transition: opacity 500ms;
  padding: 15px;
  box-shadow: rgba(0,0,0,0.5) 0px 5px 10px 0px;
  ${({ visible }) => {
    if (visible) {
      return `
        opacity: 1;
        pointer-events: auto;
      `;
    }
    return '';
  }}

  @media (max-width: 991px) {
    padding: 5px;
  }
`;

const ResultsInnerContainer = styled.div`
  width: 100%;
  min-height: 50px;
  position: relative;

  & > svg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    fill: ${({ theme }) => theme.colors.colorGrey};
    font-size: 30px;
    animation: ${keyframes`
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    `} 2s linear infinite;
  }
`;
const ConnectedItemResultsView = ({
  url,
  varenr,
  ...otherProps
}) => {
  const child = <GeneralResultView {...otherProps} />;
  if (url) {
    return child;
  }
  return (
    <VareLink varenr={varenr} LinkComponent={Link}>
      {child}
    </VareLink>
  );
};
const getResultEntries = (results) => {
  if (results.length === 0) {
    return (
      <React.Fragment>
        <Paragraph bold>
          Ingen resultater på søgningen
        </Paragraph>
        <LinkButton small arrow to="/bestil/flasker-og-daaser/kildevand">
          Se hele sortimentet
        </LinkButton>
      </React.Fragment>
    );
  }
  const resultArray = [];
  for (let i = 0; i < results.length && resultArray.length < 6; i += 1) {
    const [type, searchResult] = results[i];
    if (type === resultTypes.ROUTE) {
      const [r, {
        imageSingle, imagePlural, description, title, image,
      }] = searchResult;
      resultArray.push(
        <ResultViewContainer key={r}>
          <Link to={r}>
            <GeneralResultView
              title={title}
              description={description}
              imageSingle={imageSingle}
              imagePlural={imagePlural}
              image={image}
            />
          </Link>
        </ResultViewContainer>,
      );
    } else if (type === resultTypes.ITEM) {
      const varenr = searchResult;
      const { url, externalurl } = getItemDefaultInformation(varenr);
      if (externalurl) {
        resultArray.push(
          <ResultViewContainer key={varenr}>
            <LinkStyled external to={externalurl}>
              <Item varenr={varenr} display={ConnectedItemResultsView} allowNotInState allowExpiredKeepDisplay />
            </LinkStyled>
          </ResultViewContainer>,
        );
      } else if (url) {
        resultArray.push(
          <ResultViewContainer key={varenr}>
            <Link to={url}>
              <Item varenr={varenr} display={ConnectedItemResultsView} allowNotInState allowExpiredKeepDisplay />
            </Link>
          </ResultViewContainer>,
        );
      } else {
        resultArray.push(
          <ResultViewContainer key={varenr}>
            <Item varenr={varenr} display={ConnectedItemResultsView} allowNotInState allowExpiredKeepDisplay />
          </ResultViewContainer>,
        );
      }
    }
  }
  return (
    <>
      {resultArray}
    </>
  );
};

const GeneralSearchInput = ({
  term,
  searching,
  results,
  onSearch,
  history,
  onClear,
  showSearchOverlay,
  noResults,
}) => {
  const outsideClickHandlerProps = {};
  let CHandler = React.Fragment;
  if (!noResults) {
    CHandler = OutsideClickHandler;
    outsideClickHandlerProps.onOutsideClick = onClear;
    outsideClickHandlerProps.disabled = !term || showSearchOverlay;
  }
  return (
    <GeneralSearchInputWrap>
      <CHandler {...outsideClickHandlerProps}>
        <SearchForm onSubmit={(e) => { e.preventDefault(); }}>
          <SearchInput
            value={term}
            onChange={({ target: { value } }) => onSearch(value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                history.push(`/search/${specialEncodeUriForReactRouter(term)}`);
              }
            }}
          />
          <SearchSubmit onClick={() => history.push(`/search/${specialEncodeUriForReactRouter(term)}`)} aria-label="Søg" />
          <ResultsContainer visible={term.trim() && !noResults}>
            <ResultsInnerContainer>
              {searching && <FaCog />}
              {term.trim() && !searching && getResultEntries(results)}
              {!searching && (results.length > 0) && (
                <React.Fragment>
                  <Paragraph blue bold fontSize="16px" padding="10px 0 0 0" marginBottom="0px">
                    Klik på &quot;Søg&quot; eller tryk &quot;Enter&quot; for at se alle resultater
                  </Paragraph>
                </React.Fragment>
              )}
            </ResultsInnerContainer>
          </ResultsContainer>
        </SearchForm>
      </CHandler>
    </GeneralSearchInputWrap>
  );
};

export default withRouter(connect(
  (state) => {
    const {
      term,
      searching,
      results,
    } = getGeneralSearchState(state);
    return {
      term,
      searching,
      results,
      showSearchOverlay: getShowSearchOverlay(state),
    };
  },
  {
    onSearch: generalChangeSearch,
    onClear: generalClearSearch,
  },
)(GeneralSearchInput));
