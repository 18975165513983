import React from 'react';
import styled from 'styled-components/macro';
import { Paragraph, Span } from '../../../typography';
import { LiterFormat } from '../../../Format';
import { Row, Col } from '../../../grid';
import { sanitizeMeasurement, getRentalText } from '../../../../lib/helpers';
import { Money } from '../../../../containers/commerce';
import { FadKobling } from '../../parts';


const EntryWrapNoStyle = ({
  children,
  ...otherProps
}) => (
  <div {...otherProps}>
    {children}
  </div>
);
const EntryWrap = styled(EntryWrapNoStyle)`
  border-bottom: 2px solid ${({ theme }) => theme.colors.colorGreyLight};
`;

export default (props) => {
  const {
    name,
    countPrColli,
    liter,
    amount,
    singleAmount,
    packageNoun,
    cost,
    isLeje,
    days,
    productTypeReservedel,
    productTypePalle,
    simpleItem,
    royalUnibrewJulekittet,
    productTypePack,
    productTypeFadoel,
    fadKobling,
  } = props;
  return (
    <EntryWrap>
      <Col xs={12} padding="3px 0">
        <Paragraph lineHeight="1.2em" margin="0">
          {name}
          {(sanitizeMeasurement(liter) > 0 && (sanitizeMeasurement(liter) / sanitizeMeasurement(countPrColli)) < 2) && (
            <React.Fragment>
              &nbsp;{countPrColli}x<LiterFormat liter={liter} cl divider={countPrColli} />
            </React.Fragment>
          )}
          {(sanitizeMeasurement(liter) / sanitizeMeasurement(countPrColli)) >= 2 && (
            <React.Fragment>
              &nbsp;<LiterFormat liter={liter} divider={countPrColli} />
            </React.Fragment>
          )}
        </Paragraph>
        <Row>
          <Col xs={6} paddingTop="0px" paddingBottom="0px" bold>
            {!royalUnibrewJulekittet && amount}
            {royalUnibrewJulekittet && (
              <React.Fragment>
                2 x nissehuer<br />2 x knappehulsblinkere<br />1 x guirlande
              </React.Fragment>
            )}
            {!productTypePalle && !simpleItem && !productTypePack && !productTypeReservedel && (
              <React.Fragment>
                &nbsp;{packageNoun.toLowerCase()}{amount !== 1 && 'r'}{singleAmount > 0 && `, ${singleAmount * countPrColli} flasker`}
                {productTypeFadoel && (
                  <React.Fragment>
                    <Span normal> &nbsp;| <FadKobling value={fadKobling} noPrefix /></Span>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            {productTypePalle && (
              <React.Fragment>
                &nbsp;palle{amount !== 1 && 'r'}
              </React.Fragment>
            )}
            {(simpleItem || productTypePack || productTypeReservedel) && (
              <React.Fragment>
                &nbsp;stk. {isLeje && `(${getRentalText(days)})`}
              </React.Fragment>
            )}
          </Col>
          <Col xs={6} paddingTop="0px" paddingBottom="0px" bold textRight>
            <Money money={cost} />
          </Col>
        </Row>
      </Col>
    </EntryWrap>
  );
};
