import { createSelector } from 'reselect';
import { sanitizePrice } from '../../../lib/price';
import { getItemDefaultInformation } from '../../../content/commerce/items';
import overwriteFields from '../../../content/commerce/items/overwriteFields';
import getArg from '../../../lib/args';
import { categories } from '../../../content/commerce/itemCategories';
import { productCategoryLookup } from '../../../content/commerce/itemLookup';
import { sanitizeMeasurement } from '../../../lib/helpers';

const namespace = 'SKAFTE/COMMERCE/ITEMS/';

export const ITEMS_SET = `${namespace}ITEMS_SET`;

export const setItems = (items, productVersion) => ({
  type: ITEMS_SET,
  payload: {
    productVersion,
    items,
  },
});
export const getLocalState = state => state.commerce.items;
export const getItemFromLocalState = (localState, { varenr }) => localState.initialized && localState.items[varenr];
export const getItems = state => state.commerce.items.initialized && state.commerce.items.items;
export const getItem = (state, { varenr }) => getItems(state) && getItems(state)[varenr];

export const createGetItemValues = () => createSelector(
  state => state.commerce.items,
  (state, { items }) => items,
  (state, { values }) => values,
  (localState, items, values) => items.map(
    (varenr) => {
      const item = getItemFromLocalState(localState, { varenr });
      if (!item) {
        return null;
      }
      const defaultInformation = getItemDefaultInformation(varenr);
      if (!defaultInformation) {
        return null;
      }
      return values.reduce(
        (acc, value) => ({
          ...acc,
          [value]: defaultInformation[value] || item.values[value],
        }),
        { varenr },
      );
    },
  ).filter(itemValues => itemValues !== null),
);
export const getItemFreeDeliveryBasis = (state, { varenr }) => {
  const item = getItem(state, { varenr });
  if (!item) {
    return 0;
  }
  const { values: { freeDeliveryBasis } } = item;
  return sanitizeMeasurement(freeDeliveryBasis);
};
export const getItemTypeFromLocalState = (localState, { varenr }) => {
  const item = getItemFromLocalState(localState, { varenr });
  if (!item) {
    return '';
  }
  const { values: { itemType, subItems } } = item;
  if (subItems) {
    const itemIsLeje = subItems.reduce(
      (acc, varenr) => acc || getItemTypeFromLocalState(localState, { varenr }) === '100',
      false,
    );
    if (itemIsLeje) {
      return '100';
    }
  }
  if (!itemType) {
    return '';
  }
  return itemType.trim();
};
export const itemHasDefaultInformation = varenr => Boolean(getItemDefaultInformation(varenr));

export const itemIsOutOfStock = (state, varenr) => {
  const localState = getLocalState(state);
  const item = getItemFromLocalState(localState, { varenr });
  if (!item) {
    return true;
  }
  const { values: { inStock } } = item;
  return inStock === '-1';
};
export const itemIsExpired = (state, varenr) => {
  const localState = getLocalState(state);
  const item = getItemFromLocalState(localState, { varenr });
  if (!item) {
    return true;
  }
  const { values: { inStock } } = item;
  return inStock === '100';
};


export const getItemInconsistencyStatus = (state, { varenr }) => {
  if (itemIsOutOfStock(state, varenr)) {
    return 'SOLD_OUT';
  }
  return null;
};

export const getItemDividableBy = (varenr) => {
  const { dividableBy = 1 } = getItemDefaultInformation(varenr) || {};
  return dividableBy;
};
export const getItemPostnord = (varenr) => {
  const { postnord = false } = getItemDefaultInformation(varenr) || {};
  return postnord;
};
export const getItemTypeIsReservedel = itemType => itemType && itemType.trim() === '95';

export const getItemTypeIsPalle = itemType => itemType && itemType.trim() === '90';

export const getItemTypeIsFreeItem = itemType => itemType && itemType.trim() === '96';

export const getItemType = (state, { varenr }) => getItemTypeFromLocalState(state.commerce.items, { varenr });

export const isFustage = (localState, { varenr }) => {
  const item = getItemFromLocalState(localState, { varenr });
  if (!item) {
    return false;
  }
  const { values: { packageNoun } } = item;
  return packageNoun && packageNoun.toLowerCase().indexOf('fustage') !== -1;
};

export const getFadKobling = (localState, { varenr }) => {
  const item = getItemFromLocalState(localState, { varenr });
  if (!item) {
    return '';
  }
  const { values: { fadKobling } } = item;
  return fadKobling ? fadKobling.substring(0, 1) : '';
};

export const getFadKoblingExact = (localState, { varenr }) => {
  const item = getItemFromLocalState(localState, { varenr });
  if (!item) {
    return '';
  }
  const { values: { fadKobling } } = item;
  // Return fadkobling, but remove the word Anstikker and all whitespace if it is exists
  if (fadKobling && fadKobling.toLowerCase().indexOf('anstikker') !== -1) {
    return fadKobling.replace('Anstikker', '').replace(/\s/g, '');
  }
  return fadKobling || '';
};


export const isAnlaeg = (localState, { varenr }) => {
  if (['3607', '3608', '604', '3936', '3618'].includes(`${varenr}`)) {
    return true;
  }
  const item = getItemFromLocalState(localState, { varenr });
  if (!item) {
    return false;
  }
  const { values: { subItems } } = item;
  if (subItems) {
    return subItems.includes('3607') || subItems.includes('3608') || subItems.includes('604') || subItems.includes('3936') || subItems.includes('3618');
  }
  return false;
};

export const isIs = (localState, { varenr }) => {
  return productCategoryLookup.huskIs.includes(`${varenr}`);
 // return categories.Is.items.map(vn => `${vn}`).includes(`${varenr}`);
}
export const isGlass = (localState, { varenr }) => {
  return productCategoryLookup.huskGlas.includes(`${varenr}`);
  // return categories.OelGlas.items.map(vn => `${vn}`).includes(`${varenr}`);
};
export const isWagon = (localState, { varenr }) => `${varenr}` === '604';
export const getAmountOfAnlaegInVarenr = (localState, { varenr }) => {
  if (`${varenr}` === 'pack_1' || `${varenr}` === 'pack_3') {
    return 1;
  }
  if (`${varenr}` === 'pack_13' || `${varenr}` === 'pack_4') {
    return 2;
  }
  if (`${varenr}` === 'pack_ultimate') {
    return 3;
  }
  return 0;
};

const discountAmountVarenr = {
  21212: true,
  21222: true,
  21232: true,
};
export const isDiscountAmountItem = (localState, { varenr }) => discountAmountVarenr[varenr];

export const isKulsyre = (localState, { varenr }) => {
  const item = getItemFromLocalState(localState, { varenr });
  if (!item || !item.values.regularName) {
    return false;
  }
  return `${varenr}` === '8945' || `${varenr}`=== '8941' || item.values.regularName.toLowerCase().indexOf('kulsyre') !== -1;
};

export const isLeje = (localState, { varenr }) => getItemTypeFromLocalState(localState, { varenr }) === '100';

export const isDraughtmaster = (localState, { varenr }) => `${varenr}` === '2614';


export const isFreeItem = (localState, { varenr }) => getItemTypeFromLocalState(localState, { varenr }) === '96';

export const isReservedelOrDraughtmaster = (localState, { varenr }) => (
  getItemTypeFromLocalState(localState, { varenr }) === '95'
  || isFreeItem(localState, { varenr })
  || isDraughtmaster(localState, { varenr })
  || varenr == '663'
);

export const isPalleItem = (localState, { varenr }) => getItemTypeFromLocalState(localState, { varenr }) === '90';

export const isReturPantItem = (state, { varenr }) => {
  const {
    returPant,
  } = getItemDefaultInformation(varenr) || {};
  return Boolean(returPant);
};

export const getCost = (state, {
  varenr,
  amount,
  singleAmount,
  days = 1,
}) => {
  const item = getItem(state, { varenr });
  const { values: { price, countPrPalle, totalCasePrice } } = item;
  const isLejeItem = isLeje(state.commerce.items, { varenr });
  const isPalle = isPalleItem(state.commerce.items, { varenr });
  const multiplier = isPalle ? countPrPalle : 1;
  const {
    returPant,
  } = getItemDefaultInformation(varenr) || {};
  if (returPant) {
    return 0;
  }
  const lejePeriods = isLejeItem ? Math.ceil(Math.max(days, 1) / 7) : 1;
  return sanitizePrice((varenr === '888') ? totalCasePrice : price) * lejePeriods * (amount + singleAmount) * multiplier;
};

export const getCasePrice = (state, { varenr }) => {
  const item = getItem(state, { varenr });
  const { values: { totalCasePrice } } = item;
  const {
    returPant,
  } = getItemDefaultInformation(varenr) || {};
  if (returPant) {
    return 0;
  }
  return sanitizePrice(totalCasePrice);
};

export const getNormalDeliveryCost = (state) => {
  const item = getItem(state, { varenr: '621' });
  if (!item) {
    return 0;
  }
  const { values: { price } } = item;
  return sanitizePrice(price);
};

export const getBaseFreeDeliveryCost = () => 25000;
/*
export const getBaseFreeDeliveryCost = (state) => {
  const item = getItem(state, { varenr: '633' });
  if (!item) {
    return 0;
  }
  const { values: { price } } = item;
  return sanitizePrice(price);
};
*/
export const getDeliveryByPostNordCost = () => 9500;
/*
export const getDeliveryByPostNordCost = (state) => {
  const item = getItem(state, { varenr: '620' });
  if (!item) {
    return 0;
  }
  const { values: { price } } = item;
  return sanitizePrice(price);
};
*/

export const getChristmasDeliveryCost = (state) => {
  const item = getItem(state, { varenr: '622' });
  if (!item) {
    return getNormalDeliveryCost(state);
  }
  const { values: { price } } = item;
  return sanitizePrice(price);
};
export const getChristmasExtraDeliveryCost = state => getChristmasDeliveryCost(state) - getNormalDeliveryCost(state);

export const getSundayDeliveryExtraCost = (state) => {
  const item = getItem(state, { varenr: '623' });
  if (!item) {
    return 0;
  }
  const { values: { price } } = item;
  return sanitizePrice(price);
};
export const getSamedayDeliveryExtraCost = (state) => {
  const item = getItem(state, { varenr: '624' });
  if (!item) {
    return 0;
  }
  const { values: { price } } = item;
  return sanitizePrice(price);
};

export const getExtraCostsForDelivery = createSelector(
  getChristmasExtraDeliveryCost,
  getSundayDeliveryExtraCost,
  getSamedayDeliveryExtraCost,
  (christmas, sunday, sameday) => ({
    christmas,
    sunday,
    sameday,
  }),
);

export const getSingleItemPant = (state, { varenr }) => {
  const item = getItem(state, { varenr });
  if (!item) {
    return 0;
  }
  const { values: { deposit } } = item;
  const pantValue = sanitizePrice(deposit);
  return pantValue;
};
export const getItemName = (state, { varenr }) => {
  const {
    name,
  } = getItemDefaultInformation(varenr);
  return name;
};

export const getTotalColliAmount = (state, { varenr }) => {
  const item = getItem(state, { varenr });
  if (!item) {
    return 1;
  }
  const { values: { countPrColli, countPrPalle } } = item;
  let realCountPrColli = parseInt(countPrColli || '1', 10);
  if (Number.isNaN(realCountPrColli)) {
    realCountPrColli = 1;
  }
  const isPalle = isPalleItem(state.commerce.items, { varenr });
  if (isPalle) {
    return realCountPrColli * (countPrPalle || 1);
  }
  return realCountPrColli;
};
export const getAlcoholPercentage = (state, { varenr }) => {
  const item = getItem(state, { varenr });
  if (!item) {
    return 0;
  }
  const { values: { alcoholPercentage } } = item;
  return sanitizeMeasurement(alcoholPercentage);
};
export const getPant = (state, { varenr, amount, singleAmount }) => {
  const {
    returPant,
  } = getItemDefaultInformation(varenr) || {};
  if (returPant) {
    return 0;
  }
  const singlePant = getSingleItemPant(state, { varenr });
  const totalColliAmount = getTotalColliAmount(state, { varenr });
  return (
    (singlePant * totalColliAmount * (amount + singleAmount))
  );
};

export const getReturPant = (state, { varenr, amount }) => {
  const {
    returPant,
  } = getItemDefaultInformation(varenr) || {};
  if (!returPant) {
    return 0;
  }
  const item = getItem(state, { varenr });
  const { values: { totalCasePrice } } = item;
  return sanitizePrice(totalCasePrice) * amount;
};

export const getSingleFustagePant = (state, { varenr }) => {
  const item = getItem(state, { varenr });
  const {
    returPant,
  } = getItemDefaultInformation(varenr) || {};
  if (!item || returPant) {
    return 0;
  }
  const { values: { totalCasePrice } } = item;
  const pantValue = sanitizePrice(totalCasePrice);
  return pantValue;
};

export const getSingleKassePant = (state, { varenr }) => {
  const item = getItem(state, { varenr });
  if (!item) {
    return 0;
  }
  const { values: { caseDeposit } } = item;
  const {
    returPant,
  } = getItemDefaultInformation(varenr) || {};
  if (!caseDeposit || returPant) {
    return 0;
  }
  const pantValue = sanitizePrice(caseDeposit);
  return pantValue;
};

export const getItemFustagePant = (state, { varenr, amount }) => {
  const singlePant = getSingleFustagePant(state, { varenr });
  return singlePant * amount;
};

export const getItemLiter = (state, { varenr }) => {
  const item = getItem(state, { varenr });
  if (!item) {
    return 0;
  }
  const { values: { liter } } = item;
  if (liter) {
    const literNumber = sanitizeMeasurement(liter);
    if (!Number.isNaN(literNumber)) {
      return literNumber;
    }
  }
  return 0;
};

// Create a const called getPrice that returns the price
export const getPrice = (state, { varenr }) => {
  const item = getItem(state, { varenr });
  if (!item) {
    return 0;
  }
  const { values: { price } } = item;
  return sanitizePrice(price) / 100;
};

// Create a const where getPrice / getItemLiter is returned
export const getPricePerLiter = createSelector(
  getPrice,
  getItemLiter,
  (price, liter) => {
    if (liter === 0) {
      return 0;
    }
    return price / liter;
  },
);

export const getItemWeight = (state, { varenr }) => {
  const item = getItem(state, { varenr });
  if (!item) {
    return 0;
  }
  const {
    values: {
      weight,
      subItems,
      itemType,
      countPrPalle,
    },
  } = item;
  if (subItems) {
    return subItems.reduce(
      (acc, varenr) => acc + getItemWeight(state, { varenr }),
      0,
    );
  }
  if (weight) {
    const weightNumber = sanitizeMeasurement(weight);
    if (!Number.isNaN(weightNumber)) {
      if (getItemTypeIsPalle(itemType)) {
        return (weightNumber * countPrPalle) + getItemWeight(state, { varenr: '888' });
      }
      return weightNumber;
    }
  }
  return 0;
};

export const getItemSplittable = (state, { varenr }) => {
  const item = getItem(state, { varenr });
  if (!item) {
    return 1;
  }
  return parseInt(item.values.splittable, 10) || 1;
};

export const getProductVersion = state => state.commerce.items.productVersion;
export const getPantKasseIdentifier = (state, { varenr }) => {
  const item = getItem(state, { varenr });
  const {
    values: {
      splittable,
      caseType,
      singleNoun,
      containerMaterial,
      caseDeposit,
    },
  } = item;
  return `${splittable}_${caseType}_${singleNoun}_${containerMaterial}_${caseDeposit}`;
};
/* Initlialze */
function convertProduct(product) {
  let convertedProduct = product;
  if (overwriteFields[convertedProduct.varenr]) {
    convertedProduct = {
      ...convertedProduct,
      values: {
        ...convertedProduct.values,
        ...overwriteFields[convertedProduct.varenr],
      },
    };
  }
  if (convertedProduct.values.discount) {
    const sanitizedDiscount = sanitizePrice(convertedProduct.values.discount) * 100;
    if (sanitizedDiscount > 0) {
      const sanitizedPrice = sanitizePrice(convertedProduct.values.price);
      convertedProduct = {
        ...convertedProduct,
        values: {
          ...convertedProduct.values,
          price: sanitizedPrice - sanitizedDiscount,
          beforePrice: sanitizedPrice,
          discount: sanitizedDiscount,
        },
      };
    }
  }
  const alcPctNum = sanitizeMeasurement(convertedProduct.values.alcoholPercentage) || 0;
  convertedProduct = {
    ...convertedProduct,
    values: {
      ...convertedProduct.values,
      alcPctNum,
    },
  };
  return convertedProduct;
}
function convertProducts(products) {
  return products.reduce(
    (acc, product) => {
      acc[product.varenr] = convertProduct(product);
      return acc;
    },
    {},
  );
}

export const setItemsWithConversion = (products, productVersion) => setItems(convertProducts(products), productVersion);

export function getInitialState(existingState = undefined) {
  let items = null;
  let productVersion = null;
  if (!existingState) {
    const products = getArg('products');
    productVersion = getArg('productVersion');
    items = convertProducts(products);
  } else {
    items = existingState.items; // eslint-disable-line
    productVersion = existingState.productVersion; // eslint-disable-line
  }
  if (typeof window !== 'undefined') { // eslint-disable-line
    window.startItems = Object.keys(items).reduce( // eslint-disable-line
      (acc, varenr) => {
        acc[varenr] = true;
        return acc;
      },
      {},
    );
    window.actualItems = Object.keys(items).reduce( // eslint-disable-line
      (acc, varenr) => {
        acc[varenr] = items[varenr];
        return acc;
      },
      {},
    );
  }
  return {
    initialized: true,
    items,
    productVersion,
  };
}

export default function itemsReducer(state = {}, action) {
  switch (action.type) {
    case ITEMS_SET:
      return {
        ...state,
        initialized: true,
        items: action.payload.items,
        productVersion: action.payload.productVersion,
      };
    default:
      return state;
  }
}
