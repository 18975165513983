import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { ActionLinkNegating } from '../../../../../../components/typography';
import { BsCaretDownFill, BsCaretUpFill } from '../../../../../../components/Icons';

const StyledMenuLi = styled.li`
  position: relative;
  display: inline-block;
  margin: 0px;
`;
const StyledMenuDropdownDiv = styled.ul`
  top: 100%;
  margin-top: -1px;
  position: absolute;
  left: 0;
  min-width: 300px;
  background-color: ${({ theme }) => theme.colors.colorWhite};
  box-shadow: 0 5px 10px rgba(0,0,0,0.5);
  z-index: 100;
  padding: 5px 0;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: block;
  color: ${({ theme }) => theme.colors.colorGreyDark};
  &:active, &:hover, &:link, &:visited {
    color: ${({ theme }) => theme.colors.colorGreyDark};
  }
`;
const StyledActionLink = styled(ActionLinkNegating)`
  text-decoration: none;
  display: block;
  color: ${({ theme }) => theme.colors.colorGreyDark};
  &:active, &:hover, &:link, &:visited {
    color: ${({ theme }) => theme.colors.colorGreyDark}
  }
`;
const StyledContentDiv = styled.div`
  transition: color .3s ease;
  &:hover {
    color: ${({ theme }) => theme.hoverColors.colorBlue};
  }
  ${StyledLink}:active &, ${StyledActionLink}:active & {
    color: ${({ theme }) => theme.colors.colorWhite};
    background-color: ${({ theme }) => theme.colors.colorBlue};
  }
  ${({ topMenu }) => (topMenu ? 'padding: 13px 15px;' : 'padding: 15px;')}
`;

const StyledContentDivActive = styled.div`
  color: ${({ theme }) => theme.colors.colorWhite};
  background-color: ${({ theme }) => theme.activeColors.colorBlue};
  ${({ topMenu }) => (topMenu ? 'padding: 13px 15px;' : 'padding: 15px;')}
`;

const StyledIcon = styled.span`
  & > svg {
    position: relative;
    top: 0px;
    font-size: 11px;
  }
`;

// Const Icons based on props up and down
const Icons = ({ up, down }) => {
  if (up) {
    return <StyledIcon><BsCaretUpFill /></StyledIcon>;
  }
  if (down) {
    return <StyledIcon><BsCaretDownFill /></StyledIcon>;
  }
  return '';
};

export default ({
  title,
  expandable,
  expanded,
  children,
  onClick,
  to,
  phone,
  smallScreens,
  topMenu,
}) => {
  if (smallScreens) {
    return '';
  }
  if (expandable) {
    if (expanded) {
      return (
        <StyledMenuLi>
          <StyledActionLink onClick={onClick}>
            <StyledContentDivActive topMenu={topMenu}>
              {title} <Icons up />
            </StyledContentDivActive>
          </StyledActionLink>
          <StyledMenuDropdownDiv>
            {children}
          </StyledMenuDropdownDiv>
        </StyledMenuLi>
      );
    }
    return (
      <StyledMenuLi>
        <StyledActionLink onClick={onClick}>
          <StyledContentDiv topMenu={topMenu}>
            {title} <Icons down />
          </StyledContentDiv>
        </StyledActionLink>
      </StyledMenuLi>
    );
  }
  return (
    <StyledMenuLi>
      <StyledLink to={to} onClick={onClick} phone={phone}>
        <StyledContentDiv topMenu={topMenu}>
          {title}
        </StyledContentDiv>
      </StyledLink>
    </StyledMenuLi>
  );
};
