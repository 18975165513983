// Import External Dependencies
import React from 'react';

// Import Utilities
import Store from './store';
import { BreadCrumbsContainer } from './Breadcrumb';


// Create and export the component
export default class Breadcrumbs extends React.Component {
  constructor(props) {
    super(props);
    this.unsubscribe = null;
  }

  componentWillMount() {
    this.unsubscribe = Store.subscribe(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const breadcrumbs = Store.getState();
    const { disableBreadCrumbsSchema } = this.props;
    return (
      <BreadCrumbsContainer breadcrumbs={breadcrumbs} disableBreadCrumbsSchema={disableBreadCrumbsSchema} />
    );
  }
}
