import styled from 'styled-components/macro';
import {
  marginTheming,
  paddingTheming,
  fontTheming,
  elementTheming,
  removePaddingThemingProps,
  removeMarginThemingProps,
  removeFontThemingProps,
  removeElementThemingProps,
  removeProps,
  combine,
  createComponentWithModifiedProps,
} from '../../style/themes/main';


const removeCustomProps = removeProps();
const removeAllProps = combine(
  removePaddingThemingProps,
  removeMarginThemingProps,
  removeFontThemingProps,
  removeElementThemingProps,
  removeCustomProps,
);

const SmallNoProps = createComponentWithModifiedProps(removeAllProps, 'p', 'Skafte.com.typography.Small');


export default styled(SmallNoProps)`
  h1 &, h2 &, h3 &, h4 &, h5 &, h6 & {
    font-weight: normal;
    line-height: 1;
    color: ${({ theme }) => theme.colors.colorGreyDark};
  }
  h1 &, h2 &, h3 & {
    font-size: 65%;
  }
  ${fontTheming}
  ${paddingTheming}
  ${marginTheming}
  ${elementTheming}
`;
