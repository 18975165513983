import React from 'react';
import styled from 'styled-components/macro';
import Modal from './TooltipModal';
import { LinkStyled } from '../typography';
import { BsFillPlusCircleFill, BsQuestionCircleFill } from '../Icons';

const CloseButton = styled.div`
  color: ${({ theme }) => theme.colors.colorRed};
  border: 2px solid ${({ theme }) => theme.colors.colorWhite};
  position: absolute;
  top: -7px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 40px;
  color: #C73F4A;
  background: white;
  rotate: 45deg;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.hoverColors.colorRed};
  }
`;

const QuestionIcon = styled.div`
  display: inline-block;
  font-size: 16px;
  position: relative;
  top: 3px;
  left: 7px;
  transform: scale(1.2);
`;

const LinkToolTip = styled(LinkStyled)`
  color: ${({ theme }) => theme.colors.colorBlue};
  font-weight: 500;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.hoverColors.colorBlue};
    text-decoration: underline;
  }
`;

export default class TooltipModalLink extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onClose = this.onClose.bind(this);
    this.state = {
      open: false,
    };
  }

  onClick() {
    this.setState({ open: true });
  }

  onClose() {
    this.setState({ open: false });
  }

  render() {
    const {
      title,
      children,
      maxWidth,
      ...props
    } = this.props;
    const link = (
      <LinkToolTip action noStyle onClick={this.onClick} {...props}>
        {title && title}<QuestionIcon><BsQuestionCircleFill /></QuestionIcon>
      </LinkToolTip>
    );
    const { open } = this.state;
    if (open) {
      return (
        <React.Fragment>
          {link}
          <Modal
            isOpen
            onRequestClose={this.onClose}
            maxWidth={maxWidth}
          >
            {children}
            <CloseButton onClick={this.onClose}><BsFillPlusCircleFill /></CloseButton>
          </Modal>
        </React.Fragment>
      );
    }
    return link;
  }
}
