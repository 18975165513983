import React from 'react';
import { Col } from 'react-grid-system';
import styled from 'styled-components/macro';

const ColNoProps = ({
  h100p,
  grey,
  white,
  grey2,
  blue,
  green,
  textCenter,
  alignCenter,
  margin,
  marginTop,
  marginBottom,
  textRight,
  textLeft,
  stickToBottom,
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  minHeight,
  p0,
  overflow,
  relative,
  width100,
  zIndex,
  bold,
  borderRight,
  hide,
  fontSize,
  lineHeight,
  doubleColumn,
  ...props
}) => (
  <Col {...props} />
);

export default styled(ColNoProps)`
  ${({
    theme,
    grey,
    grey2,
    white,
    green,
  }) => {
    if (grey) {
      return `background-color: ${theme.colors.colorGreyLight};`;
    }
    if (grey2) {
      return `background-color: ${theme.colors.colorGreyLightAlt};`;
    }
    if (white) {
      return `background-color: ${theme.colors.colorWhite};`;
    }
    if (green) {
      return `background-color: ${theme.colors.colorGreenHighlight};`;
    }
    return '';
  }}
  ${({ textCenter }) => {
    if (textCenter) {
      return `
        & {
          text-align: center;
        }
        & p {
          text-align: center;
        }
      `;
    }
    return '';
  }}
  ${({ alignCenter }) => {
    if (alignCenter) {
      return `
        & {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          & > * {
            flex-basis: auto;
            flex-grow: 0;
          }
        }
      `;
    }
    return '';
  }}
  ${({ alignSelfStart }) => {
    if (alignSelfStart) {
      return `
        & {
          & > * {
            align-self: flex-start;
          }
        }
      `;
    }
    return '';
  }}
  ${({ verticalMiddle }) => {
    if (verticalMiddle) {
      return `
        & {
          align-self: center;
        }
      `;
    }
    return '';
  }}
  
  ${({ overflow }) => {
    if (overflow) {
      return 'overflow: hidden;';
    }
    return 'overflow: visible !important;';
  }}
  ${({ padding }) => {
    if (padding) {
      return `padding: ${padding} !important;`;
    }
    return 'padding: 15px;';
  }}
  ${({ bold }) => {
    if (bold) {
      return `
        & {
          font-weight: bold;
        }
        & * {
          font-weight: bold;
        }
      `;
    }
    return '';
  }}
  ${({ doubleColumn }) => doubleColumn && `
    column-count: 2;
    column-gap: 30px;

    @media (max-width: 991px) {
      column-count: 1;
    }
  `}
  
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop} !important;`}
  ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight} !important;`}
  ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft} !important;`}
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom} !important;`}
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop} !important;`}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom} !important;`}
  ${({ h100p }) => h100p && 'height: 100% !important;'}
  ${({ relative }) => relative && 'position: relative;'}
  ${({ textRight }) => textRight && 'text-align: right !important;'}
  ${({ textLeft }) => textLeft && 'text-align: left !important;'}
  ${({ h100p }) => h100p && 'height: 100%;'}
  ${({ height }) => height && `height: ${height};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight};`}
  ${({ borderRight }) => borderRight && `border-right: ${borderRight};`}
  ${({ minHeight }) => minHeight && `min-height: ${minHeight} !important;`}
  ${({ width100 }) => width100 && 'width: 100% !important; max-width: 100% !important; flex: 0 0 100% !important;'}
  ${({ p0 }) => p0 && 'padding: 0px 15px;'}
  ${({ stickToBottom }) => stickToBottom && `position: absolute !important; bottom: ${stickToBottom};`}
  ${({ aligner }) => aligner && 'display: flex !important; align-items: center; justify-content: center;'}
  ${({ alignerTop }) => alignerTop && 'align-self: flex-start;'}
  ${({ alignerBottom }) => alignerBottom && 'align-self: flex-end;'}
  ${({ hide }) => hide && 'display: none;'}
`;
