import { put, takeEvery } from 'redux-saga/effects';
import { LOAD_TEST_DATA, change } from './actions';


function* loadTestData() {
  window.__IS_TEST__ = true; // eslint-disable-line
  yield put(change('invoice-details', 'phonenumber', '26587323'));
  yield put(change('invoice-details', 'name', 'Test bestilling'));
  yield put(change('invoice-details', 'attName', 'Test navn'));
  yield put(change('invoice-details', 'address', 'Test addresse'));
  yield put(change('invoice-details', 'zipCode', '2300'));
  yield put(change('invoice-details', 'email', 'peter@skafte.dk'));
  yield put(change('invoice-details', 'comment', 'Test bestilling - ingen email'));
}

export default function* rootSaga() {
  yield takeEvery(LOAD_TEST_DATA, loadTestData);
}
