import React from 'react';
import styled from 'styled-components/macro';
import {
  marginTheming,
  paddingTheming,
  elementTheming,
  fontTheming,
} from '../style/themes/main';
import { BsCalendarDate, BsPlug, MdOutlineWarehouse, RxRulerSquare } from './Icons';

const IconBoxWrap = styled.div`
  display: flex;
  gap: 15px;
  padding: 15px 0;
`;
const IconWrap = styled.div`
  & > svg {
    width: 40px;
    height: auto;
  }
`;
const IconBoxNoStyle = ({
  children,
  dimension,
  calendar,
  storage,
  power,
  ...otherProps
}) => (
  <IconBoxWrap {...otherProps}>
    <IconWrap>
      {dimension && <RxRulerSquare />}
      {storage && <MdOutlineWarehouse />}
      {power && <BsPlug />}
      {calendar && <BsCalendarDate />}
    </IconWrap>
    <div>
      {children}
    </div>
  </IconBoxWrap>
);

const StaticallyStyledIconBox = styled(IconBoxNoStyle)`
  font-size: 16px;

  ${({ theme }) => `
    color:  ${theme.colors.colorGreen};
    & span {
      margin-top: 5px;
      color:  ${theme.colors.colorGreyDark};
    }
  `}
`;
const CustomStyledIconBox = styled(props => <StaticallyStyledIconBox {...props} />)`
`;

export default styled(props => <CustomStyledIconBox {...props} />)`
  ${marginTheming}
  ${paddingTheming}
  ${elementTheming}
  ${fontTheming}
`;
