
const actionPrefix = 'SKAFTE/COMMERCE/WATER/';

export const LID_SET = `${actionPrefix}LID_SET`;
export const FLAVOUR_SET = `${actionPrefix}FLAVOUR_SET`;
export const SIZE_SET = `${actionPrefix}SIZE_SET`;
export const IMAGE_UPLOAD = `${actionPrefix}IMAGE_UPLOAD`;
export const WATER_DEFINITIONS_SET = `${actionPrefix}WATER_DEFINITIONS_SET`;
export const WATER_DELIVERY_SET = `${actionPrefix}WATER_DELIVERY_SET`;
export const COUNT_SET = `${actionPrefix}COUNT_SET`;

export const setWaterDelivery = waterDelivery => ({
  type: WATER_DELIVERY_SET,
  payload: waterDelivery,
});

export const setCount = count => ({
  type: COUNT_SET,
  payload: count,
});

export const setLid = name => ({
  type: LID_SET,
  payload: name,
});

export const setFlavour = name => ({
  type: FLAVOUR_SET,
  payload: name,
});

export const setSize = name => ({
  type: SIZE_SET,
  payload: name,
});

export const uploadImage = id => ({
  type: IMAGE_UPLOAD,
  payload: id,
});

export const setWaterDefinitions = waterDefinitions => ({
  type: WATER_DEFINITIONS_SET,
  payload: waterDefinitions,
});
