import smartlookClient from 'smartlook-client';
import { includeSentry } from './sentry';
import getArg from './args';

export const ga = (...args) => {
  if (window.gtag) { // eslint-disable-line
    console.log(...['Google Analytics Prod', ...args]);
    window.gtag(...args); // eslint-disable-line
  } else {
    console.log(...['Google Analytics Dev', ...args]); // eslint-disable-line
  }
};

export const bing = (...args) => {
  const bingDataLayer = getBingDataLayer();
  bingDataLayer.push(...args);
  if (window.uetq) { // eslint-disable-line
    console.log(...['Bing Prod', ...args]);
  } else {
    console.log(...['Bing Dev', ...args]); // eslint-disable-line
  }
}
export const getDataLayer = () => {
  if (window.dataLayer) {
    return window.dataLayer;
  }
  return [];
};

export const getBingDataLayer = () => {
  window.uetq = window.uetq || [];
  return window.uetq;
}

export const pushToDataLayer = (obj) => {
  if (window && window.console && window.console.log) {
  //  window.console.log('Pushing to dataLayer', obj);
  }
  getDataLayer().push(obj);
};

export const fbq = (...args) => {
  if (window.fbq) { // eslint-disable-line
    window.fbq(...args); // eslint-disable-line
  } else {
    console.log(...['Facebook pixel', ...args]); // eslint-disable-line
  }
};

export const clarity = (...args) => {
  if (window.clarity) { // eslint-disable-line
    window.clarity(...args); // eslint-disable-line
  } else {
    console.log(...['Clarity', ...args]); // eslint-disable-line
  }
};

export const chooseOptInOrOut = (obj) => {
  const { localStorage } = window;
  console.log('Chnaing optinon', obj);
  localStorage.setItem('skafteConsentv2', JSON.stringify(obj));
};

export const optScripts = () => {
  const { analytics, marketing } = consentValue() || { analytics: false, marketing: false };
  ga('consent', 'update', {
    ad_storage: marketing ? 'granted' : 'denied',
    ad_user_data: marketing ? 'granted' : 'denied',
    ad_personalization: marketing ? 'granted' : 'denied',
    analytics_storage: analytics ? 'granted' : 'denied',

  });
  bing('consent', 'update', {
    ad_storage: marketing ? 'granted' : 'denied',
    analytics_storage: analytics ? 'granted' : 'denied',
  });
  fbq('consent', marketing ? 'grant' : 'revoke');
  if (analytics) {
    try {
      clarity('consent');
    } catch (e) {
      // not used
    }
  }
  if (includeSentry()) {
    try {
      if (analytics) {
        smartlookClient.init('0b283bd71a9bc8e41b7d1e52a526ae212357f0c9');
        smartlookClient.record({ forms: true, numbers: true });
      } else {
        smartlookClient.disable();
      }
    } catch (e) {
      // not used
    }
  }
}


export const consentValue = () => {
  if (!getArg('browser')) {
    return null;
  }
  const { localStorage } = window;
  const val = localStorage.getItem('skafteConsentv2');
  if (val === null) {
    return null;
  }
  return JSON.parse(val);
};

export const hasMarketingConsent = () => {
  const consent = consentValue();
  return consent && consent.marketing;
}

export const hasAnalyticsConsent = () => {
  const consent = consentValue();
  return consent && consent.analytics;
}

export function convert(total) {
  if (window.gtag && hasMarketingConsent()) { // eslint-disable-line
    window.google_conversion_id = 1071329339; // eslint-disable-line
    window.google_conversion_language = "en"; // eslint-disable-line
    window.google_conversion_format = "3"; // eslint-disable-line
    window.google_conversion_color = "ffffff"; // eslint-disable-line
    window.google_conversion_label = 'pXMxCOuGnAcQu-Ds_gM'; // eslint-disable-line
    window.google_conversion_value = total; // eslint-disable-line
    window.google_conversion_currency = "DKK"; // eslint-disable-line
    window.google_remarketing_only = false; // eslint-disable-line
    const script = document.createElement("script"); // eslint-disable-line

    script.src = "//www.googleadservices.com/pagead/conversion.js"; // eslint-disable-line
    script.async = true;
    document.body.appendChild(script); // eslint-disable-line
  } else {
    console.log('Google Conversion registered'); // eslint-disable-line
  }
  // fbq('track', 'Purchase', { value: `${total}`, currency: 'DKK' });
}
