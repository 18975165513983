import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from './routes';
import RouteRenderer from './RouteRenderer';

const children = routes.map(
  (route) => {
    const { path, catchAll } = route;
    return (
      <Route
        path={path}
        exact={!catchAll}
        render={props => (
          <RouteRenderer {...props} skafteRoute={route} key="routerRenderer" />
        )}
      />
    );
  },
);
export default () => (
  <Switch>
    {children}
  </Switch>
);
