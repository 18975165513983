import React from 'react';
import { sanitizeMeasurement } from '../../lib/helpers';
import LiterFormat from './LiterFormat';

export default ({
  liter, amount, space, noSuffix,
}) => {
  const sanVal = sanitizeMeasurement(liter);
  return (
    <LiterFormat
      cl
      liter={(sanVal / amount)}
      space={space}
      noSuffix={noSuffix}
    />
  );
};
