import React, { createContext, useContext, useState } from 'react';


const SideMenuContext = createContext(null);

export const useSideMenuContext = () => useContext(SideMenuContext);

export const enhanceWithSideMenuContext = Component => React.forwardRef((props, ref) => <Component ref={ref} {...props} sideMenuContext={useSideMenuContext[0]} />);

export default (props) => {
  const s = useState(null);
  return (
    <SideMenuContext.Provider {...props} value={s} />
  );
};
