import { deliveryTypes } from '../commerce/delivery';

const prefix = 'SKAFTE/ORDER/';

export const ORDER_SET = `${prefix}ORDER_SET`;

export const setOrder = order => ({
  type: ORDER_SET,
  payload: order,
});

const initialState = {
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ORDER_SET: {
      const { orderRef } = action.payload;
      return {
        ...state,
        [orderRef]: action.payload,
      };
    }
    default:
      return state;
  }
};

export const getOrder = (state, { orderRef }) => state.order[orderRef];

export const isDelivery = ({ deliveryChoice: { type } }) => type !== deliveryTypes.NO;

export const getCustomer = ({ customer }) => customer;
