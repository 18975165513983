import { connect } from 'react-redux';
import { MomsSelector } from '../../components/commerce';
import { getMoms, setMoms } from '../../features/commerce';

const mapStateToProps = state => ({
  on: getMoms(state),
});
const mapDispatchToProps = dispatch => ({
  change: on => dispatch(setMoms(on)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MomsSelector);
