import { useEffect } from 'react';
import { connect } from 'react-redux';
import { doAnalyticsLogViewProduct } from '../../features/actions';

const ItemLoggerComponent = ({
  varenr,
  viewProduct,
}) => {
  useEffect(
    () => {
      if (varenr) {
        viewProduct(varenr);
      }
    },
    [varenr, viewProduct],
  );
  return null;
};

export default connect(() => {}, dispatch => ({
  viewProduct: varenr => dispatch(doAnalyticsLogViewProduct(varenr)),
}))(ItemLoggerComponent);
