
export const deliveryTypes = {
  no: 'NO',
  delivery: 'DELIVERY',
  deliveryAndPickup: 'PAID',
};

export const getDeliveryTypeText = (deliveryType) => {
  switch (deliveryType) {
    case deliveryTypes.no:
      return 'Henter selv';
    case deliveryTypes.delivery:
      return 'Levering';
    case deliveryTypes.deliveryAndPickup:
      return 'Levering & afhentning';
    default:
      return null;
  }
};
