import {
  put, take, select, takeEvery, all,
} from 'redux-saga/effects';
import makeUid from 'uid';
import smartlookClient from 'smartlook-client';
import { captureMessage, configureScope, includeSentry } from '../../lib/sentry';
import { STARTUP_COMPLETE } from '../actions';

const initialState = {
  userId: null,
  sessionId: null,
  acceptAlkohol: false,
  submitAlkoholError: false,
};

const actionPrefix = 'SKAFTE/USER/';
export const SET_USER_ID = `${actionPrefix}SET_USER_ID`;
export const setUserId = userId => ({
  type: SET_USER_ID,
  payload: userId,
});

export const SET_USER_AND_SESSION_ID = `${actionPrefix}SET_USER_AND_SESSION_ID`;
export const setUserAndSessionId = payload => ({
  type: SET_USER_AND_SESSION_ID,
  payload,
});

export const SET_SESSION_ID = `${actionPrefix}SET_SESSION_ID`;
export const setSessionId = sessionId => ({
  type: SET_SESSION_ID,
  payload: sessionId,
});
export const LOG_EVENT = `${actionPrefix}LOG_EVENT`;

export const logEvent = event => ({
  type: LOG_EVENT,
  payload: event,
});


export const SET_ACCEPT_ALKOHOL = `${actionPrefix}SET_ACCEPT_ALKOHOL`;
export const setAcceptAlkohol = acceptAlkohol => ({
  type: SET_ACCEPT_ALKOHOL,
  payload: acceptAlkohol,
});

export const SET_SUBMIT_ALCOHOL_ERROR = `${actionPrefix}SET_SUBMIT_ALCOHOL_ERROR`;
export const setSubmitAlcoholError = submitAlkoholError => ({
  type: SET_SUBMIT_ALCOHOL_ERROR,
  payload: submitAlkoholError,
});

export const getUserId = ({ user: { userId } }) => userId;
export const getSessionId = ({ user: { sessionId } }) => sessionId;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_AND_SESSION_ID:
      return {
        ...state,
        userId: action.payload.userId,
        sessionId: action.payload.sessionId,
      };
    case SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    case SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.payload,
      };
    case SET_ACCEPT_ALKOHOL:
      return {
        ...state,
        acceptAlkohol: action.payload,
      };
    case SET_SUBMIT_ALCOHOL_ERROR:
      return {
        ...state,
        submitAlkoholError: action.payload,
      };
    default:
      return state;
  }
};

export function* startupComplete() {
  yield take(STARTUP_COMPLETE);
  let userId = yield select(getUserId);
  const sessionId = makeUid();
  if (userId === null) {
    userId = makeUid();
    yield put(setUserAndSessionId({
      userId,
      sessionId,
    }));
  } else {
    yield put(setSessionId(sessionId));
  }
  if (includeSentry()) {
    smartlookClient.identify(userId, { sessionId });
    configureScope((scope) => {
      scope.setTag('userId', userId);
      scope.setTag('sessionId', sessionId);
    });
  }
}


function logEventSaga({ payload: event }) {
  if (includeSentry()) {
    const eventString = JSON.stringify(event);
    captureMessage(eventString);
  }
}

export function* userSagas() {
  yield all([
    startupComplete(),
    takeEvery(LOG_EVENT, logEventSaga),
  ]);
}


export const getAcceptAlkhol = state => state.user.acceptAlkohol;

export const getSubmitAlkoholError = state => state.user.submitAlkoholError;
