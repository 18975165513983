import React from 'react';
import deepEqual from 'deep-equal';
import { Dispatch } from './store';
import Breadcrumb from './Breadcrumb';


const doDispatch = (action, breadcrumbs) => Dispatch({
  type: action,
  payload: breadcrumbs,
});
const dispatchNewBreadCrumbs = (props) => {
  const { breadcrumbs, title } = props;
  const actualBreadcrumbs = title
    ? [...breadcrumbs, <Breadcrumb link={window.location.pathname} title={title}>{title}</Breadcrumb>]
    : breadcrumbs;
  doDispatch('SET_CRUMBS', actualBreadcrumbs);
};

// Create and export the component
export default class DefineBreadcrumbs extends React.Component {
  componentDidMount() {
    dispatchNewBreadCrumbs(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { breadcrumbs, title } = nextProps;
    // eslint-disable-next-line react/destructuring-assignment
    if (!deepEqual(breadcrumbs, this.props.breadcrumbs) || title !== this.props.title) {
      dispatchNewBreadCrumbs(nextProps);
    }
  }

  componentWillUnmount() {
    doDispatch(
      'SET_CRUMBS',
      [],
    );
  }

  render() {
    return null;
  }
}
