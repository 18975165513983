import React from "react";
import styled from "styled-components/macro";
import { BsCheckCircle } from "../Icons";

const ListNoProps = ({
  check,
  borderGroup,
  floating,
  ordered,
  children,
  ...otherProps
}) => {
  const IconComponent =
    check && !borderGroup && !ordered ? (
      <CheckCircle>
        <BsCheckCircle />
      </CheckCircle>
    ) : (
      !floating && !ordered && <CustomDot />
    );

  const modifiedChildren = React.Children.toArray(children).map(
    (child, index) => {
      if (typeof child === "string") {
        return (
          <li key={index}>
            {IconComponent}
            <div>{child}</div>
          </li>
        );
      } else {
        return borderGroup ? (
          <li key={index}>
            <div>{child.props.children}</div>
          </li>
        ) : (
          <li key={index}>
            {IconComponent}
            {ordered ? `${index + 1}.` : null}
            <div>{child.props.children}</div>
          </li>
        );
      }
    }
  );

  const ListComponent = ordered ? "ol" : "ul";

  return <ListComponent {...otherProps}>{modifiedChildren}</ListComponent>;
};

const CheckCircle = styled.div`
  color: ${({ theme }) => theme.colors.colorBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
`;

const CustomDot = styled.div`
  background-color: ${({ theme }) => theme.colors.colorBlue};
  width: 7px;
  height: 7px;
  border-radius: 50%;
  flex-shrink: 0;
  position: relative;
  top: 9px;
  left: 0px;
`;

export const List = styled(ListNoProps)`
  ${({ borderGroup, floating, fontSize, theme }) =>
    borderGroup
      ? `
    display: block;
    border: 2px solid ${theme.colors.colorGreyLightAlt};
    border-radius: 5px;
    li {
      font-size: ${fontSize || "16px"};
      display: block;
      padding: 10px 15px;
      border-bottom: 2px solid ${theme.colors.colorGreyLightAlt};

      &:last-child {
        border-bottom: none;
      }
    }
  `
      : floating
      ? `
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
  `
      : `
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;
    margin-top: 5px;
    list-style: none;

    li {
      font-size: ${fontSize || "16px"};
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 10px;
      line-height: 1.5;
    }
  `}
`;
