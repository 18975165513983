import { VALIDATE, VALIDATE_FIELD } from './actions';

const initialState = {
  validating: false,
  show: false,
  message: null,
  forms: {
    invoice: {},
    delivery: {},
    ean: {},
  },
  delivery: {
  },
};
export default function validateReducer(state = initialState, action) {
  switch (action.type) {
    case `${VALIDATE}_REQUEST`:
      return {
        ...state,
        validating: true,
      };
    case `${VALIDATE}_SUCCESS`:
      return {
        ...state,
        ...action.payload,
        validating: false,
      };
    case `${VALIDATE_FIELD}_SUCCESS`: {
      const { container } = action.payload;
      if (container === 'forms') {
        const { form, field, message } = action.payload;
        return {
          ...state,
          forms: {
            ...state.forms,
            [form]: {
              ...state.forms[form],
              [field]: message,
            },
          },
        };
      }
      if (container === 'delivery') {
        const { message } = action.payload;
        return {
          ...state,
          delivery: {
            ...state.delivery,
            ...message,
          },
        };
      }
      return state;
    }
    default:
      return state;
  }
}
