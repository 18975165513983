import React from 'react';
import { Image, ImagePopup } from '../../Image';
import { Paragraph, LinkStyled } from '../../typography';
import { Row, Col } from '../../grid';
import { ScreenSizeDetector } from '../../../lib/helpers';

export default class PackImageSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chosenIndex: 0,
    };
  }

  chooseNewImage(index) {
    this.setState({ chosenIndex: index });
  }

  render() {
    const { packImages, imageMaxHeight, productPage } = this.props;
    const { chosenIndex } = this.state;
    const {
      name, imageDescription, imageMaxHeight: specificImageMaxHeight,
    } = packImages[chosenIndex];
    return (
      <React.Fragment>
        <ScreenSizeDetector imageWidth={productPage ? [[575, '600px'], [767, '600px'], [991, '600px'], [1199, '600px'], '600px'] : [[575, '200px'], [767, '140px'], [991, '200px'], [1199, '200px'], '245px']}>
          <ImagePopup
            name={name}
            maxHeight={productPage ? '400px' : specificImageMaxHeight || imageMaxHeight}
            popupImageHeight="550px"
            popupMaxWidth="550px"
            className="bucketAnimation"
          />
        </ScreenSizeDetector>
        <Paragraph>
          {imageDescription && imageDescription()}
        </Paragraph>
        {packImages.length > 1 && (
          <Row>
            <Col xs={12} padding="0" textCenter>
              {packImages.map(
                ({ name }, index) => (
                  <LinkStyled action noStyle onClick={() => this.chooseNewImage(index)}>
                    <Image
                      maxHeight="42px"
                      imageHeight="42px"
                      name={name}
                      marginLeft={index === 0 ? undefined : '5px'}
                      opacity={index === chosenIndex ? 0.3 : 1}
                      key={index}
                    />
                  </LinkStyled>
                ),
              )}
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}
