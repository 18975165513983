const actionPrefix = "SKAFTE/COMMERCE/BUCKET/";
export const ITEM_AMOUNT_SET = `${actionPrefix}ITEM_AMOUNT_SET`;
export const ITEM_SINGLE_AMOUNT_SET = `${actionPrefix}ITEM_SINGLE_AMOUNT_SET`;
export const ITEM_AMOUNT_INCREMENT = `${actionPrefix}ITEM_AMOUNT_INCREMENT`;
export const ITEM_AMOUNT_DECREMENT = `${actionPrefix}ITEM_AMOUNT_DECREMENT`;
export const PALLE_AMOUNT_SET = `${actionPrefix}PALLE_AMOUNT_SET`;
export const HANE_AMOUNT_SET = `${actionPrefix}HANE_AMOUNT_SET`;
export const DISCOUNT_AMOUNT_SET = `${actionPrefix}DISCOUNT_AMOUNT_SET`;
export const EMPTY_BUCKET = `${actionPrefix}EMPTY_BUCKET`;
export const WAGON_AMOUNT_SET = `${actionPrefix}WAGON_AMOUNT_SET`;
export const ACCEPT_ROYAL_UNIBREW = `${actionPrefix}ACCEPT_ROYAL_UNIBREW`;
export const ACCEPT_HEINEKEN = `${actionPrefix}ACCEPT_HEINEKEN`;
export const ACCEPT_HERSLEV = `${actionPrefix}ACCEPT_HERSLEV`;

export const emptyBucket = () => ({
  type: EMPTY_BUCKET,
});
export const setDiscountAmount = (amount) => ({
  type: DISCOUNT_AMOUNT_SET,
  payload: amount,
});

export const setPalleAmount = (amount) => ({
  type: PALLE_AMOUNT_SET,
  payload: amount,
});

export const setHaneAmount = (amount) => ({
  type: HANE_AMOUNT_SET,
  payload: amount,
});

export const setWagonAmount = (amount) => ({
  type: WAGON_AMOUNT_SET,
  payload: amount,
});

export const setItemAmount = (varenr, amount) => ({
  type: ITEM_AMOUNT_SET,
  payload: {
    varenr: `v${varenr}`,
    amount: typeof amount === "string" ? parseInt(amount, 10) : amount,
  },
});

export const setItemSingleAmount = (varenr, singleAmount) => ({
  type: ITEM_SINGLE_AMOUNT_SET,
  payload: {
    varenr: `v${varenr}`,
    singleAmount:
      typeof singleAmount === "string"
        ? parseFloat(singleAmount)
        : singleAmount,
  },
});

export const incrementItemAmount = (varenr) => ({
  type: ITEM_AMOUNT_INCREMENT,
  payload: `v${varenr}`,
});

export const decrementItemAmount = (varenr) => ({
  type: ITEM_AMOUNT_DECREMENT,
  payload: `v${varenr}`,
});

export const acceptRoyalUnibrew = (accept) => ({
  type: ACCEPT_ROYAL_UNIBREW,
  payload: accept,
});

export const acceptHeineken = (accept) => ({
  type: ACCEPT_HEINEKEN,
  payload: accept,
});

export const acceptHerslev = (accept) => ({
  type: ACCEPT_HERSLEV,
  payload: accept,
});
