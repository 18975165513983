import React from 'react';
import styled from 'styled-components/macro';
import { Redirect } from 'react-router-dom';
import {
  marginTheming,
  paddingTheming,
  elementTheming,
  fontTheming,
  borderTheming,
  removePaddingThemingProps,
  removeMarginThemingProps,
  removeElementThemingProps,
  removeFontThemingProps,
  removeProps,
  combine,
  createComponentWithModifiedProps,
  removeBorderThemingProps,
} from '../../style/themes/main';

const removeCustomProps = removeProps();
const removeAllProps = combine(
  removePaddingThemingProps,
  removeMarginThemingProps,
  removeElementThemingProps,
  removeFontThemingProps,
  removeCustomProps,
  removeBorderThemingProps,
);

class Tr extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
    this.onClick = this.onClick.bind(this);
  }
  onClick() {
    this.setState({
      redirect: true,
    });
  }
  render() {
    const {
      to,
      onClick,
      ...props
    } = this.props;
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to={to} push />;
    }
    return (
      <tr {...props} onClick={to ? this.onClick : onClick} />
    );
  }
}
const TrNoProps = createComponentWithModifiedProps(removeAllProps, Tr, 'Skafte.com.tables.Td');

export default styled(TrNoProps)`
  text-align: left;
  ${({ to, onClick }) => (to || onClick) && `
    cursor: pointer;
  `}
  ${marginTheming}
  ${paddingTheming}
  ${elementTheming}
  ${fontTheming}
  ${borderTheming}
`;
