import React from 'react';
import ScreenSizeDetector from './ScreenSizeDetector';
import updateWhenNotDeepEqual from './UpdateWhenNotDeepEqual';

const ScreenSizeSwitchInner = React.forwardRef(({ screenSizeComponent: Component, ...otherProps }, ref) => (
  <Component {...otherProps} ref={ref} />
));
export default (args) => {
  const Component = React.forwardRef((props, ref) => (
    <ScreenSizeDetector screenSizeComponent={args}>
      <ScreenSizeSwitchInner {...props} ref={ref} />
    </ScreenSizeDetector>
  ));
  return updateWhenNotDeepEqual(Component);
};
