
export function sanitizePrice(price, keepDecimal) {
  if (!price) {
    return 0;
  }
  if (price.indexOf && price.indexOf(',') > 0 && !keepDecimal) {
    return parseFloat(price.substring(0, price.indexOf(',')));
  } if (price.indexOf && price.indexOf(',') > 0 && keepDecimal) {
    return parseFloat(price.replace(',', '.'));
  }
  return parseFloat(price);
}

export function getPriceWithMoms(price, moms, keepDecimal, preMultiplier = 1) {
  if (moms) {
    return sanitizePrice(price, keepDecimal) * preMultiplier;
  }
  return Math.round(sanitizePrice(price, keepDecimal) * 0.8 * preMultiplier);
}
