import React from 'react';
import styled from 'styled-components/macro';
import { LinkStyled, Span } from '../../../../components/typography';
import { Div } from '../../../../components/grid';
import { BsTruck } from '../../../../components/Icons';

const LeveringLink = styled(LinkStyled)`
  color: ${({ theme }) => theme.colors.colorBlue};
  text-decoration: none;
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  &:hover {
    color: ${({ theme }) => theme.hoverColors.colorBlue};
  }

  @media (max-width: 1599px) {
    margin-left: 45px;
  }

  @media (max-width: 1199px) {
    border: 1px solid ${({ theme }) => theme.colors.colorBlue};
    width: 140px;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    top: -4px;
    margin-left: 40px;
  }
`;

const LeveringLinkIcon = styled.div`
  position: absolute;
  left: 0px;
  top: 1px;
  font-size: 50px;

  @media (max-width: 1599px) {
    font-size: 40px;
    top: 4px;
  }

  @media (max-width: 1199px) {
    display: none;
  }
  
  animation: ${({ animate }) => (animate ? 'bounce 1s infinite' : 'none')};
  transition: transform 0.2s ease-in-out;
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-5px);
    }
    60% {
      transform: translateY(-2.5px);
    }
  }
`;

const LeveringLinkTextWrap = styled(Div)`
  padding-left: 65px;

  @media (max-width: 1599px) {
    padding-left: 50px;
  }

  @media (max-width: 1199px) {
    padding-left: 0px;
  }
`;

const LeveringLinkTitle = styled(Span)`
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  font-size: 20px;
  margin-bottom: -1px;

  @media (max-width: 1599px) {
    font-size: 18px;
  }

  @media (max-width: 1199px) {
    font-size: 16px;
  }
`;

const LeveringLinkText = styled(Span)`
  font-size: 14px;
  display: block;
  color: ${({ theme }) => theme.colors.colorGreyDark};

  @media (max-width: 1199px) {
    display: none;
  }
`;
const LeveringAfhentning = () => {
  const [isHovering, setIsHovering] = React.useState(false);

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  return (
    <LeveringLink noStyle to="/levering-afhentning" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <LeveringLinkIcon animate={isHovering}><BsTruck /></LeveringLinkIcon>
      <LeveringLinkTextWrap>
        <LeveringLinkTitle>Levering eller Afhentning?</LeveringLinkTitle>
        <LeveringLinkText>Læs mere om dine muligheder her</LeveringLinkText>
      </LeveringLinkTextWrap>
    </LeveringLink>
  );
};


export default LeveringAfhentning;
