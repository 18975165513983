import React from 'react';
import styled from 'styled-components/macro';
import { Paragraph } from '../typography';

const DivNoProps = ({ chosen, possible, ...otherProps }) => <div {...otherProps} />;
const DeliveryTypeContainer = styled(DivNoProps)`
  white-space: nowrap;
`;
const DeliveryTypeInnerContainer = styled(DivNoProps)`
`;
const RadioButtonContainer = styled(DivNoProps)`
  width: 50px;
  display:inline-block;
  vertical-align: top;
  & input {
    float: right;
    margin-right: 15px;
  }
`;
const ChildContainer = styled(DivNoProps)`
  display:inline-block;
`;
const DeliveryType = ({
  choosen,
  possible,
  onClick,
  notPossibleText,
  children,
}) => (
  <DeliveryTypeContainer
    choosen={choosen}
    possible={possible}
    onClick={(!choosen && onClick) || undefined}
    role={(!choosen && 'button') || undefined}
  >
    {!possible && notPossibleText}
    <DeliveryTypeInnerContainer
      choosen={choosen}
      possible={possible}
    >
      <RadioButtonContainer
        choosen={choosen}
        possible={possible}
      >
        <Paragraph>
          <input type="radio" checked={choosen || false} />
        </Paragraph>
      </RadioButtonContainer>
      <ChildContainer>
        {children}
      </ChildContainer>
    </DeliveryTypeInnerContainer>
  </DeliveryTypeContainer>
);

export default DeliveryType;
