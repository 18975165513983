import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { openMenu, closeMenu, getMenuState } from '../../../../features/burgermenus';
import { FaTimes, GiHamburgerMenu } from '../../../../components/Icons';

const StyledIcon = styled.div`
  cursor: pointer;
  font-size: 25px;
  position: absolute;
  top: 12px;
  left: 15px;
  color: ${({ theme }) => theme.colors.colorBlue};
  &:hover {
    opacity: 0.5;
  }
`;

const BurgerIcon = ({ openMenu, closeMenu, ...props }) => (
  <StyledIcon
    // On click, open or close the menu
    onClick={() => {
      if (props.open) {
        closeMenu();
      } else {
        openMenu();
      }
    }}
    {...props}
  >
    {/* If the menu is open, show FaTimes (X) icon, else show GiHamburgerMenu (3 horizontal lines) icon */}
    {props.open ? <FaTimes /> : <GiHamburgerMenu />}
  </StyledIcon>
);

const mapStateToProps = state => ({
  open: getMenuState(state),
});

export default connect(mapStateToProps, { openMenu, closeMenu })(BurgerIcon);
