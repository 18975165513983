import React from 'react';
import { Money, VareLink } from '../../../../containers/commerce';
import { Paragraph, Span } from '../../../typography';
import { LiterFormat } from '../../../Format';
import { Row, Col } from '../../../grid';
import { Image } from '../../../Image';
import RecieptEntryWrap from '../RecieptEntryWrap';
import { sanitizeMeasurement, getRentalText } from '../../../../lib/helpers';
import { FadKobling } from '../../parts';
import { IconMinusCircle, IconPlusCircle } from './entryComponents';

export default (props) => {
  const {
    varenr,
    name,
    liter,
    amount,
    cost,
    imagePlural,
    actions,
    returPant,
    productTypePalle,
    countPrColli,
    countPrPalle,
    packageNoun,
    singleAmount,
    cannotBeManuallyBought,
    simpleItem,
    imageSingle,
    isLeje,
    packImages,
    productTypePack,
    days,
    productTypeReservedel,
    onlyRemove,
    royalUnibrewJulekittet,
    productTypeFadoel,
    fadKobling,
    productTypeIs,
  } = props;
  const {
    incrementItemAmount,
    decrementItemAmount,
  } = actions;
  return (
    <RecieptEntryWrap>
      <Row>
        <Col xs={4} padding="15px 5px" textCenter>
          {!simpleItem && (
            <React.Fragment>
              {imageSingle && !imagePlural && !packImages && (
                <Image maxHeight="80px" imageHeight="80px" name={imageSingle} left />
              )}
              {imagePlural && imagePlural && (
                <Image maxWidth="80px" imageHeight="80px" name={imagePlural} left />
              )}
            </React.Fragment>
          )}
          {simpleItem && (
            <React.Fragment>
              {imageSingle && !packImages && (
                <Image maxHeight="80px" imageHeight="80px" name={imageSingle} left />
              )}
            </React.Fragment>
          )}
          {packImages && packImages.length > 0 && (
            <Image maxHeight="80px" imageHeight="80px" name={packImages[0].name} left />
          )}
        </Col>
        <Col xs={8} padding="15px 15px 15px 0px">
          <Paragraph lineHeight="1.2em" margin="0">
            {!returPant && (
              <VareLink varenr={varenr}>{name}</VareLink>
            )}
            {returPant && (
              <React.Fragment>{name}</React.Fragment>
            )}
            {!productTypePalle && !productTypeIs && (
              <React.Fragment>
                {(sanitizeMeasurement(liter) > 0 && (sanitizeMeasurement(liter) / sanitizeMeasurement(countPrColli)) < 2) && (
                  <React.Fragment>
                    &nbsp;{countPrColli}x<LiterFormat liter={liter} cl divider={countPrColli} />
                  </React.Fragment>
                )}
                {(sanitizeMeasurement(liter) / sanitizeMeasurement(countPrColli)) >= 2 && (
                  <React.Fragment>
                    &nbsp;<LiterFormat liter={liter} divider={countPrColli} />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            {productTypePalle && (
              <Span block>
                {countPrPalle}x{countPrColli}x<LiterFormat liter={liter} cl divider={countPrColli} />
              </Span>
            )}
          </Paragraph>
          {!cannotBeManuallyBought && (
          <Span block bold margin="5px 0 0 0">
            {!royalUnibrewJulekittet && amount}
            {royalUnibrewJulekittet && (
              <React.Fragment>
                2 x nissehuer<br />2 x knappehulsblinkere<br />1 x guirlande
              </React.Fragment>
            )}
            {!productTypePalle && !simpleItem && !productTypePack && !productTypeReservedel && packageNoun && (
              <React.Fragment>
                &nbsp;{packageNoun.toLowerCase()}{amount !== 1 && 'r'}{singleAmount > 0 && `, ${singleAmount * countPrColli} flasker`}
                {productTypeFadoel && (
                  <React.Fragment>
                    <Span normal> &nbsp;| <FadKobling value={fadKobling} noPrefix />-kobling</Span>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            {productTypePalle && (
              <React.Fragment>
                &nbsp;palle{amount !== 1 && 'r'}
              </React.Fragment>
            )}
            {(simpleItem || !packageNoun || productTypePack || productTypeReservedel) && (
              <React.Fragment>
                &nbsp;stk. {isLeje && `(${getRentalText(days)})`}
              </React.Fragment>
            )}
          </Span>
          )}
          {!cannotBeManuallyBought && (
            <React.Fragment>
              <IconMinusCircle onClick={decrementItemAmount} />
              {!onlyRemove && (
                <IconPlusCircle onClick={incrementItemAmount} />
              )}
            </React.Fragment>
          )}
          {cannotBeManuallyBought && simpleItem && (
            <Paragraph floatLeft margin="5px 0 0 0" bold>
              {amount}&nbsp;stk.
            </Paragraph>
          )}
          <Paragraph floatRight margin="5px 0 0 0" bold>
            <Money money={cost} />
          </Paragraph>
        </Col>
      </Row>
    </RecieptEntryWrap>
  );
};
