import React from 'react';
import styled from 'styled-components/macro';
import { Row } from 'react-grid-system';

const RowNoProps = React.forwardRef(({
  margin,
  h100p,
  paddingTop,
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
  borderTop,
  borderRight,
  borderBottom,
  borderLeft,
  padding,
  mm5,
  block,
  border,
  bestil,
  grey,
  green,
  white,
  highlight,
  greyLight,
  textCenter,
  zIndex,
  ...props
}, ref) => <Row ref={ref} {...props} />);

export default styled(RowNoProps)`
  & {
    width: -webkit-fill-available;
  }
  ${({ h100p }) => h100p && 'height: 100% !important;'}
  ${({ height }) => height && `height: ${height};`}
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop};`}
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom};`}
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop} !important;`}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom} !important;`}
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft} !important;`}
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight} !important;`}
  ${({ textCenter }) => textCenter && 'text-align: center'}
  ${({ border }) => border && `border: ${border};`}
  ${({ borderTop }) => borderTop && `border-top: ${borderTop};`}
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
  ${({ borderRight }) => borderRight && `border-right: ${borderRight};`}
  ${({ borderBottom }) => borderBottom && `border-bottom: ${borderBottom};`}
  ${({ borderLeft }) => borderLeft && `border-left: ${borderLeft};`}
  ${({ m0 }) => m0 && 'margin-left: 0px !important; margin-right: 0px !important;'}
  ${({ mm5 }) => mm5 && 'margin-left: -5px !important; margin-right: -5px !important;'}
  ${({ innerShadow }) => innerShadow && 'box-shadow: 0 0 20px 0px rgba(0,0,0,0.1) inset;'}
  ${({ relative }) => relative && 'position: relative;'}
  ${({ bottomSpacing, theme }) => bottomSpacing && `border-bottom: 15px solid ${theme.colors.colorGreyLight};`}
  ${({ topSpacing, theme }) => topSpacing && `border-top: 15px solid ${theme.colors.colorGreyLight};`}
  
  
  ${({ padding }) => {
    if (padding) {
      return `padding: ${padding};`;
    }
    return '';
  }}
  ${({ margin }) => {
    if (margin) {
      return `margin: ${margin} !important;`;
    }
    return '';
  }}
  ${({ block }) => {
    if (block) {
      return 'display: block !important;';
    }
    return 'display: flex !important;';
  }}
  ${({
    bestil,
    green,
    highlight,
    grey,
    greyLight,
    white,
    theme,
    inStock,
  }) => {
    if (bestil) {
      return `
        background: ${theme.colors.colorBlueHighlight};
        background: -moz-linear-gradient(left, ${theme.colors.colorBlueHighlight} 0%, ${theme.colors.colorGreyLight} 100%);
        background: -webkit-linear-gradient(left, ${theme.colors.colorBlueHighlight} 0%,${theme.colors.colorGreyLight} 100%);
        background: linear-gradient(to right, ${theme.colors.colorBlueHighlight} 0%,${theme.colors.colorGreyLight} 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${theme.colors.colorBlueHighlight}', endColorstr='${theme.colors.colorGreyLight}',GradientType=1 );
        margin-left: 0px !important;
        margin-right: 0px !important;
        cursor: pointer;
        margin-top: 10px;
      `;
    }
    if (white) {
      return 'background: white;';
    }
    if (green) {
      return `background-color: ${theme.colors.colorGreenHighlight};`;
    }
    if (highlight) {
      return `background-color: ${theme.colors.colorBlueHighlight};`;
    }
    if (grey) {
      return `background-color: ${theme.colors.colorGrey};`;
    }
    if (inStock === '-1') {
      return `
        & a {
          color: ${theme.colors.colorGrey};
        }
        & span {
          color: ${theme.colors.colorGrey};
        }
        & i {
          color: ${theme.colors.colorGrey};
        }
      `;
    }
    if (greyLight) {
      return `background-color: ${theme.colors.colorGreyLight};`;
    }
    return '';
  }}
  ${({ onClick }) => onClick && 'cursor: pointer'}
`;
