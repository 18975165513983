import React from "react";
import styled from "styled-components/macro";
import { LinkStyled } from "../../../../../components/typography";
import { StarRatingIcon } from "../../../../../components/Icons";

const TrustLink = styled(LinkStyled)`
  background: transparent;
`;
export default () => (
  <TrustLink
    external
    to="https://skafte.dk/google-rating"
    rel="noopener noreferrer"
    title="Google Rating"
  >
    <StarRatingIcon />
  </TrustLink>
);
