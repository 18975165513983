import React from 'react';
import styled from 'styled-components/macro';

const StyledLi = styled.li`
  height: 1px;
  margin: 0;
  overflow: hidden;
  background-color: #e5e5e5;
`;

export default () => <StyledLi role="separator" />;
