import React from 'react';
import { Row, Col } from '../grid';
import {
  F12,
  Paragraph,
  Input,
  Span,
} from '../typography';
import Format from '../Format';
import { Money, MomsChange } from '../../containers/commerce';
import {
  Table,
  Tr,
  Td,
  Th,
} from '../tables';
import ReadMore from '../ReadMore';

export default class KurvFadoelsBeregner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      guests: 50,
    };
  }

  render() {
    const {
      liter,
      cost,
    } = this.props;
    if (liter === 0) {
      return null;
    }
    const {
      guests,
    } = this.state;
    let parsedGuests = parseInt(guests, 10);
    if (Number.isNaN(parsedGuests)) {
      parsedGuests = 0;
    }
    // console.log('What is parsed', parsedGuests);
    // console.log('What is liter', liter);
    return (
      <ReadMore title="Hvor mange fadøl pr gæst?" marginTop="10px" marginBottom="0px">
        <Row>
          <Col xs={12} paddingTop="0px">
            <Row>
              <Col xs={8} paddingBottom="0px">
                <Span fontSize="12px" paddingTop="7px">
                  Indtast antal gæster
                </Span>
              </Col>
              <Col xs={4} paddingBottom="0px" textRight>
                <Input
                  value={guests}
                  onChange={
                    ({ target: { value: guests } }) => {
                      this.setState({ guests });
                    }
                  }
                  textRight
                  width="45px"
                  data-recording-gdpr-safe
                  fontSize="16px"
                />
              </Col>
              <Col xs={8} paddingTop="10px" paddingBottom="0px" bold>
                <F12>Antal 33cl fadøl pr. gæst:</F12>
              </Col>
              <Col xs={4} paddingTop="10px" paddingBottom="0px" textRight bold>
                <Span fontSize="12px" paddingRight="4px">
                  <Format value={parsedGuests ? liter / (parsedGuests * 0.33) : 0} decimalScale={1} />
                </Span>
              </Col>
              <Col xs={8} paddingBottom="0px">
                <F12>Pris pr. gæst</F12>
              </Col>
              <Col xs={4} paddingBottom="0px" textRight>
                <Span fontSize="12px" paddingRight="4px">
                  <Money money={parsedGuests ? cost / parsedGuests : 0} />
                </Span>
              </Col>
              <Col xs={8}>
                <Span fontSize="12px">Pris pr. 33cl fadøl:</Span>
              </Col>
              <Col xs={4} textRight>
                <Span fontSize="12px" paddingRight="4px">
                  <Money money={cost / (liter / 0.33)} />
                </Span>
              </Col>
            </Row>
            <Paragraph fontSize="12px">
              Priserne i beregneren er <MomsChange />, uden pant.
              Tallene beregnes ud fra hvor mange liter øl du har i din kurv.
            </Paragraph>
            <Table lines1 fontSize="12px" paddingTd="5px 0">
              <thead>
                <Tr>
                  <Th colspan="2">Erfaringsmæssigt forbrug pr. gæst</Th>
                </Tr>
              </thead>
              <tbody>
                <Tr>
                  <Td>Receptioner</Td>
                  <Td textRight>1 øl</Td>
                </Tr>
                <Tr>
                  <Td>Familiefest</Td>
                  <Td textRight>2 - 3 øl</Td>
                </Tr>
                <Tr>
                  <Td>Firmafest</Td>
                  <Td textRight>4 øl</Td>
                </Tr>
                <Tr>
                  <Td>Svendegilde</Td>
                  <Td textRight>5 - 6 øl</Td>
                </Tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </ReadMore>
    );
  }
}
