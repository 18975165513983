import { connect } from 'react-redux';
import { hasFustagerWithoutAnlaeg } from '../../features/commerce/selectors';

const FustagerWithoutAnlaeg = ({
  fustagerWithoutAnlaeg,
  children,
}) => {
  if (children) {
    return children(fustagerWithoutAnlaeg);
  }
  return null;
};

export default connect((state) => ({
  fustagerWithoutAnlaeg: hasFustagerWithoutAnlaeg(state),
}))(FustagerWithoutAnlaeg);
