export const getSearchState = state => state.commerce.search;

export const getSearchTerm = state => getSearchState(state).term;

export const getSearchResults = state => getSearchState(state).results;

export const getIsSearching = state => getSearchState(state).isSearching;

export const getIsBuildingSearchResults = state => getSearchState(state).isBuildingSearchResults;

export const hasIndex = state => getSearchState(state).hasIndex;

export const getGeneralSearchState = state => getSearchState(state).generalResults;

export const getGeneralSearchTerm = state => getGeneralSearchState(state).term;

export const getShowSearchOverlay = state => getSearchState(state).showSearchOverlay;
