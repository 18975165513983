import moment from 'moment';
import { createSelector } from 'reselect';
import {
  dateTypes,
  dateModes,
  SET_DATE,
  SET_TIME,
  SET_MODE,
  RESET_DATE,
  VALIDATE_STATE,
  isBefore,
  isSunday,
  isSaturday,
  storeDateFormat,
  timeTranslation,
  combineFees,
  getTimeFee as sharedGetTimeFee,
  getDateFee as sharedGetDateFee,
  noNightChoices,
  noWeekendChoices,
  noWeekdayChoices,
  createGetAvailableTimes,
} from './shared';
import { SET_DELIVERY_INFO } from '../actions';
import { deliveryTypes } from '../shared';
import { closedDates } from '../../../../content/commerce/closedDates';
import getArg from '../../../../lib/args';

const getDefaultTime = (mode) => {
  switch (mode) {
    case dateModes.noNight:
      return noNightChoices.early;
    case dateModes.noWeekend:
      return noWeekendChoices.morning;
    case dateModes.noDelivery:
      return noWeekdayChoices.corona1;
      // corona19
      // return noWeekdayChoices.regular;
    case dateModes.none:
    default:
      return null;
  }
};

const initialState = {
  date: null,
  mode: dateModes.none,
  time: getDefaultTime(dateModes.none),
  selfHasDates: false,
};

const modeTransition = (state, { dateType, mode, deliveryType }) => {
  if (
    dateType !== state.type
    || state.mode === mode
    || deliveryType !== deliveryTypes.no
  ) {
    return state;
  }
  return {
    ...state,
    mode,
    time: getDefaultTime(mode),
  };
};

const dateTransition = (state, { dateType, date }) => {
  if (
    dateType === dateTypes.delivery
    && state.type === dateTypes.pickup
    && state.mode !== dateModes.none
    && state.date !== null
  ) {
    if (
      date === null
      || isBefore(state.date, date)
    ) {
      return {
        ...state,
        date: null,
        time: getDefaultTime(state.mode),
      };
    }
  }
  if (
    dateType !== state.type
    || state.date === date
  ) {
    return state;
  }
  const newState = {
    ...state,
    date,
  };
  // If today
  // And is delivery
  if (
    state.selfHasDates
    || dateType === dateTypes.delivery
  ) {
    if (isSaturday(date) || isSunday(date)) {
      return modeTransition(newState, { dateType, mode: dateModes.noWeekend, deliveryType: deliveryTypes.no });
    }
    if (state.mode === dateModes.noWeekend) {
      return modeTransition(newState, { dateType, mode: dateModes.noDelivery, deliveryType: deliveryTypes.no });
    }
  }
  return newState;
};

const stateValidation = (state) => {
  if (state[dateTypes.delivery].date !== null) {
    if (isBefore(state[dateTypes.delivery].date, moment().format(storeDateFormat))) {
      return {
        ...state,
        [dateTypes.delivery]: dateTransition(state[dateTypes.delivery], { dateType: dateTypes.delivery, date: null }),
        [dateTypes.pickup]: dateTransition(state[dateTypes.pickup], { dateType: dateTypes.delivery, date: null }),
      };
    }
  }
  return state;
};
const setDeliveryInfoTransition = (state, { hasLeje: selfHasDates }) => {
  if (
    state.selfHasDates === selfHasDates
  ) {
    return state;
  }
  const newState = {
    ...state,
    selfHasDates,
  };
  if (selfHasDates) {
    if (state.mode === dateModes.none) {
      return dateTransition(
        modeTransition(newState, { dateType: state.type, mode: dateModes.noDelivery, deliveryType: deliveryTypes.no }),
        {
          date: state.date,
          dateType: state.type,
        },
      );
    }
  } else if (state.type === dateTypes.pickup) {
    return modeTransition(newState, { dateType: state.type, mode: dateModes.none, deliveryType: deliveryTypes.no });
  }
  return newState;
};

const makeReducer = (dateType) => {
  const typeInitialState = {
    ...initialState,
    type: dateType,
    mode: dateType === dateTypes.delivery ? dateModes.noDelivery : initialState.mode,
  };
  return (state = typeInitialState, action) => {
    switch (action.type) {
      case SET_DATE:
        return dateTransition(state, action.payload);
      case SET_MODE:
        return modeTransition(state, action.payload);
      case SET_DELIVERY_INFO:
        return setDeliveryInfoTransition(state, action.payload);
      case SET_TIME:
        if (
          action.payload.dateType === state.type
          && action.payload.deliveryType === deliveryTypes.no
        ) {
          return {
            ...state,
            time: action.payload.time,
          };
        }
        return state;
      case RESET_DATE:
        return typeInitialState;
      default:
        return state;
    }
  };
};
const deliveryReducer = makeReducer(dateTypes.delivery);
const pickupReducer = makeReducer(dateTypes.pickup);

const initialTotalState = {
  [dateTypes.delivery]: deliveryReducer(undefined, { type: '' }),
  [dateTypes.pickup]: pickupReducer(undefined, { type: '' }),
};
const runReducerOnKey = (reducer, key) => (state, action) => {
  const newState = reducer(state[key], action);
  if (newState !== state[key]) {
    return {
      ...state,
      [key]: newState,
    };
  }
  return state;
};

export default (state = initialTotalState, action) => {
  switch (action.type) {
    case VALIDATE_STATE:
      return stateValidation(state);
    default: {
      let newState = runReducerOnKey(deliveryReducer, dateTypes.delivery)(state, action);
      newState = runReducerOnKey(pickupReducer, dateTypes.pickup)(newState, action);
      return newState;
    }
  }
};


export const getRealLocalState = state => state.commerce.delivery.dates[deliveryTypes.no];
export const getLocalState = dateType => state => getRealLocalState(state)[dateType];

export const getSelfHasDates = state => getLocalState(dateTypes.delivery)(state).selfHasDates;

export const hasDateAsPossibility = dateType => createSelector(
  getLocalState(dateType),
  (localState) => {
    if (dateType === dateTypes.delivery) {
      return true;
    }
    return localState.selfHasDates;
  },
);
export const getDate = dateType => createSelector(
  getLocalState(dateType),
  localState => localState.mode !== dateModes.none && localState.date && moment(localState.date, storeDateFormat),
);


export const getMode = dateType => state => getLocalState(dateType)(state).mode;
const disabledWeekdays = [];

export const getDisabledWeekDays = () => () => disabledWeekdays;
export const getDisabledDays = () => createSelector(
  () => null,
  () => Object.keys(closedDates),
);

export const isRequired = dateType => (state) => {
  const {
    selfHasDates,
  } = getLocalState(dateType)(state);
  if (selfHasDates) {
    return true;
  }
  return false;
};

export const validate = dateType => (state) => {
  if (isRequired(dateType)(state)) {
    const {
      date,
    } = getLocalState(dateType)(state);
    return Boolean(date);
  }
  return true;
};

const getDeliveryDate = getDate(dateTypes.delivery);
export const getMinDate = dateType => createSelector(
  getDeliveryDate,
  (deliveryDate) => {
    if (dateType === dateTypes.delivery) {
      return moment();
    }
    if (!deliveryDate) {
      return moment();
    }
    return deliveryDate;
  },
);

export const getIsDisabled = dateType => (state) => {
  const localState = getLocalState(dateType)(state);
  if (localState.mode === dateModes.none) {
    return true;
  }
  return false;
};

const availableTimeSelectors = {
  [dateModes.noDelivery]: {
    [dateTypes.delivery]: createGetAvailableTimes(dateTypes.delivery, [
      // corona19
      // noWeekdayChoices.corona1,
      // noWeekdayChoices.corona2,
      noWeekdayChoices.regular,
      noWeekdayChoices.special,
      noNightChoices.early,
      noNightChoices.medium,
      noNightChoices.late,
      noNightChoices.afterMidnight,
    ]),
    [dateTypes.pickup]: createGetAvailableTimes(dateTypes.pickup, [
      // corona19
      // noWeekdayChoices.corona1,
      // noWeekdayChoices.corona2,
      noWeekdayChoices.regular,
      noNightChoices.early,
      noNightChoices.medium,
      noNightChoices.late,
      noNightChoices.afterMidnight,
    ]),
  },
  [dateModes.noNight]: {
    [dateTypes.delivery]: createGetAvailableTimes(dateTypes.delivery, [
      noNightChoices.early,
      noNightChoices.medium,
      noNightChoices.late,
    ]),
    [dateTypes.pickup]: createGetAvailableTimes(dateTypes.pickup, [
      noNightChoices.early,
      noNightChoices.medium,
      noNightChoices.late,
    ]),
  },
  [dateModes.noWeekend]: {
    [dateTypes.delivery]: createGetAvailableTimes(dateTypes.delivery, [
      noWeekendChoices.morning,
      noWeekendChoices.afternoon,
      noWeekendChoices.midday,
      noWeekendChoices.evening,
      noWeekendChoices.night,
    ]),
    [dateTypes.pickup]: createGetAvailableTimes(dateTypes.pickup, [
      noWeekendChoices.morning,
      noWeekendChoices.afternoon,
      noWeekendChoices.midday,
      noWeekendChoices.evening,
      noWeekendChoices.night,
    ]),
  },
};

export const getAvailableTimesInner = dateType => (currentTime = new Date().getTime(), dateMode, date) => {
  switch (dateMode) {
    case dateModes.noWeekend:
    case dateModes.noNight:
    case dateModes.noDelivery:
      return availableTimeSelectors[dateMode][dateType](date, currentTime);
    case dateModes.none:
    default:
      return null;
  }
};

export const getAvailableTimes = dateType => (state, currentTime) => {
  const localState = getLocalState(dateType)(state);
  return getAvailableTimesInner(dateType)(currentTime, localState.mode, localState.date);
};

export const getTime = dateType => (state) => {
  const localState = getLocalState(dateType)(state);
  if (getArg('browser')) {
    const possibleTimes = getAvailableTimesInner(dateType)(undefined, localState.mode, localState.date);
    if (possibleTimes && !possibleTimes.includes(localState.time)) {
      return possibleTimes[0];
    }
  }
  return localState.time;
};

export const getActualTime = dateType => (state) => {
  const localState = getLocalState(dateType)(state);
  switch (localState.mode) {
    case dateModes.noDelivery:
    case dateModes.noWeekend:
    case dateModes.noNight: {
      const time = getTime(dateType)(state);
      return time ? timeTranslation[time].time : null;
    }
    default:
      return null;
  }
};

export const getTimeFee = dateType => state => (time) => {
  const fees = sharedGetTimeFee(time);
  const date = getDate(dateType)(state);
  return Object.keys(fees).reduce(
    (acc, feeKey) => {
      if (fees[feeKey].removeFee && fees[feeKey].removeFee(date)) {
        return acc;
      }
      return {
        ...acc,
        [feeKey]: fees[feeKey],
      };
    },
    {},
  );
};
export const getDateFee = () => () => sharedGetDateFee;
const normalDateFees = {};
export const getNormalDateFees = () => () => normalDateFees;
export const getDateExtraFee = () => () => () => normalDateFees;
export const getFees = dateType => createSelector(
  getMode(dateType),
  getTime(dateType),
  getDate(dateType),
  getDateFee(dateType),
  getTimeFee(dateType),
  (mode, time, date, getDF, getTF) => {
    let fees = {};
    if (mode !== dateModes.none) {
      fees = {
        ...fees,
        ...getDF(date),
      };
    }
    if (
      mode === dateModes.noNight
      || mode === dateModes.noWeekend
      || mode === dateModes.noDelivery
    ) {
      fees = {
        ...fees,
        ...getTF(time),
      };
    }
    return fees;
  },
);

export const getPriceComposition = createSelector(
  getFees(dateTypes.delivery),
  getFees(dateTypes.pickup),
  combineFees,
);
