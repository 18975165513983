import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { getTotalNumberOfItems } from '../../../../../features/commerce';
import { openBucket, closeBucket } from '../../../../../features/burgermenus';
import { Div } from '../../../../../components/grid';
import { GrCart } from '../../../../../components/Icons';

const NumberItems = styled.div`
  color: ${({ theme }) => theme.colors.colorBlue};
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 2px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
`;
const HeaderBucketNoStyle = ({ numberOfItems, isBucketOpen, openBucket, closeBucket, ...otherProps }) => (
  <Div onClick={isBucketOpen ? closeBucket : openBucket} id="mainBucketMenu" {...otherProps}>
    <NumberItems>
      {numberOfItems}
    </NumberItems>
    <GrCart />
  </Div>
);
const HeaderBucket = styled(HeaderBucketNoStyle)`
  display: inline-block;
  position: relative;
  float: right;
  height: 50px;
  margin-left: 30px;
  cursor: pointer;

  & > svg {
    font-size: 50px;

    & > path {
      stroke: ${({ theme }) => theme.colors.colorBlue};
    }
  }

  &:hover {
    & > svg > path {
      stroke: ${({ theme }) => theme.hoverColors.colorBlue};
    }
  }
`;

export default connect(
  state => ({
    numberOfItems: getTotalNumberOfItems(state),
    isBucketOpen: state.burgermenus.bucket,
  }),
  {
    openBucket,
    closeBucket,
  },
)(HeaderBucket);
