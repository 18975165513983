import React from 'react';
import { Money } from '../../../../containers/commerce';
import { LiterFormat } from '../../../Format';
import { Paragraph } from '../../../typography';
import RecieptEntryWrap from '../RecieptEntryWrap';
import { Row, Col } from '../../../grid';
import { IconMinusCircle, IconPlusCircle } from './entryComponents';

export default (props) => {
  const {
    amount,
    liter,
    cost,
    amountPrPalle,
    actions,
  } = props;
  const {
    incrementItemAmount,
    decrementItemAmount,
  } = actions;
  return (
    <RecieptEntryWrap>
      <Row>
        <Col xs={12}>
          <Paragraph bold marginBottom="0px">
            Vand med logo:
          </Paragraph>
          <Paragraph marginBottom="0px">
            {amount} x {amountPrPalle} x <LiterFormat liter={liter} />
          </Paragraph>
          <IconMinusCircle onClick={decrementItemAmount} />
          <IconPlusCircle onClick={incrementItemAmount} />
          <Paragraph floatRight margin="5px 0 0 0" bold>
            <Money money={cost} />
          </Paragraph>
        </Col>
      </Row>
    </RecieptEntryWrap>
  );
};
