import styled from 'styled-components/macro';

const FilterButton = styled.button`
  position: relative;
  display: inline-block;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  background: ${({ theme }) => theme.colors.colorWhite};
  padding: ${({ clear }) => (clear ? '4px 5px 4px 5px' : '4px 32px 4px 5px')};
  border: 1px solid ${({ theme, clear }) => (clear ? theme.colors.colorRed : theme.colors.colorGreyLightAlt)};

  & span {
    color: ${({ theme, clear }) => (clear ? theme.colors.colorRed : theme.colors.colorGreyDark)};
  }

  &:hover {
    border-color: ${({ theme, clear }) => (clear ? theme.colors.colorRed : theme.colors.colorGreen)};
    background: ${({ theme, clear }) => (clear ? theme.colors.colorRed : theme.colors.colorWhite)};

    & span {
      color: ${({ theme, clear }) => (clear ? theme.colors.colorWhite : theme.colors.colorGreyDark)};
    }
  }

  &[data-selected='true'] {
    border-color: ${({ theme }) => theme.colors.colorGreen};
    color: ${({ theme }) => theme.colors.colorGreen};

    & span {
      color: ${({ theme }) => theme.colors.colorGreen};
    }
  }

  & span.icon-separator {
    background: ${({ theme }) => theme.colors.colorGreyLightAlt};
    width: 1px;
    height: 100%;
    display: inline-block;
    content: "";
    position: absolute;
    top: 0px;
    right: 27px;

    & > svg {
      font-size: 30px;
      position: relative;
      top: -2px;
      left: -1px;
    }
  }

  ${({ disabled, theme }) => disabled && `
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      border-color: ${theme.colors.colorGreyLightAlt};
    }
  `}

  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

export default FilterButton;
