import React from 'react';

export default (Component) => {
  class UpdateWhenNotDeepEqual extends React.PureComponent {
    render() {
      const {
        forwardedRef,
        ...props
      } = this.props;
      return <Component {...props} ref={forwardedRef} />;
    }
  }
  return UpdateWhenNotDeepEqual;
};
