import getArg from './lib/args';

const imageSources = () => import('./image-sources');

const ref = { currentValue: null };
const serverRef = { currentValue: {} };

export const setDefault = (value) => {
  ref.currentValue = value;
};

export const doImport = () => imageSources().then((value) => {
  ref.currentValue = value.default;
});

export const getServerRefs = () => serverRef.currentValue;

export default (name) => {
  const {
    currentValue: {
      [name]: v,
    },
  } = ref;
  if (getArg('serverDirect')) {
    serverRef.currentValue = {
      ...serverRef.currentValue,
      [name]: v,
    };
  }
  return v;
};
