import {
  put,
  // all,
  // takeEvery,
  // select,
  call,
  take,
  actionChannel,
} from 'redux-saga/effects';
import {
  // formValueSelector,
  change,
} from '../form';
import { changeHasDeliveryInfo } from '../commerce/details';
import {
  // STARTUP,
  STARTUP_COMPLETE,
} from '../actions';

const actionPrefix = 'SKAFTE/BESTILLINK/';

export const BESTIL_LINK_SET = `${actionPrefix}BESTIL_LINK_SET`;
const BESTIL_LINK_SET_INTERNAL = `${actionPrefix}BESTIL_LINK_SET_INTERNAL`;

export const setBestilLink = payload => ({
  type: BESTIL_LINK_SET,
  payload,
});

const setBestilLinkInternal = payload => ({
  type: BESTIL_LINK_SET_INTERNAL,
  payload,
});

export const getOldCustomer = state => state.bestillink.linkdato && state.bestillink;

const initialState = {
};
export default function bestillinkReducer(state = initialState, action) {
  switch (action.type) {
    case BESTIL_LINK_SET_INTERNAL:
      return { ...action.payload };
    default:
      return state;
  }
}

const collectPhonenumbers = (phone1, phone2) => {
  let phonenumbers = undefined; // eslint-disable-line
  if (phone1) {
    phonenumbers = [phone1];
  }
  if (phone2) {
    if (phonenumbers) {
      phonenumbers.push(phone2);
    } else {
      phonenumbers = ['', phone2];
    }
  }
  return phonenumbers;
};

// const invoiceSelector = formValueSelector('invoice-details');
function* bestilLinkSaga({ payload }) {
  const {
    linkdato,
    kundenr,
    company: name,
    name: attName,
    Ecom_ShipTo_Postal_Street_Line1: address,
    city,
    zip,
    email,
    email2,
    Phone_number: phoneNumber,
    Phone_number2: phoneNumber2,
    Phone_number3: phoneNumber3,
    cvr,
    ean,
    levnavn: deliveryName,
    levatt: deliveryAttName,
    levgade: deliveryAddress,
    levby: deliveryCity,
    levpost: deliveryZip,
    levtlf1: deliveryPhoneNumber,
    levtlf2: deliveryPhoneNumber2,
    levtlf3: deliveryPhoneNumber3,
    note: comment,
    // ny,
    // vare,
    // ugedag,
  } = payload;
  const levExtraPhonenumbers = collectPhonenumbers(deliveryPhoneNumber2, deliveryPhoneNumber3);
  const extraPhonenumbers = collectPhonenumbers(phoneNumber2, phoneNumber3);
  if (extraPhonenumbers) {
    yield put(change('invoice-details', 'extraPhonenumbers', extraPhonenumbers));
  }
  let extraEmails = undefined; // eslint-disable-line
  if (email2) {
    extraEmails = email2.split(' ').filter((v, i) => i !== 0).map(
      (e) => {
        if (e.indexOf(':') === 1) {
          return e.substring(2);
        }
        return e;
      },
    );
  }
  if (extraEmails) {
    yield put(change('invoice-details', 'extraEmails', extraEmails));
  }
  if (kundenr) {
    yield put(change('invoice-details', 'kundenr', kundenr));
  }
  if (phoneNumber) {
    yield put(change('invoice-details', 'phonenumber', phoneNumber));
  }
  if (name) {
    yield put(change('invoice-details', 'name', name));
  }
  if (attName) {
    yield put(change('invoice-details', 'attName', attName));
  }
  if (address) {
    yield put(change('invoice-details', 'address', address));
  }
  if (zip) {
    yield put(change('invoice-details', 'zipCode', zip));
  }
  if (city) {
    yield put(change('invoice-details', 'city', city));
  }
  if (email) {
    yield put(change('invoice-details', 'email', email));
  }
  if (comment) {
    yield put(change('invoice-details', 'comment', comment));
  }

  // Levering form
  if (deliveryName) {
    yield put(changeHasDeliveryInfo(true));
    yield put(change('delivery-details', 'name', deliveryName));
    if (levExtraPhonenumbers) {
      yield put(change('delivery-details', 'extraPhonenumbers', levExtraPhonenumbers));
    }
    if (deliveryAttName) {
      yield put(change('delivery-details', 'attName', deliveryAttName));
    }
    if (deliveryAddress) {
      yield put(change('delivery-details', 'address', deliveryAddress));
    }
    if (deliveryZip) {
      yield put(change('delivery-details', 'zipCode', deliveryZip));
    }
    if (deliveryCity) {
      yield put(change('delivery-details', 'city', deliveryCity));
    }
    if (deliveryPhoneNumber) {
      yield put(change('delivery-details', 'phonenumber', deliveryPhoneNumber));
    }
  }

  // Ean form
  if (cvr) {
    yield put(change('ean-form', 'cvr', cvr));
  }
  if (ean) {
    yield put(change('ean-form', 'ean', ean));
  }
  const customer = {
    linkdato,
    kundenr,
    email,
  };
  customer.invoiceAddress = {
    name,
    attName,
    phonenumber: phoneNumber,
    address,
    zipCode: zip,
    city,
    extraPhonenumbers,
    extraEmails,
  };
  if (deliveryName) {
    customer.deliveryAddress = {
      name: deliveryName,
      attName: deliveryAttName,
      address: deliveryAddress,
      zipCode: deliveryZip,
      city: deliveryCity,
      phonenumber: deliveryPhoneNumber,
      extraPhonenumbers: levExtraPhonenumbers,
    };
  }
  if (ean && cvr) {
    customer.ean = {
      cvr,
      ean,
    };
  }
  yield put(setBestilLinkInternal({ ...customer }));
}

export function* rootBestilLinkSaga() {
  const ac = yield actionChannel(BESTIL_LINK_SET);
  yield take(STARTUP_COMPLETE);
  while (true) {
    const action = yield take(ac);
    yield call(bestilLinkSaga, action);
  }
}
