import React from 'react';
import styled from 'styled-components/macro';
import TabButton from '../TabButton';

const TabButtonStyled = styled(TabButton)`
`;

export default ({ tabs }) => tabs.map(
  ({
    to,
    mediumTitle,
    showMedium,
    id,
  }) => showMedium && <TabButtonStyled id={id} to={to}>{mediumTitle}</TabButtonStyled>,
);
