import React from 'react';
import { Money, MomsChange } from '../../../../containers/commerce';

const Break = ({ small }) => (
  <br style={{ display: small ? 'block' : 'none' }} />
);

export default ({
  price,
  isLeje,
  small,
  simpleAmountDescriptor,
  extendedAmountDescriptor,
  packageNoun,
  countPrColli,
  replaceStkWithPackageNoun,
}) => {
  const stkTag = replaceStkWithPackageNoun ? packageNoun : 'stk';
  const preText = isLeje ? 'Leje' : 'Pris';
  const amountDescriptor = simpleAmountDescriptor
    ? `for ${countPrColli} ${stkTag}`
    : (
      extendedAmountDescriptor
        ? `pr. ${packageNoun.toLowerCase()} á ${countPrColli} ${stkTag}`
        : `pr. ${stkTag}`
    );
  return (
    <React.Fragment>
      {preText} {amountDescriptor}: <Break small={small} /><Money money={price} /> <MomsChange />
    </React.Fragment>
  );
};
