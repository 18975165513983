import { CHANGE } from './actions';
import Field from './Field';

export * from './actions';
export * from './selectors';
export { Field };
const initialState = {

};
export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE: {
      const {
        form,
        key,
        value,
      } = action.payload;
      const formValue = state[form] || {};
      return {
        ...state,
        [form]: {
          ...formValue,
          [key]: value,
        },
      };
    }
    default:
      return state;
  }
};
