import React from 'react';
import styled from 'styled-components/macro';
import VisibilitySensor from 'react-visibility-sensor';
import { Div } from '../grid';
import getArg from '../../lib/args';

const ImageBannerContainer = styled(Div)`
  position: relative;
  display: block;
  width: 100%;
  background-size: cover;
  ${({ bgPosition }) => {
    if (bgPosition) {
      return `background-position: ${bgPosition};`;
    }
    return 'background-position: center center;';
  }}
  ${({ height }) => height && `height: ${height};`}
`;

export default class ImageBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasBeenVisible: false,
    };
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
  }

  onVisibilityChange = (visible) => {
    const {
      hasBeenVisible,
    } = this.state;
    if (!hasBeenVisible && visible) {
      this.setState({
        hasBeenVisible: true,
      });
    }
  }

  render() {
    const {
      name,
      children,
      alwaysVisible,
      ...otherProps
    } = this.props;
    const {
      hasBeenVisible,
    } = this.state;
    const mainChildren = (
      <ImageBannerContainer {...otherProps} bgImageNotVisible={!hasBeenVisible && !alwaysVisible}>
        {children}
      </ImageBannerContainer>
    );
    if (!getArg('browser')) {
      return mainChildren;
    }
    return (
      <VisibilitySensor onChange={this.onVisibilityChange} active={!hasBeenVisible && !alwaysVisible} partialVisibility>
        {mainChildren}
      </VisibilitySensor>
    );
  }
}
