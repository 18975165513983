import React, { useMemo } from 'react';

export default ({ children }) => {
  const markup = useMemo(
    () => ({
      __html: children,
    }),
    [children],
  );
  return <script type="application/ld+json" dangerouslySetInnerHTML={markup} />; // eslint-disable-line
};
