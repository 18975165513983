import React from 'react';
import styled from 'styled-components/macro';
import { Money } from '../../../containers/commerce';
import { AlcoholPercentage, FadKobling } from '../parts';
import { sanitizeMeasurement, ScreenSizeDetector } from '../../../lib/helpers';
import { sanitizePrice } from '../../../lib/price';
import { Col, Row, Div } from '../../grid';
import { Image } from '../../Image';
import {
  Paragraph, Heading4, Span, Bold, LinkButton,
} from '../../typography';

const DivWrap = styled(Div)`
  border: 1px solid ${({ theme }) => theme.colors.colorGreyLightAlt};
  background: #fff;
  border-radius: 15px;
`;

const defaultSingleWidth = [[425, '100px'], '100px'];
const RenderSingleImage = ({ imageSingle, singleWidth = defaultSingleWidth }) => {
  if (!imageSingle) {
    return null;
  }
  return (
    <ScreenSizeDetector maxWidth={singleWidth} imageWidth={singleWidth}>
      <Image name={imageSingle} width="100%" maxHeight="100px" className="bucketAnimation" />
    </ScreenSizeDetector>
  );
};

export default React.forwardRef((props) => {
  const {
    price,
    liter,
    name,
    imageSingle,
    fadoelsType,
    fadKobling,
    alcoholPercentage,
    singleWidth,
    url,
    extraItemRef,
  } = props;
  return (
    <React.Fragment>
      <Col xs={12} sm={6} md={6} lg={4} xl={3} padding="6px">
        <DivWrap ref={extraItemRef} h100p>
          <Row mm5 h100p>
            <Col xs={12} h100p>
              <Row>
                <Col xs={12} paddingBottom="0px" textCenter>
                  <Heading4 className="bucketAnimation">{name}</Heading4>
                  <Paragraph>
                    {sanitizeMeasurement(liter)} liters <Span lowercase>{fadoelsType}</Span> på <AlcoholPercentage noSuffix value={alcoholPercentage} />% med <Span><FadKobling noPrefix value={fadKobling} />-kobling</Span>.
                  </Paragraph>
                </Col>
                <Col xs={4} padding="5px" textCenter>
                  <Paragraph paddingTop="20px">
                    <Bold>Literpris</Bold><br />
                    <Money noSuffix money={sanitizePrice(price) / sanitizeMeasurement(liter)} />
                  </Paragraph>
                </Col>
                <Col xs={4} padding="5px" textCenter>
                  <RenderSingleImage imageSingle={imageSingle} singleWidth={singleWidth} />
                </Col>
                <Col xs={4} padding="5px" textCenter>
                  <Paragraph paddingTop="20px">
                    <Bold>Pris</Bold><br />
                    <Money noSuffix money={sanitizePrice(price)} />
                  </Paragraph>
                </Col>
                <Col xs={12}>
                  <LinkButton fullWidth small textCenter to={url}>
                    {name}
                  </LinkButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </DivWrap>
      </Col>
    </React.Fragment>
  );
});
