import React from 'react';
import styled from 'styled-components/macro';
import SchemaTag from '../../../../../lib/SchemaTag';
import { LinkStyled } from '../../../../../components/typography';

const StyledBreadCrumbsContainer = styled.ul`
  list-style-type: none;
  display: block;
  float: left;
  top: 0px;
  position: relative;
`;
const BreadCrumbSeparatorNoStyle = ({ className }) => (
  <li className={className}>
    /
  </li>
);
const BreadCrumbSeparator = styled(BreadCrumbSeparatorNoStyle)`
  display: inline-block;
  color:  ${({ theme }) => theme.colors.colorGrey};
  margin: 0 5px;
`;
const BreadCrumbLink = styled(LinkStyled)`
  font-weight: normal;
  color: ${({ theme }) => theme.colors.colorGrey};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.colorBlue};
    text-decoration: underline;
  }
`;
export const getBreadCrumbRepresentation = breadcrumbs => ({
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: breadcrumbs.map(
    (breadcrumb, index) => {
      if (breadcrumb.props.link && breadcrumb.props.title) {
        return {
          '@type': 'ListItem',
          position: index + 1,
          item: {
            // eslint-disable-next-line no-undef
            '@id': `${window.location.origin}${breadcrumb.props.link}`,
            name: breadcrumb.props.title,
          },
        };
      }
      return null;
    },
  ).filter(f => f != null),
});

export const BreadCrumbsContainer = ({ breadcrumbs, disableBreadCrumbsSchema }) => (
  <StyledBreadCrumbsContainer>
    {breadcrumbs.map((breadcrumb, i) => {
      if (i > 0) {
        return (
          <React.Fragment key={i}>
            <BreadCrumbSeparator />
            {breadcrumb}
          </React.Fragment>
        );
      }
      return (
        <React.Fragment key={i}>
          {breadcrumb}
        </React.Fragment>
      );
    })}
    {!disableBreadCrumbsSchema && (
      <SchemaTag>
        {JSON.stringify(getBreadCrumbRepresentation(breadcrumbs))}
      </SchemaTag>
    )}
  </StyledBreadCrumbsContainer>
);

const BreadCrumbNoProps = ({
  link, title, children, className,
}) => {
  if (link || link === '') {
    return (
      <li className={className}>
        <BreadCrumbLink noStyle to={link} title={title}>
          {children}
        </BreadCrumbLink>
      </li>
    );
  }
  return (
    <li className={className} title={title}>
      {children}
    </li>
  );
};

const StyledBreadCrumb = styled(BreadCrumbNoProps)`
  display: inline-block;

  & a {
    font-weight: normal;
  }
`;

export default StyledBreadCrumb;
