import React from 'react';
import styled from 'styled-components/macro';
import { Money } from '../../../../containers/commerce';
import { LiterFormat } from '../../../Format';
import { Paragraph } from '../../../typography';
import { IconMinusCircle, IconPlusCircle } from './entryComponents';

const EntryWrapNoStyle = ({
  children,
  ...otherProps
}) => (
  <div {...otherProps}>
    {children}
  </div>
);
const EntryWrap = styled(EntryWrapNoStyle)`
  border-bottom: 2px solid ${({ theme }) => theme.colors.colorGreyLight};
  padding-bottom: 10px;
`;

export default (props) => {
  const {
    amount,
    liter,
    cost,
    amountPrPalle,
    actions,
  } = props;
  const {
    incrementItemAmount,
    decrementItemAmount,
  } = actions;
  return (
    <EntryWrap>
      <Paragraph bold>
        Vand med logo:
      </Paragraph>
      <Paragraph marginBottom="0px">
        {amount} x {amountPrPalle} x <LiterFormat liter={liter} />
      </Paragraph>
      <IconMinusCircle onClick={decrementItemAmount} />
      <IconPlusCircle onClick={incrementItemAmount} />
      <Paragraph floatRight margin="5px 0 0 0" bold>
        <Money money={cost} />
      </Paragraph>
    </EntryWrap>
  );
};
