import {
  put, take, actionChannel, select,
} from 'redux-saga/effects';
import { buffers } from 'redux-saga';
import { change, CHANGE } from '../form';
import { STARTUP_COMPLETE } from '../actions';
import getArg from '../../lib/args';

export function getInitialState() {
  const postnrliste = getArg('postnr').split('\n');
  const postnrContents = {};
  for (let i = 0; i < postnrliste.length; i += 1) {
    const elements = postnrliste[i].split(';');
    if (elements.length !== 2) {
      continue; // eslint-disable-line
    }
    const postnr = elements[0].trim();
    const by = elements[1].trim();
    postnrContents[postnr] = by;
  }
  return {
    postnrContents,
  };
}
export const getPostnrContents = ({ postnr: { postnrContents } }) => postnrContents;
export default function (state = {}) {
  return state;
}
export function* postnrSaga() {
  const changeActionChannel = yield actionChannel(CHANGE, buffers.expanding());
  yield take(STARTUP_COMPLETE);
  while (true) {
    const action = yield take(changeActionChannel);
    const { payload: { form, key: field, value: payload } } = action;
    if (field === 'zipCode') {
      const postnrContents = yield select(getPostnrContents);
      const postnr = payload.trim();
      if (postnrContents[postnr]) {
        if (form === 'invoice-details') {
          yield put(change('invoice-details', 'city', postnrContents[postnr]));
        } else if (form === 'delivery-details') {
          yield put(change('delivery-details', 'city', postnrContents[postnr]));
        }
      }
    }
  }
}
