
export const getLocalState = state => state.form;

export const pickFormValue = form => (state, key) => {
  if (!state[form] || !state[form][key]) {
    return '';
  }
  return state[form][key];
};
export const formValueSelector = form => (state, ...keys) => {
  if (!keys || keys.length === null) {
    return null;
  }
  const localState = getLocalState(state);
  if (keys.length === 1) {
    return pickFormValue(form)(localState, keys[0]);
  }
  return keys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: pickFormValue(form)(localState, key),
    }),
    {},
  );
};

const invoiceDetailsSelector = formValueSelector('invoice-details');
export const getKundenr = (state) => {
  let kundenr = invoiceDetailsSelector(
    state,
    'kundenr',
  );
  if (kundenr) {
    kundenr = kundenr.trim();
    if (kundenr.length !== 5 || !/^\d+$/.test(kundenr)) {
      return '';
    }
  }
  return kundenr;
};
