import { createSelector } from 'reselect';
import {
  getBucketItemsWithoutWater,
  getWaterBucketItems,
  getItemAmounts,
  getItemSingleAmount,
  getItemAmount,
  isWaterItem,
  getItemEntry,
  getBucketItemEntries,
  getBucketItems,
} from './bucket/selectors';
import { paymentTypes } from './payment';
import {
  getDeliveryType,
  deliveryTypes,
  getDeliveryTypeError,
  getDate,
  isDelivery,
  getDeliveryFees as getDeliveryDeliveryFees,
  hasDateAsPossibility,
  isRequired,
  isInSpecialMode,
} from './delivery';
import {
  getSubDeliveryType,
  subDeliveryTypes,
} from './delivery/dates/delivery';
import {
  getCost,
  isFustage,
  isKulsyre,
  isLeje,
  isPalleItem,
  isReservedelOrDraughtmaster,
  getCasePrice,
  getPant,
  getReturPant,
  getItemFustagePant,
  getSingleKassePant,
  getPantKasseIdentifier,
  getItemFreeDeliveryBasis,
  getItemFromLocalState,
  itemHasDefaultInformation,
  itemIsOutOfStock,
  getTotalColliAmount,
  getItemName,
  isAnlaeg,
  isIs,
  isGlass,
  isReturPantItem,
  getItemLiter,
  getItemWeight,
  getItemInconsistencyStatus,
  getAmountOfAnlaegInVarenr,
  isWagon,
  getAlcoholPercentage,
  getItemPostnord,
  isFreeItem,
  isDiscountAmountItem,
  getFadKobling,
} from './items';
import { dateTypes } from './delivery/dates';
import {
  getCostFromEntries,
  getPantFromEntries,
} from './water/selectors';
import { sanitizeFloat } from '../../lib/helpers';
import { hasDeliveryInfo } from './details';
import { formValueSelector } from '../form/selectors';
import externalDeliveryLookup from '../../content/commerce/externalDelivery';

export const getDateDiff = createSelector(
  getDate(dateTypes.delivery),
  getDate(dateTypes.pickup),
  getDeliveryType,
  (deliveryDate, pickupDate, deliveryType) => {
    switch (deliveryType) {
      case deliveryTypes.no:
      case deliveryTypes.deliveryAndPickup: {
        if (pickupDate && deliveryDate) {
          return pickupDate.diff(deliveryDate, 'days');
        }
        return 1;
      }
      default:
        return 1;
    }
  },
);
export const getBucketItemsWithoutReturPant = createSelector(
  state => state,
  (state) => getBucketItems(state),
  (state, bucketItems) => bucketItems.filter(
    varenr => !isReturPantItem(state, { varenr }),
  ),
);

export const getBucketItemsWithoutReturPantAndAnlaegTillaeg = createSelector(
  getBucketItemsWithoutReturPant,
  bucketItems => bucketItems.filter(varenr => varenr !== '603' && varenr !== '605'),
);

export const hasLejeItem = createSelector(
  state => state.commerce.items,
  state => getBucketItemsWithoutWater(state),
  (stateItems, bucketItems) => bucketItems.reduce((hasLeje, varenr) => (
    hasLeje || isLeje(stateItems, { varenr })
  ), false),
);

export const hasOnlyPostnordItems = createSelector(
  state => state.commerce.items,
  state => getBucketItemsWithoutWater(state),
  (stateItems, bucketItems) => bucketItems.length > 0 && bucketItems.reduce((onlyPostnord, varenr) => (
    onlyPostnord && getItemPostnord(varenr)
  ), true),
);
export const hasFustageItem = createSelector(
  state => state.commerce.items,
  state => getBucketItemsWithoutWater(state),
  (stateItems, bucketItems) => bucketItems.reduce((hasFustage, varenr) => (
    hasFustage || isFustage(stateItems, { varenr })
  ), false),
);
export const getItemInconsistensies = createSelector(
  state => state.commerce.items,
  state => getBucketItemsWithoutWater(state),
  state => state,
  (stateItems, bucketItems, state) => bucketItems.reduce((inconsistencies, varenr) => {
    const inconsistencyStatus = getItemInconsistencyStatus(state, { varenr });
    if (inconsistencyStatus) {
      const name = getItemName(state, { varenr });
      return [
        ...inconsistencies,
        { varenr, status: inconsistencyStatus, name },
      ];
    }
    return inconsistencies;
  }, []),
);

// Indikerer om der er en vare i kurven fra bestil eller bestilfadøl(Ikke kun reservedele og draughtmaster)
export const hasBestilOrFadoelItem = createSelector(
  state => state.commerce.items,
  state => getBucketItemsWithoutWater(state),
  (stateItems, bucketItems) => bucketItems.reduce((hasBestilOrFadoel, varenr) => (
    hasBestilOrFadoel || (!isReservedelOrDraughtmaster(stateItems, { varenr }))
  ), false),
);

export const hasOnlyFreeDeliveryItems = createSelector(
  state => state.commerce.items,
  state => getBucketItemsWithoutWater(state),
  (stateItems, bucketItems) => bucketItems.every(varenr => (
    isFreeItem(stateItems, { varenr })
  )),
);

export const hasKulsyreItem = createSelector(
  state => state.commerce.items,
  state => getBucketItemsWithoutWater(state),
  (stateItems, bucketItems) => bucketItems.reduce((hasKulsyre, varenr) => (
    hasKulsyre || (isKulsyre(stateItems, { varenr }))
  ), false),
);

export const getAmountOfDraughtmaster = state => getItemAmount(state, { varenr: '2614' });

export const getAmountOfAnlaeg = createSelector(
  state => state.commerce.items,
  state => state,
  state => getBucketItemsWithoutWater(state),
  (stateItems, state, bucketItems) => bucketItems.reduce(
    (anlaeg, varenr) => (
      isAnlaeg(stateItems, { varenr })
        ? anlaeg + (getAmountOfAnlaegInVarenr(stateItems, { varenr }) * getItemAmount(state, { varenr }))
        : anlaeg
    ),
    0,
  ),
);
export const getDiscountAmount = createSelector(
  state => state.commerce.items,
  state => state,
  state => getBucketItemsWithoutWater(state),
  (stateItems, state, bucketItems) => Math.floor(
    bucketItems.reduce(
      (discountAmountSemi, varenr) => (
        isDiscountAmountItem(stateItems, { varenr })
          ? discountAmountSemi + getItemAmount(state, { varenr })
          : discountAmountSemi
      ),
      0,
    ) / 3,
  ),
);
export const getAmountOfWagons = createSelector(
  state => state.commerce.items,
  state => state,
  state => getBucketItemsWithoutWater(state),
  (stateItems, state, bucketItems) => bucketItems.reduce(
    (wagons, varenr) => (
      isWagon(stateItems, { varenr })
        ? wagons + getItemAmount(state, { varenr })
        : wagons
    ),
    0,
  ),
);
export const getNonExistingItems = createSelector(
  state => getBucketItemsWithoutWater(state),
  state => state.commerce.items,
  (bucketItems, stateItems) => bucketItems.reduce(
    (nonExistingItems, varenr) => {
      const item = getItemFromLocalState(stateItems, { varenr });
      if (!item) {
        return [...nonExistingItems, varenr];
      }
      return nonExistingItems;
    },
    [],
  ),
);
export const hasAnlaegItem = createSelector(
  state => state.commerce.items,
  state => getBucketItemsWithoutWater(state),
  (stateItems, bucketItems) => bucketItems.reduce(
    (hasAnlaeg, varenr) => hasAnlaeg || isAnlaeg(stateItems, { varenr }),
    false,
  ),
);

export const hasSomeNoneSTypeFustageButNoAnlaeg = createSelector(
  state => state.commerce.items,
  state => getBucketItemsWithoutReturPant(state),
  hasAnlaegItem,
  (stateItems, bucketItems, hasAnlaeg) => {
    if (hasAnlaeg) {
      return false;
    }
    const fustager = bucketItems.filter(
      varenr => isFustage(stateItems, { varenr }),
    );
    return fustager.some(varenr => getFadKobling(stateItems, { varenr }) !== 'S');
  },
);

export const hasIsItem = createSelector(
  state => state.commerce.items,
  state => getBucketItemsWithoutWater(state),
  (stateItems, bucketItems) => bucketItems.some(
    varenr => isIs(stateItems, { varenr }),
  ),
);

export const hasGlassItem = createSelector(
  state => state.commerce.items,
  state => getBucketItemsWithoutWater(state),
  (stateItems, bucketItems) => bucketItems.some(
    varenr => isGlass(stateItems, { varenr }),
  ),
);

export const hasAnlaegWithoutIs = createSelector(
  hasAnlaegItem,
  hasIsItem,
  (hasAnlaeg, hasIs) => hasAnlaeg && !hasIs,
);

export const hasAnlaegWithoutGlass = createSelector(
  hasAnlaegItem,
  hasGlassItem,
  (hasAnlaeg, hasGlass) => hasAnlaeg && !hasGlass,
);

export const hasFustagerWithoutAnlaeg = createSelector(
  hasFustageItem,
  hasAnlaegItem,
  (hasFustage, hasAnlaeg) => hasFustage && !hasAnlaeg,
);

export const getNonDataItems = createSelector(
  state => getBucketItemsWithoutWater(state),
  bucketItems => bucketItems.reduce(
    (nonDataItems, varenr) => {
      if (!itemHasDefaultInformation(varenr)) {
        return [...nonDataItems, varenr];
      }
      return nonDataItems;
    },
    [],
  ),
);

export const getSoldOutItems = createSelector(
  state => getBucketItemsWithoutWater(state),
  state => state,
  (bucketItems, state) => bucketItems.reduce(
    (soldOutItems, varenr) => {
      if (itemIsOutOfStock(state, varenr)) {
        return [...soldOutItems, varenr];
      }
      return soldOutItems;
    },
    [],
  ),
);
export const hasWaterItems = createSelector(
  state => getWaterBucketItems(state),
  waterBucketItems => waterBucketItems.length > 0,
);
export const getNumberOfPaller = createSelector(
  state => state.commerce.items,
  state => getBucketItemsWithoutWater(state),
  state => getWaterBucketItems(state),
  state => state,
  (stateItems, bucketItems, waterBucketItems, state) => {
    const amounts = getItemAmounts(state, { items: bucketItems });
    const waterAmounts = getItemAmounts(state, { items: waterBucketItems });
    return amounts.reduce(
      (amountOfPaller, { varenr, amount }) => {
        const isPalle = isPalleItem(stateItems, { varenr });
        if (isPalle) {
          return amountOfPaller + amount;
        }
        return amountOfPaller;
      },
      0,
    ) + waterAmounts.reduce(
      (amountOfPaller, { amount }) => amountOfPaller + amount,
      0,
    );
  },
);

export const getItemCostBasedOnAmount = (state, { varenr, amount }) => {
  if (isWaterItem(state, { varenr })) {
    const itemEntry = getItemEntry(state, { varenr });
    const overwrittenItemEntry = {
      ...itemEntry,
      amount,
    };
    return getCostFromEntries(state, overwrittenItemEntry);
  }
  const days = getDateDiff(state);
  return getCost(state, {
    varenr,
    amount,
    singleAmount: 0,
    days,
  });
};

export const getItemCost = (state, { varenr }) => {
  if (isWaterItem(state, { varenr })) {
    const itemEntry = getItemEntry(state, { varenr });
    return getCostFromEntries(state, itemEntry);
  }
  const amount = getItemAmount(state, { varenr });
  const singleAmount = getItemSingleAmount(state, { varenr });
  const days = getDateDiff(state);
  return getCost(state, {
    varenr,
    amount,
    singleAmount,
    days,
  });
};

export const getFreeDeliveryBasis = createSelector(
  state => getBucketItemsWithoutWater(state),
  state => state,
  (items, state) => {
    const amounts = getItemAmounts(state, { items });
    return Math.min(
      amounts.reduce(
        (acc, { varenr, amount, singleAmount }) => {
          const freeDeliveryBasis = getItemFreeDeliveryBasis(state, { varenr });
          const basis = freeDeliveryBasis || 0;
          return acc + (basis * (amount + singleAmount));
        },
        0,
      ),
      1,
    );
  },
);

export const getTotalReturPant = createSelector(
  state => getBucketItemsWithoutWater(state),
  state => state,
  (bucketItems, state) => {
    const bucketItemAmounts = getItemAmounts(state, { items: bucketItems });
    return bucketItemAmounts.reduce(
      (pant, bucketItemAmount) => pant + getReturPant(state, bucketItemAmount),
      0,
    );
  },
);

export const getItemAlcoholLevel = createSelector(
  state => getBucketItemsWithoutWater(state),
  state => state,
  (bucketItems, state) => {
    let level = 'NONE';
    for (let i = 0; i < bucketItems.length; i += 1) {
      const varenr = bucketItems[i];
      const alcoholPercentage = getAlcoholPercentage(state, { varenr });
      if (alcoholPercentage >= 1.2) {
        if (alcoholPercentage >= 16.5) {
          level = '18';
        } else if (level !== '18') {
          level = '16';
        }
      }
    }
    return level;
  },
);
export const getFlaskePant = createSelector(
  state => getBucketItemsWithoutWater(state),
  state => getWaterBucketItems(state),
  state => state,
  (bucketItems, waterItems, state) => {
    const bucketItemAmounts = getItemAmounts(state, { items: bucketItems });
    const itemPant = bucketItemAmounts.reduce(
      (pant, bucketItemAmount) => pant + getPant(state, bucketItemAmount),
      0,
    );
    const waterPant = waterItems.reduce(
      (pant, varenr) => {
        const itemEntry = getItemEntry(state, { varenr });
        return pant + getPantFromEntries(state, itemEntry);
      },
      0,
    );
    return itemPant + waterPant;
  },
);
export const getKassePant = createSelector(
  state => state.commerce.items,
  state => getBucketItemsWithoutWater(state),
  state => state,
  (items, bucketItems, state) => {
    const kasseItems = bucketItems.filter(
      varenr => getSingleKassePant(state, { varenr }) > 0,
    );
    const kasseAmounts = getItemAmounts(state, { items: kasseItems });
    const calcBasis = kasseAmounts.reduce(
      (acc, { varenr, amount, singleAmount }) => {
        const identifier = getPantKasseIdentifier(state, { varenr });
        const {
          [identifier]: existing,
        } = acc;
        if (existing) {
          return {
            ...acc,
            [identifier]: {
              ...existing,
              amount: existing.amount + amount,
              singleAmount: existing.singleAmount + singleAmount,
            },
          };
        }
        return {
          ...acc,
          [identifier]: {
            amount,
            singleAmount,
            pant: getSingleKassePant(state, { varenr }),
          },
        };
      },
      {},
    );
    return Object.keys(calcBasis).reduce(
      (sum, key) => {
        const {
          [key]: {
            amount,
            singleAmount,
            pant,
          },
        } = calcBasis;
        return sum + ((amount + Math.ceil(sanitizeFloat(singleAmount))) * pant);
      },
      0,
    );
  },
);
export const getFustagePant = createSelector(
  getDeliveryType,
  state => state.commerce.items,
  state => getBucketItemsWithoutWater(state),
  state => state,
  (deliveryType, stateItems, bucketItems, state) => {
    if (deliveryType === deliveryTypes.deliveryAndPickup) {
      return 0;
    }
    const fustageItems = bucketItems.filter(
      varenr => isFustage(stateItems, { varenr }),
    );
    const fustageAmounts = getItemAmounts(state, { items: fustageItems });
    return fustageAmounts.reduce(
      (pant, fustageAmount) => pant + getItemFustagePant(state, fustageAmount),
      0,
    );
  },
);

export const getWaterWeight = createSelector(
  state => getWaterBucketItems(state),
  state => state,
  (waterBucketItems, state) => waterBucketItems.reduce(
    (waterWeight, varenr) => {
      const {
        size,
        amount,
      } = getItemEntry(state, { varenr });
      if (size === 'halv') {
        return waterWeight + (amount * (7 / 6) * getItemWeight(state, { varenr: '9212' }));
      }
      if (size === 'tredjedel') {
        return waterWeight + (amount * getItemWeight(state, { varenr: '9239' }));
      }
      return waterWeight;
    },
    0,
  ),
);
export const getTotalWeight = createSelector(
  state => state.commerce.items,
  state => getBucketItemsWithoutWater(state),
  state => getWaterWeight(state),
  state => state,
  (stateItems, bucketItems, waterWeight, state) => {
    const amounts = getItemAmounts(state, { items: bucketItems });
    return amounts.reduce(
      (weight, { varenr, amount }) => weight + (amount * getItemWeight(state, { varenr })),
      waterWeight,
    );
  },
);

export const getFustageLiter = createSelector(
  state => state.commerce.items,
  state => getBucketItemsWithoutWater(state),
  state => state,
  (stateItems, bucketItems, state) => {
    const fustageItems = bucketItems.filter(
      varenr => isFustage(stateItems, { varenr }),
    );
    const fustageAmounts = getItemAmounts(state, { items: fustageItems });
    return fustageAmounts.reduce(
      (liter, { varenr, amount }) => liter + (amount * getItemLiter(state, { varenr })),
      0,
    );
  },
);

export const getFustageCost = createSelector(
  state => state.commerce.items,
  state => getBucketItemsWithoutWater(state),
  state => getDateDiff(state),
  state => state,
  (stateItems, bucketItems, days, state) => {
    const fustageItems = bucketItems.filter(
      varenr => isFustage(stateItems, { varenr }),
    );
    const fustageAmounts = getItemAmounts(state, { items: fustageItems });
    return fustageAmounts.reduce(
      (cost, amount) => cost + getCost(state, { ...amount, days }),
      0,
    );
  },
);

export const getKulsyrePant = createSelector(
  state => state.commerce.items,
  state => getBucketItemsWithoutWater(state),
  state => state,
  (stateItems, bucketItems, state) => {
    const kulsyreItems = bucketItems.filter(
      varenr => isKulsyre(stateItems, { varenr }),
    );
    const kulsyreAmounts = getItemAmounts(state, { items: kulsyreItems });
    return kulsyreAmounts.reduce(
      (pant, { varenr, amount }) => pant + (getCasePrice(state, { varenr }) * amount),
      0,
    );
  },
);

export const deliveryTypeError = (state, deliveryType) => getDeliveryTypeError(deliveryType)(state);

export const deliveryTypeIsPossible = (state, deliveryType) => deliveryTypeError(state, deliveryType) == null;

export const isDeliveryInConflict = (state) => {
  const deliveryType = getDeliveryType(state);
  return !deliveryTypeIsPossible(state, deliveryType);
};

export const getFeePrice = state => createSelector(
  fees => fees,
  () => state,
  (fees, state2) => Object.keys(fees).reduce(
    (acc, varenr) => {
      const { amount } = fees[varenr];
      const cost = getCost(state2, {
        varenr,
        amount,
        singleAmount: 0,
        days: 1,
      });
      return acc + cost;
    },
    0,
  ),
);

export const getEffectiveZip = (state) => {
  if (hasDeliveryInfo(state)) {
    return formValueSelector('delivery-details')(state, 'zipCode');
  }
  return formValueSelector('invoice-details')(state, 'zipCode');
};

export const getExternalZip = (state) => {
  const effectiveZip = (getEffectiveZip(state) || '').trim();
  const isDel = isDelivery(state);
  return isDel && externalDeliveryLookup[effectiveZip] && effectiveZip && getSubDeliveryType(dateTypes.delivery)(state) !== subDeliveryTypes.postNord;
};

export const getDeliveryFees = (state, server, externalZip) => {
  if (!server && isInSpecialMode(state)) {
    if (isDelivery(state)) {
      return {
        636: {
          amount: 100,
          main: true,
        },
      };
    }
    return {};
  }
  const rest = getDeliveryDeliveryFees(state);

  if (externalZip || (!server && getExternalZip(state))) {
    return {
      ...rest,
      [634]: {
        amount: 1,
      },
    };
  }
  return rest;
};

export const getDeliveryCost = (state, server, externalZip) => {
  const fees = getDeliveryFees(state, server, externalZip);
  return getFeePrice(state)(
    fees,
  );
};

const filterObject = (object, callback) => Object.keys(object).reduce(
  (acc, objectKey) => {
    if (callback(object[objectKey])) {
      return {
        ...acc,
        [objectKey]: object[objectKey],
      };
    }
    return acc;
  },
  {},
);
export const getDeliveryCostWithoutFees = state => getFeePrice(state)(
  filterObject(
    getDeliveryFees(state),
    ({ main }) => main,
  ),
);

export const getDeliveryFeesCost = state => getFeePrice(state)(
  filterObject(
    getDeliveryFees(state),
    ({ main }) => !main,
  ),
);

export const getDeliveryFeesCostWithoutExternalDelivery = (state) => {
  const deliveryFees = getDeliveryFees(state);
  return getFeePrice(state)(Object.keys(deliveryFees).reduce(
    (acc, varenr) => {
      const { main } = deliveryFees[varenr];
      if (!main && `${varenr}` !== '634') {
        return {
          ...acc,
          [varenr]: deliveryFees[varenr],
        };
      }
      return acc;
    },
    {},
  ));
};

export const getExternalDeliveryFeeCost = (state) => {
  const deliveryFees = getDeliveryFees(state);
  return getFeePrice(state)(Object.keys(deliveryFees).reduce(
    (acc, varenr) => {
      if (`${varenr}` === '634') {
        return {
          ...acc,
          [varenr]: deliveryFees[varenr],
        };
      }
      return acc;
    },
    {},
  ));
};

export const getTotalCostWithoutPant = (state, server, externalZip) => {
  const items = getBucketItemsWithoutWater(state);
  const amounts = getItemAmounts(state, { items });
  const deliveryCost = getDeliveryCost(state, server, externalZip);
  const waterItems = getWaterBucketItems(state);
  const days = getDateDiff(state);
  const itemCost = amounts.reduce(
    (acc, amount) => acc + getCost(state, { ...amount, days }),
    0,
  );
  const waterCost = waterItems.reduce(
    (acc, varenr) => {
      const itemEntry = getItemEntry(state, { varenr });
      return getCostFromEntries(state, itemEntry) + acc;
    },
    0,
  );
  return itemCost + waterCost + deliveryCost;
};

export const getTotalCostWithoutPantAndAnlaegTillaeg = (state) => {
  const items = getBucketItemsWithoutWater(state);
  const amounts = getItemAmounts(state, { items });
  const deliveryCost = getDeliveryCost(state);
  const waterItems = getWaterBucketItems(state);
  const days = getDateDiff(state);
  const itemCost = amounts.reduce(
    (acc, amount) => {
      if (amount.varenr === '603' || amount.varenr === '605') {
        return acc;
      }
      return acc + getCost(state, { ...amount, days });
    },
    0,
  );
  const waterCost = waterItems.reduce(
    (acc, varenr) => {
      const itemEntry = getItemEntry(state, { varenr });
      return getCostFromEntries(state, itemEntry) + acc;
    },
    0,
  );
  return itemCost + waterCost + deliveryCost;
};

export const paymentMethodIsPossible = (state, paymentMethod) => {
  const deliveryType = getDeliveryType(state);
  if (deliveryType !== deliveryTypes.no
      && paymentMethod === paymentTypes.cash) {
    return false;
  }
  return true;
};

export const getOrderItemEntries = createSelector(
  state => getBucketItemEntries(state),
  state => getDateDiff(state),
  state => state.commerce.items,
  state => state,
  (bucketItemEntries, days, itemsState, state) => bucketItemEntries.map(
    (bucketItemEntry) => {
      const { isWater, amount, ...restEntry } = bucketItemEntry;
      if (isWater) {
        const singleCost = getCostFromEntries(state, { ...restEntry, amount: 1 });
        const totalCost = getCostFromEntries(state, { ...restEntry, amount });
        return {
          amount,
          isWater: true,
          singleCost,
          totalCost,
          ...restEntry,
        };
      }
      const { varenr, ...restRestEntry } = restEntry;
      let { singleAmount } = restRestEntry;
      const singleCost = getCost(state, {
        varenr,
        days,
        amount: 1,
        singleAmount: 0,
      });
      const totalCost = getCost(state, {
        varenr,
        singleAmount,
        amount,
        days,
      });
      const exactQuantity = `${amount + singleAmount}`;
      if (singleAmount > 0) {
        const colli = getTotalColliAmount(state, { varenr });
        singleAmount = Math.round(singleAmount * colli);
      }
      const name = getItemName(state, { varenr });
      return {
        name,
        varenr,
        singleAmount,
        amount,
        singleCost,
        totalCost,
        exactQuantity,
      };
    },
  ),
);


export const getOrderType = (state) => {
  const hasLeje = hasLejeItem(state);
  const hasBestilOrFadoel = hasBestilOrFadoelItem(state);
  const hasWaterItem = hasWaterItems(state);
  if (hasWaterItem) {
    return 'WATER_ORDER';
  }
  if (!hasBestilOrFadoel) {
    return 'GENERIC_ORDER';
  }
  if (!hasLeje) {
    return 'BESTIL_ORDER';
  }
  return 'FADOEL_ORDER';
};


export const getTotalPant = (state) => {
  const flaskePant = getFlaskePant(state);
  const kassePant = getKassePant(state);
  const hasReturn = isRequired(dateTypes.pickup)(state) || (
    hasDateAsPossibility(dateTypes.pickup)(state)
    && Boolean(getDate(dateTypes.pickup)(state))
  );
  const fustagePant = hasReturn ? 0 : getFustagePant(state);
  const kulsyrePant = hasReturn ? 0 : getKulsyrePant(state);
  return flaskePant + kassePant + fustagePant + kulsyrePant;
};

export const getTotalCost = (state, server, externalZip) => {
  const withoutPant = getTotalCostWithoutPant(state, server, externalZip);
  const totalPant = getTotalPant(state);
  const returPant = getTotalReturPant(state);
  return (withoutPant + totalPant) - returPant;
};

export const getTotalCostWithoutAnalegTillaeg = (state) => {
  const withoutPant = getTotalCostWithoutPantAndAnlaegTillaeg(state);
  const totalPant = getTotalPant(state);
  const returPant = getTotalReturPant(state);
  return (withoutPant + totalPant) - returPant;
};

export const getItemChangeEntries = (state, order) => {
  const {
    pant,
    deliveryCost,
    itemEntries,
  } = order;
  const errors = [];

  const days = getDateDiff(state);
  const calculatedItemTotal = itemEntries.reduce(
    (current, { totalCost }) => current + totalCost,
    0,
  );
  const items = getBucketItemsWithoutWater(state);
  const amounts = getItemAmounts(state, { items });
  const waterItems = getWaterBucketItems(state);
  let currentTotal = 0;
  amounts.forEach(
    (amount) => {
      const { varenr } = amount;
      const itemEntry = itemEntries.find(({ varenr: itemEntryVarenr }) => itemEntryVarenr === varenr);

      const currentTotalCost = getCost(state, { ...amount, days });
      currentTotal += currentTotalCost;
      if (itemEntry) {
        const { totalCost, name } = itemEntry;
        if (totalCost !== currentTotalCost) {
          errors.push(`Prisen på ${name} har ændret sig`);
        }
      }
    },
  );
  waterItems.forEach(
    (varenr) => {
      const itemEntry = itemEntries.find(({ isWater }) => isWater);
      const currentItemEntry = getItemEntry(state, { varenr });
      const currentCost = getCostFromEntries(state, currentItemEntry);
      currentTotal += currentCost;
      if (itemEntry) {
        const { totalCost } = itemEntry;
        if (totalCost !== currentCost) {
          errors.push('Prisen på vand uden logo har ændret sig');
        }
      }
    },
  );
  if (errors.length === 0 && currentTotal !== calculatedItemTotal) {
    // I tilfælde af at vi ikke kunne finde fejl på en specifik vare, men at der er fejl i vare subtotalen
    errors.push('Prisen på nogle varer har ændret sig');
  }
  const currentPant = getTotalPant(state);
  if (pant !== currentPant) {
    errors.push(`Panten har ændret sig fra ${currentPant} til ${pant}`);
  }
  const currentDeliveryCost = getDeliveryCost(state);
  if (currentDeliveryCost !== deliveryCost) {
    errors.push(`Leveringsomkostningerne har ændret sig fra ${currentDeliveryCost} til ${deliveryCost}`);
  }
  return errors;
};
