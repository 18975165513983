import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from './selectors';
import { change } from './actions';

const translateForm = { invoice: 'invoice-details', delivery: 'delivery-details', ean: 'ean-form' };

const Field = ({
  component: Component,
  ...props
}) => (
  <Component {...props} />
);

export default connect(
  (state, { formKey, name }) => ({
    value: formValueSelector(translateForm[formKey])(state, name),
  }),
  (dispatch, { formKey, name }) => ({
    onChange: ({ target: { value } }) => dispatch(change(translateForm[formKey], name, value)),
  }),
)(Field);
