import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import LogoBobler from './LogoBobler';

const LogoLink = styled(Link)`
  color: ${({ theme }) => theme.colors.colorBlue};
  text-decoration: none;
  position: relative;
  display: inline-block;

  &:hover {
    color: ${({ theme }) => theme.hoverColors.colorBlue};

    & > svg {
      fill: ${({ theme }) => theme.hoverColors.colorBlue};
    }
  }
  
  ${({ responsive }) => (responsive
    ? 'padding-right: 30px;'
    : 'padding-right: 50px;'
  )}

  span {
    display: block;
    font-family: 'Inter', sans-serif;
  }

  @media (max-width: 500px) {
    ${({ responsive }) => responsive && 'padding-right: 20px;'}
  }

  @media (max-width: 440px) {
    padding-right: 0px;
  }
`;
const LogoText1 = styled.span`
  font-weight: bold;
  text-transform: uppercase;

  ${({ responsive }) => (responsive
    ? 'font-size: 28px;'
    : 'font-size: 32px; margin-bottom: 3.5px;'
  )}

  @media (max-width: 440px) {
    font-size: 20px;
    margin-top: 2px;
  }

  @media (max-width: 340px) {
    font-size: 16px;
    margin-top: 3px;
  }
`;
const LogoText2 = styled.span`
  font-weight: 500;
  font-size: 15.5px;
`;
const SkafteBobler = styled(LogoBobler)`
  position: absolute;
  right: 0;
  width: auto;

  ${({ responsive }) => (responsive
    ? 'height: 22px; top: 3px;'
    : 'height: 45px; top: 5px;'
  )}

  @media (max-width: 575px) {
    display: none;
  }
`;

export default ({
  to,
  title,
  text1,
  text2,
  responsive,
}) => (
  <LogoLink responsive={responsive} to={to} title={title}>
    <LogoText1 responsive={responsive}>
      {text1}
    </LogoText1>
    <LogoText2>
      {text2}
    </LogoText2>
    <SkafteBobler responsive={responsive} />
  </LogoLink>
);
