import {
  all,
  take,
  takeLatest,
  call,
  select,
  takeEvery,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { fbq, pushToDataLayer } from '../lib/analytics';
import {
  ITEM_AMOUNT_INCREMENT,
  ITEM_AMOUNT_SET,
  ITEM_AMOUNT_DECREMENT,
} from './commerce/bucket/actions';
import { getItemCostBasedOnAmount } from './commerce/selectors';
import {
  getLocalState as getBucketState,
  getBucketDifferences,
} from './commerce/bucket/selectors';
import { getItemDefaultInformation } from '../content/commerce/items';
import { GENERAL_SEARCH_CHANGE, SEARCH_CHANGE } from './commerce/search/actions';
import { getGeneralSearchTerm } from './commerce/search/selectors';
import { STARTUP_COMPLETE, ANALYTICS_LOG_VIEW_PRODUCT } from './actions';
import { itemReverseLookup, pageReverseLookup } from '../content/commerce/itemLookup';

function* addToBucketDetector() { // eslint-disable-line
  yield take(STARTUP_COMPLETE);
  let currentBucket = yield select(getBucketState);
  while (true) {
    yield take([ITEM_AMOUNT_INCREMENT, ITEM_AMOUNT_SET, ITEM_AMOUNT_DECREMENT]);
    const newBucket = yield select(getBucketState);
    if (currentBucket !== newBucket) {
      const differences = getBucketDifferences(currentBucket, newBucket);
      const state = yield select(state => state);
      const addedItems = [];
      for (let i = 0; i < differences.ADDED_ITEMS.length; i += 1) {
        const { varenr, amount } = differences.ADDED_ITEMS[i];
        const itemCost = getItemCostBasedOnAmount(state, { varenr, amount: 1 });
        const defaultInfo = getItemDefaultInformation(varenr) || {};
        const { name } = defaultInfo;

        const dataLayerItem = {
          item_id: varenr,
          quantity: amount,
          item_name: name,
          price: itemCost / 100,
          currency: 'DKK',
        };
        if (itemReverseLookup[varenr]) {
          dataLayerItem.item_category = itemReverseLookup[varenr];
          if (pageReverseLookup[itemReverseLookup[varenr]]) {
            dataLayerItem.item_category2 = pageReverseLookup[itemReverseLookup[varenr]];
          }
        }
        addedItems.push(dataLayerItem);
      }
      const removedItems = [];
      for (let i = 0; i < differences.REMOVED_ITEMS.length; i += 1) {
        const { varenr, amount } = differences.REMOVED_ITEMS[i];
        const itemCost = getItemCostBasedOnAmount(state, { varenr, amount: 1 });
        const defaultInfo = getItemDefaultInformation(varenr) || {};
        const { name } = defaultInfo;
        const dataLayerItem = {
          item_id: varenr,
          quantity: amount,
          item_name: name,
          price: itemCost / 100,
          currency: 'DKK',
        };
        if (itemReverseLookup[varenr]) {
          dataLayerItem.item_category = itemReverseLookup[varenr];
          if (pageReverseLookup[itemReverseLookup[varenr]]) {
            dataLayerItem.item_category2 = pageReverseLookup[itemReverseLookup[varenr]];
          }
        }
        removedItems.push(dataLayerItem);
      }
      if (addedItems.length > 0 || removedItems.length > 0) {
        pushToDataLayer({ ecommerce: null });
      }
      if (addedItems.length > 0) {
        fbq('track', 'AddToCart');
        const value = addedItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
        pushToDataLayer({
          event: 'add_to_cart',
          ecommerce: {
            currency: 'DKK',
            value,
            items: addedItems,
          },
        });
      }
      if (removedItems.length > 0) {
        const value = removedItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
        pushToDataLayer({
          event: 'remove_from_cart',
          ecommerce: {
            currency: 'DKK',
            value,
            items: removedItems,
          },
        });
      }
    }
    currentBucket = newBucket;
  }
}

function* search() { // eslint-disable-line
  yield call(delay, 500);
  pushToDataLayer({
    event: 'search',
    search_string: yield select(getGeneralSearchTerm),
  });
  fbq('track', 'Search');
}

function* itemView(action) {
  const { payload: varenr } = action;
  if (varenr) {
    const state = yield select(state => state);
    const itemCost = getItemCostBasedOnAmount(state, { varenr, amount: 1 });
    const defaultInfo = getItemDefaultInformation(varenr) || {};
    const { name } = defaultInfo;
    const dataLayerItem = {
      item_id: varenr,
      item_name: name,
      price: itemCost / 100,
      quantity: 1,
      currency: 'DKK',
    };
    if (itemReverseLookup[varenr]) {
      dataLayerItem.item_category = itemReverseLookup[varenr];
      if (pageReverseLookup[itemReverseLookup[varenr]]) {
        dataLayerItem.item_category2 = pageReverseLookup[itemReverseLookup[varenr]];
      }
    }
    pushToDataLayer({ ecommerce: null });
    pushToDataLayer({
      event: 'view_item',
      varenr,
      ecommerce: {
        currency: 'DKK',
        value: itemCost / 100,
        items: [dataLayerItem],
      },
    });
  }
}

export default all([
  addToBucketDetector(),
  takeLatest([SEARCH_CHANGE, GENERAL_SEARCH_CHANGE], search),
  takeEvery([ANALYTICS_LOG_VIEW_PRODUCT], itemView),
]);
