import React from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { BsCalculatorFill, BsCheck, BsCreditCard, FaCog, FiArrowRight, GrCart } from '../Icons';

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${({ alignCenter }) => (alignCenter ? 'center' : 'start')};
  justify-content: ${({ justifyRight, justifyCenter }) => (justifyRight ? 'flex-end' : justifyCenter ? 'center' : 'flex-start')};
  height:  ${({ expandHeight }) => (expandHeight ? '100%' : 'auto')};
`;

const TypeSwitch = ({ action, anchor, to, ...otherProps }) => {
  if (action) {
    return <button type="button" {...otherProps} />;
  }
  if (anchor) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a href={to} {...otherProps} />;
  }
  return <Link to={to} {...otherProps} />;
};

const Button = styled(TypeSwitch)`
  background: ${({ theme, red }) => theme.colors[red ? 'colorRed' : 'colorGreen']};
  color: ${({ theme }) => theme.colors.colorWhite};
  display: flex;
  align-items: center;
  border: none;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  line-height: 1.5em;
  transition: background 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme.hoverColors.colorGreen};
  }

  text-align: ${({ textCenter }) => (textCenter ? 'center' : 'left')};
  
  ${({ small, xtraSmall }) =>
    (small || xtraSmall)
    ? css`
      padding: ${xtraSmall ? '8px 10px' : '10px 15px'};
      font-size: 14px;
      border-radius: 10px;
    `
    : css`
      padding: 15px;
      font-size: 16px;
      border-radius: 15px;
    `
  }

  ${({ fullWidth, godkend, small }) =>
    (fullWidth || godkend) &&
    css`
      margin-top: 10px;
      width: 100%;
      justify-content: center;
      gap: 15px;
      font-size: ${godkend ? '20px' : (small ? '14px' : '16px')};
    `
  }

  ${({ fullWidth, godkend }) =>
    !fullWidth &&
    !godkend &&
    css`
      gap: 10px;
    `}
`;


const KeyframeSpin = keyframes`
  from {
      transform: scale(1) rotate(0deg);
  }
  to {
      transform: scale(1) rotate(360deg);
  }
`;

const IconWrapper = styled.div`
  display: flex;

  & > svg {
    transform: translate(0, 0);
    transition: transform 0.2s ease;
  }

  ${Button}:hover & > svg {
    transform: translate(5px, 0);
  }

  ${({ icon20px }) => icon20px && css`
    font-size: 20px;
  `}
  
  ${({ icon24px }) => icon24px && css`
    font-size: 24px;
  `}

  ${({ spin }) => spin && css`
    & > svg {
      animation: ${KeyframeSpin} 2s linear infinite;
    }
  `}

  ${({ GrColor }) => GrColor && css`
    & path {stroke: white;}
  `}
`;

const IconSwitch = ({ arrow, check, calculator, creditcard, spinCog, cart, ...otherProps }) =>
  spinCog ? <IconWrapper icon20px spin {...otherProps}><FaCog /></IconWrapper> :
  arrow ? <IconWrapper icon24px {...otherProps}><FiArrowRight /></IconWrapper> :
  check ? <IconWrapper icon24px {...otherProps}><BsCheck /></IconWrapper> :
  calculator ? <IconWrapper icon20px {...otherProps}><BsCalculatorFill /></IconWrapper> :
  creditcard ? <IconWrapper icon20px {...otherProps}><BsCreditCard /></IconWrapper> :
  cart ? <IconWrapper icon24px GrColor {...otherProps}><GrCart /></IconWrapper> :
null;

const LinkButton = ({
  children,
  to,
  alignCenter,
  justifyCenter,
  justifyRight,
  expandHeight,
  arrow,
  check,
  calculator,
  creditcard,
  spinCog,
  cart,
  ...otherProps
}) => (
  <ButtonWrap alignCenter={alignCenter} justifyCenter={justifyCenter} justifyRight={justifyRight} expandHeight={expandHeight}>
    <Button to={to} {...otherProps}>
      {children}<IconSwitch arrow={arrow} check={check} calculator={calculator} creditcard={creditcard} spinCog={spinCog} cart={cart} />
    </Button>
  </ButtonWrap>
);

export default LinkButton;
