import React from 'react';
import { connect } from 'react-redux';
import { getMoms } from '../../features/commerce';
import { setMoms } from '../../features/commerce/moms';
import { getCurrencyRate } from '../../features/currency';
import { MoneyFormat } from '../../components/Format';
import { LinkStyled } from '../../components/typography';

export default connect(
  (state, { moms, currency }) => ({
    moms: typeof moms === 'undefined' ? getMoms(state) : moms,
    realMoms: typeof moms === 'undefined',
    currencyRate: currency ? getCurrencyRate(state, { currency }) : undefined,
    suffix: currency && ` ${currency}`,
  }),
  {
    setMoms,
  },
)(({
  realMoms,
  setMoms,
  moms,
  momsIndication,
  momsIndicationBreak,
  ...props
}) => {
  const momsText = moms ? 'med' : 'uden';
  return (
    <React.Fragment>
      <MoneyFormat {...props} moms={moms} />
      {momsIndication && (
        <React.Fragment>
          &nbsp;
          {realMoms
            ? (
              <LinkStyled action onClick={() => setMoms(!moms)}>{momsText}</LinkStyled>
            )
            : momsText
          }&nbsp;moms
        </React.Fragment>
      )}
      {momsIndicationBreak && (
        <React.Fragment>
          <br />
          {realMoms
            ? (
              <LinkStyled action onClick={() => setMoms(!moms)}>{momsText}</LinkStyled>
            )
            : momsText
          }&nbsp;moms
        </React.Fragment>
      )}
    </React.Fragment>
  );
});
