import React from 'react';

const Sikrest10DageFoerText = () => {
  return (
    <React.Fragment>
      Bestil sikrest 10 dage før til uge 25, 49 &amp; 50
    </React.Fragment>
  );
}

export default Sikrest10DageFoerText;
