import {
  ITEM_AMOUNT_SET,
  DISCOUNT_AMOUNT_SET,
  ITEM_SINGLE_AMOUNT_SET,
  ITEM_AMOUNT_INCREMENT,
  ITEM_AMOUNT_DECREMENT,
  PALLE_AMOUNT_SET,
  HANE_AMOUNT_SET,
  WAGON_AMOUNT_SET,
  setItemAmount,
  setItemSingleAmount,
  incrementItemAmount,
  decrementItemAmount,
  EMPTY_BUCKET,
  ACCEPT_ROYAL_UNIBREW,
  ACCEPT_HEINEKEN,
  ACCEPT_HERSLEV,
} from "./actions";
import { SWITCH_MODE } from "../../actions";
import { WATER_ADD_TO_BUCKET } from "../actions";
import { sanitizeMeasurement } from "../../../lib/helpers";
import getArg from "../../../lib/args";
import {
  getItemAmount,
  getItemSingleAmount,
  getItemAmounts,
  getTotalNumberOfItems,
  getBucketItems,
  getBucketItemsWithoutWater,
  getWaterBucketItems,
  isWaterItem,
  getItemEntry,
  getBucketItemEntries,
  // hasMoreThanTwoNoerrebroInBucket,
} from "./selectors";
import { getItemDividableBy } from "../items";
import { getItemDefaultInformation } from "../../../content/commerce/items";

export {
  // actions
  ITEM_AMOUNT_SET,
  ITEM_SINGLE_AMOUNT_SET,
  ITEM_AMOUNT_INCREMENT,
  ITEM_AMOUNT_DECREMENT,
  setItemAmount,
  setItemSingleAmount,
  incrementItemAmount,
  decrementItemAmount,
  // selectors
  getItemAmount,
  getItemSingleAmount,
  getTotalNumberOfItems,
  getBucketItems,
  getItemAmounts,
  getBucketItemsWithoutWater,
  getWaterBucketItems,
  isWaterItem,
  getItemEntry,
  getBucketItemEntries,
};
const getItemDividableByV = (varenr) => getItemDividableBy(varenr.substring(1));
const initialState = {
  specialMode: false,
  currentOrder: 0,
  items: {},
  amountOfPaller: 0,
  amountOfHaner: 0,
  amountOfWagons: 0,
  discountAmount: 0,
  offerRoyalUnibrewOffer: false,
  offerHeinekenOffer: false,
  offerHerslevOffer: false,
};
initialState.otherModeBucket = {
  ...initialState,
  specialMode: true,
};

function bucketReducer(state = initialState, action) {
  switch (action.type) {
    case SWITCH_MODE: {
      if (state.specialMode !== action.payload) {
        const { otherModeBucket, ...rest } = state;
        return {
          ...otherModeBucket,
          otherModeBucket: rest,
        };
      }
      return state;
    }
    case EMPTY_BUCKET:
      return initialState;
    case DISCOUNT_AMOUNT_SET:
      return {
        ...state,
        discountAmount: action.payload,
      };
    case PALLE_AMOUNT_SET: {
      return {
        ...state,
        amountOfPaller: action.payload,
      };
    }
    case HANE_AMOUNT_SET: {
      return {
        ...state,
        amountOfHaner: action.payload,
      };
    }
    case WAGON_AMOUNT_SET: {
      return {
        ...state,
        amountOfWagons: action.payload,
      };
    }
    case WATER_ADD_TO_BUCKET: {
      const { count, lid, size, flavour, uploadId } = action.payload;
      const {
        currentOrder: existingOrder,
        items: { vWater: existingWater, ...restItems },
      } = state;
      if (count === 0) {
        if (existingWater) {
          return {
            ...state,
            items: {
              ...restItems,
            },
          };
        }
        return state;
      }
      const order = existingWater ? existingWater.order : existingOrder;
      return {
        ...state,
        currentOrder: existingWater ? order : order + 1,
        items: {
          ...state.items,
          vWater: {
            amount: count,
            singleAmount: 0,
            order,
            isWater: true,
            lid,
            size,
            flavour,
            uploadId,
          },
        },
      };
    }
    case ITEM_AMOUNT_SET: {
      const { varenr, amount } = action.payload;
      const dividableBy = getItemDividableByV(varenr);
      const { [varenr]: existingEntry, ...restEntries } = state.items;
      if (amount <= 0 && (!existingEntry || !existingEntry.singleAmount)) {
        return { ...state, items: restEntries };
      }
      const newAmount = amount - (amount % dividableBy);
      if (existingEntry) {
        return {
          ...state,
          items: {
            ...restEntries,
            [varenr]: { ...existingEntry, amount: newAmount },
          },
        };
      }
      const { currentOrder: order } = state;
      return {
        ...state,
        currentOrder: order + 1,
        items: {
          ...state.items,
          [varenr]: { amount: newAmount, singleAmount: 0, order },
        },
      };
    }
    case ITEM_SINGLE_AMOUNT_SET: {
      const { varenr, singleAmount } = action.payload;
      const { [varenr]: existingEntry, ...restEntries } = state.items;
      if (singleAmount <= 0 && (!existingEntry || !existingEntry.amount)) {
        return { ...state, items: restEntries };
      }
      if (existingEntry) {
        return {
          ...state,
          items: {
            ...restEntries,
            [varenr]: { ...existingEntry, singleAmount },
          },
        };
      }
      const { currentOrder: order } = state;
      return {
        ...state,
        currentOrder: order + 1,
        items: { ...state.items, [varenr]: { amount: 0, singleAmount, order } },
      };
    }
    case ITEM_AMOUNT_INCREMENT: {
      const varenr = action.payload;
      const divideableBy = getItemDividableByV(varenr);
      const { [varenr]: existingEntry, ...restEntries } = state.items;
      if (existingEntry) {
        const { amount } = existingEntry;
        return {
          ...state,
          items: {
            ...restEntries,
            [varenr]: { ...existingEntry, amount: amount + divideableBy },
          },
        };
      }
      const { currentOrder: order } = state;
      return {
        ...state,
        currentOrder: order + 1,
        items: {
          ...state.items,
          [varenr]: { amount: divideableBy, singleAmount: 0, order },
        },
      };
    }
    case ITEM_AMOUNT_DECREMENT: {
      const varenr = action.payload;
      const divideableBy = getItemDividableByV(varenr);
      const { [varenr]: existingEntry, ...restEntries } = state.items;
      if (existingEntry) {
        const { amount, singleAmount } = existingEntry;
        if (amount === 0 || (amount <= divideableBy && !singleAmount)) {
          return { ...state, items: { ...restEntries } };
        }
        return {
          ...state,
          items: {
            ...restEntries,
            [varenr]: {
              ...existingEntry,
              amount: Math.max(0, amount - divideableBy),
            },
          },
        };
      }
      return state;
    }
    default:
      return state;
  }
}

const has60LiterRoyalUnibrew = (items) => {
  const liters = Object.keys(items).reduce((acc, varenr) => {
    const info = getItemDefaultInformation(varenr.substring(1));
    if (
      info &&
      info.royalUnibrew &&
      !getArg("serverDirect") &&
      typeof window !== "undefined"
    ) {
      // eslint-disable-next-line no-undef
      if (
        window &&
        window.actualItems &&
        window.actualItems[varenr.substring(1)]
      ) {
        // eslint-disable-next-line no-undef
        const {
          values: { liter },
        } = window.actualItems[varenr.substring(1)];
        return sanitizeMeasurement(liter) * items[varenr].amount + acc;
      }
      return 0;
    }
    return acc;
  }, 0);
  return liters >= 60;
};

const has60LiterHeineken = (items) => {
  const liters = Object.keys(items).reduce((acc, varenr) => {
    const info = getItemDefaultInformation(varenr.substring(1));
    if (
      info &&
      info.heinekenOffer &&
      !getArg("serverDirect") &&
      typeof window !== "undefined"
    ) {
      // eslint-disable-next-line no-undef
      if (
        window &&
        window.actualItems &&
        window.actualItems[varenr.substring(1)]
      ) {
        // eslint-disable-next-line no-undef
        const {
          values: { liter },
        } = window.actualItems[varenr.substring(1)];
        return sanitizeMeasurement(liter) * items[varenr].amount + acc;
      }
      return 0;
    }
    return acc;
  }, 0);
  return liters >= 60;
};

const hasHerslev = (items) => {
  const liters = Object.keys(items).reduce((acc, varenr) => {
    const info = getItemDefaultInformation(varenr.substring(1));
    if (
      info &&
      info.herslevBryghus &&
      !getArg("serverDirect") &&
      typeof window !== "undefined"
    ) {
      // eslint-disable-next-line no-undef
      if (
        window &&
        window.actualItems &&
        window.actualItems[varenr.substring(1)]
      ) {
        // eslint-disable-next-line no-undef
        const {
          values: { liter },
        } = window.actualItems[varenr.substring(1)];
        return sanitizeMeasurement(liter) * items[varenr].amount + acc;
      }
      return 0;
    }
    return acc;
  }, 0);
  return liters >= 40;
};

export default function bucketMetaReducer(state = initialState, action) {
  let newState = bucketReducer(state, action);
  if (action.type === ACCEPT_ROYAL_UNIBREW) {
    newState = {
      ...newState,
      offerRoyalUnibrewOffer: false,
    };
    if (action.payload) {
      newState = bucketReducer(newState, {
        type: ITEM_AMOUNT_SET,
        payload: { varenr: "v4953", amount: 1 },
      });
    }
  }
  if (action.type === ACCEPT_HEINEKEN) {
    newState = {
      ...newState,
      offerHeinekenOffer: false,
    };
    if (action.payload) {
      newState = bucketReducer(newState, {
        type: ITEM_AMOUNT_SET,
        payload: { varenr: "v13064", amount: 1 },
      });
    }
  }
  if (action.type === ACCEPT_HERSLEV) {
    newState = {
      ...newState,
      offerHerslevOffer: false,
    };
    if (action.payload) {
      newState = bucketReducer(newState, {
        type: ITEM_AMOUNT_SET,
        payload: { varenr: "v10009", amount: 1 },
      });
    }
  }
  if (
    (action.type === ITEM_AMOUNT_SET && action.payload.amount !== 0) ||
    action.type === ITEM_AMOUNT_INCREMENT
  ) {
    let varenr = action.payload;
    if (action.type === ITEM_AMOUNT_SET) {
      varenr = varenr.varenr; // eslint-disable-line
    }
    const info = getItemDefaultInformation(varenr.substring(1));
    if (info && (info.modular || info.fadoelsanlaeg)) {
      const hasModularOrTrykhane = Object.keys(state.items).some((varenr2) => {
        const info2 = getItemDefaultInformation(varenr2.substring(1));
        return `${varenr2}` === "v3935" || (info2 && info2.modular);
      });
      const hasFadoelsAnlaeg = Object.keys(state.items).some((varenr2) => {
        const info2 = getItemDefaultInformation(varenr2.substring(1));
        return `${varenr2}` === "v3935" || (info2 && info2.fadoelsanlaeg);
      });
      if (
        (info.modular && !hasModularOrTrykhane && hasFadoelsAnlaeg) ||
        (info.fadoelsanlaeg && !hasFadoelsAnlaeg && hasModularOrTrykhane)
      ) {
        newState = bucketReducer(newState, {
          type: ITEM_AMOUNT_SET,
          payload: { varenr: "v3935", amount: 1 },
        });
      }
    }
    if (info && info.royalUnibrewJulekit) {
      const hasroyalUnibrewJulekit = Object.keys(state.items).some(
        (varenr2) => {
          const info2 = getItemDefaultInformation(varenr2);
          return `${varenr2}` === "v3754" || (info2 && info2.modular);
        }
      );
      if (!hasroyalUnibrewJulekit) {
        newState = bucketReducer(newState, {
          type: ITEM_AMOUNT_SET,
          payload: { varenr: "v3754", amount: 1 },
        });
      }
    }
    /*
    if (info && info.norrebro) {
      const hasFreeGlasses = Object.keys(newState.items).some(
        (varenr2) => {
          return `${varenr2}` === 'v13064';
        },
      );
      console.log(
        'Has Fee glasses',
        hasFreeGlasses,
        'hasMoreThanTwoNoerrebroInBucket',
        hasMoreThanTwoNoerrebroInBucket(newState.items),
        newState.items,
      );
      if (
        !hasFreeGlasses
        && hasMoreThanTwoNoerrebroInBucket(newState.items)
      ) {
        newState = bucketReducer(newState, { type: ITEM_AMOUNT_SET, payload: { varenr: 'v13064', amount: 1 } });
      }
    }
    */
    if (
      !has60LiterRoyalUnibrew(state.items) &&
      info &&
      info.royalUnibrew &&
      has60LiterRoyalUnibrew(newState.items)
    ) {
      // First royal unibrew added
      newState = {
        ...newState,
        offerRoyalUnibrewOffer: true,
      };
    }
    if (
      !has60LiterHeineken(state.items) &&
      info &&
      info.heinekenOffer &&
      has60LiterHeineken(newState.items)
    ) {
      // First royal unibrew added
      newState = {
        ...newState,
        offerHeinekenOffer: true,
      };
    }
    if (
      !hasHerslev(state.items) &&
      info &&
      info.herslevBryghus &&
      hasHerslev(newState.items)
    ) {
      // First royal unibrew added
      newState = {
        ...newState,
        offerHerslevOffer: true,
      };
    }
  } else if (
    (action.type === ITEM_AMOUNT_SET && action.payload.amount === 0) ||
    action.type === ITEM_AMOUNT_DECREMENT
  ) {
    if (
      has60LiterRoyalUnibrew(state.items) &&
      !has60LiterRoyalUnibrew(newState.items)
    ) {
      if (newState.items.v4953) {
        newState = bucketReducer(newState, {
          type: ITEM_AMOUNT_SET,
          payload: { varenr: "v4953", amount: 0 },
        });
      }
    }
    if (
      has60LiterHeineken(state.items) &&
      !has60LiterHeineken(newState.items)
    ) {
      if (newState.items.v13064) {
        newState = bucketReducer(newState, {
          type: ITEM_AMOUNT_SET,
          payload: { varenr: "v13064", amount: 0 },
        });
      }
    }
    if (hasHerslev(state.items) && !hasHerslev(newState.items)) {
      if (newState.items.v10009) {
        newState = bucketReducer(newState, {
          type: ITEM_AMOUNT_SET,
          payload: { varenr: "v10009", amount: 0 },
        });
      }
    }
    // let varenr = action.payload;
    if (action.type === ITEM_AMOUNT_SET) {
      // varenr = varenr.varenr; // eslint-disable-line
    }
    /*
    const info = getItemDefaultInformation(varenr.substring(1));
    if (info && info.norrebro) {
      const hasFreeGlasses = Object.keys(newState.items).some(
        (varenr2) => {
          return `${varenr2}` === 'v13064';
        },
      );
      if (
        hasFreeGlasses
        && !hasMoreThanTwoNoerrebroInBucket(newState.items)
      ) {
        newState = bucketReducer(newState, { type: ITEM_AMOUNT_SET, payload: { varenr: 'v13064', amount: 0 } });
      }
    }
    */
  }
  return newState;
}
