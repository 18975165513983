import React from "react";
import styled from "styled-components/macro";
import {
  marginTheming,
  paddingTheming,
  fontTheming,
  elementTheming,
  removePaddingThemingProps,
  removeMarginThemingProps,
  removeFontThemingProps,
  removeElementThemingProps,
  removeProps,
  combine,
  createComponentWithModifiedProps,
} from "../../style/themes/main";

const removeCustomProps = removeProps("centerVertically");
const removeAllProps = combine(
  removePaddingThemingProps,
  removeMarginThemingProps,
  removeFontThemingProps,
  removeElementThemingProps,
  removeCustomProps
);

const Heading1NoProps = createComponentWithModifiedProps(
  removeAllProps,
  "h1",
  "Skafte.com.typography.Heading1"
);
const Heading2NoProps = createComponentWithModifiedProps(
  removeAllProps,
  "h2",
  "Skafte.com.typography.Heading2"
);
const Heading3NoProps = createComponentWithModifiedProps(
  removeAllProps,
  "h3",
  "Skafte.com.typography.Heading3"
);
const Heading4NoProps = createComponentWithModifiedProps(
  removeAllProps,
  "h4",
  "Skafte.com.typography.Heading4"
);
const Heading5NoProps = createComponentWithModifiedProps(
  removeAllProps,
  "h5",
  "Skafte.com.typography.Heading5"
);

const generateFontSizeWithBreakPoints = (fontSizeBreakPoints) => {
  let totalCSS = "";
  for (let i = 0; i < fontSizeBreakPoints.length - 1; i += 1) {
    const [maxWidth, fontSize] = fontSizeBreakPoints[i];
    totalCSS = `
      @media only screen and (max-width: ${maxWidth}px) {
        & {
          font-size: ${fontSize}px;
        }
      }
      ${totalCSS}
    `;
  }
  const fontSize = fontSizeBreakPoints[fontSizeBreakPoints.length - 1];
  return `
    font-size: ${fontSize}px;
    ${totalCSS}
  `;
};

const Heading1FontSizeBreakPoints = [[575, 24], [767, 26], [991, 28], 30];
const Heading2FontSizeBreakPoints = [[575, 20], [767, 22], [991, 24], 26];
const Heading3FontSizeBreakPoints = [[575, 16], [767, 18], [991, 20], 22];
const Heading4FontSizeBreakPoints = [[575, 14], [767, 14], [991, 16], 18];
const Heading5FontSizeBreakPoints = [[575, 14], [767, 14], [991, 14], 15];

const StaticallyStyled = styled(Heading1NoProps)`
  font-family: "Inter", sans-serif;
  img {
    padding: 0 10px;
  }
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 1.4em;
`;

const StaticallyStyledHeading1 = StaticallyStyled;
const StaticallyStyledHeading2 = (props) => (
  <StaticallyStyled as={Heading2NoProps} {...props} />
);
const StaticallyStyledHeading3 = (props) => (
  <StaticallyStyled as={Heading3NoProps} {...props} />
);
const StaticallyStyledHeading4 = (props) => (
  <StaticallyStyled as={Heading4NoProps} {...props} />
);
const StaticallyStyledHeading5 = (props) => (
  <StaticallyStyled as={Heading5NoProps} {...props} />
);

const CustomStyled = styled(StaticallyStyledHeading1)`
  ${({ centerVertically }) => {
    if (centerVertically) {
      return `
        & img {
          vertical-align: middle;
        }
      `;
    }
    return "";
  }}
  ${fontTheming}
  ${paddingTheming}
  ${marginTheming}
  ${elementTheming}
`;
const FullyStyledHeading1 = styled((props) => (
  <CustomStyled {...props} as={StaticallyStyledHeading1} />
))`
  ${generateFontSizeWithBreakPoints(Heading1FontSizeBreakPoints)}
`;
const FullyStyledHeading2 = styled((props) => (
  <CustomStyled {...props} as={StaticallyStyledHeading2} />
))`
  ${generateFontSizeWithBreakPoints(Heading2FontSizeBreakPoints)}
`;
const FullyStyledHeading3 = styled((props) => (
  <CustomStyled {...props} as={StaticallyStyledHeading3} />
))`
  ${generateFontSizeWithBreakPoints(Heading3FontSizeBreakPoints)}
`;
const FullyStyledHeading4 = styled((props) => (
  <CustomStyled {...props} as={StaticallyStyledHeading4} />
))`
  ${generateFontSizeWithBreakPoints(Heading4FontSizeBreakPoints)}
`;
const FullyStyledHeading5 = styled((props) => (
  <CustomStyled {...props} as={StaticallyStyledHeading5} />
))`
  ${generateFontSizeWithBreakPoints(Heading5FontSizeBreakPoints)}
`;

export const Heading1 = (props) => <FullyStyledHeading1 {...props} />;
export const Heading2 = (props) => <FullyStyledHeading2 {...props} />;
export const Heading3 = (props) => <FullyStyledHeading3 {...props} />;
export const Heading4 = (props) => <FullyStyledHeading4 {...props} />;
export const Heading5 = (props) => <FullyStyledHeading5 {...props} />;
