import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { getMenuState, closeMenu } from '../../../../features/burgermenus';
import Menu from './Menu';
import { screenSizeSwitch } from '../../../../lib/helpers';

const BurgerMenuWrapper = styled.div`
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: calc(100% - 70px);
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.colorWhite};
  overflow: auto;
  box-sizing: border-box;
  transform: translateX(-300px);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  overflow-x: hidden;
  
  ${({ isOpen }) => {
    if (isOpen) {
      return `
        opacity: 1;
        transform: translateX(0);
      `;
    }
    return '';
  }}
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

class SkafteBurgerMenu extends React.Component {
  static displayName = 'layout.DefaultLayout.Header.BurgerMenu';

  componentDidMount() {
    const { history } = this.props;
    history.listen(this.handleHistoryChange);
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props;
    if (prevProps.open !== open) {
      if (open) {
        document.body.classList.add('menu-open');
      } else {
        document.body.classList.remove('menu-open');
      }
    }
  }

  componentWillUnmount() {
    const { history } = this.props;
    history.listen(this.handleHistoryChange);
    const { open, closeMenu } = this.props;
    if (open) {
      closeMenu();
    }
  }

  handleHistoryChange = () => {
    const { open, closeMenu } = this.props;
    if (open) {
      closeMenu();
    }
  };

  render() {
    const { open, onStateChange, closeMenu } = this.props;
    return (
      <React.Fragment>
        <BurgerMenuWrapper className="burger-menu" isOpen={open} onStateChange={onStateChange}>
          <Menu />
        </BurgerMenuWrapper>
        {open && <Overlay isOpen={open} onClick={closeMenu} />}
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({ open: getMenuState(state) }),
    (dispatch) => ({
      closeMenu: () => dispatch(closeMenu()),
      onStateChange: (state) => !state.isOpen && dispatch(closeMenu()),
    })
  )(screenSizeSwitch([[991, SkafteBurgerMenu], () => null]))
);
