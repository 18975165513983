import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { switchMode } from '../features/actions';
import { isInSpecialMode } from '../features/commerce/delivery';
import DefaultLayout from '../layout/DefaultLayout';
import DefineBreadcrumbs from '../layout/DefaultLayout/Header/components/Breadcrumbs/DefineBreadcrumbs';
import { fbq, pushToDataLayer } from '../lib/analytics';
import getArg from '../lib/args';
import getItemInformation from '../content/commerce/items';
import { setBestilLink } from '../features/bestillink';
import { setMoms, setPrefferedMoms, getPrefferedMoms } from '../features/commerce/moms';
import { generalClearSearch, getGeneralSearchTerm } from '../features/commerce/search';
import { setOverridePlejerliste } from '../features/plejerliste';
import { generateLink, generateAbsoluteLink } from '../lib/helpers';
import { MainBestilLayout, KunneIkkeHenteRute, HenterRute } from './routes';

const log = (props) => {
  const {
    skafteRoute: {
      disableAnalytics,
      meta,
    },
  } = props;
  if (!disableAnalytics) {
    const {
      location: {
        pathname,
      },
    } = window; // eslint-disable-line
    const obj = {
      page_path: pathname,
      page_location: `https://skafte.dk${pathname}`,
    };
    if (meta && meta.title) {
      obj.page_title = meta.title;
    }
    pushToDataLayer({
      event: 'page_view',
      ...obj,
    });
    // ga('event', 'page_view', obj);
    fbq('track', 'PageView');
  }
};

const mouseFlow = (props) => {
  const {
    skafteRoute: {
      path,
    },
  } = props;
  window._mfq = window._mfq || []; // eslint-disable-line
  window._mfq.push(['newPageView', path]); // eslint-disable-line
};

const momsSetter = (props) => {
  const {
    skafteRoute: {
      moms,
    },
    prefferedMoms,
    setPrefferedMoms,
  } = props;
  const momsValue = moms || typeof moms === 'undefined';
  if (momsValue !== prefferedMoms) {
    setPrefferedMoms(momsValue);
  }
};

export class RouteRenderer extends React.Component {
  componentWillMount() {
    momsSetter(this.props);
    const {
      skafteRoute: {
        specialMode,
      },
      isSpecialMode,
      switchMode,
    } = this.props;
    if ((!!specialMode) !== isSpecialMode) {
      switchMode(!!specialMode);
    }
    if (!getArg('serverDirect')) {
      const { generalClearSearch, searchTerm, skafteRoute: { dontClearSearch } } = this.props;
      if (!dontClearSearch && searchTerm) {
        generalClearSearch();
      }
      log(this.props);
      const {
        location: {
          search,
        },
        skafteRoute: {
          bestilLayout,
        },
        setBestilLink,
        setOverridePlejerliste,
        setMoms,
      } = this.props;
      if (search) {
        let madeChanges = false;
        const qString = queryString.parse(search);
        if (bestilLayout) {
          if (qString.linkdato) {
            setBestilLink(qString);
            madeChanges = true;
          }
          if (qString.vare) {
            const items = qString.vare.split(',');
            const addedItems = {};
            const finalItems = items.reduce(
              (ret, item) => {
                const trimmed = item.trim();
                if (!addedItems[trimmed] && trimmed) {
                  return [...ret, trimmed];
                }
                return ret;
              },
              [],
            );
            setOverridePlejerliste(finalItems);
            madeChanges = true;
          }
        }
        if (typeof qString.moms !== 'undefined') {
          if (qString.moms === '0') {
            setMoms(false);
            madeChanges = true;
          } else if (qString.moms === '1') {
            setMoms(true);
            madeChanges = true;
          }
        }
        if (madeChanges) {
          this.preventRender = true;
          window.setTimeout( // eslint-disable-line
            () => {
              this.preventRender = false;
              this.forceUpdate();
            },
            150,
          );
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      skafteRoute: {
        goToTopKey,
        path,
      },
    } = this.props;
    const {
      generalClearSearch,
      searchTerm,
      skafteRoute: {
        goToTopKey: nextGoToTopKey,
        path: nextPath,
        dontClearSearch,
        specialMode,
      },
      isSpecialMode,
      switchMode,
    } = nextProps;
    if ((!!specialMode) !== isSpecialMode) {
      switchMode(!!specialMode);
    }
    if (path !== nextPath) {
      log(nextProps);
      mouseFlow(nextProps);
      momsSetter(nextProps);
      if (!dontClearSearch && searchTerm) {
        generalClearSearch();
      }
      if (!nextGoToTopKey || nextGoToTopKey !== goToTopKey) {
        window.scrollTo(0, 0); // eslint-disable-line
      }
    }
  }

  render() {
    const {
      skafteRoute: {
        noSearchBar,
        noSearchBarResults,
        path,
        redirectTo,
        itemRoute,
        redirectToWithPlejerliste,
        bestilLayout,
        disableBreadCrumbsSchema,
        bestilProps = {},
        props = {},
        meta,
      },
      ...rest
    } = this.props;
    let {
      skafteRoute: {
        component: Component,
        breadcrumbs,
      },
    } = this.props;
    if (itemRoute) {
      const {
        routeDef,
      } = getItemInformation(props.varenr);
      Component = routeDef.component; // eslint-disable-line
      breadcrumbs = routeDef.breadcrumbs; // eslint-disable-line
    }
    const {
      plejerListe,
    } = rest;
    let children = null;
    if (this.preventRender) {
      children = null;
    } else if (plejerListe && plejerListe.length > 0 && redirectToWithPlejerliste) {
      children = <Redirect from={path} to={redirectToWithPlejerliste} />;
    } else if (redirectTo) {
      children = <Redirect from={path} to={redirectTo} />;
    } else {
      children = (
        <Component {...rest} {...props} fallback={<HenterRute />} />
      );
    }
    if (bestilLayout) {
      children = (
        <MainBestilLayout {...bestilProps} fallback={<HenterRute />}>
          {children}
        </MainBestilLayout>
      );
    }
    return (
      <DefaultLayout noSearchBar={noSearchBar} noSearchBarResults={noSearchBarResults} disableBreadCrumbsSchema={disableBreadCrumbsSchema}>
        {breadcrumbs && (
          <DefineBreadcrumbs breadcrumbs={breadcrumbs.base} title={breadcrumbs.title} />
        )}
        {meta && (
          <Helmet>
            {meta.title && <title>{meta.title}</title>}
            {meta.description && <meta name="description" content={meta.description} />}
            {meta.canonical
              ? (
                <link rel="canonical" href={generateLink(meta.canonical)} />
              )
              : (
                <link rel="canonical" href={generateAbsoluteLink()} />
              )}
          </Helmet>
        )}
        <KunneIkkeHenteRute>
          {children}
        </KunneIkkeHenteRute>
      </DefaultLayout>
    );
  }
}

export default connect(
  state => ({
    plejerListe: state.plejerliste.plejerListe,
    prefferedMoms: getPrefferedMoms(state),
    searchTerm: getGeneralSearchTerm(state),
    isSpecialMode: isInSpecialMode(state),
  }),
  {
    setBestilLink,
    setOverridePlejerliste,
    setMoms,
    setPrefferedMoms,
    generalClearSearch,
    switchMode,
  },
)(RouteRenderer);
