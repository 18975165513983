import React from 'react';
import { sanitizeMeasurement } from '../../lib/helpers';
import Format from './Format';

export default ({ length }) => {
  const sanLength = sanitizeMeasurement(length) / 10;
  return (
    <Format
      decimalScale={2}
      value={sanLength}
    />
  );
}