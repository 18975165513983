import React from 'react';
import VolumeFormat from './VolumeFormat';
import { sanitizeMeasurement } from '../../lib/helpers';

export default ({
  liter, cl, noSuffix, divider = 1,
}) => {
  const m = sanitizeMeasurement(liter) / divider;
  if (cl) {
    return (
      <VolumeFormat
        cl
        value={m * 100}
        noSuffix={noSuffix}
      />
    );
  }
  return (
    <VolumeFormat
      value={liter}
      noSuffix={noSuffix}
      space
    />
  );
};
