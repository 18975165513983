import React from 'react';
import { css } from 'styled-components/macro';
import getImageRequestName, { getImageRequestNameWebp } from '../../images';

export const createComponentWithModifiedProps = (modifyFunction, Component, displayName) => {
  const ModifiedComponent = React.forwardRef((props, ref) => {
    const newProps = modifyFunction(props);
    return <Component ref={ref} {...newProps} />;
  });
  if (displayName) {
    ModifiedComponent.displayName = displayName;
  } else if (Component.displayName) {
    ModifiedComponent.displayName = `Modified(${Component.displayName})`;
  }
  return ModifiedComponent;
};

export const removeProps = (...propsToRemove) => (props) => {
  const newProps = { ...props };
  for (let i = 0; i < propsToRemove.length; i += 1) {
    const propName = propsToRemove[i];
    if (newProps[propName]) {
      delete newProps[propName];
    }
  }
  return newProps;
};

export const combine = (...fs) => arg => fs.reduce((result, f) => f(result), arg);

const calculateStyles = (styleObj, props) => Object.keys(styleObj).reduce(
  (acc, styleKey) => {
    if (typeof styleObj[styleKey] === 'function') {
      const value = styleObj[styleKey](props);
      if (typeof value === 'undefined') {
        return acc;
      }
      return {
        ...acc,
        [styleKey]: value,
      };
    }
    return {
      ...acc,
      [styleKey]: styleObj[styleKey],
    };
  },
  {},
);
export const combineInlineStyles = (...styleObjs) => props => styleObjs.reduce(
  (acc, styleObj) => {
    const styles = calculateStyles(styleObj, props);
    return {
      ...acc,
      ...styles,
    };
  },
  {},
);

export const removeBlockThemingProps = removeProps('inline', 'inlineBlock', 'hide');
export const blockTheming = css`
  ${({ inlineBlock }) => inlineBlock && 'display: inline-block;'}
  ${({ inline }) => inline && 'display: inline-block;'}
  ${({ hide }) => hide && 'display: none;'}
`;

export const removeFontThemingProps = removeProps('fontSize', 'fontWeight', 'textCenter', 'textRight', 'textLeft', 'underline', 'italic', 'lineHeight', 'bold', 'fontFamily');
export const fontThemingInline = {
  fontFamily: ({ fontFamily }) => fontFamily || undefined,
  fontSize: ({ fontSize }) => fontSize || undefined,
  bold: ({ bold }) => (bold && '500') || undefined,
  normal: ({ normal }) => (normal && '400') || undefined,
  fontWeight: ({ fontWeight }) => fontWeight || undefined,
  textAlign: ({ textCenter, textRight, textLeft }) => {
    if (textCenter) {
      return 'center';
    }
    if (textRight) {
      return 'right';
    }
    if (textLeft) {
      return 'left';
    }
    return undefined;
  },
  textDecoration: ({ underline }) => (underline && 'underline') || undefined,
  lineHeight: ({ lineHeight }) => lineHeight || undefined,
  fontStyle: ({ italic }) => (italic && 'italic') || undefined,
};
export const fontTheming = css`
  ${({ fontSize }) => fontSize && `font-size: ${fontSize} !important;`}
  ${({ bold }) => bold && 'font-weight: bold;'}
  ${({ normal }) => normal && 'font-weight: normal;'}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily};`}
  ${({ textCenter }) => textCenter && 'text-align: center;'}
  ${({ textRight }) => textRight && 'text-align: right;'}
  ${({ textLeft }) => textLeft && 'text-align: left;'}
  ${({ underline }) => underline && 'text-decoration: underline;'}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight};`}
  ${({ italic }) => italic && 'font-style: italic;'}
  ${({ uppercase }) => uppercase && 'text-transform: uppercase;'}
  ${({ lowercase }) => lowercase && 'text-transform: lowercase;'}
  ${({ capitalize }) => capitalize && 'text-transform: capitalize;'}
`;

export const removeMarginThemingProps = removeProps('marginLeft', 'marginTop', 'marginRight', 'marginBottom', 'marginX', 'marginY', 'margin');
export const marginThemingInline = {
  margin: ({ margin }) => margin || undefined,
  marginLeft: ({ marginX, marginLeft }) => {
    if (marginX) {
      return marginX;
    }
    if (marginLeft) {
      return marginLeft;
    }
    return undefined;
  },
  marginRight: ({ marginX, marginRight }) => {
    if (marginX) {
      return marginX;
    }
    if (marginRight) {
      return marginRight;
    }
    return undefined;
  },
  marginTop: ({ marginY, marginTop }) => {
    if (marginY) {
      return marginY;
    }
    if (marginTop) {
      return marginTop;
    }
    return undefined;
  },
  marginBottom: ({ marginY, marginBottom }) => {
    if (marginY) {
      return marginY;
    }
    if (marginBottom) {
      return marginBottom;
    }
    return undefined;
  },
};
export const marginTheming = css`
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ marginX }) => marginX && `
    margin-left: ${marginX};
    margin-right: ${marginX};
  `}
  ${({ marginY }) => marginY && `
    margin-top: ${marginY};
    margin-bottom: ${marginY};
  `}
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`}
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight};`}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft};`}
`;

export const removePaddingThemingProps = removeProps('paddingLeft', 'paddingTop', 'paddingRight', 'paddingBottom', 'paddingX', 'paddingY', 'padding');
export const paddingThemingInline = {
  padding: ({ padding }) => padding || undefined,
  paddingLeft: ({ paddingX, paddingLeft }) => {
    if (paddingX) {
      return paddingX;
    }
    if (paddingLeft) {
      return paddingLeft;
    }
    return undefined;
  },
  paddingRight: ({ paddingX, paddingRight }) => {
    if (paddingX) {
      return paddingX;
    }
    if (paddingRight) {
      return paddingRight;
    }
    return undefined;
  },
  paddingTop: ({ paddingY, paddingTop }) => {
    if (paddingY) {
      return paddingY;
    }
    if (paddingTop) {
      return paddingTop;
    }
    return undefined;
  },
  paddingBottom: ({ paddingY, paddingBottom }) => {
    if (paddingY) {
      return paddingY;
    }
    if (paddingBottom) {
      return paddingBottom;
    }
    return undefined;
  },
};
export const paddingTheming = css`
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ paddingX }) => paddingX && `
    padding-left: ${paddingX};
    padding-right: ${paddingX};
  `}
  ${({ paddingY }) => paddingY && `
    padding-top: ${paddingY};
    padding-bottom: ${paddingY};
  `}
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop};`}
  ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight};`}
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom};`}
  ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft};`}
`;
export const printTheming = css`
  ${({ print }) => print && `
    @media print {
      ${print}
    }
  `}
`;
export const removeBorderThemingProps = removeProps('border', 'borderTop', 'borderRight', 'borderLeft', 'borderBottom', 'borderTop');
export const borderThemingInline = {
  border: ({ border }) => border || undefined,
  borderTop: ({ borderTop }) => borderTop || undefined,
  borderRight: ({ borderRight }) => borderRight || undefined,
  borderLeft: ({ borderLeft }) => borderLeft || undefined,
  borderBottom: ({ borderBottom }) => borderBottom || undefined,
};
export const borderTheming = css`
  ${({ border }) => border && `border: ${border};`}
  ${({ borderTop }) => borderTop && `border-top: ${borderTop};`}
  ${({ borderRight }) => borderRight && `border-right: ${borderRight};`}
  ${({ borderBottom }) => borderBottom && `border-bottom: ${borderBottom};`}
  ${({ borderLeft }) => borderLeft && `border-left: ${borderLeft};`}
`;

export const removeElementThemingProps = removeProps(
  'floatLeft', 'floatRight', 'relative', 'absolute', 'left', 'right', 'clear', 'zIndex', 'opacity',
  'top', 'bottom', 'inline', 'inlineBlock', 'block', 'table', 'maxWidth', 'maxHeight', 'minHeight', 'minWidth', 'height', 'width',
  'verticalTop', 'verticalMiddle', 'verticalBottom', 'bgImage', 'bgImageWidth', 'bgImageHeight', 'bgImageNotVisible', 'background', 'backgroundColor',
);
export const elementThemingInline = {
  float: ({ floatLeft, floatRight }) => {
    if (floatLeft) {
      return 'left';
    }
    if (floatRight) {
      return 'right';
    }
    return undefined;
  },
  position: ({ absolute, relative }) => {
    if (absolute) {
      return 'absolute';
    }
    if (relative) {
      return 'relative';
    }
    return undefined;
  },
  left: ({ left }) => left || undefined,
  right: ({ right }) => right || undefined,
  top: ({ top }) => top || undefined,
  bottom: ({ bottom }) => bottom || undefined,
  display: ({
    inline,
    block,
    inlineBlock,
    table,
    hide,
  }) => {
    if (hide) {
      return 'none';
    }
    if (inline) {
      return 'inline';
    }
    if (block) {
      return 'block';
    }
    if (inlineBlock) {
      return 'inline-block';
    }
    if (table) {
      return 'table';
    }
    return undefined;
  },
  width: ({ width }) => width || undefined,
  height: ({ height }) => height || undefined,
  maxWidth: ({ maxWidth }) => maxWidth || undefined,
  maxHeight: ({ maxHeight }) => maxHeight || undefined,
  minWidth: ({ minWidth }) => minWidth || undefined,
  minHeight: ({ minHeight }) => minHeight || undefined,
  clear: ({ clear }) => (clear && 'both') || undefined,
  zIndex: ({ zIndex }) => zIndex || undefined,
  opacity: ({ opacity }) => opacity || undefined,
  verticalAlign: ({ verticalTop, verticalMiddle, verticalBottom }) => {
    if (verticalTop) {
      return 'top';
    }
    if (verticalMiddle) {
      return 'middle';
    }
    if (verticalBottom) {
      return 'bottom';
    }
    return undefined;
  },
  background: ({ background }) => background || undefined,
  backgroundColor: ({ backgroundColor }) => backgroundColor || undefined,
  backgroundImage: ({
    bgImageNotVisible,
    bgImage,
    bgImageWidth,
    bgImageHeight,
  }) => {
    if (bgImage && !bgImageNotVisible) {
      const heightProp = bgImageHeight ? `${bgImageHeight}`.replace('px', '') : 1;
      const widthProp = bgImageWidth ? `${bgImageWidth}`.replace('px', '') : 1;
      const request = (bgImageHeight || bgImageWidth)
        ? getImageRequestName(bgImage, `${widthProp}x${heightProp}`)
        : getImageRequestName(bgImage);
      return `url(${request});`;
    }
    return undefined;
  },
};
export const elementTheming = css`
  ${({ floatLeft }) => floatLeft && 'float: left;'}
  ${({ floatRight }) => floatRight && 'float: right;'}
  ${({ absolute }) => absolute && 'position: absolute;'}
  ${({ relative }) => relative && 'position:relative;'}
  ${({ pointer }) => pointer && 'cursor:pointer;'}
  ${({ left }) => left && `left: ${left};`}
  ${({ right }) => right && `right: ${right};`}
  ${({ top }) => top && `top: ${top};`}
  ${({ bottom }) => bottom && `bottom: ${bottom};`}
  ${({ inline }) => inline && 'display: inline;'}
  ${({ block }) => block && 'display: block;'}
  ${({ inlineBlock }) => inlineBlock && 'display: inline-block;'}
  ${({ hide }) => hide && 'display: none;'}
  ${({ table }) => table && 'display: table;'}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ h100p }) => h100p && 'height: 100%;'}
  ${({ clear }) => clear && 'clear: both;'}
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ verticalTop }) => verticalTop && 'vertical-align: top;'}
  ${({ verticalMiddle }) => verticalMiddle && 'vertical-align: middle;'}
  ${({ verticalBottom }) => verticalBottom && 'vertical-align: bottom;'}
  ${({ h100p }) => h100p && 'height: 100%;'}
  ${({ bgColor }) => bgColor && `background-color: ${bgColor};`}
  ${({ color }) => color && `color: ${color};`}
  ${({ aligner }) => aligner && 'display: flex !important; align-items: center; justify-content: center;'}
  ${({ alignerTop }) => alignerTop && 'align-self: flex-start;'}
  ${({ alignerBottom }) => alignerBottom && 'align-self: flex-end;'}
  ${({
    bgImageNotVisible,
    bgImage,
    bgImageWidth,
    bgImageHeight,
  }) => {
    if (bgImage && !bgImageNotVisible) {
      const heightProp = bgImageHeight ? `${bgImageHeight}`.replace('px', '') : 1;
      const widthProp = bgImageWidth ? `${bgImageWidth}`.replace('px', '') : 1;
      const request = (bgImageHeight || bgImageWidth)
        ? getImageRequestName(bgImage, `${widthProp}x${heightProp}`)
        : getImageRequestName(bgImage);
      const webpRequest = (bgImageHeight || bgImageWidth)
        ? getImageRequestNameWebp(bgImage, `${widthProp}x${heightProp}`)
        : getImageRequestNameWebp(bgImage);
      return css`
        body & {
          background-image: url(${request});
        }
        body.webp & {
          background-image: url(${webpRequest});
        }
      `;
    }
    return '';
  }}
`;

// Search for #ddd

export default {
  colors: {
    colorWhite: '#FFFFFF',
    colorOffWhite: '#F3F3F4',
    colorBlack: '#000000',
    colorBlue: '#4A728A',
    colorBlueHighlight: '#D0DFE7',
    colorGreen: '#42643A',
    colorGreenHighlight: '#CFE1CB',
    colorGreyDark: '#333333',
    colorGrey: '#9A9A9A',
    colorGreyLight: '#EEEEEE',
    colorGreyLightAlt: '#D8D8DA',
    colorSukkerfri: '#d8315b',
    colorSaft: '#712f79',
    colorRed: '#C73F4A',
    colorYellow: '#F3BD1A',
  },
  hoverColors: {
    colorBlue: '#6D97B0',
    colorGreen: '#55824A',
    colorRed: '#dd656f',
  },
  activeColors: {
    colorBlue: '#255D80',
  },
};
