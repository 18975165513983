import React from 'react';
import styled from 'styled-components/macro';
import Image from './Image';
import Modal from '../Modal';
import { LinkStyled } from '../typography';
import { BsFillPlusCircleFill } from '../Icons';


const StyledModal = styled(Modal)`
  max-width: calc(90% - 40px);
  max-height: 90%;

  @media (max-width: 991px) {
    top: 120px !important;
    transform: translate(-50%, 0) !important;
    max-height: calc(90% - 160px);
  }

  & img {
    position: relative !important;
    height: auto !important;
    top: auto !important;
    left: auto !important;
    max-width: 100%;
    min-width: 100%;
    min-height: 100%;
  }
  & a {
    display: block;
    & div:before {
      display: none !important;
    }
  }
`;
const CloseButton = styled.div`
  color: ${({ theme }) => theme.colors.colorRed};
  border: 2px solid ${({ theme }) => theme.colors.colorWhite};
  position: absolute;
  display: flex;
  top: 0px;
  right: 0px;
  font-size: 40px;
  background: white;
  rotate: 45deg;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.hoverColors.colorRed};
  }
`;

const SpecialActionLink = styled(LinkStyled)`
`;
const HoverImage = styled(Image)`
  ${SpecialActionLink}:hover & {
    opacity: 0.3;
  }
`;
export default class ImagePopup extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onClose = this.onClose.bind(this);
    this.state = {
      open: false,
    };
  }

  onClick() {
    this.setState({ open: true });
  }

  onClose() {
    this.setState({ open: false });
  }

  render() {
    const image = (
      // eslint-disable-next-line react/destructuring-assignment
      <SpecialActionLink action noStyle onClick={this.onClick} className={this.props.className}>
        <HoverImage {...this.props} />
      </SpecialActionLink>
    );
    const {
      name,
      popupImageHeight,
      popupImageWidth,
      popupMaxHeight,
      popupMaxWidth,
    } = this.props;
    const { open } = this.state;
    if (open) {
      return (
        <React.Fragment>
          {image}

          <StyledModal
            isOpen
            onRequestClose={this.onClose}
          >
            <LinkStyled action noStyle onClick={this.onClose}>
              <Image
                imageHeight={popupImageHeight}
                maxHeight={popupMaxHeight}
                imageWidth={popupImageWidth}
                maxWidth={popupMaxWidth}
                name={name}
                alwaysVisible
                withMinWidths
              />
            </LinkStyled>
            <CloseButton onClick={this.onClose}><BsFillPlusCircleFill /></CloseButton>
          </StyledModal>
        </React.Fragment>
      );
    }
    return image;
  }
}
