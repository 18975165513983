import React from 'react';

const { Provider, Consumer } = React.createContext('default');

export { Consumer as ItemContextConsumer };
export default ({ context, children }) => (
  <Provider value={context}>
    {children}
  </Provider>
);
