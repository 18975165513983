import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import styled from 'styled-components/macro';

const TabDesignNoProps = ({ active, ...otherProps }) => (
  <Link {...otherProps} />
);
const TabDesign = styled(TabDesignNoProps)`
  display: inline-block;
  position: relative;
  top: 2px;
  font-size: 14px;
  padding: 15px 20px;
  margin-right: 10px;
  font-weight: bold;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-decoration: none;
  @media (max-width: 1199px) {
    padding: 15px;
    font-size: 13px;
    margin-right: 2px;
  }
  @media (max-width: 400px) {
    padding: 10px;
    font-size: 12px;
  }

  ${({ active, theme }) => {
    if (active) {
      return `
        background-color:  ${theme.colors.colorGreyLightAlt};
        color:  ${theme.colors.colorGreyDark};
        box-shadow: 0 0 10px rgba(0,0,0,0.5);
        @media screen and (max-width: 991px) {
          box-shadow: 0 0 0 rgba(0,0,0,0);
        }
      `;
    }
    return `
      background-color:  ${theme.colors.colorBlue};
      color:  ${theme.colors.colorWhite};
      box-shadow: 0 0 30px rgba(0,0,0,0.2) inset;
    `;
  }}
`;
export default ({ to, children, id }) => {
  const realId = id ? `underCat_${id}` : undefined;
  return (
    <Switch>
      <Route exact path={to} render={() => <TabDesign active to={to} id={realId}>{children}</TabDesign>} />
      <Route render={() => <TabDesign to={to} id={realId}>{children}</TabDesign>} />
    </Switch>
  );
};
