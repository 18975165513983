import styled, { css } from 'styled-components/macro';

const sizes = {
  xs: 0,
  sm: 575,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600
};

const breakpoints = Object.keys(sizes).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `;
  return accumulator;
}, {});

const hide = (breakpoint, value) => css`
  ${value && breakpoints[breakpoint]`
    display: none;
  `}

  ${!value && breakpoints[breakpoint]`
    display: block;
  `}
`;
const hideStyles = css`
  ${({ hidexs }) => hide('xs', hidexs)}
  ${({ hidesm }) => hide('sm', hidesm)}
  ${({ hidemd }) => hide('md', hidemd)}
  ${({ hidelg }) => hide('lg', hidelg)}
  ${({ hidexl }) => hide('xl', hidexl)}
  ${({ hidexxl }) => hide('xxl', hidexxl)}
`;

export const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  ${({ padding }) => padding ? `padding: ${padding};` : 'padding: 0 15px;'}
  ${({ white, theme }) => white && `background-color: ${theme.colors.colorWhite};`}
  ${({ mb }) => mb && 'margin-bottom: 15px;'}
  ${breakpoints.sm`max-width: 540px;`}
  ${breakpoints.md`max-width: 750px;`}
  ${breakpoints.lg`max-width: 960px;`}
  ${breakpoints.xl`max-width: 1140px;`}
  ${breakpoints.xxl`max-width: 1540px;`}
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: ${({ gap }) => gap ? `${gap}px` : '15px'};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ padding }) => padding ? `padding: ${padding};` : 'padding: 15px 0;'}
`;

export const Col = styled.div`
  display: grid;
  ${({ xs }) => xs && `grid-column: span ${xs};`}
  ${({ sm }) => sm && breakpoints.sm`grid-column: span ${sm};`}
  ${({ md }) => md && breakpoints.md`grid-column: span ${md};`}
  ${({ lg }) => lg && breakpoints.lg`grid-column: span ${lg};`}
  ${({ xl }) => xl && breakpoints.xl`grid-column: span ${xl};`}
  ${({ xxl }) => xxl && breakpoints.xxl`grid-column: span ${xxl};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom};`}
  ${({ rounded }) => rounded && 'border-radius: 15px; overflow: hidden;'}
  ${({ white, theme }) => white && `background-color: ${theme.colors.colorWhite};`}
  ${hideStyles}
`;