import { connect } from 'react-redux';
import { getItem } from '../../features/commerce';

export default connect((state, { varenr }) => ({
  item: getItem(state, { varenr }),
}))(({
  item: {
    values: {
      countPrColli,
    },
  },
}) => {
  return countPrColli;
});
