import React from 'react';
import Format from '../../Format';
import { sanitizeMeasurement } from '../../../lib/helpers';

export default ({ value, noSuffix }) => (
  <Format
    fixedDecimalScale
    decimalScale={1}
    suffix={!noSuffix && ' %'}
    value={sanitizeMeasurement(value)}
  />
);
