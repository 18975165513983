// Hvis der er nogen tvivl om hvad et felt i skaftes fil hedder i systemet, kig i app/console/commands/UpdateItems.php
export default {
  [9252]: {
    countPrPalle: 39,
  },
  [3904]: {
    countPrColli: 1,
  },
  [20955]: {
    packageNoun: 'meter',
  },
  [20930]: {
    packageNoun: 'meter',
  },
  [8945]: {
    packageNoun: 'batteri',
  },
  [888]: {
    caseDeposit: 0,
  },
  [3901]: {
    packageNoun: 'kasse',
  },
  [8962]: {
    packageNoun: 'flaske',
  },
  [8964]: {
    packageNoun: 'flaske',
  },
  [8966]: {
    packageNoun: 'flaske',
  },
  [8970]: {
    packageNoun: 'flaske',
  },
  [8944]: {
    weight: 11.25,
  },
};
