import React from 'react';
import styled from 'styled-components/macro';
import SlideToggle from 'react-slide-toggle';
import { FaAngleDown, FaAngleUp } from './Icons';

const ReadMoreButtonNoStyle = ({
  open,
  title,
  ...otherProps
}) => (
  <button type="button" {...otherProps}>
    {title && (
      <React.Fragment>
        {title}&nbsp;
      </React.Fragment>
    )}
    {!title && (
      <React.Fragment>
        Læs {open ? 'mindre' : 'mere'}&nbsp;
      </React.Fragment>
    )}
    {open ? <FaAngleUp /> : <FaAngleDown />}
  </button>
);

const ReadMoreButton = styled(ReadMoreButtonNoStyle)`
  display: flex;
  align-items: center;
  outline: none;
  font-size: 14px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.colorWhite};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.colorGreyDark};
  border: 1px solid ${({ theme }) => (theme.colors.colorGreyLightAlt)};
  transition: all 0.2s ease;

  &:hover {
    border: 1px solid ${({ theme }) => (theme.colors.colorGreyLightAlt)};
  }

  ${({ open, theme }) => {
    if (open) {
      return `
        border: 1px solid ${({ theme }) => (theme.colors.colorGreyLightAlt)};
        background-color: ${theme.colors.colorWhite};
    `;
    }
    return `
    `;
  }}
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`}
  ${({ marginBottom }) => {
    if (marginBottom) {
      return `
        margin-bottom: ${marginBottom};
    `;
    }
    return 'margin-bottom: 10px;';
  }}
  ${({ padding }) => {
    if (padding) {
      return `
        padding: ${padding};
    `;
    }
    return 'padding: 5px 12px;';
  }}
`;
export default class ReadMore extends React.Component {
  state = { toggleEvent: 0, open: false };

  onToggle = () => {
    this.setState(({ open }) => ({ toggleEvent: Date.now(), open: !open }));
  };

  render() {
    const { toggleEvent, open } = this.state;
    const {
      children, title, marginTop, marginBottom, padding,
    } = this.props;
    return (
      <div>
        <ReadMoreButton className="toggle" open={open} onClick={this.onToggle} title={title} marginTop={marginTop} marginBottom={marginBottom} padding={padding} />
        <SlideToggle toggleEvent={toggleEvent} collapsed={!open}>
          {({ setCollapsibleElement }) => (
            <div className="my-collapsible">
              <div
                className="my-collapsible__content"
                ref={setCollapsibleElement}
              >
                <div className="my-collapsible__content-inner">
                  {children}
                </div>
              </div>
            </div>
          )}
        </SlideToggle>
      </div>
    );
  }
}
