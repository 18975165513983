import React from 'react';
import { Paragraph } from '../components/typography';
import { Container, Row, Col } from '../components/grid';

export default class KunneIkkeHenteRute extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container white>
          <Row>
            <Col xs={12} height="300px" padding="100px" textCenter>
              <Paragraph textCenter>
                Kunne ikke hente rute, og siden er crashet.<br />
                Prøv at opdatere siden, men hvis fejlen bliver ved med at ske, kan du bruge følgende link til at nulstille din cache, hvilket i mange tilfælde løser problemet: <a href="/vaerktoejer/nulstil_cache">Nulstil cache</a>
              </Paragraph>
            </Col>
          </Row>
        </Container>
      );
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}
