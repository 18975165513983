import React from 'react';
import { ImagePopup } from '../../Image';

export default ({
  packImages,
}) => (
  <React.Fragment>
    {packImages.length > 0 && (
      packImages.map(
        ({ name }, index) => (
          <ImagePopup
            name={name}
            marginLeft={index === 0 ? undefined : '5px'}
            key={index}
            imageHeight="50px"
            maxHeight="50px"
            popupImageHeight="700px"
            popupMaxWidth="700px"
          />
        ),
      )
    )}
  </React.Fragment>
);
