import React from 'react';
import styled from 'styled-components/macro';
import GodkendTabButton from '../layout/MainBestilLayout/GodkendTabButton';
import TabButtonContainer from '../layout/MainBestilLayout/TabButtonContainer';
import { FaCog } from '../../../components/Icons';

const TabButtonWrapNoStyle = ({ children, ...otherProps }) => (
  <div {...otherProps}>
    {children}
  </div>
);
const TabButtonWrap = styled(TabButtonWrapNoStyle)`
  border-bottom: 10px solid ${({ theme }) => theme.colors.colorGreyLightAlt};
  overflow: hidden;
  padding-top: 5px;
  @media (max-width: 991px) {
    border-bottom: 0px !important;
  }
`;
const tabs = [
  {
    id: 'fadoel',
    to: '/bestil/fadoel-og-anlaeg/fadoel',
    title: 'Fadøl',
    mediumTitle: 'Fadøl',
    smallTitle: 'Fadøl',
    showSmall: true,
    showMedium: true,
  },
  {
    id: 'anlaeg-og-bar',
    to: '/bestil/fadoel-og-anlaeg/anlaeg-og-bar',
    title: 'Anlæg og bar',
    mediumTitle: 'Anlæg og bar',
    smallTitle: 'Anlæg',
    showSmall: true,
    showMedium: true,
  },
  {
    id: 'oelglas',
    to: '/bestil/fadoel-og-anlaeg/oelglas',
    title: 'Is & Glas',
    mediumTitle: 'Is & Glas',
    smallTitle: 'Is & Glas',
    showSmall: true,
    showMedium: true,
  },
  {
    id: 'reservedele',
    to: '/bestil/fadoel-og-anlaeg/reservedele',
    title: 'Reservedele',
    mediumTitle: 'Reservedele',
    smallTitle: (<React.Fragment><FaCog /></React.Fragment>),
    showSmall: true,
    showMedium: true,
  },
  {
    id: 'kulsyre',
    to: '/bestil/fadoel-og-anlaeg/kulsyre',
    title: 'Kulsyre',
    mediumTitle: 'Kulsyre',
    smallTitle: 'CO2',
    showSmall: true,
    showMedium: true,
  },
];

export default ({ activeTab }) => (
  <TabButtonWrap>
    <TabButtonContainer activeTab={activeTab} tabs={tabs} />
    <GodkendTabButton to="/bestil/detaljer" title="Gå til kassen" />
  </TabButtonWrap>
);
