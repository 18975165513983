import React from 'react';
import styled from 'styled-components/macro';
import { Route, Switch } from 'react-router-dom';
import classNames from 'classnames';
import BucketWrapNoStyle from '../BucketWrapNoStyle';
import { Money, BucketEntry } from '../../../../containers/commerce';
import { MoneyFormat } from '../../../Format';
import BucketEntryView from '../entryViews/BucketEntryView';
import BucketDelivery from '../BucketDelivery';
import WaterBucketEntryView from '../entryViews/WaterBucketEntryView';
import KurvFadoelsBeregner from '../../../../containers/KurvFadoelsBeregner';
import { Table, Td } from '../../../tables';
import {
  LinkStyled, Hr, LinkButton,
} from '../../../typography';
import { GrCart } from '../../../Icons';

const renderItems = items => items.map(
  varenr => (
    <BucketEntry
      display={BucketEntryView}
      waterDisplay={WaterBucketEntryView}
      varenr={varenr}
      key={varenr}
    />
  ),
);
const renderDelivery = () => <BucketDelivery />;
const BucketWrap = styled(BucketWrapNoStyle)`
  margin-top: 0px;
  outline: none;
  border: none;
  height: 100%;
  background: ${({ theme }) => theme.colors.colorWhite};
`;
const BucketHeader = styled.div`
  border-top: 10px solid ${({ theme }) => theme.colors.colorBlue};
  background: ${({ theme }) => theme.colors.colorGreyLight};
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const HeadingWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > svg {
    font-size: 35px;
    margin-right: 5px;
    position: relative;
    top: -2px;
  }
`;
const BucketHeading = styled.span`
  font-size: 16px;
  font-weight: bold;

  @media (max-width: 370px) {
    display: none;
  }
`;
const HeadingButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const ItemsWrapNoStyle = ({
  children,
  ...otherProps
}) => (
  <div {...otherProps}>
    {children}
  </div>
);
const ItemsWrap = styled(ItemsWrapNoStyle)`
  border-right: 3px solid ${({ theme }) => theme.colors.colorGreyLight};
  border-left: 3px solid ${({ theme }) => theme.colors.colorGreyLight};
  background: ${({ theme }) => theme.colors.colorWhite};
  padding: 0 15px;
`;
const ButtomWrapNoStyle = ({
  children,
  ...otherProps
}) => (
  <div {...otherProps}>
    {children}
  </div>
);
const ButtomWrap = styled(ButtomWrapNoStyle)`
  background: ${({ theme }) => theme.colors.colorGreyLight};
  padding: 15px;
`;
const CustomLinkButton = styled(LinkButton)`
  display: none;

  @media (max-width: 991px) {
    display: block;
  }
`;

export default ({
  totalCost,
  totalCostWithoutPant,
  totalPant,
  totalReturPant,
  items,
  isDelivery,
  deliveryCost,
  deliveryCostWithoutFees,
  deliveryFeesCost,
  externalDeliveryFeeCost,
  hasMoms,
  className,
  ...props
}) => (
  <BucketWrap {...props} className={classNames(className, 'visibleBucketWrap')}>
    <BucketHeader>
      <HeadingWrap>
        <GrCart /><BucketHeading>Indkøbskurv</BucketHeading>
      </HeadingWrap>
      <HeadingButtonWrap>
        <Switch>
          <Route
            path="/bestil/detaljer"
            exact
            render={() => (
              <CustomLinkButton anchor xtraSmall to="/bestil/detaljer#betaling">
                Til betaling
              </CustomLinkButton>
            )}
          />
          <Route
            render={() => (
              <CustomLinkButton xtraSmall to="/bestil/detaljer">
                Til kassen
              </CustomLinkButton>
            )}
          />
        </Switch>
      </HeadingButtonWrap>
    </BucketHeader>
    <ItemsWrap>
      {renderItems(items)}
      {renderDelivery({
        isDelivery,
        deliveryCost,
        deliveryCostWithoutFees,
        deliveryFeesCost,
        externalDeliveryFeeCost,
      })}
    </ItemsWrap>
    <span id="subBucketHit" style={{ display: 'block' }} />
    <ButtomWrap>
      {(!hasMoms || totalPant !== 0) && (
        <React.Fragment>
          <Table>
            <tbody>
              <tr>
                <Td bold>
                  I alt {hasMoms ? 'med moms' : 'uden moms'}{
                  totalPant !== 0 && (
                    <React.Fragment>,<br />uden pant</React.Fragment>
                  )}:
                </Td>
                <Td bold textRight>
                  {totalPant !== 0 && <br />}
                  <Money money={totalCostWithoutPant} />
                </Td>
              </tr>
            </tbody>
          </Table>
          {(!hasMoms || totalPant !== 0 || totalReturPant !== 0) && <Hr bgColor="#999" />}
        </React.Fragment>
      )}
      <Table margin="10px 0" paddingTd="2px 0">
        <tbody>
          {totalPant !== 0 && (
            <tr>
              <Td><LinkStyled to="/bestil/returpant">Pant</LinkStyled>:</Td>
              <Td textRight><Money money={totalPant} /></Td>
            </tr>
          )}
          {totalReturPant !== 0 && (
            <tr>
              <Td><LinkStyled to="/bestil/returpant">Returpant</LinkStyled>:</Td>
              <Td textRight><Money money={totalReturPant} multiplier={-1} /></Td>
            </tr>
          )}
          {!hasMoms && (
            <tr>
              <Td>Moms:</Td>
              <Td textRight><MoneyFormat onlyMoms money={totalCost} /></Td>
            </tr>
          )}
        </tbody>
      </Table>
      {(!hasMoms || totalPant !== 0 || totalReturPant !== 0) && <Hr bgColor="#999" />}
      <Table margin="0 0 30px">
        <tbody>
          <tr>
            <Td bold>I alt med moms:</Td>
            <Td bold textRight><MoneyFormat moms money={totalCost} /></Td>
          </tr>
        </tbody>
      </Table>
      <Switch>
        <Route
          path="/bestil/detaljer"
          exact
          render={() => (
            <LinkButton anchor fullWidth small arrow to="/bestil/detaljer#betaling">
              Gå til betalingsmetode
            </LinkButton>
          )}
        />
        <Route
          render={() => (
            <LinkButton fullWidth small arrow to="/bestil/detaljer">
              Gå til kassen
            </LinkButton>
          )}
        />
      </Switch>
      <KurvFadoelsBeregner />
    </ButtomWrap>
  </BucketWrap>
);
