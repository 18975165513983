import React from 'react';
import styled from 'styled-components/macro';
import { Money } from '../../../containers/commerce';
import { Span } from '../../typography';

// Create a const that uses styled Span and make the background color yellow and the font color dark.
const SalesPrice = styled(Span)`
  background-color: ${({ theme }) => theme.colors.colorYellow};
  color: ${({ theme }) => theme.colors.dark};
  padding: 15px;
  border-radius: 10px;
  line-height: 1em;
  font-size: 16px;
  font-weight: bold;

  // Change padding if the prop nembestilling is true.
  ${({ nembestilling }) => nembestilling && `
    padding: 10px;
    font-size: 14px;
    margin-top: 10px;
  `}
`;

export default ({ beforePrice, discount, ...otherProps }) => {
  if (!beforePrice) {
    return null;
  }
  return (
    <SalesPrice {...otherProps}>
      Før:&nbsp;
      <Money money={beforePrice} />&nbsp; |&nbsp;
      Spar <Money money={discount} />
    </SalesPrice>
  );
};
