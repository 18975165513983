import React from 'react';
import { connect } from 'react-redux';
import { sanitizePrice } from '../../lib/price';
import { sanitizeMeasurement } from '../../lib/helpers';
import Money from './Money';
import { getItem } from '../../features/commerce';

export default connect((state, { varenr }) => ({
  item: getItem(state, { varenr }),
}))(({
  item: {
    values: {
      price,
      countPrColli,
      totalCasePrice,
    },
  },
  prStk,
  embPrice,
  momsIndication,
  ...props
}) => {
  let priceVal = sanitizePrice(price);
  if (embPrice) {
    priceVal = sanitizePrice(totalCasePrice);
  }
  if (prStk) {
    priceVal /= sanitizeMeasurement(countPrColli);
  }
  return (
    <Money
      money={priceVal}
      momsIndication={momsIndication}
      {...props}
    />
  );
});
