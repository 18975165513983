
export const MENU_OPEN = 'SKAFTE/BURGERMENUS/MENU_OPEN';
export const MENU_CLOSE = 'SKAFTE/BURGERMENUS/MENU_CLOSE';
export const BUCKET_OPEN = 'SKAFTE/BURGERMENUS/BUCKET_OPEN';
export const BUCKET_CLOSE = 'SKAFTE/BURGERMENUS/BUCKET_CLOSE';

export const openMenu = () => ({
  type: MENU_OPEN,
});

export const closeMenu = () => ({
  type: MENU_CLOSE,
});

export const openBucket = () => ({
  type: BUCKET_OPEN,
});

export const closeBucket = () => ({
  type: BUCKET_CLOSE,
});

const initialState = {
  menu: false,
  bucket: false,
};
const getLocalState = state => state.burgermenus;
export const getBucketState = state => getLocalState(state).bucket;
export const getMenuState = state => getLocalState(state).menu;

export default function burgerMenusReducer(state = initialState, { type }) {
  switch (type) {
    case MENU_OPEN: {
      return { menu: true, bucket: false };
    }
    case MENU_CLOSE: {
      return { ...state, menu: false };
    }
    case BUCKET_OPEN: {
      return { menu: false, bucket: true };
    }
    case BUCKET_CLOSE: {
      return { ...state, bucket: false };
    }
    default:
      return state;
  }
}
