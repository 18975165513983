/* eslint no-undef: 0 */ // --> OFF, because of document undeined
/* eslint react/jsx-filename-extension: off */ // --> OFF, because of document undeined
/* eslint-disable import/first */
require('@babel/polyfill'); // eslint-disable-line
import React from 'react';
import { configureAnchors } from 'react-scrollable-anchor';
import { loadableReady } from '@loadable/component';
import ReactDOM from 'react-dom';
import sizeMe from 'react-sizeme';
import moment from 'moment';
import Modal from 'react-modal';
import smartlookClient from 'smartlook-client';
import { persistStore } from 'redux-persist';
import { SizesProvider } from 'react-sizes';
import createAppStore from './store';
import Main from './Main';
import { doStartup, completeStartup } from './features/actions';
import { getDocument } from './lib/html';
import { optScripts } from './lib/analytics';
import { initSentry, includeSentry, doImport as doImportSentry } from './lib/sentry';
import getArg from './lib/args';
import init from './features/init';
import { getInitialState as getInitialItemsState } from './features/commerce/items';
import { loadTestData } from './features/form/actions';
import gridSet, { getSizesFallback, getActualDeviceType } from './lib/grid-set';
import setupBootstrap from './bootstrap';
import { doImport } from './getImageInfo';

setupBootstrap()
  .then(
    () => {
      if (includeSentry()) {
        console.log('Initializing sentry');
        doImportSentry();
        initSentry({
          dsn: 'https://826d18d177c44559b489ca34a382520f@sentry.io/1432307',
        });
      }
        optScripts();

      sizeMe.noPlaceholders = true;
      configureAnchors({ offset: -60, scrollDuration: 200 });
      const actualDeviceType = getActualDeviceType();
      const deviceType = getArg('deviceType');
      gridSet(actualDeviceType);
      moment.locale('da');
      Modal.setAppElement('#root');
      const serverRender = getArg('serverRender');
      const argState = getArg('state');
      if (serverRender) {
        argState.commerce.items = getInitialItemsState(argState.commerce.items);
      }
      const initialState = serverRender ? argState : init({});
      const store = createAppStore(initialState);
      store.dispatch(doStartup());
      window.__LOAD_TEST_DATA__ = () => { // eslint-disable-line
        store.dispatch(loadTestData());
      };
      const sizesConfig = getSizesFallback(actualDeviceType);
      const App = () => (
        <SizesProvider config={sizesConfig}>
          {Main(store)}
        </SizesProvider>
      );
      const document = getDocument();
      if (document.getElementById('root')) {
        if (serverRender && deviceType === actualDeviceType) {
          loadableReady(() => {
            const appResult = App();
            ReactDOM.hydrate(
              appResult,
              document.getElementById('root'),
              () => {
                setTimeout(() => {
                  // eslint-disable-next-line no-unused-vars
                  const persistor = persistStore(store, null, () => {
                    store.dispatch(completeStartup());
                    doImport();
                  });
                }, 0);
              },
            );
          });
        } else {
          const appResult = App();
          ReactDOM.render(
            appResult,
            document.getElementById('root'),
            () => {
              setTimeout(() => {
                console.log('Seting complete timeout');
                // eslint-disable-next-line no-unused-vars
                const persistor = persistStore(store, null, () => {
                  console.log('Completeing startup');
                  store.dispatch(completeStartup());
                });
              }, 0);
            },
          );
        }
      }
    },
  );
