import compareVersions from 'compare-versions';
import { detectBrowser } from '../helpers';

export default (componentList) => {
  if (componentList.length === 0) {
    throw new Error('List of components passed to BrowserSwitch hoc cannot be 0');
  }
  if (componentList.length === 1) {
    throw new Error('List of components passed to BrowserSwitch hoc cannot be 1');
  }
  const {
    name: currentBrowser,
    version: currentVersion,
  } = detectBrowser();
  let RetComponent = null;
  const error = 'BrowserSwitch hoc must recieve a list of the following format [[matcher, Component], [matcher, Component]..., Component]';
  for (let i = 0; i < componentList.length - 1; i += 1) {
    const [matcher, Component] = componentList[i];
    if (!matcher || !Component) {
      throw new Error(error);
    }
    const {
      browser,
    } = matcher;

    if (!browser) {
      throw new Error('Error in BrowerSwitch, each matcher must have a browser');
    }
    if (!RetComponent && browser === currentBrowser) {
      if (matcher.min) {
        const comp = compareVersions(matcher.min, currentVersion);
        if (comp === 1) {
          continue;
        }
      }
      if (matcher.max) {
        const comp = compareVersions(matcher.max, currentVersion);
        if (comp === -1) {
          continue;
        }
      }
      RetComponent = Component;
    }
  }
  if (!RetComponent) {
    return componentList[componentList.length - 1];
  }
  return RetComponent;
};
