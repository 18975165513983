import React, { useState } from 'react';
import styled from 'styled-components/macro';
import orderBy from 'lodash/orderBy';
import ItemValuesDecorator from './ItemValuesDecorator';
import Item from './Item';
import { productCategoryLookup } from '../../content/commerce/itemLookup';
import { getItemDefaultInformation } from '../../content/commerce/items';
import { getItemLiter, getFadKoblingExact, getPricePerLiter } from '../../features/commerce/items';
import { FilterButton } from './parts';
import { Col, Div, Row } from '../../components/grid';
import { BsCheck } from '../../components/Icons';

const ItemTraverser = ({
  filterLiterSize,
  filterFadkobling,
  filterLiterPrice,
  category,
  triple,
  showType,
  showTypeFadoel,
  showAlcPct,
  includedCategories = [],
  excludedCategories = [],
  itemsState,
  itemsLocalState,
  inclusionMode = 'INTERSECTION',
  ...props
}) => {
  // --------------------------------------------
  // CATEGORY FILTERING
  // --------------------------------------------

  const filterItemsByCategories = (items, includedCategories, excludedCategories) => items.filter((varenr, index, self) => {
    const item = getItemDefaultInformation(varenr);
    if (item) {
      // If the inclusionMode is intersection the item MUST be present in ALL defined "includeCategories" to be shown
      // If the inclusionMode is something else, the item should just be part of on of the includeCategories.
      const includeFunction = inclusionMode === 'INTERSECTION' ? 'every' : 'some';
      return includedCategories[includeFunction](category => item.categories.includes(category))
      && !excludedCategories.some(category => item.categories.includes(category))
      && self.indexOf(varenr) === index;
    }
    return false;
  });

  const actualIncludedCategories = category ? [category, ...includedCategories] : includedCategories;
  if (actualIncludedCategories.length === 0) {
    return null;
  }

  let items = productCategoryLookup[actualIncludedCategories[0]];
  if (actualIncludedCategories.length > 1) {
    items = actualIncludedCategories.slice(1).reduce((acc, category) => acc.concat(productCategoryLookup[category]), items);
  }
  items = filterItemsByCategories(items, actualIncludedCategories, excludedCategories);
  items = orderBy(items, varenr => getItemDefaultInformation(varenr).itemOrder, 'asc');


  // --------------------------------------------
  // FILTERS
  // The filters use functions from resources/assets/js/features/commerce/items/index.js, such as getItemLiter and getFadKobling, to filter the items.
  // --------------------------------------------


  // --------------------------------------------
  // LITER FILTER
  // --------------------------------------------

  // Add state to track the selected liter sizes
  const [selectedLiterSizes, setSelectedLiterSizes] = useState([]);


  // Get the unique liter sizes, exclude under 4 liter and sort the sizes
  const literSizes = [...new Set(items.map(item => getItemLiter(itemsState, { varenr: item })))].filter(size => size >= 4).sort((a, b) => a - b);

  // --------------------------------------------
  // FADKOBLING FILTER
  // --------------------------------------------

  // Add state to track the selected fadkobling types
  const [selectedFadKoblingTypes, setSelectedFadKoblingTypes] = useState([]);


  // Get the unique fadkobling types and rename "Key Keg" to "K"
  const fadKoblingTypes = [...new Set(items.map(item => {
    const fadKobling = getFadKoblingExact(itemsLocalState, { varenr: item });
    return fadKobling === "Key Keg" ? "K" : fadKobling;
  }))].filter(type => type.trim() !== '').sort((a, b) => a - b);


  // --------------------------------------------
  // LITER PRICE FILTER
  // --------------------------------------------

  // Add state to track the selected liter prices
  const [selectedLiterPrice, setSelectedLiterPrice] = useState({
    '0-30': false,
    '30-45': false,
    '45+': false,
  });
  function calculateCombined(i, literSizes, fadKoblingTypes, literPrice) {
    // Create a combined array of items to filter on
    let combined = i;
    // Filter the items based on the selected liter sizes
    if (literSizes.length) {
      combined = combined.filter(item => literSizes.includes(getItemLiter(itemsState, { varenr: item })));
    }
    // Filter the items based on the selected fadkobling types
    if (fadKoblingTypes.length) {
      combined = combined.filter(item => fadKoblingTypes.includes(getFadKoblingExact(itemsLocalState, { varenr: item })));
    }
    // Filter the items based on the selected liter prices in a range value of 0-30, 30-45 and 45+
    if (Object.values(literPrice).some(selected => selected)) {
      combined = combined.filter((item) => {
        const pricePerLiter = getPricePerLiter(itemsState, { varenr: item });
        return (literPrice[0] && pricePerLiter >= 0 && pricePerLiter < 30)
                || (literPrice[1] && pricePerLiter >= 30 && pricePerLiter < 45)
                || (literPrice[2] && pricePerLiter >= 45);
      });
    }
    return combined;
  }
  const combined = calculateCombined(items, selectedLiterSizes, selectedFadKoblingTypes, selectedLiterPrice);
  // Create a const called literPrices that gets the unique liter prices and devides them in three intervals with the labels: 0-30 kr/l, 30-45 kr/l and 45+ kr/l. Filter the intervals out if there are no items to show
  const literPrices = [
    { label: '0-30 kr/l', value: '0-30', items: [...new Set(items.map(item => getPricePerLiter(itemsState, { varenr: item })))].filter(price => price >= 0 && price < 30) },
    { label: '30-45 kr/l', value: '30-45', items: [...new Set(items.map(item => getPricePerLiter(itemsState, { varenr: item })))].filter(price => price >= 30 && price < 45) },
    { label: '45+ kr/l', value: '45+', items: [...new Set(items.map(item => getPricePerLiter(itemsState, { varenr: item })))].filter(price => price >= 45) },
  ].filter(({ items }) => items.length > 0);


  // --------------------------------------------
  // FILTER BUTTONS
  // --------------------------------------------

  // Function to toggle the selected value in an array
  const toggleSelected = (array, value) => {
    if (array.includes(value)) {
      return array.filter(val => val !== value);
    }
    return [...array, value];
  };

  // Function to render the filter buttons. If the isPriceFilter prop is true, the buttons will be rendered with the selectedLiterPrice state. However the liter price buttons should not be rendered if there are no items to show in
  const renderFilterButtons = (array, selected, setSelected, suffix, isPriceFilter, isLiterSizes) => (
    array.map((value, index) => {
      if (isPriceFilter) {
        if (calculateCombined(items, [],[],{[index]: true}).length === 0) {
          return null;
        }
        return (
          <FilterButton
            data-selected={selected[index]}
            onClick={() => setSelected({
              ...selected,
              [index]: !selected[index],
            })}
          >
            <span>{value.label}</span>
            <span className="icon-separator">{selected[index] ? <BsCheck /> : ''}</span>
          </FilterButton>
        );
      }
      if (
        (isLiterSizes && calculateCombined(items, [value],[],{}).length === 0)
        || (!isLiterSizes && calculateCombined(items, [],[value],{}).length === 0)
      ) {
        return null;
      }

      return (
        <FilterButton
          data-selected={selected.includes(value)}
          onClick={() => setSelected(toggleSelected(selected, value))}
        >
          <span>{value}{suffix}</span>
          <span className="icon-separator">{selected.includes(value) ? <BsCheck /> : ''}</span>
        </FilterButton>
      );
    })
  );

  // Render the filter buttons
  const filterFadkoblingButtons = renderFilterButtons(fadKoblingTypes, selectedFadKoblingTypes, setSelectedFadKoblingTypes, '-kobling', false, false);
  const filterLiterSizeButtons = renderFilterButtons(literSizes, selectedLiterSizes, setSelectedLiterSizes, ' liter', false, true);
  const filterLiterPriceButtons = renderFilterButtons(literPrices, selectedLiterPrice, setSelectedLiterPrice, '', true, false);

  // Create a filterButton that clears all filters. The button should be rendered with the clear prop to style it differently.
  const clearFilterButton = (
    <FilterButton
      clear
      onClick={() => {
        setSelectedFadKoblingTypes([]);
        setSelectedLiterSizes([]);
        setSelectedLiterPrice({
          0: false,
          1: false,
          2: false,
        });
      }}
    >
      <span>Ryd filtre</span>
    </FilterButton>
  );

  // --------------------------------------------
  // Miscilaneous components
  // --------------------------------------------

  // Component ColStyled based on Col from grid.
  const FlexWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;

    @media (max-width: 767px) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 8px;
    }

    @media (max-width: 575px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 440px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 5px;
    }
  `;

  const VisText = styled.div`
    font-weight: bold;

    @media (max-width: 767px) {
      font-size: 14px;
      text-align: center;
      grid-column: 1 / 5;
    }

    @media (max-width: 575px) {
      grid-column: 1 / 4;
    }

    @media (max-width: 440px) {
      grid-column: 1 / 3;
    }
  `;

  const NoItems = styled(Div)`
    background: ${({ theme }) => theme.colors.colorWhite};
    padding: 15px;
    width: calc(100% - 30px);
    margin: 0 7px 15px;
    font-size: 16px;
  `;

  // Create a const that have a text if there are no items to show.
  const noItemsText = combined.length === 0
    ? (
      <NoItems>Ingen produkter matcher dine valg</NoItems>
    )
    : null;

  // --------------------------------------------
  // RENDERING
  // --------------------------------------------
  return (
    <React.Fragment>
      {(filterFadkobling || filterLiterPrice || filterLiterSize) && (
        <Col xs={12} paddingTop="0px">
          <FlexWrap>
            <VisText>Vis kun: </VisText>
            {/* Filter on fadkobling */}
            {filterFadkobling && fadKoblingTypes.length > 1 && (filterFadkoblingButtons)}

            {/* Filter on liter price */}
            {filterLiterPrice && (filterLiterPriceButtons)}

            {/* Filter on liter size */}
            {filterLiterSize && literSizes.length > 1 && (filterLiterSizeButtons)}

            {/* Clear filter button */}
            {clearFilterButton}
          </FlexWrap>
        </Col>
      )}
      <Row margin="0">
        {combined.map(
          varenr => (
            <Item varenr={varenr} showType={showType} showTypeFadoel={showTypeFadoel} showAlcPct={showAlcPct} triple={triple} key={varenr} {...props} />
          ),
        )}
      </Row>
      {/* Text if there are no items to show */}
      {(filterFadkobling || filterLiterPrice || filterLiterSize) && (
        <React.Fragment>
          {noItemsText}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default props => (
  <ItemValuesDecorator
    Component={ItemTraverser}
    {...props}
  />
);
