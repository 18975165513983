// Import External Dependencies
import { createStore } from 'redux';

// Create the reducer
const crumbs = (state = [], action) => {
  switch (action.type) {
    case 'SET_CRUMBS':
      return action.payload;
    default:
      return state;
  }
};

// Create the store
const store = createStore(crumbs);

// Export store and Dispatch method
export default store;
export const Dispatch = store.dispatch;
