import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { setShowSearchOverlay, getShowSearchOverlay } from '../../../../features/commerce/search';
import { FaSearch, FaTimes } from '../../../../components/Icons';

const IconSwitch = ({ showSearch, ...props }) => (showSearch ? <FaTimes {...props} /> : <FaSearch {...props} />);

const IconWrap = styled.div`
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  top: 12px;
  left: 50%;
  margin-left: -12.5px;
  color: ${({ theme }) => theme.colors.colorBlue};
  ${({ showSearch }) => showSearch && 'font-size: 27px; width: 25px !important;'}
`;

const SearchIcon = ({ openSearch, showSearch, ...props }) => (
  <IconWrap showSearch={showSearch} onClick={showSearch ? undefined : openSearch} {...props}>
    <IconSwitch showSearch={showSearch} />
  </IconWrap>
);

export default connect(state => ({ showSearch: getShowSearchOverlay(state) }), { openSearch: () => setShowSearchOverlay(true) })(SearchIcon);
