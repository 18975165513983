import loadScript from 'load-external-scripts';
import { getWindow, getDocument } from './lib/html';
import { setArgs } from './lib/args';
import { setDefault, doImport } from './getImageInfo';

function getHtmlArg(name) {
  const res = getWindow()[`__${name}__`]; // eslint-disable-line
  if (!res) {
    console.log('ARG NOT EXISTING', name);
  }
  return res;
}
export default async function setupBootstrap() {
  getWindow().loadScript = loadScript;
  const serverRender = getHtmlArg('SERVER_RENDER');
  const epayMerchantNumber = getHtmlArg('EPAY_MERCHANT_NUMBER');

  const isProduction = getHtmlArg('IS_PRODUCTION');
  const isPageSpeed = getHtmlArg('IS_PAGESPEED');
  const deviceType = getHtmlArg('DEVICE_TYPE');

  if (!serverRender) {
    const args = {
      serverRender,
      epayMerchantNumber,
      products: getHtmlArg('PRODUCTS'),
      waterDefinition: getHtmlArg('WATER_DEFINITION'),
      waterDelivery: getHtmlArg('WATER_DELIVERY'),
      currencies: getHtmlArg('CURRENCIES'),
      plejerliste: getHtmlArg('PLEJERLISTE'),
      postnr: getHtmlArg('POSTNR'),
      productVersion: getHtmlArg('PRODUCT_VERSION'),
      serverDirect: false,
      isProduction,
      isPageSpeed,
      host: getWindow().location.origin,
      browser: true,
      deviceType,
    };
    setArgs(args);
    setDefault({});
    await doImport();
  } else {
    const state = getHtmlArg('STATE');
    const args = {
      serverRender,
      state,
      epayMerchantNumber,
      serverDirect: false,
      isProduction,
      isPageSpeed,
      host: getWindow().location.origin,
      browser: true,
      deviceType,
    };
    setArgs(args);
    const imageInfo = getHtmlArg('IMAGE_INFO');
    setDefault(imageInfo);
  }

  /**
   * We'll load the axios HTTP library which allows us to easily issue requests
   * to our Laravel back-end. This library automatically handles sending the
   * CSRF token as a header based on the value of the "XSRF" token cookie.
   */
  const window = getWindow();
  // eslint-disable-next-line global-require
  window.axios = require('axios');

  window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

  /**
   * Next we will register the CSRF Token as a common header with Axios so that
   * all outgoing HTTP requests automatically have it attached. This is just
   * a simple convenience so we don't have to attach every token manually.
   */
  const document = getDocument();
  const token = document.head.querySelector('meta[name="csrf-token"]');

  if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
  } else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
  }
}
