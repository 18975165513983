import React from 'react';
import styled from 'styled-components/macro';
import {
  marginTheming,
  paddingTheming,
  fontTheming,
  printTheming,
  elementTheming,
  removePaddingThemingProps,
  removeMarginThemingProps,
  removeFontThemingProps,
  removeElementThemingProps,
  removeProps,
  combine,
  createComponentWithModifiedProps,
} from '../../style/themes/main';


const removeCustomProps = removeProps(
  'grey', 'blue', 'white', 'red', 'bgGreyDark', 'bgWhite', 'color',
);
const removeAllProps = combine(
  removePaddingThemingProps,
  removeMarginThemingProps,
  removeFontThemingProps,
  removeElementThemingProps,
  removeCustomProps,
);

const PargraphNoProps = createComponentWithModifiedProps(removeAllProps, 'p', 'Skafte.com.typography.Paragraph');

const StaticallyStyledParagraph = styled(PargraphNoProps)`
  flex: 100%;
  display: block;
  font-size: 16px;
  text-align: left;
  line-height: 1.5em;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.colorGreyDark};
`;

export default styled(props => <StaticallyStyledParagraph {...props} />)`
  ${({
    white,
    grey,
    blue,
    red,
    green,
    color,
    yellow,
    theme,
  }) => {
    if (white) {
      return `color: ${theme.colors.colorWhite};`;
    }
    if (grey) {
      return `color: ${theme.colors.colorGrey};`;
    }
    if (blue) {
      return `color: ${theme.colors.colorBlue};`;
    }
    if (yellow) {
      return `color: ${theme.colors.colorYellow};`;
    }
    if (red) {
      return `color: ${theme.colors.colorRed};`;
    }
    if (green) {
      return `color: ${theme.colors.colorGreen};`;
    }
    if (color) {
      return `color: ${color};`;
    }
    return '';
  }}
  ${({ bgGreyDark, bgWhite, theme }) => {
    if (bgWhite) {
      return `background-color: ${theme.colors.colorWhite};`;
    }
    if (bgGreyDark) {
      return `background-color: ${theme.colors.colorGreyDark};`;
    }
    return '';
  }}
  ${paddingTheming}
  ${marginTheming}
  ${fontTheming}
  ${elementTheming}
  ${printTheming}
`;
