import React from 'react';
import { connect } from 'react-redux';
import styled, { keyframes } from 'styled-components/macro';
import { withRouter } from 'react-router-dom';
import { generalChangeSearch, generalClearSearch } from '../../features/commerce/search/actions';
import { Item, VareLink } from '../../containers/commerce';
import getItemDefaultInformation from '../../content/commerce/items';
import { getGeneralSearchState } from '../../features/commerce/search/selectors';
import { resultTypes } from '../../features/commerce/search/shared';
import { LinkButton, Input, Paragraph } from '../typography';
import { Container, Row } from '../grid';
import { ScreenSizeDetector, specialEncodeUriForReactRouter } from '../../lib/helpers';
import GeneralResultView from './GeneralResultView';
import { FaCog, FaSearch } from '../Icons';

const maxResults = 15;
const leastResults = 3;
const fittingMaxResults = 1024;
const resultViewHeight = 55;

const SearchFormNoStyle = ({
  children,
  ...otherProps
}) => (
  <form {...otherProps}>
    {children}
  </form>
);
const SearchForm = styled(SearchFormNoStyle)`
  position: relative;
  width: 100%;
  top: 0px;
  margin: 10px;
`;

const SearchInputNoStyle = ({
  ...otherProps
}) => (
  <Input type="text" placeholder="Hvad leder du efter?" {...otherProps} />
);

const SearchInput = styled(SearchInputNoStyle)`
  box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.colors.colorGreyLightAlt};
  border-radius: 15px;
  padding: 16px 15px;
  width: calc(100% - 30px);
  font-size: 14px;
  transition: box-shadow 180ms ease-in;
  
  &::placeholder {
    font-style: italic;
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.colorBlue};
    box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.colors.colorBlue}, 0px 0px 10px rgba(0,0,0,0.2);
  }
`;

const SearchSubmitNoStyle = ({
  ...otherProps
}) => (
  <button type="submit" name="Søg" aria-label="Søg" {...otherProps}>
    <FaSearch />
  </button>
);
const SearchSubmit = styled(SearchSubmitNoStyle)`
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  border: none;
  background: none;
  padding: 13.5px 15px;

  & > svg {
    fill: ${({ theme }) => theme.colors.colorBlue};
    font-size: 20px;

    &:hover {
      fill: ${({ theme }) => theme.hoverColors.colorBlue};
      cursor: pointer;
    }
  }
`;

const ResultsContainer = styled.div`
  width: 100%;
`;

const ResultsInnerContainer = styled.div`
  position: absolute;
  width: calc(100% - 30px);
  background: ${({ theme }) => theme.hovercolorWhite};
  padding: 15px;
  ${() => {
    let ret = '';
    for (let i = maxResults; i > leastResults; i -= 1) {
      ret = `${ret}
        @media only screen and (max-height: ${fittingMaxResults - 1 - resultViewHeight * (maxResults - i)}px) {
          & > :nth-child(${i}) {
            display: none;
          }
        }
      `;
    }
    return ret;
  }}
  & > :nth-child(n+14)

  & > svg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    fill: ${({ theme }) => theme.colors.colorGrey};
    font-size: 30px;
    animation: ${keyframes`
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    `} 2s linear infinite;
  }
`;

const VareLinkLinkComponent = ({
  history,
  to,
  onClick,
  ...props
}) => (
  <a href="#" onClick={() => { onClick(); history.push(to); }} {...props} /> /* eslint-disable-line */ 
);

const ConnectedItemResultsView = ({
  name,
  image,
  imageSingle,
  imagePlural,
  price,
  packageNoun,
  countPrColli,
  liter,
  url,
  varenr,
  onNavigate,
  history,
  returPant,
  totalCasePrice,
  productTypeReservedel,
}) => {
  if (url) {
    return (
      <GeneralResultView name={name} varenr={varenr} totalCasePrice={totalCasePrice} returPant={returPant} liter={liter} countPrColli={countPrColli} price={price} title={name} image={image} imageSingle={imageSingle} imagePlural={imagePlural} packageNoun={packageNoun} productTypeReservedel={productTypeReservedel} />
    );
  }
  return (
    <VareLink varenr={varenr} LinkComponent={VareLinkLinkComponent} onClick={onNavigate} history={history}>
      <GeneralResultView name={name} varenr={varenr} totalCasePrice={totalCasePrice} returPant={returPant} liter={liter} countPrColli={countPrColli} price={price} title={name} image={image} imageSingle={imageSingle} imagePlural={imagePlural} packageNoun={packageNoun} productTypeReservedel={productTypeReservedel} />
    </VareLink>
  );
};
const getResultEntries = (results, history, onHideMenu) => {
  if (results.length === 0) {
    return (
      <React.Fragment>
        <Paragraph bold>
          Ingen resultater på søgningen
        </Paragraph>
        <LinkButton action onClick={() => { onHideMenu(); history.push('/bestil/flasker-og-daaser/kildevand'); }}>
          Se vores store sortiment på vores bestillingsside
        </LinkButton>
      </React.Fragment>
    );
  }
  const resultArray = [];
  for (let i = 0; i < results.length && i < maxResults; i += 1) {
    const [type, searchResult] = results[i];
    if (type === resultTypes.ROUTE) {
      const [r, {
        image, imageSingle, imagePlural, /* description, */ title,
      }] = searchResult;
      resultArray.push(
        <a
          href="#"
          onClick={(evt) => {
            evt.preventDefault();
            onHideMenu();
            history.push(r);
          }}
        >
          <GeneralResultView
            key={r}
            title={title}
            name={title}
            /* description={description} */
            image={image}
            imageSingle={imageSingle}
            imagePlural={imagePlural}
          />
        </a>,
      );
    } else if (type === resultTypes.ITEM) {
      const varenr = searchResult;
      const { url } = getItemDefaultInformation(varenr);
      if (url) {
        resultArray.push(
          (
            <a
              href="#"
              onClick={(evt) => {
                evt.preventDefault();
                onHideMenu();
                history.push(url);
              }}
              key={varenr}
            >
              <Item varenr={varenr} display={ConnectedItemResultsView} allowNotInState />
            </a>
          ),
        );
      } else {
        resultArray.push(
          <Item varenr={varenr} display={ConnectedItemResultsView} key={varenr} allowNotInState onNavigate={onHideMenu} />,
        );
      }
    }
    resultArray.push(null);
  }
  return (
    <>
      {resultArray}
    </>
  );
};

const GeneralSearchInput = ({
  term,
  searching,
  results,
  onSearch,
  hideMenu,
  history,
  hideMenuAndClose,
}) => (
  <React.Fragment>
    <Container>
      <Row>
        <SearchForm onSubmit={(e) => { hideMenu(); e.preventDefault(); history.push(`/search/${specialEncodeUriForReactRouter(term)}`); }}>
          <SearchInput
            value={term}
            onChange={({ target: { value } }) => onSearch(value)}
          />
          <SearchSubmit />
        </SearchForm>
      </Row>
    </Container>
    <Container white>
      <Row>
        <ResultsContainer visible={term.trim()}>
          <ResultsInnerContainer>
            {searching && <FaCog />}
            {term.trim() && !searching && getResultEntries(results, history, hideMenuAndClose)}
            {!searching && (results.length > 0) && (
              <ScreenSizeDetector bold={[[375, false], true]} fontSize={[[375, '12px'], [575, '14px'], '16px']}>
                <Paragraph blue padding="10px 0 0 0" marginBottom="0px">
                  Klik på &quot;Søg&quot; eller tryk &quot;Enter&quot; for at se alle resultater
                </Paragraph>
              </ScreenSizeDetector>
            )}
          </ResultsInnerContainer>
        </ResultsContainer>
      </Row>
    </Container>
  </React.Fragment>
);


export default withRouter(
  connect(
    (state) => {
      const {
        term,
        searching,
        results,
      } = getGeneralSearchState(state);
      return {
        term,
        searching,
        results,
      };
    },
    {
      onSearch: generalChangeSearch,
      onClear: generalClearSearch,
    },
  )(GeneralSearchInput),
);
