export const INIT = 'SKAFTE/INIT';
export const doInit = () => ({
  type: INIT,
});

export const STARTUP = 'SKAFTE/STARTUP';
export const doStartup = () => ({
  type: STARTUP,
});

export const STARTUP_COMPLETE = 'SKAFTE/STARTUP_COMPLETE';

export const completeStartup = () => ({
  type: STARTUP_COMPLETE,
});

export const SWITCH_MODE = 'SKAFTE/SWITCH_ORDER_MODE';

export const switchMode = special => ({
  type: SWITCH_MODE,
  payload: special,
});


export const ANALYTICS_LOG_VIEW_PRODUCT = 'SKAFTE/ANALYTICS_LOG_VIEW_PRODUCT';
export const doAnalyticsLogViewProduct = varenr => ({
  type: ANALYTICS_LOG_VIEW_PRODUCT,
  payload: varenr,
});
