import React from 'react';
import { Paragraph } from '../../../typography';
import { Image } from '../../../Image';
import { Row, Col, Div } from '../../../grid';

export default ({
  name,
  imageSingle,
  imagePlural,
  image,
  expiredMessage,
  expiredSize,
}) => (
  <Row marginRight="0px" marginLeft="0px">
    <Col xs={12} h100p padding="0px">
      <Row marginRight="0px" marginLeft="0px">
        <Col xs={12} textCenter padding="0">
          <Row marginRight="0px" marginLeft="0px">
            {imageSingle && (
              <Col xs={imagePlural ? 6 : 12} padding="0px" textCenter verticalMiddle>
                <Image name={imageSingle} imageHeight="100px" maxHeight="100px" />
              </Col>
            )}
            {imagePlural && (
              <Col xs={imageSingle ? 6 : 12} padding="0px" textCenter verticalMiddle>
                <Image name={imagePlural} imageHeight="100px" maxHeight="100px" />
              </Col>
            )}
            {image && (
              <Col xs={12} padding="0px" textCenter verticalMiddle>
                <Image name={image} imageHeight="100px" maxHeight="100px" />
              </Col>
            )}
          </Row>
        </Col>
        <Col xs={12} padding="5px 0 0 0">
          <Div marginBottom="10px">
            <Paragraph fontWeight="bold" fontSize="16px" margin="0 0 10px 0" className="bucketAnimation">
              {name}{expiredSize && (
                <React.Fragment>
                  &nbsp;{expiredSize}
                </React.Fragment>
              )}
            </Paragraph>
          </Div>
          {expiredMessage && (
            <Paragraph red>
              {expiredMessage()}
            </Paragraph>
          )}
        </Col>
      </Row>
    </Col>
  </Row>
);
