import React from 'react';
import styled from 'styled-components/macro';

const SpacerNoStyle = ({
  size,
  hide,
  ...otherProps
}) => (
  <hr {...otherProps} />
);

export default styled(SpacerNoStyle)`
  display: block;
  height: 0px;
  background: none;

  ${({ size }) => {
    if (size) {
      return `margin-top: ${size}px;`;
    }
    return 'margin-top: 0;';
  }}

  ${({ hide }) => {
    if (hide) {
      return 'display: none;';
    }
    return '';
  }}
`;
