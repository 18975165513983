// Import
import React from 'react';
import styled from 'styled-components/macro';
import { MomsSelector } from '../../../../containers/commerce';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import Div from '../../../../components/Div';
import { Col, Container, Row } from '../../../../components/TheGrid';

const MomsSelectorContainer = styled(Div)`
  text-align: left;
  margin: 0;
  background-color: ${({ theme }) => theme.colors.colorOffWhite};
`;

const StyledCol = styled(Col)`
  padding: 9.5px 0;
`;

// Const MomsContainer contains the following code:
const MomsContainer = ({
  disableBreadCrumbsSchema,
}) => (
  <MomsSelectorContainer>
    <Container>
      <Row padding="0">
        <StyledCol xs={8}>
          <Breadcrumbs disableBreadCrumbsSchema={disableBreadCrumbsSchema} />
        </StyledCol>
        <StyledCol xs={4}>
          <MomsSelector />
        </StyledCol>
      </Row>
    </Container>
  </MomsSelectorContainer>
);

// Export
export default MomsContainer;
