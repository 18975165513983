import {
  put, all, takeEvery, select, call,
} from 'redux-saga/effects';
import { formValueSelector, change } from '../form';

const LOAD_CUSTOMER_INFO = 'LOAD_CUSTOMER_INFO';

export const loadCustomerInfoRequest = () => ({
  type: `${LOAD_CUSTOMER_INFO}_REQUEST`,
});
export const loadCustomerInfoSuccess = () => ({
  type: `${LOAD_CUSTOMER_INFO}_SUCCESS`,
});
export const loadCustomerInfoFail = message => ({
  type: `${LOAD_CUSTOMER_INFO}_FAIL`,
  payload: message,
});

const initialState = {
  isLoading: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case `${LOAD_CUSTOMER_INFO}_REQUEST`:
      return { ...state, isLoading: true };
    case `${LOAD_CUSTOMER_INFO}_SUCCESS`:
    case `${LOAD_CUSTOMER_INFO}_FAIL`:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export const isLoadingCustomerInfo = state => state.customerInfo.isLoading;

function apiCall(phonenumber) {
  const {
    host,
    protocol,
  } = window.location; // eslint-disable-line
  return window.axios.get(`${protocol}//${host}/api/customerInfo/${phonenumber}`); // eslint-disable-line
}

const selector = formValueSelector('invoice-details');
function* loadCustomerInfoSaga() {
  try {
    const phonenumber = yield select(state => selector(state, 'phonenumber'));
    const response = yield call(apiCall, phonenumber);
    const {
      name,
      address,
      postnr,
      cvr,
      email,
    } = response.data;
    if (name) {
      yield put(change('invoice-details', 'name', name));
    }
    if (address) {
      yield put(change('invoice-details', 'address', address));
    }
    if (postnr) {
      yield put(change('invoice-details', 'zipCode', postnr));
    }
    if (cvr) {
      yield put(change('ean-form', 'cvr', cvr));
    }
    if (email) {
      yield put(change('invoice-details', 'email', email));
    }
    yield put(loadCustomerInfoSuccess());
  } catch (e) {
    yield put(loadCustomerInfoFail());
  }
}

export function* rootCustomInfoSaga() {
  yield all([
    takeEvery(`${LOAD_CUSTOMER_INFO}_REQUEST`, loadCustomerInfoSaga),
  ]);
}
