import styled from 'styled-components/macro';
import {
  marginTheming,
  paddingTheming,
  fontTheming,
  elementTheming,
  removePaddingThemingProps,
  removeMarginThemingProps,
  removeFontThemingProps,
  removeElementThemingProps,
  removeProps,
  combine,
  createComponentWithModifiedProps,
} from '../../style/themes/main';


const removeCustomProps = removeProps(
  '',
);
const removeAllProps = combine(
  removePaddingThemingProps,
  removeMarginThemingProps,
  removeFontThemingProps,
  removeElementThemingProps,
  removeCustomProps,
);

const InputNoProps = createComponentWithModifiedProps(removeAllProps, 'input', 'Skafte.com.typography.Input');

export default styled(InputNoProps)`
  padding: 5px;
  outline: none;
  border-radius: 5px;
  width: calc(100% - 10px);
  border: 1px solid ${({ theme }) => theme.colors.colorGreyLightAlt};
  ${paddingTheming}
  ${marginTheming}
  ${fontTheming}
  ${elementTheming}
`;
