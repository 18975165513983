import getArg from './args';

const sentryRefs = {
  begunLoading: false,
  loadingFinished: false,
  deferred: [],
};

export const includeSentry = () => getArg('isProduction') && !getArg('isPageSpeed');
const execute = (name, args) => {
  switch (name) {
    case 'init':
      sentryRefs.refs.init(...args);
      break;
    case 'captureException':
      sentryRefs.refs.captureException(...args);
      break;
    case 'configureScope':
      sentryRefs.refs.configureScope(...args);
      break;
    case 'captureMessage':
      sentryRefs.refs.captureMessage(...args);
      break;
    default:
  }
};
const doDeferred = () => {
  for (let i = 0; i < sentryRefs.deferred.length; i += 1) {
    const args = sentryRefs.deferred[i];
    execute(...args);
  }
  sentryRefs.deferred = [];
};
export const doImport = () => {
  if (includeSentry() && sentryRefs.begunLoading === false) {
    sentryRefs.begunLoading = true;
    import('@sentry/browser').then(
      ({
        init,
        captureException,
        configureScope,
        captureMessage,
      }) => {
        sentryRefs.refs = {
          init,
          captureException,
          configureScope,
          captureMessage,
        };
        sentryRefs.loadingFinished = true;
        doDeferred();
      },
    );
  }
};

const createFunction = name => (...args) => {
  if (includeSentry()) {
    const allArgs = [name, args];
    if (sentryRefs.loadingFinished) {
      execute(allArgs);
    } else {
      sentryRefs.deferred = [...sentryRefs.deferred, allArgs];
    }
  }
};

export const initSentry = createFunction('init');

export const captureMessage = createFunction('captureMessage');

export const captureException = createFunction('captureException');

export const configureScope = createFunction('configureScope');
