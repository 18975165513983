import { detect as browserDetection } from 'detect-browser';
import moment from 'moment';
import libClassNames from 'classnames';
import widthSwitch from './components/WidthSwitch';
import browserSwitch from './components/BrowserSwitch';
import ScreenSizeDetector from './components/ScreenSizeDetector';
import screenSizeSwitch from './components/ScreenSizeSwitch';
import updateWhenNotDeepEqual from './components/UpdateWhenNotDeepEqual';
import monitorHeight from './components/monitorHeight';
import getArg from './args';

export {
  monitorHeight,
  widthSwitch,
  browserSwitch,
  ScreenSizeDetector,
  screenSizeSwitch,
  updateWhenNotDeepEqual,
};

let browser = null;
export function detectBrowser() {
  if (!browser) {
    browser = browserDetection();
  }
  return browser;
}

export function classNames(...args) {
  let className = libClassNames(...args);
  className = className.trim();
  if (className !== '') {
    return className;
  }
  return undefined;
}

export const sanitizeMeasurement = (m) => {
  if (!m) {
    return 0;
  }
  if (m.indexOf && m.indexOf(',') !== -1) {
    return parseFloat(m.replace(',', '.'));
  }
  if (m.indexOf) {
    return parseFloat(m);
  }
  return m;
};

export const generateLink = (relative) => {
  if (getArg('serverDirect')) {
    return getArg('host') + relative;
  }
  const {
    location: {
      host,
      protocol,
    },
  } = window;
  return `${protocol}//${host}${relative}`;
};
export const generateAbsoluteLink = () => {
  if (getArg('serverDirect')) {
    return getArg('host') + getArg('location');
  }
  const {
    location: {
      host,
      protocol,
      pathname,
    },
  } = window;
  return `${protocol}//${host}${pathname}`;
};

export function sanitizeFloat(f) {
  if (f > 0.9999 && f < 1.0001) {
    return 1;
  }
  return f;
}

export const formatDateWhenAfter = (date) => {
  if (!date) {
    return null;
  }
  const dateParsed = moment(date, 'DD-MM-YYYY HH:mm');
  return dateParsed.diff(moment(), 'minutes') > 0 ? dateParsed.format('D. MMMM YYYY') : null;
};

export const getRentalText = days => (days > 5 ? `Leje ${days} dage` : 'Leje op til 5 dage');

export const specialEncodeUriForReactRouter = part => encodeURIComponent(part.replace(/%/g, '%25'));

export const reversePercentageReplacements = t => (t || '').replace(/%25/g, '%');
