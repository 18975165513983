const actionPrefix = 'SKAFTE/COMMERCE/SEARCH/';

export const GENERAL_SEARCH_CHANGE = `${actionPrefix}GENERAL_SEARCH_CHANGE`;

export const generalChangeSearch = value => ({
  type: GENERAL_SEARCH_CHANGE,
  payload: value,
});

export const GENERAL_SEARCH_RESULTS_DONE = `${actionPrefix}GENERAL_SEARCH_RESULTS`;

export const generalSearchResultsDone = results => ({
  type: GENERAL_SEARCH_RESULTS_DONE,
  payload: results,
});


export const GENERAL_SEARCH_CLEAR = `${actionPrefix}GENERAL_SEARCH_CLEAR`;
export const generalClearSearch = () => ({
  type: GENERAL_SEARCH_CLEAR,
});

export const SEARCH_CHANGE = `${actionPrefix}SEARCH_CHANGE`;

export const SEARCH_RESULTS_CHANGE = `${actionPrefix}SEARCH_RESULTS_CHANGE`;

export const SEARCH_CLEAR = `${actionPrefix}SEARCH_CLEAR`;

export const SEARCH_RESULTS_DONE = `${actionPrefix}SEARCH_RESULTS_DONE`;

export const SET_INDEX = `${actionPrefix}SET_INDEX`;

export const SET_SHOW_SEARCH_OVERLAY = `${actionPrefix}SET_SHOW_SEARCH_OVERLAY`;

export const setIndex = index => ({
  type: SET_INDEX,
  payload: index,
});

export const changeSearch = value => ({
  type: SEARCH_CHANGE,
  payload: value,
});

export const changeSearchResults = items => ({
  type: SEARCH_RESULTS_CHANGE,
  payload: items,
});

export const doneWithSearchResults = results => ({
  type: SEARCH_RESULTS_DONE,
  payload: results,
});

export const clearSearch = () => ({
  type: SEARCH_CLEAR,
});

export const setShowSearchOverlay = show => ({
  type: SET_SHOW_SEARCH_OVERLAY,
  payload: show,
});
