
import {
  WATER_DEFINITIONS_SET,
  WATER_DELIVERY_SET,
  IMAGE_UPLOAD,
  SIZE_SET,
  LID_SET,
  FLAVOUR_SET,
  COUNT_SET,
} from './actions';
import getArg from '../../../lib/args';

export * from './actions';
export * from './selectors';

const initialState = {
  count: 1,
  size: null,
  lid: null,
  flavour: null,
  id: null,
  waterDefinitions: {
    lids: [],
    flavours: [],
    sizes: [],
  },
  waterDelivery: null,
};
const calculateBasicState = (currentState, waterDefinitions, waterDelivery) => {
  const {
    size,
    lid,
    flavour,
  } = currentState;
  const {
    lids,
    flavours,
    sizes,
  } = waterDefinitions;
  const hasSize = sizes.filter(({ name }) => name === size).length > 0;
  const hasLid = lids.filter(({ name }) => name === lid).length > 0;
  const hasFlavour = flavours.filter(({ name }) => name === flavour).length > 0;
  const firstSize = sizes[0].name;
  const firstLid = lids[0].name;
  const firstFlavour = flavours[0].name;
  return {
    ...currentState,
    waterDefinitions,
    waterDelivery,
    size: hasSize ? size : firstSize,
    lid: hasLid ? lid : firstLid,
    flavour: hasFlavour ? flavour : firstFlavour,
  };
};

export function getInitialState() {
  const waterDefinitions = getArg('waterDefinition');
  const waterDelivery = getArg('waterDelivery');
  return calculateBasicState(initialState, waterDefinitions, waterDelivery);
}

export default function waterReducer(state = {}, action) {
  switch (action.type) {
    case WATER_DEFINITIONS_SET:
      return calculateBasicState(state, action.payload);
    case WATER_DELIVERY_SET:
      return { ...state, waterDelivery: action.payload };
    case IMAGE_UPLOAD:
      return { ...state, id: action.payload };
    case SIZE_SET:
      return { ...state, size: action.payload };
    case FLAVOUR_SET:
      return { ...state, flavour: action.payload };
    case LID_SET:
      return { ...state, lid: action.payload };
    case COUNT_SET:
      return { ...state, count: action.payload };
    default:
      return state;
  }
}
