import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components/macro';

const customStyles = {
  content: {
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
};
const ModalNoProps = ({ children, maxWidth, ...props }) => (
  <Modal style={customStyles} maxWidth={maxWidth} {...props}>
    {children}
  </Modal>
);

export default styled(ModalNoProps)`
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: ${({ theme }) => theme.colors.colorWhite};
  position: fixed;
  outline: 0;

  padding: 40px;
  border: 2px solid ${({ theme }) => theme.colors.colorGreyLightAlt};
  max-height: 100%;
  max-width: 700px;

  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}

  @media (max-width: 991px) {
    width: calc(100% - 100px);
    max-width: 600px;
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
    top: 120px !important;
    transform: translate(-50%,0%) !important;
    max-height: calc(100% - 240px);
  }

  @media (max-width: 1px) {
  }
`;
