import { connect } from 'react-redux';
import { getFustageLiter, getTotalCostWithoutPant } from '../../features/commerce/selectors';
import KurvFadoelsBeregner from '../../components/KurvFadoelsBeregner';

export default connect(
  state => ({
    cost: getTotalCostWithoutPant(state),
    liter: getFustageLiter(state),
  }),
)(KurvFadoelsBeregner);
