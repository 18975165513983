import React from 'react';
import { connect } from 'react-redux';
import {
  getTotalCost,
  getTotalPant,
  getTotalCostWithoutPant,
  getMoms,
} from '../../features/commerce';
import {
  getBucketItemsWithoutReturPant,
  getTotalWeight,
  getDeliveryCost,
  getDeliveryCostWithoutFees,
  getDeliveryFeesCostWithoutExternalDelivery,
  getExternalDeliveryFeeCost,
  getTotalReturPant,
  getEffectiveZip,
} from '../../features/commerce/selectors';
import { isDelivery, getDeliveryType } from '../../features/commerce/delivery';

export default connect(
  state => ({
    items: getBucketItemsWithoutReturPant(state),
    totalCostWithoutPant: getTotalCostWithoutPant(state),
    totalCost: getTotalCost(state),
    totalPant: getTotalPant(state),
    totalReturPant: getTotalReturPant(state),
    isDelivery: isDelivery(state),
    deliveryCost: getDeliveryCost(state),
    deliveryCostWithoutFees: getDeliveryCostWithoutFees(state),
    deliveryFeesCost: getDeliveryFeesCostWithoutExternalDelivery(state),
    deliveryType: getDeliveryType(state),
    externalDeliveryFeeCost: getExternalDeliveryFeeCost(state),
    effectiveZip: getEffectiveZip(state),
    hasMoms: getMoms(state),
    totalWeight: getTotalWeight(state),
  }),
)(
  ({ display: Component, ...otherProps }) => (
    <Component {...otherProps} />
  ),
);
