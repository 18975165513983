import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import formReducer from './form';
import commerceReducer, { commerceSaga, rootSearchSaga, rootDeliverySaga } from './commerce';
import tabsReducer from './tabs';
import customerInfoReducer, { rootCustomInfoSaga } from './customerInfo';
import plejerlisteReducer, { plejerlisteSaga } from './plejerliste';
import bestillinkReducer, { rootBestilLinkSaga } from './bestillink';
import burgerMenusReducer from './burgermenus';
import postnr, { postnrSaga } from './postnr';
import formSaga from './form/sagas';
import formError from './form-error';
import formErrorSaga from './form-error/sagas';
import order from './order';
import currency from './currency';
import user, { userSagas } from './user';
import { STARTUP_COMPLETE } from './actions';
import analyticsSagas from './analyticsSagas';

// WHITELIST
const formPersistConfig = {
  key: 'form2',
  storage,
};
const bestillinkPersistConfirg = {
  key: 'bestillinkV2',
  storage,
};
const plejerlistePersistConfig = {
  key: 'plejerliste',
  storage,
  whitelist: ['overridePlejerliste', 'plejerListe'],
};
const userPersistConfig = {
  key: 'user',
  storage,
  blacklist: ['sessionId'],
};

export default combineReducers({
  commerce: commerceReducer,
  tabs: tabsReducer,
  form: persistReducer(formPersistConfig, formReducer),
  application: (state = { started: false }, { type }) => {
    switch (type) {
      case STARTUP_COMPLETE:
        return { ...state, started: true };
      default:
        return state;
    }
  },
  customerInfo: customerInfoReducer,
  plejerliste: persistReducer(plejerlistePersistConfig, plejerlisteReducer),
  bestillink: persistReducer(bestillinkPersistConfirg, bestillinkReducer),
  burgermenus: burgerMenusReducer,
  formError,
  order,
  currency,
  postnr,
  user: persistReducer(userPersistConfig, user),
});

export function* rootSaga() {
  yield all([
    commerceSaga(),
    rootSearchSaga,
    rootCustomInfoSaga(),
    plejerlisteSaga(),
    postnrSaga(),
    rootBestilLinkSaga(),
    formErrorSaga(),
    analyticsSagas,
    rootDeliverySaga(),
    userSagas(),
    formSaga(),
  ]);
}
