import React from "react";
import TooltipModalLink from "../TooltipModal/TooltipModalLink";
import { Col, Row } from "../grid";
import { Heading4, Paragraph } from "../typography";
import { ScreenSizeDetector } from "../../lib/helpers";

const MonteringPaaEgetBordModal = () => {
  return (
    <TooltipModalLink title="Montering på eget bord">
      <Row>
        <Col xs={12} paddingBottom="0px">
          <Heading4>Montering på eget bord</Heading4>
        </Col>
        <ScreenSizeDetector
          paddingBottom={[[767, "0px"], "15px"]}
          paddingTop={[[767, "0px"], "15px"]}
        >
          <Col sm={12} md={6}>
            <Paragraph>
              Tappetårnet monteres på dit eget bord lige som en arkitektlampe,
              det spændes fast uden brug af værktøj og skader ikke bordet.
            </Paragraph>
            <Paragraph>
              Tappetårnet kan højest gabe over en bordplade på 55mm.
            </Paragraph>
          </Col>
        </ScreenSizeDetector>
        <ScreenSizeDetector paddingTop={[[767, "0px"], "15px"]}>
          <Col sm={12} md={6}>
            <Paragraph>
              Du kan også vælge at leje en ekstra bardisk som du finder længere
              nede på siden.
            </Paragraph>
            <Paragraph>
              Bestiller du levering og afhentning monterer vi meget gerne
              udstyret for dig, så det er klar til at tappe fra.
            </Paragraph>
          </Col>
        </ScreenSizeDetector>
      </Row>
    </TooltipModalLink>
  );
};

export default MonteringPaaEgetBordModal;
