import React from 'react';
import { Money, VareLink } from '../../../../containers/commerce';
import { Paragraph, Span } from '../../../typography';
import { Row, Col } from '../../../grid';
import RecieptEntryWrap from '../RecieptEntryWrap';
import { IconMinusCircle, IconPlusCircle } from './entryComponents';

export default (props) => {
  const {
    varenr,
    name,
    amount,
    cost,
    actions,
    packageNoun,
    packageNounExt,
    returPant,
  } = props;
  const {
    incrementItemAmount,
    decrementItemAmount,
  } = actions;
  return (
    <RecieptEntryWrap>
      <Row>
        <Col xs={12}>
          <Paragraph lineHeight="1.2em" margin="0">
            {returPant !== true && (
              <React.Fragment>
                <VareLink varenr={varenr}>{name}</VareLink>
              </React.Fragment>
            )}
            {returPant === true && (
              <React.Fragment>
                {name}
              </React.Fragment>
            )}
          </Paragraph>
          <Span block bold margin="5px 0 0 0">
            {amount} {packageNoun.toLowerCase()}{amount !== 1 && (packageNounExt || 'r')} retur
          </Span>
          <IconMinusCircle onClick={decrementItemAmount} />
          <IconPlusCircle onClick={incrementItemAmount} />
          <Paragraph floatRight margin="5px 0 0 0" bold>
            <Money money={cost} />
          </Paragraph>
        </Col>
      </Row>
    </RecieptEntryWrap>
  );
};
