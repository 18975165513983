const namespace = 'SKAFTE/COMMERCE/MOMS';
export const MOMS_SET = `${namespace}/MOMS_SET`;
export const PREFFERED_MOMS_SET = `${namespace}PREFFERED_MOMS_SET`;

export const setMoms = on => ({
  type: MOMS_SET,
  payload: on,
});
export const setPrefferedMoms = on => ({
  type: PREFFERED_MOMS_SET,
  payload: on,
});

export const getLocalState = state => state.commerce.moms;

export const getMoms = (state) => {
  const {
    prefferedMoms,
    moms,
  } = getLocalState(state);
  if (moms === null) {
    return prefferedMoms;
  }
  return moms;
};

export const getPrefferedMoms = state => getLocalState(state).prefferedMoms;
const initialState = {
  prefferedMoms: false,
  moms: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case MOMS_SET:
      return {
        ...state,
        moms: action.payload,
      };
    case PREFFERED_MOMS_SET:
      return {
        ...state,
        prefferedMoms: action.payload,
      };
    default:
      return state;
  }
};
