import React from 'react';
import styled from 'styled-components/macro';
import { Container, Row, Col } from '../../../components/grid';
import { MomsSelector } from '../../../containers/commerce';
import {
  Logo,
  HeaderBucket,
  Breadcrumbs,
  Burger,
  SearchHeader,
  HeaderWrap,
  // ClosedAnnouncement,
} from './components';
// import HideAtDate from '../../../components/HideAtDate';

const LogoHeader = styled.div`
  position: relative;
  box-sizing: border-box;
  display: block;
  background-color: ${({ theme }) => theme.colors.colorWhite};
  padding: 5px 15px;
`;


const BreadcrumbsHeader = styled.div`
  position: relative;
`;

const LogoHeaderCol = styled(Col)`
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  @media (max-width: 575px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

export default ({
  disableBreadCrumbsSchema,
}) => (
  <React.Fragment>
    <LogoHeader>
      <Container>
        <Row>
          <LogoHeaderCol xs={4}>
            <Logo responsive to="/" title="Skafte Øl &amp; Vand" text1="Skafte.dk" />
          </LogoHeaderCol>
          <LogoHeaderCol xs={8}>
            <MomsSelector />
          </LogoHeaderCol>
        </Row>
      </Container>
    </LogoHeader>
    {/*
    <HideAtDate date="23/12/2022 20:00">
      <ClosedAnnouncement />
    </HideAtDate>
    */}
    <BreadcrumbsHeader>
      <Container>
        <Row>
          <Col xs={12} paddingTop="7px" paddingBottom="7px">
            <Breadcrumbs disableBreadCrumbsSchema={disableBreadCrumbsSchema} />
          </Col>
        </Row>
      </Container>
    </BreadcrumbsHeader>
    <HeaderWrap responsive>
      <Container>
        <Row textCenter>
          <Burger />
          <SearchHeader />
          <HeaderBucket />
        </Row>
      </Container>
    </HeaderWrap>
  </React.Fragment>
);
