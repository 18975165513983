export default ({ value, noPrefix }) => {
  let display = value.substring(0, 1);
  if (value === 'Modular 20') {
    display = 'MD20';
  }
  if (value === 'S3 Anstikker') {
    display = 'S3';
  }

  if (noPrefix) {
    return `${display}`;
  }
  return `Type ${display}`;
};
