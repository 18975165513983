import React from "react";
import styled from "styled-components/macro";
import ReactPlayer from 'react-player';
import { LinkStyled } from "./typography";

const PlayerWrap = styled.div`
  position: relative;
  padding-top: 56.25%;
  border-radius: 15px;
  overflow: hidden;
`;

const Player = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const VideoLinkWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -15px;
  gap: 15px;
  padding: 30px 15px 15px 15px;
  background-color: ${({ theme }) => theme.colors.colorWhite};
  border-radius-bottom-left: 15px;
  border-radius-bottom-right: 15px;

  @media (max-width: 575px) {
    display: block;
  }
`;

const VideoLink = styled(LinkStyled)`
  @media (max-width: 575px) {
    margin-bottom: 10px;

    &:after {
      content: '';
      display: block;
      height: 15px;
    }
  }
`;

class ThePlayer extends React.Component {
  state = {
    seekToTime: null,
  };

  handleSeekToPress = (time) => {
    const { seekToTime } = this.state;
    const { chapters = [] } = this.props;

    if (chapters.length > 0) {
      const { time: chapterTime } = chapters.find(chapter => chapter.time === time);

      if (chapterTime) {
        const [minutes, seconds] = chapterTime.split(':');
        const timeString = `?t=${minutes}m${seconds}s`;
        if (seekToTime !== timeString) {
          this.setState({ seekToTime: timeString });
          this.p.seekTo(parseInt(time));
        } else {
          this.setState({ seekToTime: null });
          this.p.seekTo(0);
        }
      }
    } else {
      if (seekToTime) {
        this.setState({ seekToTime: null });
        this.p.seekTo(parseInt(time));
      }
    }
  };


  render () {
    const { url, thumbnail, chapters = [], ...restProps } = this.props;
    const { seekToTime } = this.state;
    const urlWithTime = `https://www.youtube-nocookie.com/embed/${url}${seekToTime || ''}`;

    return (
      <div className='player-wrapper'>
        <PlayerWrap>
          <Player
            ref={p => { this.p = p }}
            url={urlWithTime}
            className='react-player'
            playing
            controls
            width='100%'
            height='100%'
            light={!seekToTime && <Thumbnail src={`/video-content/thumbnail/${thumbnail}.jpg`} alt='Thumbnail' />}
            {...restProps}
          />
        </PlayerWrap>
        {chapters && chapters.length > 0 && (
          <VideoLinkWrap>
            {chapters.map(chapter => (
              <VideoLink
                action
                key={chapter.time}
                onClick={() => this.handleSeekToPress(chapter.time)}
              >
                {chapter.description}
              </VideoLink>
            ))}
          </VideoLinkWrap>
        )}
      </div>
    )
  }
}

export default ({ url, thumbnail, ...restProps }) => (<ThePlayer url={url} thumbnail={thumbnail} {...restProps} />);
