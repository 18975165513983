import React from 'react';
import styled from 'styled-components/macro';
import ItemAnimationContext from '../../../containers/ItemAnimation/ItemAnimationContext';
import ItemRefContext from '../../../containers/ItemAnimation/ItemRefContext';

const ButtonNoProps = ({
  small,
  medium,
  fullWidth,
  fullWidthCenter,
  ...otherProps
}) => (
  <button type="button" {...otherProps} />
);
const StyledButton = styled(ButtonNoProps)`
  display: inline-block;
  background:  ${({ theme }) => theme.colors.colorBlue};
  border: 1px solid ${({ theme }) => theme.colors.colorBlue};
  color:  ${({ theme }) => theme.colors.colorWhite};
  ${({
    small,
    medium,
    fullWidth,
    fullWidthCenter,
  }) => {
    if (small) {
      return `
        padding: 5px 15px;
      `;
    }
    if (medium) {
      return `
      padding: 5px 16px;
      font-size: 20px;
      `;
    } if (fullWidth) {
      return `
        width: 30%;
        position: relative;
        padding: 5px 15px;
        font-size: 16px;
      `;
    } if (fullWidthCenter) {
      return `
        width: 25%;
        position: relative;
        padding: 6px 15px;
        font-size: 16px;
      `;
    }
    return `
      padding: 5px 15px;
      font-size: 16px;
    `;
  }}
  font-weight: bold;
  outline: 0;
  margin: 0;

  &:hover {
    background:  ${({ theme }) => theme.hoverColors.colorBlue};
    cursor: pointer;
  }
`;

const InputNoProps = React.forwardRef(({
  small,
  medium,
  fullWidth,
  fullWidthCenter,
  percentage,
  ...otherProps
}, ref) => (
  <input data-recording-gdpr-safe {...otherProps} ref={ref} />
));
const StyledInput = styled(InputNoProps)`
  border: 1px solid ${({ theme }) => theme.colors.colorGreyLightAlt};
  display: inline-block;
  ${({
    small,
    medium,
    fullWidth,
    fullWidthCenter,
  }) => {
    if (small) {
      return `
        padding: 5.5px 5px;
        text-align: center;
      `;
    }
    if (medium) {
      return `
      padding: 10px 5px;
      font-size: 12px;
      top: -3px;
      width: 50px;
      text-align: center;
      `;
    } if (fullWidth) {
      return `
      width: calc(40% - 15px);
      padding: 7.5px 5px;
      font-size: 12px;
      top: -2px;
      `;
    } if (fullWidthCenter) {
      return `
      width: calc(50% - 24px);
      padding: 8px 10px;
      font-size: 12px;
      top: -1.5px;
      text-align: center;
      `;
    }
    return `
      padding: 6.9px 5px;
      top: -1.9px;
      width: 40px;
      text-align: center;
    `;
  }}
  outline: 0px;
  margin: 0;
  position: relative;
  
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

  /* Firefox */
  &[type=number] {
  -moz-appearance: textfield;
}
`;

class BuyButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = { inputVal: 0, invalid: false };
    this.handleChange = this.handleChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onIncrementItemAmount = this.onIncrementItemAmount.bind(this);
    this.inputRef = React.createRef();
  }

  onIncrementItemAmount() {
    const {
      actions: {
        incrementItemAmount,
      },
      amount,
      onAnimation,
      itemRef,
    } = this.props;
    incrementItemAmount();
    if (amount === 0) {
      onAnimation(itemRef, this.inputRef);
    }
  }

  onBlur(event) {
    const {
      amount,
      dividableBy = 1,
      actions: {
        setItemAmount,
      },
      itemRef,
      onAnimation,
    } = this.props;
    const val = event.target.value;
    const invalid = val === '';
    const parsedVal = parseInt(val, 10);
    if (invalid) {
      this.setState({ inputVal: 0, invalid: false });
      return;
    }
    if ((parsedVal % dividableBy) !== 0) {
      const realVal = parsedVal - (parsedVal % dividableBy);
      if (amount === 0) {
        onAnimation(itemRef, this.inputRef);
      }
      if (realVal !== amount) {
        setItemAmount(realVal);
      }
      this.setState({ inputVal: realVal, invalid: false });
      window.alert(`Antallet af rammer skal være deleligt med ${dividableBy}`); // eslint-disable-line
    }
  }

  handleChange(event) {
    const {
      amount,
      onAnimation,
      actions: {
        setItemAmount,
      },
      dividableBy = 1,
      itemRef,
    } = this.props;
    const val = event.target.value;
    const parsedVal = parseInt(val, 10);
    const invalid = val === '' || parsedVal % dividableBy !== 0;
    if (!invalid) {
      if (amount === 0) {
        onAnimation(itemRef, this.inputRef);
      }
      setItemAmount(val);
    }
    this.setState(prevState => ({ ...prevState, inputVal: val, invalid }));
  }

  render() {
    const {
      actions: {
        decrementItemAmount,
      },
      amount,
      small,
      medium,
      fullWidth,
      fullWidthCenter,
    } = this.props;
    const {
      invalid,
      inputVal,
    } = this.state;
    const actualInputVal = invalid
      ? inputVal
      : amount;
    return (
      <React.Fragment>
        <StyledButton
          className="removeOneButton"
          onClick={decrementItemAmount}
          small={small}
          medium={medium}
          fullWidth={fullWidth}
          fullWidthCenter={fullWidthCenter}
        >
          -
        </StyledButton>
        <StyledInput
          type="number"
          value={actualInputVal}
          onChange={this.handleChange}
          small={small}
          medium={medium}
          fullWidth={fullWidth}
          fullWidthCenter={fullWidthCenter}
          onBlur={this.onBlur}
          ref={this.inputRef}
        />
        <StyledButton
          className="addOneButton"
          onClick={this.onIncrementItemAmount}
          small={small}
          medium={medium}
          fullWidth={fullWidth}
          fullWidthCenter={fullWidthCenter}
        >
          +
        </StyledButton>
      </React.Fragment>
    );
  }
}


export default props => (
  <ItemAnimationContext.Consumer>
    {onAnimate => (
      <ItemRefContext.Consumer>
        {itemRef => (
          <BuyButtons {...props} onAnimation={onAnimate} itemRef={itemRef} />
        )}
      </ItemRefContext.Consumer>
    )}
  </ItemAnimationContext.Consumer>
);
