import React from 'react';
import styled from 'styled-components/macro';
import VisibilitySensor from 'react-visibility-sensor';
import { Div } from '../grid';
import getArg from '../../lib/args';

const TextOverlayedImageContainer = styled(Div)`
  position: relative;
  display: block;
  width: 100%;
  ${({ height }) => height && `height: ${height};`}
  
  ${({ bgPos }) => {
    if (bgPos) {
      return `background-position: ${bgPos};`;
    }
    return `
      background-position: center center;
    `;
  }}
  background-size: cover;
`;
const TextOverlayedImageChildContainer = styled.div`
  position: absolute;
  ${({ top, bottom }) => {
    if (top) {
      return 'top: 0;';
    }
    if (bottom) {
      return 'bottom: 0;';
    }
    return `
      top: 50%;
      transform: translateY(-50%);
    `;
  }}
  ${({ left, right }) => {
    if (left) {
      return 'left: 0;';
    }
    if (right) {
      return 'right: 0;';
    }
    return `
      left: 50%;
      transform: translateX(-50%);
    `;
  }}
`;

export default class TextOverlayedImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasBeenVisible: false,
    };
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
  }

  onVisibilityChange = (visible) => {
    const {
      hasBeenVisible,
    } = this.state;
    // console.log('It is visible', hasBeenVisible, visible);
    if (!hasBeenVisible && visible) {
      this.setState({
        hasBeenVisible: true,
      });
    }
  }

  render() {
    const {
      top,
      right,
      left,
      bottom,
      name,
      children,
      alwaysVisible,
      ...otherProps
    } = this.props;
    const {
      hasBeenVisible,
    } = this.state;
    const mainChildren = (
      <TextOverlayedImageContainer {...otherProps} bgImageNotVisible={!hasBeenVisible && !alwaysVisible}>
        <TextOverlayedImageChildContainer top={top} right={right} left={left} bottom={bottom}>
          {children}
        </TextOverlayedImageChildContainer>
      </TextOverlayedImageContainer>
    );
    if (!getArg('browser')) {
      return mainChildren;
    }
    return (
      <VisibilitySensor onChange={this.onVisibilityChange} active={!hasBeenVisible && !alwaysVisible} partialVisibility>
        {mainChildren}
      </VisibilitySensor>
    );
  }
}
