import React from 'react';
import { Bold, Span } from '../typography';

export default ({
  days,
  pureText,
}) => (
  <React.Fragment>
    {days && (
      <React.Fragment>
        Op til 5 dage
      </React.Fragment>
    )}
    {!days && !pureText && (
      <React.Fragment>
        <Span block fontSize="12px">
          Lejepriser er for en periode og ikke pr. dag.
          Lejeperioden kan være <u>op til 5 dage</u> til samme pris.
        </Span>
        <Span block fontSize="12px">
          I forbindelse med helligdage udvides lejeperioden uden ekstra omkostninger.
        </Span>
        <Span block fontSize="12px">
          Ved leje i mere end 5 dage, tillægges ekstra leje automatisk i kurven når datoer er valgt.
        </Span>
      </React.Fragment>
    )}
    {pureText && (
      <React.Fragment>
        Lejepriser er for en periode og ikke pr. dag. Lejeperioden kan være <Bold>op til 5 dage</Bold> til samme pris.
      </React.Fragment>
    )}
  </React.Fragment>
);
