import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { getLocalState } from '../../features/commerce/items';


export default connect(
  state => ({
    itemsState: getLocalState(state),
  }),
)(
  ({
    itemsState,
    Component,
    ...props
  }) => {
    const itemsStateReverse = useMemo(() => ({ commerce: { items: itemsState } }), [itemsState]);
    return (
      <Component
        itemsLocalState={itemsState}
        itemsState={itemsStateReverse}
        {...props}
      />
    );
  },
);
