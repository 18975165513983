

import React from 'react';
import { ThemeProvider } from 'styled-components/macro';
import { Provider } from 'react-redux';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import { ScreenClassProvider } from 'react-grid-system';
import { ItemContext } from './containers/commerce';
import { MainTheme } from './style/themes';
import SideMenuContextProvider from './SideMenuContext';
import {
  BurgerMenu,
  BucketMenu,
  MenuSearch,
} from './layout/DefaultLayout/Header/components';
import Routes from './routes';
import getArg from './lib/args';

const context = {};
export default (store) => {
  const children = (
    <React.Fragment>
      <BurgerMenu />
      <BucketMenu />
      <MenuSearch />
      <div id="mainContainer">
        <Routes />
      </div>
    </React.Fragment>
  );
  const elements = (
    getArg('serverDirect')
      ? <StaticRouter location={getArg('location')} context={context}>{children}</StaticRouter>
      : <BrowserRouter>{children}</BrowserRouter>
  );
  return (
    <Provider store={store}>
      <ThemeProvider theme={MainTheme}>
        <ItemContext context="default">
          <SideMenuContextProvider>
            <ScreenClassProvider>
              {elements}
            </ScreenClassProvider>
          </SideMenuContextProvider>
        </ItemContext>
      </ThemeProvider>
    </Provider>
  );
};
