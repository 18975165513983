import arrayItemContents from './ItemContents';

let actualContents = null;

export const getItemContents = () => {
  if (actualContents == null) {
    actualContents = {};
    arrayItemContents.forEach(
      (item, idx) => {
        actualContents[item.varenr] = {
          default: {
            ...item,
            itemOrder: idx,
          },
        };
      },
    );
  }
  return actualContents;
};
export const getItemDefaultInformation = varenr => getItemContents()[varenr] && getItemContents()[varenr].default;

export default (varenr, context = 'default') => getItemContents()[varenr] && (getItemContents()[varenr][context] || getItemContents()[varenr].default);
