import React from 'react';
import styled from 'styled-components/macro';
import { Paragraph } from '../../typography';
import ItemAnimationContext from '../../../containers/ItemAnimation/ItemAnimationContext';
import ItemRefContext from '../../../containers/ItemAnimation/ItemRefContext';

const ButtonNoProps = ({
  small,
  medium,
  fullWidth,
  fullWidthBig,
  minus,
  plus,
  ...otherProps
}) => (
  <button type="button" minus={minus} plus={plus} {...otherProps} />
);
const StyledButton = styled(ButtonNoProps)`
  display: inline-block;
  background:  ${({ theme }) => theme.colors.colorGreen};
  border: 1px solid  ${({ theme }) => theme.colors.colorGreen};
  color:  ${({ theme }) => theme.colors.colorWhite};
  font-weight: bold;
  outline: 0;
  margin: 0;

  &:hover {
    background:  ${({ theme }) => theme.hoverColors.colorGreen};
    cursor: pointer;
  }
  ${({
    minus, plus, fullWidth, fullWidthBig,
  }) => {
    if (minus && fullWidthBig) {
      return `
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      `;
    }
    if (plus && fullWidthBig) {
      return `
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      `;
    }
    if (minus && fullWidth) {
      return `
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      `;
    }
    if (plus && fullWidth) {
      return `
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      `;
    }
    return null;
  }}
  ${({
    small, medium, fullWidth, fullWidthBig,
  }) => {
    if (small) {
      return `
      padding: 6px 15px;
      top: -2px;
      position: relative;
      font-size: 16px;
      `;
    }
    if (medium) {
      return `
      padding: 7px 20px;
      font-size: 20px;
      position: relative;
      top: -1px;
      `;
    } if (fullWidth) {
      return `
        margin-top: 10px;
        width: 25%;
        position: relative;
        padding: 6px 15px;
        top: -2px;
        font-size: 16px;
      `;
    } if (fullWidthBig) {
      return `
        margin-top: 10px;
        width: 25%;
        position: relative;
        padding: 6px 15px;
        top: 0px;
        font-size: 24px;
      `;
    }
    return `
      padding: 10px 25px;
      font-size: 24px;
    `;
  }}
`;

const InputNoProps = React.forwardRef(({
  small,
  medium,
  fullWidth,
  fullWidthBig,
  ...otherProps
}, ref) => (
  <input {...otherProps} ref={ref} />
));
const StyledInput = styled(InputNoProps)`
  display: inline-block;
  outline: 0px;
  margin: 0;
  position: relative;
  top: -3px;
  text-align: left;
  border-left: 0;
  border-right: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.colorGreyLightAlt};
  border-bottom: 1px solid ${({ theme }) => theme.colors.colorGreyLightAlt};
  ${({
    small, medium, fullWidth, fullWidthBig,
  }) => {
    if (small) {
      return `
      width: 60px;
      padding: 6.5px 10px;
      font-size: 12px;
      `;
    }
    if (medium) {
      return `
      width: 60px;
      padding: 9px 15px;
      font-size: 14px;
      `;
    } if (fullWidth) {
      return `
      width: calc(50% - 20px);
      padding: 8px 10px;
      font-size: 12px;
      text-align: center;
      `;
    } if (fullWidthBig) {
      return `
      width: calc(50% - 20px);
      padding: 11px 10px;
      font-size: 16px;
      text-align: center;
      `;
    }
    return `
      width: 60px;
      padding: 15px 20px;
      font-size: 16px;
    `;
  }}
`;
class BuyButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = { inputVal: 0, invalid: false };
    this.handleChange = this.handleChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onIncrementItemAmount = this.onIncrementItemAmount.bind(this);
    this.inputRef = React.createRef();
  }

  onIncrementItemAmount() {
    const {
      actions: {
        incrementItemAmount,
      },
      amount,
      onAnimation,
      itemRef,
    } = this.props;
    incrementItemAmount();
    if (amount === 0) {
      onAnimation(itemRef, this.inputRef);
    }
  }

  onBlur(event) {
    const {
      amount,
      dividableBy = 1,
      actions: {
        setItemAmount,
      },
      onAnimation,
      itemRef,
    } = this.props;
    const val = event.target.value;
    const invalid = val === '';
    const parsedVal = parseInt(val, 10);
    if (invalid) {
      this.setState({ inputVal: 0, invalid: false });
      return;
    }
    if ((parsedVal % dividableBy) !== 0) {
      const realVal = parsedVal - (parsedVal % dividableBy);
      if (amount === 0) {
        onAnimation(itemRef, this.inputRef);
      }
      if (realVal !== amount) {
        setItemAmount(realVal);
      }
      this.setState({ inputVal: realVal, invalid: false });
      window.alert(`Antallet af rammer skal være deleligt med ${dividableBy}`); // eslint-disable-line
    }
  }

  handleChange(event) {
    const {
      amount,
      actions: {
        setItemAmount,
      },
      dividableBy = 1,
      itemRef,
      onAnimation,
    } = this.props;
    const val = event.target.value;
    const parsedVal = parseInt(val, 10);
    const invalid = val === '' || parsedVal % dividableBy !== 0;
    if (!invalid) {
      setItemAmount(val);
      if (amount === 0) {
        onAnimation(itemRef, this.inputRef);
      }
    }
    this.setState(prevState => ({ ...prevState, inputVal: val, invalid }));
  }

  render() {
    const {
      actions: {
        decrementItemAmount,
      },
      amount,
      small,
      medium,
      fullWidth,
      fullWidthBig,
      inStock,
    } = this.props;
    const {
      invalid,
      inputVal,
    } = this.state;
    const actualInputVal = invalid
      ? inputVal
      : amount;
    return (
      <React.Fragment>
        <StyledButton
          className="addOneButton"
          onClick={decrementItemAmount}
          small={small}
          medium={medium}
          fullWidth={fullWidth}
          fullWidthBig={fullWidthBig}
          minus
        >
          -
        </StyledButton>
        <StyledInput
          type="number"
          value={actualInputVal}
          onChange={this.handleChange}
          onBlur={this.onBlur}
          small={small}
          medium={medium}
          fullWidth={fullWidth}
          fullWidthBig={fullWidthBig}
          ref={this.inputRef}
        />
        <StyledButton
          className="removeOneButton"
          onClick={this.onIncrementItemAmount}
          small={small}
          medium={medium}
          fullWidth={fullWidth}
          fullWidthBig={fullWidthBig}
          plus
        >
          +
        </StyledButton>
        {inStock > 0 && (
          <Paragraph fontSize="14px" red>
            {inStock} stk. tilbage.
          </Paragraph>
        )}
      </React.Fragment>
    );
  }
}

export default props => (
  <ItemAnimationContext.Consumer>
    {onAnimate => (
      <ItemRefContext.Consumer>
        {itemRef => (
          <BuyButtons {...props} onAnimation={onAnimate} itemRef={itemRef} />
        )}
      </ItemRefContext.Consumer>
    )}
  </ItemAnimationContext.Consumer>
);
