import styled from 'styled-components/macro';
import {
  marginTheming,
  paddingTheming,
  elementTheming,
  fontTheming,
  removePaddingThemingProps,
  removeMarginThemingProps,
  removeElementThemingProps,
  removeFontThemingProps,
  removeProps,
  combine,
  createComponentWithModifiedProps,
} from '../../style/themes/main';

const removeCustomProps = removeProps();
const removeAllProps = combine(
  removePaddingThemingProps,
  removeMarginThemingProps,
  removeElementThemingProps,
  removeFontThemingProps,
  removeCustomProps,
);

const ThNoProps = createComponentWithModifiedProps(removeAllProps, 'th', 'Skafte.com.tables.Td');

export default styled(ThNoProps)`
  font-weight: bold;
  text-align: left;
  ${({ onClick }) => onClick && 'cursor: pointer;'}
  ${marginTheming}
  ${paddingTheming}
  ${elementTheming}
  ${fontTheming}
`;
