import React from 'react';
import ReactDOM from 'react-dom';
import enhanceWithClickOutside from 'react-click-outside';
import { withRouter } from 'react-router-dom';
import { enhanceWithSideMenuContext } from '../../../../../SideMenuContext';
import MenuItem from './menuItem';

class MenuItemContainer extends React.Component {
  constructor(props) {
    super(props);
    const { children } = props;
    this.state = {
      expanded: false,
      expandable: children && children.length > 0,
    };
    this.handleClick = this.handleClick.bind(this);
    this.settingExpandedToFalse = false;
  }

  componentDidMount() {
    const {
      history,
    } = this.props;
    this.unsubscribe = history.listen(() => {
      const { expanded } = this.state;
      if (expanded) {
        this.settingExpandedToFalse = true;
        this.setState({ expanded: false }, () => { this.settingExpandedToFalse = false; });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    const { children: nextChildren } = nextProps;
    this.setState(
      ({
        expanded: prevExpanded,
      }) => {
        const nextExpandable = nextChildren && nextChildren.length > 0;
        if (this.settingExpandedToFalse) {
          return { expandable: nextExpandable };
        }
        return {
          expanded: prevExpanded && nextExpandable,
          expandable: nextExpandable,
        };
      },
    );
  }

  componentWillUnmount() {
    this.unsubscribe();
  }


  handleClickOutside() {
    const { expanded } = this.state;
    if (expanded) {
      this.setState(state => ({ ...state, expanded: false }));
    }
  }

  handleClick() {
    const { name, setActiveSideMenu } = this.props;
    const { expandable, expanded } = this.state;
    if (expandable) {
      this.setState(
        ({ expanded, ...restState }) => ({ expanded: !expanded, ...restState }),
      );
    }
    if (expanded || expandable) {
      setActiveSideMenu(expanded ? null : name);
    }
  }

  render() {
    const {
      title,
      children,
      topMenu,
      smallScreens,
      bigScreens,
      to,
      activeSideMenu,
      lastSideMenu,
      name,
      portalRef,
    } = this.props;
    const {
      expanded,
      expandable,
    } = this.state;
    return (
      <React.Fragment>
        <MenuItem
          expanded={expanded}
          expandable={expandable}
          title={title}
          onClick={this.handleClick}
          to={to}
          smallScreens={smallScreens}
          bigScreens={bigScreens}
          topMenu={topMenu}
        >
          {children}
        </MenuItem>
        {portalRef && portalRef.current && (
          ReactDOM.createPortal(
            <div style={{ display: activeSideMenu === name || name === lastSideMenu ? 'block' : 'none' }}>
              {children}
            </div>,
            portalRef.current,
          )
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(enhanceWithClickOutside(enhanceWithSideMenuContext(MenuItemContainer)));
