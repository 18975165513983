import React from 'react';
import { Money, VareLink } from '../../../../containers/commerce';
import { Row, Col } from '../../../grid';
import { Paragraph, Span } from '../../../typography';
import RecieptEntryWrap from '../RecieptEntryWrap';
import { Image } from '../../../Image';
import { getRentalText } from '../../../../lib/helpers';
import { IconMinusCircle, IconPlusCircle } from './entryComponents';

export default (props) => {
  const {
    varenr,
    name,
    amount,
    cost,
    actions,
    packImages,
    isLeje,
    days,
  } = props;
  const {
    incrementItemAmount,
    decrementItemAmount,
  } = actions;
  return (
    <RecieptEntryWrap>
      <Row>
        <Col xs={4} padding="15px 5px" textCenter>
          {packImages && packImages.length > 0 && (
            <Image maxHeight="60px" imageHeight="60px" name={packImages[0].name} left />
          )}
        </Col>
        <Col xs={8} padding="15px 15px 15px 0px">
          <Paragraph lineHeight="1.2em" margin="0">
            <VareLink varenr={varenr}>{name}</VareLink>
          </Paragraph>
          <Span block bold margin="5px 0 0 0">
            {amount} stk. {isLeje && `(${getRentalText(days)})`}
          </Span>
          <IconMinusCircle onClick={decrementItemAmount} />
          <IconPlusCircle onClick={incrementItemAmount} />
          <Paragraph floatRight margin="5px 0 0 0" bold>
            <Money money={cost} />
          </Paragraph>
        </Col>
      </Row>
    </RecieptEntryWrap>
  );
};
