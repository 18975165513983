import React from "react";
import styled from "styled-components/macro";
import AspectRatio from "react-aspect-ratio";
import VisibilitySensor from "react-visibility-sensor";
import webp from "check-for-webp";
import getImageRequestName, { getImageRequestNameWebp } from "../../images";
import getImageInfo from "../../getImageInfo";
import getArg from "../../lib/args";
import { LinkStyled } from "../typography";

const NormalImage = styled.img`
  width: auto !important;
`;
const EmptyDiv = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledAspectRatio = styled(AspectRatio)`
  & img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const StyledAspectRatioWithMinWidths = styled((props) => (
  <StyledAspectRatio {...props} />
))`
  & img,
  & > div {
    height: 100%;
  }
`;

const StyledAspectRatioWithoutMinWidths = styled((props) => (
  <StyledAspectRatio {...props} />
))`
  & > div {
    width: 100%;
    height: 100%;
  }
`;

const Img = ({ visible, imgComponent: Component, ...props }) => {
  if (!getArg("browser") && getArg("isPageSpeed")) {
    const { src, ...otherProps } = props;
    return <Component src="/img/blank.gif" data-echo={src} {...otherProps} />;
  }
  if (visible) {
    return <Component {...props} />;
  }
  return <EmptyDiv>&nbsp;</EmptyDiv>;
};

class ImageNoStyle extends React.Component {
  constructor(props) {
    super(props);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
    this.state = {
      ready: false,
    };
  }

  onVisibilityChange(visible) {
    if (visible) {
      this.setState({ ready: true });
    }
  }

  render() {
    const {
      children,
      name,
      alt,
      maxWidth,
      margin,
      marginRight,
      marginLeft,
      maxHeight,
      pullLeft,
      pullRight,
      opacity,
      block,
      relative,
      top,
      hide,
      right,
      left,
      bottom,
      imageWidth,
      imageHeight,
      verticalTop,
      verticalMiddle,
      verticalBottom,
      absolute,
      withMinWidths,
      to,
      style = {},
      alwaysVisible,
      ...otherProps
    } = this.props;
    const { ready } = this.state;
    const heightProp = imageHeight ? `${imageHeight}`.replace("px", "") : 1;
    const widthProp = imageWidth ? `${imageWidth}`.replace("px", "") : 1;
    let imageRequest =
      imageHeight || imageWidth
        ? getImageRequestName(name, `${widthProp}x${heightProp}`)
        : getImageRequestName(name);
    if (webp || (!getArg("browser") && getArg("isPageSpeed"))) {
      imageRequest =
        imageHeight || imageWidth
          ? getImageRequestNameWebp(name, `${widthProp}x${heightProp}`)
          : getImageRequestNameWebp(name);
    }
    if (getImageInfo(name)) {
      const { width: actualWidth, height: actualHeight } = getImageInfo(name);
      const AspectRatioComponent = withMinWidths
        ? StyledAspectRatioWithMinWidths
        : StyledAspectRatioWithoutMinWidths;
      return (
        <VisibilitySensor
          onChange={this.onVisibilityChange}
          active={!ready && !alwaysVisible}
          partialVisibility
        >
          <AspectRatioComponent
            ratio={actualWidth / actualHeight}
            {...otherProps}
            style={{ display: "inline-block", width: "100%", ...style }}
          >
            <div>
              {to && (
                <LinkStyled anchor to={to}>
                  <Img
                    imgComponent={NormalImage}
                    src={imageRequest}
                    alt={alt || name}
                    visible={ready || alwaysVisible}
                  />
                </LinkStyled>
              )}
              {!to && (
                <React.Fragment>
                  <Img
                    imgComponent={NormalImage}
                    src={imageRequest}
                    alt={alt || name}
                    visible={ready || alwaysVisible}
                  />
                </React.Fragment>
              )}
            </div>
          </AspectRatioComponent>
        </VisibilitySensor>
      );
    }
    return (
      <VisibilitySensor
        onChange={this.onVisibilityChange}
        active={!ready && !alwaysVisible}
        partialVisibility
      >
        <React.Fragment>
          <Img
            imgComponent="img"
            src={imageRequest}
            alt={alt || name}
            visible={ready || alwaysVisible}
            {...otherProps}
          />
        </React.Fragment>
      </VisibilitySensor>
    );
  }
}
const getMaxHeights = ({
  maxHeight,
  maxWidth,
  imageHeight,
  imageWidth,
  name,
}) => {
  const { width: actualWidth, height: actualHeight } = getImageInfo(name);
  let translatedWidth = actualWidth;
  let translatedHeight = actualHeight;
  if (imageWidth) {
    translatedWidth = parseInt(`${imageWidth}`.replace("px", ""), 10);
    if (maxHeight) {
      const newHeight = translatedWidth * (actualHeight / actualWidth);
      translatedHeight = parseInt(`${maxHeight}`.replace("px", ""), 10);
      if (translatedHeight > newHeight) {
        translatedWidth =
          Math.round(translatedHeight * (actualWidth / actualHeight) * 100) /
          100;
      } else if (translatedHeight < newHeight) {
        translatedWidth =
          Math.round(newHeight * (actualWidth / actualHeight) * 100) / 100;
      }
    } else {
      translatedHeight =
        Math.round((translatedWidth / actualWidth) * actualHeight * 100) / 100;
    }
  } else if (imageHeight) {
    translatedHeight = parseInt(`${imageHeight}`.replace("px", ""), 10); // 60
    if (maxWidth) {
      const newWidth = translatedHeight * (actualWidth / actualHeight); //
      translatedWidth = parseInt(`${maxWidth}`.replace("px", ""), 10); // 31
      if (translatedWidth > newWidth) {
        translatedHeight =
          Math.round(newWidth * (actualHeight / actualWidth) * 100) / 100;
      } else if (translatedWidth < newWidth) {
        translatedHeight =
          Math.round(translatedWidth * (actualHeight / actualWidth) * 100) /
          100;
      }
    } else {
      translatedWidth =
        Math.round((translatedHeight / actualHeight) * actualWidth * 100) / 100;
    }
  }
  const newMaxWidth = maxWidth
    ? parseInt(`${maxWidth}`.replace("px", ""), 10)
    : translatedWidth;
  const newMaxHeight = maxHeight
    ? parseInt(`${maxHeight}`.replace("px", ""), 10)
    : translatedHeight;
  const widthExposed = newMaxHeight * (actualWidth / actualHeight);
  const heightExposed = newMaxWidth * (actualHeight / actualWidth);
  let minWidth = newMaxHeight < heightExposed ? widthExposed : newMaxWidth;
  let minHeight = newMaxWidth < widthExposed ? heightExposed : newMaxHeight;
  if (imageWidth) {
    translatedWidth = parseInt(`${imageWidth}`.replace("px", ""), 10);
    if (minWidth > translatedWidth) {
      minWidth = translatedWidth;
    }
  }
  if (imageHeight) {
    translatedHeight = parseInt(`${imageHeight}`.replace("px", ""), 10);
    if (minHeight > translatedHeight) {
      minHeight = translatedHeight;
    }
  }
  return {
    maxWidth: newMaxWidth,
    maxHeight: newMaxHeight,
    minWidth,
    minHeight,
  };
};

export default styled(ImageNoStyle)`
  ${({ maxWidth, imageWidth, imageHeight, maxHeight, withMinWidths, name }) => {
    if (maxWidth && maxHeight) {
      return `
        max-width: ${maxWidth};
        max-height: ${maxHeight};
      `;
    }
    if (getImageInfo(name)) {
      const {
        maxWidth: realMaxWidth,
        maxHeight: realMaxHeight,
        minWidth,
        minHeight,
      } = getMaxHeights({
        maxWidth,
        maxHeight,
        imageHeight,
        imageWidth,
        name,
      });
      return `
        &, & > div {
          max-width: ${realMaxWidth}px;
          max-height: ${realMaxHeight}px;
        }
        ${
          withMinWidths
            ? `
          &, & > div, & img {
            min-width: ${minWidth}px;
            min-height: ${minHeight}px;
          }
        `
            : ""
        }
      `;
    }
    return `
      ${maxWidth && `max-width: ${maxWidth};`}
      ${maxHeight && `max-height: ${maxHeight};`}
    `;
  }}
  ${({ pullLeft }) => pullLeft && "float: left;"}
  ${({ pullRight }) => pullRight && "float: right;"}
  ${({ block }) => block && "display: block;"}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight};`}
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft};`}
  ${({ relative }) => relative && "position: relative;"}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ verticalTop }) => verticalTop && "vertical-align: top;"}
  ${({ verticalMiddle }) => verticalMiddle && "vertical-align: middle;"}
  ${({ verticalBottom }) => verticalBottom && "vertical-align: bottom;"}
  ${({ absolute }) => absolute && "position: absolute !important;"}
  ${({ top }) => top && `top: ${top};`}
  ${({ right }) => right && `right: ${right};`}
  ${({ bottom }) => bottom && `bottom: ${bottom};`}
  ${({ left }) => left && `left: ${left};`}
  ${({ hide }) => hide && "display: none;"}
`;
