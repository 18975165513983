import React from 'react';
import Format from './Format';
import { getPriceWithMoms } from '../../lib/price';

export default ({
  money,
  moms,
  onlyMoms,
  multiplier,
  keepDecimal,
  noSuffix,
  noDecimal,
  suffix,
  currencyRate = 1,
  preMultiplier = 1,
  ...props
}) => {
  const actualMultiplier = onlyMoms ? 0.2 * (multiplier || 1) : (multiplier || 1);
  const v = getPriceWithMoms(money, onlyMoms || moms, keepDecimal, preMultiplier) * actualMultiplier * currencyRate;
  return (
    <Format
      fixedDecimalScale
      decimalScale={noDecimal ? 0 : 2}
      suffix={!noSuffix && (suffix || ' kr.')}
      value={v / 100}
      {...props}
    />
  );
};
