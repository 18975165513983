import React from "react";
import { Heading4, Paragraph } from "../typography";
// import { Price } from '../../containers/commerce';

export default () => (
  <React.Fragment>
    <Heading4>
      24 gratis dåseøl ved køb af mindst 3 fustager Nørrebro, Royal IPA eller
      Royal Blanche
    </Heading4>
    <Paragraph>
      Når du køber mindst 3 fustager af ovenstående fadøl kan du få 1 ramme
      Royal Blanche dåseøl gratis med din ordre.
    </Paragraph>
    <Paragraph>
      Kan kombineres med andre fadøl, som går under dette tilbud.
    </Paragraph>
    <Paragraph>Max 1 gratis ramme pr. ordre.</Paragraph>
    <Paragraph>
      Pant bliver lagt til i kurven. Åbner du ikke mindst tre fustager Nørrebro,
      Royal IPA eller Royal Blanche faktureres rammen med dåseøl + pant
    </Paragraph>
  </React.Fragment>
);
