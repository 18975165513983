import moment from 'moment';
import { createSelector } from 'reselect';
import { deliveryTypes } from '../shared';
import {
  dateTypes,
  dateModes,
  SET_DATE,
  SET_TIME,
  SET_MODE,
  RESET_DATE,
  VALIDATE_STATE,
  isSaturday,
  isSunday,
  isBefore,
  isToday,
  storeDateFormat,
  timeTranslation,
  getDateFee as sharedGetDateFee,
  getTimeFee as sharedGetTimeFee,
  combineFees,
  subtractFees,
  sameDayChoices,
  nightChoices,
  deliverySaturdayChoices,
  deliverySundayChoices,
  createGetAvailableTimes,
} from './shared';
import { SET_DELIVERY_INFO } from '../actions';
import { closedDates } from '../../../../content/commerce/closedDates';
import getArg from '../../../../lib/args';

export const subDeliveryTypes = {
  skafte: 'SKAFTE',
  postNord: 'POST_NORD',
  water: 'WATER',
};

const getDefaultTime = (mode) => {
  switch (mode) {
    case dateModes.sameDay:
      return sameDayChoices.early;
    case dateModes.night:
      // return nightChoices.dhl;
      return nightChoices.early;
    case dateModes.deliverySaturday:
      return deliverySaturdayChoices.morning;
    case dateModes.deliverySunday:
      return deliverySundayChoices.afternoon;
    case dateModes.interval: {
      return { fromTime: 8, toTime: 16 };
    }
    default:
      return null;
  }
};

const initialState = {
  localDeliveryType: subDeliveryTypes.skafte,
  deliveryInfo: {
    specialMode: false,
    hasAnySodaOrFadoel: false,
    hasWater: false,
    hasLeje: false,
    freeDeliveryIndex: 0,
    amountOfDraughtmaster: 0,
    numberOfItems: 0,
  },
  date: null,
  mode: dateModes.interval,
  time: getDefaultTime(dateModes.interval),
  active: true,
};
const setDeliveryInfoTransition = (state, deliveryInfo) => ({
  ...state,
  deliveryInfo,
});


const modeTransition = (
  state,
  {
    deliveryType,
    dateType,
    mode,
    time,
  },
) => {
  if (
    deliveryType !== deliveryTypes.delivery
    || dateType !== state.type
    || state.mode === mode
  ) {
    return state;
  }
  return {
    ...state,
    mode,
    time: time || getDefaultTime(mode),
  };
};

const specialDays = ['20191223', '20191227', '20191230'];
const dateTransition = (state, { dateType, date }) => {
  if (
    dateType === dateTypes.delivery
    && state.type === dateTypes.pickup
    && state.mode !== dateModes.none
    && state.date !== null
  ) {
    if (
      date === null
      || state.date === date
      || isBefore(state.date, date)
    ) {
      return {
        ...state,
        date: null,
        time: getDefaultTime(state.mode),
      };
    }
  }
  if (
    dateType !== state.type
    || state.date === date
  ) {
    return state;
  }
  const newState = {
    ...state,
    date,
  };
  if (specialDays.includes(date)) {
    return modeTransition(
      newState,
      {
        dateType,
        mode: dateModes.interval,
        time: { from: 8, to: 13 },
        deliveryType: deliveryTypes.delivery,
      },
    );
  }
  // If today
  // And is delivery
  if (isSunday(date)) {
    return modeTransition(newState, { dateType, mode: dateModes.deliverySunday, deliveryType: deliveryTypes.delivery });
  }
  if (isSaturday(date)) {
    return modeTransition(newState, { dateType, mode: dateModes.deliverySaturday, deliveryType: deliveryTypes.delivery });
  }
  if (
    isToday(date)
  ) {
    return modeTransition(newState, { dateType, mode: dateModes.sameDay, deliveryType: deliveryTypes.delivery });
  }
  if (
    state.mode === dateModes.sameDay
    || state.mode === dateModes.deliverySaturday
    || state.mode === dateModes.deliverySunday
  ) {
    return modeTransition(newState, { dateType, mode: dateModes.interval, deliveryType: deliveryTypes.delivery });
  }
  return newState;
};

const stateValidation = (state) => {
  if (state[dateTypes.delivery].date !== null) {
    if (isBefore(state[dateTypes.delivery].date, moment().format(storeDateFormat))) {
      return {
        ...state,
        [dateTypes.delivery]: dateTransition(state[dateTypes.delivery], { dateType: dateTypes.delivery, date: null }),
      };
    }
  }
  return state;
};

const makeReducer = (dateType) => {
  const typeInitialState = {
    ...initialState,
    type: dateType,
  };
  return (state = typeInitialState, action) => {
    switch (action.type) {
      case SET_DATE:
        return dateTransition(state, action.payload);
      case SET_MODE:
        return modeTransition(state, action.payload);
      case SET_DELIVERY_INFO:
        return setDeliveryInfoTransition(state, action.payload);
      case SET_TIME:
        if (
          action.payload.dateType === state.type
          && action.payload.deliveryType === deliveryTypes.delivery
        ) {
          return {
            ...state,
            time: action.payload.time,
          };
        }
        return state;
      case RESET_DATE:
        return typeInitialState;
      default:
        return state;
    }
  };
};
const deliveryReducer = makeReducer(dateTypes.delivery);
const pickupReducer = makeReducer(dateTypes.pickup);

const initialTotalState = {
  [dateTypes.delivery]: deliveryReducer(undefined, { type: '' }),
};
const runReducerOnKey = (reducer, key) => (state, action) => {
  const newState = reducer(state[key], action);
  if (newState !== state[key]) {
    return {
      ...state,
      [key]: newState,
    };
  }
  return state;
};

export default (state = initialTotalState, action) => {
  switch (action.type) {
    case VALIDATE_STATE:
      return stateValidation(state);
    default: {
      let newState = runReducerOnKey(deliveryReducer, dateTypes.delivery)(state, action);
      newState = runReducerOnKey(pickupReducer, dateTypes.pickup)(newState, action);
      return newState;
    }
  }
};


export const getRealLocalState = state => state.commerce.delivery.dates[deliveryTypes.delivery];
export const getLocalState = dateType => state => getRealLocalState(state)[dateType];

export const getSubDeliveryType = dateType => (state) => {
  const {
    deliveryInfo: {
      hasAnySodaOrFadoel,
      hasWater,
      numberOfItems,
    },
  } = getLocalState(dateType)(state);
  if (hasWater) {
    return subDeliveryTypes.water;
  }
  if (!hasAnySodaOrFadoel && numberOfItems > 0) {
    return subDeliveryTypes.postNord;
  }
  return subDeliveryTypes.skafte;
};

export const hasDateAsPossibility = dateType => () => dateType === dateTypes.delivery;
export const getDate = dateType => createSelector(
  getLocalState(dateType),
  localState => localState.mode !== dateModes.none && localState.date && moment(localState.date, storeDateFormat),
);

export const getMode = dateType => state => getLocalState(dateType)(state).mode;

export const getDisabledWeekdays = () => () => [];

export const getDisabledDays = () => createSelector(
  () => null,
  () => Object.keys(closedDates),
);
export const isRequired = () => () => false;

export const validate = dateType => (state) => {
  if (isRequired(dateType)(state)) {
    const {
      date,
    } = getLocalState(dateType)(state);
    return Boolean(date);
  }
  return true;
};

export const getMinDate = () => () => moment();

export const getIsDisabled = dateType => () => dateType === dateTypes.pickup;

const availableTimeSelectors = {
  [dateModes.sameDay]: {
    [dateTypes.delivery]: createGetAvailableTimes(dateTypes.delivery, [
      sameDayChoices.early,
      sameDayChoices.late,
      nightChoices.dhlDelivery,
      nightChoices.dhl,
      nightChoices.early,
      nightChoices.medium,
      nightChoices.late,
    ]),
    [dateTypes.pickup]: createGetAvailableTimes(dateTypes.pickup, [
      sameDayChoices.early,
      sameDayChoices.late,
      nightChoices.dhlDelivery,
      nightChoices.dhl,
      nightChoices.early,
      nightChoices.medium,
      nightChoices.late,
    ]),
  },
  [dateModes.night]: {
    [dateTypes.delivery]: createGetAvailableTimes(dateTypes.delivery, [
      nightChoices.dhlDelivery,
      nightChoices.dhl,
      nightChoices.early,
      nightChoices.medium,
      nightChoices.late,
      nightChoices.afterMidnight,
    ]),
    [dateTypes.pickup]: createGetAvailableTimes(dateTypes.pickup, [
      nightChoices.dhlDelivery,
      nightChoices.dhl,
      nightChoices.early,
      nightChoices.medium,
      nightChoices.late,
      nightChoices.afterMidnight,
    ]),
  },
  [dateModes.deliverySaturday]: {
    [dateTypes.delivery]: createGetAvailableTimes(dateTypes.delivery, [
      deliverySaturdayChoices.morning,
      deliverySaturdayChoices.afternoon,
      deliverySaturdayChoices.midday,
      deliverySaturdayChoices.evening,
      deliverySaturdayChoices.night,
      deliverySaturdayChoices.afterMidnight,
    ]),
    [dateTypes.pickup]: createGetAvailableTimes(dateTypes.pickup, [
      deliverySaturdayChoices.morning,
      deliverySaturdayChoices.afternoon,
      deliverySaturdayChoices.midday,
      deliverySaturdayChoices.evening,
      deliverySaturdayChoices.night,
      deliverySaturdayChoices.afterMidnight,
    ]),
  },
  [dateModes.deliverySunday]: {
    [dateTypes.delivery]: createGetAvailableTimes(dateTypes.delivery, [
      deliverySundayChoices.afternoon,
      deliverySundayChoices.midday,
      deliverySundayChoices.evening,
      deliverySundayChoices.night,
      deliverySundayChoices.afterMidnight,
    ]),
    [dateTypes.pickup]: createGetAvailableTimes(dateTypes.pickup, [
      deliverySundayChoices.afternoon,
      deliverySundayChoices.midday,
      deliverySundayChoices.evening,
      deliverySundayChoices.night,
      deliverySundayChoices.afterMidnight,
    ]),
  },
};

export const getAvailableTimesInner = dateType => (currentTime = new Date().getTime(), dateMode, date) => {
  switch (dateMode) {
    case dateModes.sameDay:
    case dateModes.night:
    case dateModes.deliverySaturday:
    case dateModes.deliverySunday:
      return availableTimeSelectors[dateMode][dateType](date, currentTime);
    case dateModes.interval:
    case dateModes.none:
    default:
      return null;
  }
};

export const getTime = dateType => (state) => {
  const localState = getLocalState(dateType)(state);
  if (getArg('browser')) {
    const possibleTimes = getAvailableTimesInner(dateType)(undefined, localState.mode, localState.date);
    if (possibleTimes && !possibleTimes.includes(localState.time)) {
      return possibleTimes[0];
    }
  }
  return localState.time;
};

export const getAvailableTimes = dateType => (state, currentTime) => {
  const localState = getLocalState(dateType)(state);
  return getAvailableTimesInner(dateType)(currentTime, localState.mode, localState.date);
};
export const getActualTime = dateType => (state) => {
  const localState = getLocalState(dateType)(state);
  switch (localState.mode) {
    case dateModes.sameDay:
    case dateModes.night:
    case dateModes.deliverySaturday:
    case dateModes.deliverySunday: {
      const time = getTime(dateType)(state);
      return time ? timeTranslation[time].time : null;
    }
    case dateModes.interval:
      return localState.time ? `${localState.time.fromTime}:00 - ${localState.time.toTime}:00` : null;
    case dateModes.none:
    default:
      return null;
  }
};


export const getTimeFee = dateType => state => (time) => {
  const fees = sharedGetTimeFee(time);
  const date = getDate(dateType)(state);
  return Object.keys(fees).reduce(
    (acc, feeKey) => {
      if (fees[feeKey].removeFee && fees[feeKey].removeFee(date)) {
        return acc;
      }
      return {
        ...acc,
        [feeKey]: fees[feeKey],
      };
    },
    {},
  );
};

export const getNormalDateFees = dateType => (state) => {
  const subDeliveryType = getSubDeliveryType(dateType)(state);
  switch (subDeliveryType) {
    case subDeliveryTypes.skafte: {
      const {
        deliveryInfo: {
          numberOfItems,
          freeDeliveryIndex,
        },
      } = getLocalState(dateType)(state);
      if (numberOfItems === 0) {
        return {};
      }
      const amount = (5 - Math.round(Math.min(1, freeDeliveryIndex) * 5));
      if (amount > 0) {
        return {
          [633]: {
            amount,
            dontStack: true,
          },
        };
      }
      return {};
    }
    case subDeliveryTypes.postNord: {
      const {
        deliveryInfo: {
          amountOfDraughtmaster,
          onlyFreeDeliveryItems,
        },
      } = getLocalState(dateType)(state);
      if (amountOfDraughtmaster < 4) {
        if (onlyFreeDeliveryItems) {
          return {};
        }
        return {
          [620]: {
            amount: 1,
            dontStack: true,
          },
        };
      }
      return {};
    }
    default:
    case subDeliveryTypes.water: {
      return {};
    }
  }
};
export const getDateFee = dateType => state => (date) => {
  let fees = sharedGetDateFee(date);
  const subDeliveryType = getSubDeliveryType(dateType)(state);
  switch (subDeliveryType) {
    case subDeliveryTypes.skafte: {
      const {
        deliveryInfo: {
          numberOfItems,
          freeDeliveryIndex,
        },
      } = getLocalState(dateType)(state);
      if (numberOfItems === 0) {
        return fees;
      }
      const amount = (5 - Math.round(Math.min(1, freeDeliveryIndex) * 5));
      fees = {
        ...fees,
        [633]: {
          amount,
          dontStack: true,
          main: true,
        },
      };
      return fees;
    }
    case subDeliveryTypes.postNord: {
      const {
        deliveryInfo: {
          amountOfDraughtmaster,
          onlyFreeDeliveryItems,
        },
      } = getLocalState(dateType)(state);
      if (amountOfDraughtmaster < 4) {
        if (onlyFreeDeliveryItems) {
          return fees;
        }
        return {
          ...fees,
          [620]: {
            amount: 1,
            dontStack: true,
            main: true,
          },
        };
      }
      return fees;
    }
    case subDeliveryTypes.water:
      return fees;
    default:
      return fees;
  }
};

export const getDateExtraFee = dateType => date => createSelector(
  getNormalDateFees(dateType),
  getDateFee(dateType),
  (normalDateFess, getDF) => {
    const dateFee = getDF(date);
    return subtractFees(dateFee, normalDateFess);
  },
);
export const getFees = dateType => createSelector(
  getMode(dateType),
  getTime(dateType),
  getDate(dateType),
  getDateFee(dateType),
  getTimeFee(dateType),
  (mode, time, date, getDF, getTF) => {
    let fees = {};
    if (mode !== dateModes.none) {
      fees = {
        ...fees,
        ...getDF(date),
      };
    }
    if (
      mode === dateModes.sameDay
      || mode === dateModes.night
      || mode === dateModes.deliverySaturday
      || mode === dateModes.deliverySunday
    ) {
      fees = {
        ...fees,
        ...getTF(time),
      };
    }
    return fees;
  },
);

export const getPriceComposition = createSelector(
  getFees(dateTypes.delivery),
  getFees(dateTypes.pickup),
  combineFees,
);
