import React from 'react';
import Header from './Header';
import ItemAnimationProvider from '../../containers/ItemAnimation/ItemAnimationProvider';


export default ({
  children,
  noSearchBar,
  noSearchBarResults,
  disableBreadCrumbsSchema,
}) => (
  <ItemAnimationProvider>
    <Header noSearchBar={noSearchBar} noSearchBarResults={noSearchBarResults} disableBreadCrumbsSchema={disableBreadCrumbsSchema} />
    {children}
  </ItemAnimationProvider>
);
