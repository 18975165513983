import React from 'react';
import { sanitizeMeasurement } from '../../lib/helpers';
import Format from './Format';

export default ({ weight, decimals }) => {
  const sanWeight = sanitizeMeasurement(weight);
  return (
    decimals ? (
      <Format
        decimalScale={2}
        value={sanWeight}
      />
    ) : (
      <Format
        decimalScale={0}
        value={sanWeight}
      />
    )
  );
};