import styled from 'styled-components/macro';
import {
  marginTheming,
  paddingTheming,
  elementTheming,
  removePaddingThemingProps,
  removeMarginThemingProps,
  removeElementThemingProps,
  removeProps,
  combine,
  createComponentWithModifiedProps,
} from '../../style/themes/main';


const removeCustomProps = removeProps();
const removeAllProps = combine(
  removePaddingThemingProps,
  removeMarginThemingProps,
  removeElementThemingProps,
  removeCustomProps,
);

const HrNoProps = createComponentWithModifiedProps(removeAllProps, 'hr', 'Skafte.com.typography.Hr');

export default styled(HrNoProps)`
  display: block;
  height: 2px;
  ${paddingTheming}
  ${marginTheming}
  ${elementTheming}
`;
