import React from 'react';
import styled from 'styled-components/macro';
import { Money } from '../../../../containers/commerce';
import { LiterFormat } from '../../../Format';
import { Paragraph } from '../../../typography';
import { Col, Row } from '../../../grid';

const EntryWrapNoStyle = ({
  children,
  ...otherProps
}) => (
  <div {...otherProps}>
    {children}
  </div>
);
const EntryWrap = styled(EntryWrapNoStyle)`
  border-bottom: 2px solid ${({ theme }) => theme.colors.colorGreyLight};
`;

export default (props) => {
  const {
    amount,
    liter,
    cost,
    amountPrPalle,
  } = props;
  return (
    <EntryWrap>
      <Col xs={12} padding="3px 0">
        <Paragraph lineHeight="1.2em" margin="0">
          Vand med logo:
        </Paragraph>
        <Row>
          <Col xs={6} paddingTop="0px" paddingBottom="0px" bold>
            {amount} x {amountPrPalle} x <LiterFormat liter={liter} />
          </Col>
          <Col xs={6} paddingTop="0px" paddingBottom="0px" bold textRight>
            <Money money={cost} />
          </Col>
        </Row>
      </Col>
    </EntryWrap>
  );
};
