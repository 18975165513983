import React from 'react';
import withSizes from 'react-sizes';
import deepEqual from 'deep-equal';
import InjectProps from './InjectProps';

const matchSize = (width, sizeConversions) => {
  for (let i = 0; i < sizeConversions.length - 1; i += 1) {
    const [maxWidth, propValue] = sizeConversions[i];
    if (width <= maxWidth) {
      return propValue;
    }
  }
  return sizeConversions[sizeConversions.length - 1];
};

const convertObjectProp = (propName, sizeConversions, prevFunc) => ({ width }) => {
  const prevProps = prevFunc({ width });
  return {
    ...prevProps,
    [propName]: matchSize(width, sizeConversions),
  };
};

const inc = { num: 0 };
const getElementDisplayName = () => `screen_size_detector_${inc.num++}`; //eslint-disable-line

export default class ScreenSizeDetector extends React.Component {
  constructor(props) {
    super(props);
    const { children, ...otherProps } = props;
    const propKeys = Object.keys(otherProps);
    const mapSizeToProps = propKeys.reduce((acc, key) => (
      convertObjectProp(key, otherProps[key], acc)
    ), () => ({ }));
    this.oldProps = otherProps;
    this.Element = withSizes(mapSizeToProps)(InjectProps);
    this.Element.displayName = getElementDisplayName();
  }
  componentWillUpdate() {
    const { children, ...props } = this.props;
    if (!deepEqual(props, this.oldProps)) {
      this.oldProps = props;
      const propKeys = Object.keys(props);
      const mapSizeToProps = propKeys.reduce((acc, key) => (
        convertObjectProp(key, props[key], acc)
      ), () => ({ }));
      this.Element = withSizes(mapSizeToProps)(InjectProps);
      this.Element.displayName = getElementDisplayName();
    }
  }
  render() {
    const {
      Element,
      props: {
        children,
      },
    } = this;
    return (
      <Element>
        {children}
      </Element>
    );
  }
}
