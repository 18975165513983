import React from 'react';
// import { Paragraph } from '../typography';

export default () => (
  <React.Fragment>
    {/*
    <Paragraph red bold>
      Udsolgt onsdag den 21. juni til og med mandag den 26. juni 2022
    </Paragraph>
    */}
  </React.Fragment>
);
