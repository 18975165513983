import datesReducer from './dates';
import {
  PREFERRED_DELIVERY_TYPE_SET,
  DELIVERY_TYPE_SET,
  RESET_DELIVERY_TYPE,
  SET_DELIVERY_INFO,
  setDeliveryInfo,
  SET_SPECIAL_DELIVERY_TYPE,
  SET_SPECIAL_DELIVERY_DATE,
  SET_SPECIAL_NO_DELIVERY_DATE,
} from './actions';
import { SWITCH_MODE } from '../../actions';
import {
  getPreferredDeliveryType,
  getDeliveryType,
  getLocalState,
  getDeliveryInfo,
  isDelivery,
  getDate,
  getTime,
  getMode,
  isRequired,
  getActualTime,
  validate,
  getDeliveryFees,
  hasDateAsPossibility,
  hasReturnDate,
  getDeliveryTypeErrorLocalState,
  getDeliveryTypeError,
  isInSpecialMode,
  getSpecialDeliveryType,
  getSpecialDeliveryDate,
  getSpecialNoDeliveryDate,
} from './selectors';
import { deliveryTypes } from './shared';
import rootDeliverySaga from './sagas';

export {
  getPreferredDeliveryType,
  getDeliveryType,
  getLocalState,
  getDeliveryInfo,
  isDelivery,
  getDate,
  getTime,
  getMode,
  isRequired,
  getActualTime,
  validate,
  getDeliveryFees,
  hasDateAsPossibility,
  hasReturnDate,
  getDeliveryTypeErrorLocalState,
  getDeliveryTypeError,
  isInSpecialMode,
  getSpecialDeliveryType,
  getSpecialDeliveryDate,
  getSpecialNoDeliveryDate,
};
export * from './actions';
export * from './shared';

export { rootDeliverySaga };

const initialState = {
  deliveryInfo: {
    specialMode: false,
    hasAnySodaOrFadoel: false,
    hasWater: false,
    hasLeje: false,
    freeDeliveryIndex: 0,
    amountOfDraughtmaster: 0,
    numberOfItems: 0,
  },
  specialNoDeliveryDate: null,
  specialDeliveryDate: null,
  specialDeliveryType: deliveryTypes.no,
  preferredType: deliveryTypes.delivery,
  type: null,
  dates: datesReducer(undefined, { type: '' }),
};
const runDatesReducer = (state, action) => {
  const newState = datesReducer(state.dates, action);
  if (newState !== state.dates) {
    return {
      ...state,
      dates: newState,
    };
  }
  return state;
};


const setDeliveryInfoTransition = (state, deliveryInfo) => {
  const newState = {
    ...state,
    deliveryInfo,
  };
  if (
    newState.type !== null
    && getDeliveryTypeErrorLocalState(newState.type)(newState)
  ) {
    if (newState.type === deliveryTypes.delivery) {
      return {
        ...newState,
        type: deliveryTypes.deliveryAndPickup,
      };
    }
    return {
      ...newState,
      type: deliveryTypes.delivery,
    };
  }
  return newState;
};

export default function deliveryReducer(state = initialState, action) {
  switch (action.type) {
    case PREFERRED_DELIVERY_TYPE_SET: {
      return {
        ...state,
        preferredType: action.payload,
      };
    }
    case SET_SPECIAL_DELIVERY_TYPE:
      return {
        ...state,
        specialDeliveryType: action.payload,
      };
    case SET_SPECIAL_NO_DELIVERY_DATE:
      return {
        ...state,
        specialNoDeliveryDate: action.payload,
      };
    case SET_SPECIAL_DELIVERY_DATE:
      return {
        ...state,
        specialDeliveryDate: action.payload,
      };
    case DELIVERY_TYPE_SET:
      return {
        ...state,
        type: action.payload,
      };
    case SWITCH_MODE: {
      if (action.payload !== state.deliveryInfo.specialMode) {
        const deliveryInfo = {
          ...state.deliveryInfo,
          specialMode: action.payload,
        };
        const newState = setDeliveryInfoTransition(state, deliveryInfo);
        return runDatesReducer(newState, setDeliveryInfo(deliveryInfo));
      }
      return state;
    }
    case SET_DELIVERY_INFO: {
      const newState = setDeliveryInfoTransition(state, action.payload);
      return runDatesReducer(newState, action);
    }
    case RESET_DELIVERY_TYPE:
      return initialState;
    default:
      return runDatesReducer(state, action);
  }
}
