import React from 'react';
import { Container } from 'react-grid-system';
import styled from 'styled-components/macro';

const ContainerNoProps = ({
  white, grey, green, blue, mb, mt, height, padding, borderTop, darkBlue, ...props
}) => <Container {...props} />;

export default styled(ContainerNoProps)`
  ${({
    theme,
    white,
    grey,
    darkBlue,
    green,
  }) => {
    if (white) {
      return `background-color: ${theme.colors.colorWhite};`;
    } if (grey) {
      return `background-color: ${theme.colors.colorGreyLight};`;
    } if (green) {
      return `background-color: ${theme.colors.colorGreenHighlight};`;
    } if (darkBlue) {
      return `background-color: ${theme.colors.colorBlue};`;
    }
    return '';
  }}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom} !important;`}
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom} !important;`}
  ${({ mb }) => mb && 'margin-bottom: 15px;'}
  ${({ mt }) => mt && 'margin-top: 15px;'}
  ${({ borderTop, theme }) => borderTop && `border-top: 3px solid ${theme.colors.colorGreyLight}; padding-top: 15px;`}
  ${({ height }) => {
    if (height) {
      return `height: ${height};`;
    }
    return '';
  }}
  ${({ padding }) => {
    if (padding) {
      return `padding: ${padding};`;
    }
    return '';
  }}
`;
