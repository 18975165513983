import { storeDateFormat } from './dates/shared';

const actionPrefix = 'SKAFTE/COMMERCE/DELIVERY/';

export const DELIVERY_TYPE_SET = `${actionPrefix}DELIVERY_TYPE_SET`;
export const setDeliveryType = deliveryType => ({
  type: DELIVERY_TYPE_SET,
  payload: deliveryType,
});
export const PREFERRED_DELIVERY_TYPE_SET = `${actionPrefix}PREFERRED_DELIVERY_TYPE_SET`;
export const setPreferredDeliveryType = deliveryType => ({
  type: PREFERRED_DELIVERY_TYPE_SET,
  payload: deliveryType,
});
export const RESET_DELIVERY_TYPE = `${actionPrefix}_RESET_DELIVERY_TYPE`;
export const resetDeliveryType = () => ({
  type: RESET_DELIVERY_TYPE,
});

export const SET_DELIVERY_INFO = `${actionPrefix}SET_DELIVERY_INFO`;
export const setDeliveryInfo = deliveryInfo => ({
  type: SET_DELIVERY_INFO,
  payload: deliveryInfo,
});

export const SET_SPECIAL_DELIVERY_TYPE = `${actionPrefix}SET_SPECIAL_DELIVERY_TYPE`;
export const setSpecialDeliveryType = deliveryType => ({
  type: SET_SPECIAL_DELIVERY_TYPE,
  payload: deliveryType,
});

export const SET_SPECIAL_DELIVERY_DATE = `${actionPrefix}SET_SPECIAL_DELIVERY_DATE`;
export const setSpecialDeliveryDate = deliveryDate => ({
  type: SET_SPECIAL_DELIVERY_DATE,
  payload: deliveryDate.format(storeDateFormat),
});

export const SET_SPECIAL_NO_DELIVERY_DATE = `${actionPrefix}SET_SPECIAL_NO_DELIVERY_DATE`;
export const setSpecialNoDeliveryDate = noDeliveryDate => ({
  type: SET_SPECIAL_NO_DELIVERY_DATE,
  payload: noDeliveryDate,
});
