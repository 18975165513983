import moment from 'moment';
import { delay } from 'redux-saga';
import {
  put,
  take,
  race,
  select,
  call,
} from 'redux-saga/effects';
import { SET_DATE, dateTypes, resetDate } from './dates/shared';
import { getDate } from './dates';
import { getDeliveryType } from './selectors';
import { STARTUP_COMPLETE } from '../../actions';

export default function* deliverySaga() {
  yield take(STARTUP_COMPLETE);
  while (true) {
    // Check for conditions
    const {
      date,
      // deliveryType,
    } = yield select((state) => {
      const deliveryType = getDeliveryType(state);
      const date = getDate(deliveryType)(dateTypes.delivery)(state);
      return {
        date,
        deliveryType,
      };
    });
    // Was the date yesterday
    if (date) {
      if (date.isBefore(moment(), 'day')) {
        // It was yesterday
        yield put(resetDate(dateTypes.delivery)());
        // eslint-disable-next-line no-continue
        continue;
      }
      const timeTillAfterDate = date.endOf('day').diff(moment());
      yield race([
        take(SET_DATE),
        call(delay, timeTillAfterDate + 5000),
      ]);
    } else {
      yield take(SET_DATE);
    }
    // Wait for next time a check is needed
  }
}
