import { createSelector } from 'reselect';

// eslint-disable-next-line no-unused-vars
const initialState = {
  count: 0,
  size: null,
  lid: null,
  flavour: null,
  id: null,
  waterDefinitions: {
    lids: [],
    flavours: [],
    sizes: [],
  },
};
export const getWaterState = state => state.commerce.water;
export const getWaterDelivery = state => getWaterState(state).waterDelivery;
export const getWaterDefinitions = state => getWaterState(state).waterDefinitions;
export const getFlavourTypes = state => getWaterDefinitions(state).flavours;
export const getLidTypes = state => getWaterDefinitions(state).lids;
export const getSizeTypes = state => getWaterDefinitions(state).sizes;

export const getCount = state => getWaterState(state).count;
export const getSize = state => getWaterState(state).size;
export const getFlavour = state => getWaterState(state).flavour;
export const getLid = state => getWaterState(state).lid;
export const getUploadId = state => getWaterState(state).id;


export const getEntriesFromKeys = ({
  waterDefinitions: {
    lids,
    flavours,
    sizes,
  },
  lid,
  size,
  flavour,
}) => ({
  lid: lids.find(({ name }) => name === lid),
  flavour: flavours.find(({ name }) => name === flavour),
  size: sizes.find(({ name }) => name === size),
});
export const getChosenEntries = createSelector(
  getWaterDefinitions,
  getSize,
  getFlavour,
  getLid,
  (waterDefinitions, size, flavour, lid) => getEntriesFromKeys({
    waterDefinitions,
    lid,
    size,
    flavour,
  }),
);

export const calculatePriceOfEntry = ({ lid, flavour, size }) => Math.round((lid.price + flavour.price + size.price) * 1.25);

export const calculatePantOfEntry = ({ size: { pant } }) => Math.round(pant * 1.25);
export const getSingleCost = createSelector(
  getChosenEntries,
  choosenEntries => calculatePriceOfEntry(choosenEntries),
);

export const getSingleCostWithPant = createSelector(
  getChosenEntries,
  chosenEntries => calculatePriceOfEntry(chosenEntries) + calculatePantOfEntry(chosenEntries),
);

export const getTotalCost = createSelector(
  getSingleCost,
  getChosenEntries,
  (cost, { size: { count } }) => count * cost,
);

export const getTotalCostWithPant = createSelector(
  getSingleCostWithPant,
  getChosenEntries,
  (cost, { size: { count } }) => count * cost,
);
export const getCost = createSelector(
  getTotalCost,
  getCount,
  (totalCost, count) => totalCost * count,
);

export const getCostWithPant = createSelector(
  getTotalCostWithPant,
  getCount,
  (totalCost, count) => totalCost * count,
);

export const getSingleCostFromEntries = createSelector(
  getWaterDefinitions,
  (state, props) => props,
  (waterDefinitions, {
    lid,
    size,
    flavour,
  }) => {
    const entries = getEntriesFromKeys({
      waterDefinitions,
      lid,
      size,
      flavour,
    });
    return calculatePriceOfEntry(entries);
  },
);
export const getTotalCostFromEntries = createSelector(
  getWaterDefinitions,
  (state, props) => props,
  (waterDefinitions, {
    lid,
    size,
    flavour,
  }) => {
    const entries = getEntriesFromKeys({
      waterDefinitions,
      lid,
      size,
      flavour,
    });
    const { size: { count } } = entries;
    return calculatePriceOfEntry(entries) * count;
  },
);

export const getCostFromEntries = createSelector(
  getWaterDefinitions,
  (state, props) => props,
  (waterDefinitions, {
    lid,
    size,
    flavour,
    amount,
  }) => {
    const entries = getEntriesFromKeys({
      waterDefinitions,
      lid,
      size,
      flavour,
    });
    const { size: { count } } = entries;
    return calculatePriceOfEntry(entries) * count * amount;
  },
);

export const getPantFromEntries = createSelector(
  getWaterDefinitions,
  (state, props) => props,
  (waterDefinitions, {
    lid,
    size,
    flavour,
    amount,
  }) => {
    const entries = getEntriesFromKeys({
      waterDefinitions,
      lid,
      size,
      flavour,
    });
    const { size: { count } } = entries;
    return calculatePantOfEntry(entries) * count * amount;
  },
);
