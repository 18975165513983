import styled from 'styled-components/macro';
import {
  marginTheming,
  paddingTheming,
  elementTheming,
  fontTheming,
  borderTheming,
  removePaddingThemingProps,
  removeMarginThemingProps,
  removeElementThemingProps,
  removeFontThemingProps,
  removeBorderThemingProps,
  removeProps,
  combine,
  createComponentWithModifiedProps,
} from '../../style/themes/main';

const removeCustomProps = removeProps('paddingTd', 'paddingTh', 'striped', 'lines');
const removeAllProps = combine(
  removePaddingThemingProps,
  removeMarginThemingProps,
  removeElementThemingProps,
  removeFontThemingProps,
  removeCustomProps,
  removeBorderThemingProps,
);

const TableNoProps = createComponentWithModifiedProps(removeAllProps, 'table', 'Skafte.com.tables.Table');

export default styled(TableNoProps)`
  border-spacing: 0px;
  border-collapse: collapse;
  font-size: ${({ fontSize }) => (fontSize || '14px')};
  width: ${({ tableWidth }) => (tableWidth || '100%')};
  ${({ striped1, striped2, theme }) => {
    if (striped1) {
      return `
      & tr:nth-child(odd) {
        background-color:  ${theme.colors.colorGreyLight};
      }
      & tr:nth-child(even) {
        background-color:  ${theme.colors.colorGreyLightAlt};
      }
      & td, & th {
        padding: 10px;
      }
      & th {
        background-color:  ${theme.colors.colorGreyLightAlt};
      }
      `;
    }
    if (striped2) {
      return `
      & tr:nth-child(odd) {
        background-color:  ${theme.colors.colorGreyLight};
      }
      & tr:nth-child(even) {
        background-color:  ${theme.colors.colorWhite};
      }
      & td, & th {
        padding: 10px;
      }
      & th {
        background-color:  ${theme.colors.colorGreyLightAlt};
      }
      `;
    }
    return '';
  }}
  ${({ lines1, lines2, theme }) => {
    if (lines1) {
      return `
      & tr {
        border-top: 1px solid ${theme.colors.colorGreyLightAlt};
        border-bottom: 1px solid ${theme.colors.colorGreyLightAlt};
      }
      & tr:first-child {
        border-top: 0;
      }
      & tr:hover {
        background-color:  ${theme.colors.colorGreyLight};
      }
      & td, & th {
        padding: 10px 5px;
      }
      `;
    }
    if (lines2) {
      return `
      & tr {
        border-top: 1px solid ${theme.colors.colorGreyLightAlt};
        border-bottom: 1px solid ${theme.colors.colorGreyLightAlt};
      }
      & tr:nth-child(even) {
        background-color:  ${theme.colors.colorOffWhite};
      }
      & tbody tr:nth-child(even) {
        background-color:  ${theme.colors.colorOffWhite};
      }
      & thead tr:nth-child(odd) {
        background-color:  ${theme.colors.colorOffWhite};
      }
      & td, & th {
        padding: 10px 5px;
      }
      `;
    }
    return '';
  }}
  ${({ paddingTd }) => {
    if (paddingTd) {
      return `
      & td, & th {
        padding: ${paddingTd};
      }`;
    }
    return '';
  }}
  ${({ paddingTh }) => {
    if (paddingTh) {
      return `
      & th {
        padding: ${paddingTh};
      }`;
    }
    return '';
  }}
  ${marginTheming}
  ${paddingTheming}
  ${elementTheming}
  ${fontTheming}
  ${borderTheming}
`;
