import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components/macro";
import { Div } from "../../../../components/grid";
import { screenSizeSwitch } from "../../../../lib/helpers";
import { useSideMenuContext } from "../../../../SideMenuContext";
import MenuItem, { MenuSubItemLink } from "./MenuItem";
import { ActionLink } from "../../../../components/typography";
import { BsChevronLeft } from "../../../../components/Icons";

const Container = styled.ul`
  position: absolute;
  display: block;
  list-style-type: none;
  transition: left 0.5s, right 0.5s;
  &.show {
    left: 0;
    right: 0;
  }
  &.hide {
    right: 100%;
    left: -100%;
  }
  @media (max-width: 991px) {
    padding-bottom: 15px;
  }
`;
const BigContainer = styled.ul`
  position: relative;
  display: block;
  list-style-type: none;
`;
const ActualContainer = screenSizeSwitch([[991, Container], BigContainer]);
const Navigation = styled(Div)`
  display: block;
  position: relative;
  @media only screen and (max-width: 991px) {
    position: relative;
    top: auto;
    right: auto;
    padding-top: 0px;
    padding-bottom: 0px;
    transition: left 0.5s, right 0.5s;
  }
  @media only screen and (max-height: 420px) {
    padding-top: 0px;
  }
`;

const SideMenuContainer = styled(Div)`
  position: absolute;
  transition: left 0.5s, right 0.5s;
  &.show {
    left: 0;
    right: 0;
  }
  &.hide {
    right: -100%;
    left: 100%;
  }

  & a {
    display: block;
    position: relative;
    wdith: 100%;
    padding: 16px 14px 14px 14px;
    text-align: left;
    border-bottom: 1px solid ${({ theme }) => theme.colors.colorGreyLightAlt};
    color: ${({ theme }) => theme.colors.colorGreyDark};
    text-decoration: none;
    font-size: 16px;
    text-align: center;
  }
  @media (max-width: 991px) {
    padding-bottom: 15px;
  }
`;

const StyledIcon = styled.div`
  fill: ${({ theme }) => theme.colors.colorGreyDark};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  display: block;
  font-size: 20px;
  margin-top: 1px;
`;
const MenuSpacer = styled(Div)`
  height: 2px;
  background: ${({ theme }) => theme.colors.colorGreyLightAlt};
  width: calc(100% - 20px);
  position: relative;
  margin: 5px 10px;

  @media (max-width: 991px) {
    width: 100%;
    height: 5px;
    margin: 0;
  }
`;
const BigStructure = ({ children }) => (
  <ActualContainer className="show">{children}</ActualContainer>
);
const SmallStructure = ({
  children,
  activeSideMenu,
  setActiveSideMenu,
  portalRef,
}) => (
  <React.Fragment>
    <ActualContainer className={activeSideMenu === null ? "show" : "hide"}>
      {children}
    </ActualContainer>
    <SideMenuContainer className={activeSideMenu ? "show" : "hide"}>
      <ActionLink onClick={() => setActiveSideMenu(null)}>
        <StyledIcon>
          <BsChevronLeft />
        </StyledIcon>{" "}
        Hovedmenu
      </ActionLink>
      <ul ref={portalRef} />
    </SideMenuContainer>
  </React.Fragment>
);

const Structure = screenSizeSwitch([[991, SmallStructure], BigStructure]);

export default () => {
  const portalRef = useRef();
  const [sideMenuContext] = useSideMenuContext();
  const [[activeSideMenu, lastSideMenu], setActiveSideMenu] = useState([
    sideMenuContext,
    null,
  ]);
  const setSideMenu = (newSideMenu) => {
    if (newSideMenu === null) {
      setActiveSideMenu([newSideMenu, activeSideMenu]);
    } else {
      setActiveSideMenu([newSideMenu, null]);
    }
  };
  const [
    [currentPortalRef, currentPortalRefNumber],
    setCurrentPortalRef,
  ] = useState([null, 0]);
  useEffect(() => {
    if (portalRef.current !== currentPortalRef) {
      setCurrentPortalRef([portalRef.current, currentPortalRefNumber + 1]);
    }
  });
  const sharedProps = {
    lastSideMenu,
    activeSideMenu,
    setActiveSideMenu: setSideMenu,
    portalRef,
  };
  return (
    <Navigation>
      <Structure {...sharedProps}>
        <MenuItem
          {...sharedProps}
          name="NemBestilling"
          title="Bestil her"
          to="/bestil/flasker-og-daaser/kildevand"
        />
        <MenuItem {...sharedProps} name="Fadoel" title="Fadøl">
          <MenuSubItemLink to="/fadoel" title="Fadøl" />
          <MenuSubItemLink to="/fadoel/pilsner" title="Pilsner" />
          <MenuSubItemLink to="/fadoel/classic" title="Classic" />
          <MenuSubItemLink to="/fadoel/hvedeoel" title="Hvedeøl" />
          <MenuSubItemLink to="/fadoel/ipa" title="IPA" />
          <MenuSubItemLink to="/fadoel/ale" title="Ale" />
          <MenuSubItemLink to="/fadoel/porter" title="Porter / Stout" />
          <MenuSubItemLink to="/fadoel/letoel" title="Alkoholfri & Letøl" />
          <MenuSubItemLink
            to="/fadoel/cocktails-vin-cider"
            title="Cocktails, vin &amp; cider"
          />
          <MenuSpacer />
          <MenuSubItemLink to="/fadoel/priser" title="Fadølspriser" />
          <MenuSubItemLink to="/fadoel/billigt-fadoel" title="Billigt Fadøl" />
          <MenuSubItemLink to="/fadoel/oktoberfest" title="Oktoberfest øl" />
          <MenuSubItemLink to="/fadoel/juleoel" title="Juleøl" />
          <MenuSubItemLink to="/fadoel/paaskebryg" title="Påskebryg" />
          <MenuSubItemLink
            to="/fadoel/dhl-fadoel"
            title="Fadøl til DHL Stafetten"
          />
          <MenuSubItemLink to="/fadoel/dansk-fadoel" title="Dansk fadøl" />
          <MenuSubItemLink
            to="/fadoel/udenlandske-oel-paa-fad"
            title="Udenlandske fadøl"
          />
          <MenuSubItemLink to="/fadoel/modular-20" title="Modular 20" />
          <MenuSubItemLink to="/fadoel/flex-20" title="Flex 20" />
        </MenuItem>
        <MenuItem {...sharedProps} name="Anlaeg" title="Anlæg - Tilbehør">
          <MenuSubItemLink to="/fadoelsanlaeg" title="Fadølsanlæg" />
          <MenuSubItemLink to="/fadoelsbar" title="Fadølsbar" />
          <MenuSubItemLink to="/oelglas" title="Ølglas" />
          <MenuSubItemLink to="/isterninger" title="Isterninger" />
          <MenuSubItemLink to="/reservedele" title="Reservedele" />
          <MenuSpacer />
          <MenuSubItemLink
            to="/fadoelsanlaeg/opsaetning-af-fadoelsanlaeg"
            title="Opsætning af fadølsanlæg"
          />
          <MenuSubItemLink
            to="/fadoelsanlaeg/tilslutning-af-fadoelsanlaeg"
            title="Tilslutning af fadølsanlæg, fadøl og kulsyre"
          />
          <MenuSubItemLink
            to="/fadoelsanlaeg/vandkoelet-fadoelsanlaeg"
            title="Vandkølet fadølsanlæg"
          />
          <MenuSubItemLink
            to="/fadoelsanlaeg/saadan-tapper-du-den-perfekte-fadoel"
            title="Sådan tapper du den perfekte fadøl"
          />
        </MenuItem>
        <MenuItem {...sharedProps} name="Kulsyre" title="Kulsyre - CO2">
          <MenuSubItemLink to="/kulsyre" title="Kulsyre" />
          <MenuSubItemLink
            to="/kulsyre/kulsyre-til-fadoel"
            title="Kulsyre til Fadøl"
          />
          <MenuSubItemLink
            to="/kulsyre/sodastream"
            title="Kulsyre til SodaStream"
          />
          <MenuSubItemLink
            to="/kulsyre/danskvandsanlaeg"
            title="Kulsyre til Danskvandsanlæg"
          />
          <MenuSubItemLink
            to="/kulsyre/opfyldning-af-kulsyreflasker"
            title="Opfyldning af kulsyreflasker"
          />
          <MenuSubItemLink
            to="/kulsyre/kulsyrebatterier"
            title="Kulsyrebatterier"
          />
          <MenuSubItemLink
            to="/kulsyre/co2-akvarie"
            title="Kulsyre til Akvarier"
          />
          <MenuSubItemLink
            to="/kulsyre/drikkevandskoeler"
            title="Kulsyre til Drikkevandskølere"
          />
          <MenuSubItemLink
            to="/kulsyre/co2-svejsning"
            title="Kulsyre til CO2 Svejsning"
          />
        </MenuItem>
        <MenuItem
          {...sharedProps}
          name="Leverandoer"
          title="Vand, Sodavand & Alkohol"
          bigScreens
        >
          <MenuSubItemLink
            to="/leverandoer-sodavand-kildevand-oel"
            title="Leverandør af sodavand, kildevand &amp; øl"
          />
          <MenuSpacer />
          <MenuSubItemLink to="/sodavand" title="Sodavand" />
          <MenuSubItemLink to="/kildevand" title="Kildevand" />
          <MenuSubItemLink to="/danskvand" title="Danskvand" />
          <MenuSubItemLink to="/antons-saft" title="Antons Øko saft" />
          <MenuSubItemLink to="/cocio" title="Cocio" />
          <MenuSubItemLink to="/red-bull" title="Red Bull" />
          <MenuSubItemLink to="/soebogaard" title="Søbogaard" />
          <MenuSubItemLink to="/ice-tea" title="Ice Tea" />
          <MenuSubItemLink to="/juice" title="Juice" />
          <MenuSpacer />
          <MenuSubItemLink to="/oel" title="Køb øl online" />
          <MenuSubItemLink to="/oel/daaseoel" title="Dåseøl" />
          <MenuSubItemLink to="/oel/flaskeoel" title="Flaskeøl" />
          <MenuSubItemLink to="/oel/alkoholfri-oel" title="Alkoholfri Øl" />
          <MenuSubItemLink to="/vin" title="Vin" />
          <MenuSubItemLink to="/alkoholsodavand" title="Alkoholsodavand" />
        </MenuItem>
        <MenuItem
          {...sharedProps}
          name="Sodavand"
          title="Sodavand"
          smallScreens
        >
          <MenuSubItemLink
            to="/leverandoer-sodavand-kildevand-oel"
            title="Din leverandør af sodavand, kildevand & øl"
          />
          <MenuSpacer />
          <MenuSubItemLink title="Sodavand" to="/sodavand" />
          <MenuSubItemLink title="Coca-Cola" to="/sodavand/coca-cola" />
          <MenuSubItemLink title="Frem Sodavand" to="/sodavand/frem-sodavand" />
          <MenuSubItemLink
            title="San Pellegrino Sodavand"
            to="/sodavand/san-pellegrino-sodavand"
          />
          <MenuSubItemLink title="Schweppes" to="/sodavand/schweppes" />
          <MenuSubItemLink title="Faxe Kondi" to="/sodavand/faxe-kondi" />
          <MenuSubItemLink title="Tuborg Squash" to="/sodavand/tuborg-squash" />
          <MenuSubItemLink title="Kinley" to="/sodavand/kinley" />
          <MenuSubItemLink title="Pepsi" to="/sodavand/pepsi-cola" />
          <MenuSubItemLink title="Danskvand" to="/danskvand" />
        </MenuItem>
        <MenuItem
          {...sharedProps}
          name="Kildevand"
          title="Kildevand"
          smallScreens
        >
          <MenuSubItemLink
            to="/leverandoer-sodavand-kildevand-oel"
            title="Din leverandør af sodavand, kildevand & øl"
          />
          <MenuSpacer />
          <MenuSubItemLink title="Kildevand" to="/kildevand" />
          <MenuSubItemLink title="Aqua d'or" to="/kildevand/aquador" />
          <MenuSubItemLink title="Denice Vand" to="/kildevand/denice-vand" />
          <MenuSubItemLink
            title="Kildevand i paller"
            to="/kildevand/vand-paller"
          />
          <MenuSubItemLink
            title="Vand med Logo"
            to="/kildevand/vand-med-logo"
          />
          <MenuSubItemLink
            title="San Pellegrino Vand"
            to="/kildevand/san-pellegrino-vand"
          />
          <MenuSubItemLink
            title="Vand uden pant"
            to="/kildevand/vand-uden-pant"
          />
          <MenuSubItemLink title="Vitamin Well" to="/kildevand/vitamin-well" />
        </MenuItem>
        <MenuItem
          {...sharedProps}
          name="Alkohol"
          title="Øl, Cider &amp; Alkohol"
          smallScreens
        >
          <MenuSubItemLink
            to="/leverandoer-sodavand-kildevand-oel"
            title="Din leverandør af sodavand, kildevand & øl"
          />
          <MenuSpacer />
          <MenuSubItemLink to="/oel" title="Køb øl online" />
          <MenuSubItemLink to="/oel/daaseoel" title="Dåseøl" />
          <MenuSubItemLink to="/oel/flaskeoel" title="Flaskeøl" />
          <MenuSubItemLink to="/oel/alkoholfri-oel" title="Alkoholfri Øl" />
          <MenuSubItemLink to="/vin" title="Vin" />
          <MenuSubItemLink to="/alkoholsodavand" title="Alkoholsodavand" />
        </MenuItem>
        <MenuItem
          {...sharedProps}
          name="VandSodavand"
          title="Andre drikkevarer"
          smallScreens
        >
          <MenuSubItemLink
            to="/leverandoer-sodavand-kildevand-oel"
            title="Din leverandør af sodavand, kildevand & øl"
          />
          <MenuSpacer />
          <MenuSubItemLink to="/cocio" title="Cocio" />
          <MenuSubItemLink to="/red-bull" title="Red Bull" />
          <MenuSubItemLink to="/soebogaard" title="Søbogaard" />
          <MenuSubItemLink to="/ice-tea" title="Ice Tea" />
          <MenuSubItemLink to="/juice" title="Juice" />
        </MenuItem>
        <MenuItem {...sharedProps} name="Infosider" title="Infosider">
          <MenuSubItemLink
            to="/levering-afhentning"
            title="Levering &amp; afhentning"
          />
          <MenuSubItemLink
            to="/levering-afhentning/afhentning"
            title="Vejledning til afhentning"
          />
          <MenuSubItemLink
            to="/levering-afhentning/levering/fadoel"
            title="Levering af fadøl"
          />
          <MenuSubItemLink
            to="/levering-afhentning/vilkaar-fadoel"
            title="Vilkår for fadøl &amp; fadølsanlæg"
          />
          <MenuSpacer />
          <MenuSubItemLink
            to="/holdbarhed-paa-fadoel"
            title="Holdbarhed på fadøl"
          />
          <MenuSubItemLink
            to="/forbrug-af-fadoel"
            title="Det drak andre kunder"
          />
          <MenuSubItemLink
            to="/fustager-forklaring-vejledning"
            title="Teknisk forklaring om fustager"
          />
          <MenuSubItemLink to="/videoer" title="Videoer" />
          <MenuSpacer />
          <MenuSubItemLink
            to="/pant-og-returregler"
            title="Pant og returregler"
          />
          <MenuSubItemLink
            to="/holdbarhed-paa-flasker-daaser"
            title="Holdbarhed på flasker og dåser"
          />
          <MenuSubItemLink
            to="/hjaelpemidler"
            title="Hjælpemidler til kasser"
          />
          <MenuSubItemLink to="/oekologiprocent" title="Økologiprocent" />
          <MenuSpacer />
          <MenuSubItemLink to="/betaling" title="Betal Faktura" />
          <MenuSubItemLink to="/faq/faq" title="FAQ" />
        </MenuItem>
        <MenuItem {...sharedProps} name="OmOs" title="Om os" smallScreens>
          <MenuSubItemLink to="/kontakt-os" title="Kontakt" />
          <MenuSubItemLink
            to="/om-peter-skafte-aps"
            title="Om Peter Skafte ApS"
          />
          <MenuSubItemLink to="/historie" title="Historie" />
        </MenuItem>
      </Structure>
    </Navigation>
  );
};
