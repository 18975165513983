import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components/macro';

const customStyles = {
  content: {
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
};
const ModalNoProps = ({ ...props }) => (
  <Modal style={customStyles} {...props} />
);

export default styled(ModalNoProps)`
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: ${({ theme }) => theme.colors.colorWhite};
  position: fixed;
  padding: 20px;
  outline: 0;
  border: 2px solid ${({ theme }) => theme.colors.colorGreyLightAlt};
  max-height: 100%;
  ${({ overflow }) => {
    if (overflow) {
      return 'overflow: visible;';
    }
    return 'overflow-y: scroll;';
  }}
`;
