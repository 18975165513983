import React from 'react';
import styled from 'styled-components/macro';
import ItemAnimationContext from './ItemAnimationContext';

const timing = 1000;
const Container = styled.div`
  position: relative;
`;

const ShadowDiv = styled(({
  x,
  y,
  width,
  height,
  ...props
}) => <div {...props} />)`
  position: absolute;
  left: ${({ x }) => x}px;
  top: ${({ y }) => y}px;
  ${({ height }) => {
    if (height) {
      return `height: ${height}px;`;
    }
    return '';
  }}
  ${({ width }) => {
    if (width) {
      return `width: ${width}px;`;
    }
    return '';
  }}
  pointer-events: none;
  transition: left ${timing}ms, top ${timing}ms;
  z-index: 1000;
  opacity: 0.5;
  & .slick-next, & .slick-prev, & .slick-dots {
    display: none !important;
  }
`;

export default class ItemAnimationProvider extends React.Component {
  constructor(props) {
    super(props);
    this.onAnimation = this.onAnimation.bind(this);
    this.otherRef = React.createRef();
    this.state = {
      animation: 0,
      itemProps: {},
      x: 0,
      y: 0,
    };
  }

  onAnimation(itemRef, inputRef) {
    if (itemRef.current && inputRef.current) {
      const subRef = itemRef.current.querySelector('.bucketAnimation');
      if (subRef) {
        const subBucketHit = document.querySelector('#subBucketHit'); // eslint-disable-line
        const mainBucketMenu = document.querySelector('#mainBucketMenu'); // eslint-disable-line
        if ((subBucketHit && subBucketHit.getBoundingClientRect) || (mainBucketMenu && mainBucketMenu.getBoundingClientRect)) {
          const bucketPos = (subBucketHit && subBucketHit.getBoundingClientRect) ? subBucketHit.getBoundingClientRect() : mainBucketMenu.getBoundingClientRect(); // eslint-disable-line
          const ownPos = subRef.getBoundingClientRect();
          const inputPos = inputRef.current.getBoundingClientRect();
          const docEl = document.documentElement; // eslint-disable-line
          const rectTop = inputPos.top + window.pageYOffset - docEl.clientTop + inputPos.height / 2 - ownPos.height / 2; // eslint-disable-line
          const rectLeft = inputPos.left + window.pageXOffset - docEl.clientLeft + inputPos.width / 2 - ownPos.width / 2; // eslint-disable-line
          const endRectTop = bucketPos.top + window.pageYOffset - docEl.clientTop + bucketPos.height / 2 - ownPos.height / 2; // eslint-disable-line
          const endRectLeft = bucketPos.left + window.pageXOffset - docEl.clientLeft + bucketPos.width / 2 - ownPos.width / 2; // eslint-disable-line
          const shouldMaintainWidth = document.querySelector('#root'); // eslint-disable-line
          const bounding = shouldMaintainWidth.getBoundingClientRect();
          shouldMaintainWidth.style.width = `${bounding.width} px`;
          shouldMaintainWidth.style.overflowX = 'hidden';

          this.setState({
            animation: 1,
            y: rectTop,
            x: rectLeft,
            subRef,
            itemProps: {
              width: subRef.clientWidth,
              height: subRef.clientHeight,
            },
          });
          setTimeout(() => {
            this.setState({ animation: 2, y: endRectTop, x: endRectLeft });
            setTimeout(() => {
              this.setState({
                animation: 0,
                itemProps: {},
                x: 0,
                y: 0,
              });
              shouldMaintainWidth.style.width = null;
              shouldMaintainWidth.style.overflowX = null;
            }, timing);
          });
        }
      }
    }
  }

  render() {
    const {
      children,
    } = this.props;
    const {
      animation,
      x,
      y,
      subRef,
      itemProps,
    } = this.state;
    return (
      <ItemAnimationContext.Provider value={this.onAnimation}>
        <>
          {Boolean(animation) && (
            <Container>
              <ShadowDiv x={x} y={y} dangerouslySetInnerHTML={{ __html: subRef ? subRef.outerHTML : '' }} {...itemProps} />
            </Container>
          )}
          {children}
        </>
      </ItemAnimationContext.Provider>
    );
  }
}
