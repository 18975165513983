import {
  GENERAL_SEARCH_CHANGE,
  GENERAL_SEARCH_RESULTS_DONE,
  GENERAL_SEARCH_CLEAR,
  SEARCH_CHANGE,
  SEARCH_RESULTS_CHANGE,
  SEARCH_RESULTS_DONE,
  SEARCH_CLEAR,
  SET_INDEX,
  SET_SHOW_SEARCH_OVERLAY,
} from './actions';
import searchSaga from './sagas';

export * from './actions';
export * from './selectors';

export { searchSaga as rootSearchSaga };

const initialState = {
  showSearchOverlay: false,
  lastShowSearchChange: 0,
  isSearching: false,
  isBuildingSearchResults: false,
  term: '',
  results: {
    amount: 0,
    categories: [],
    itemsInCategories: {},
    uncategorized: [
      [], [], [],
    ],
    expiredItems: [],
  },
  generalResults: {
    term: '',
    searching: false,
    results: [],
  },
  hasIndex: false,
  index: null,
};

export function getInitialState() {
  return initialState;
}
export default function searchReducer(state = {}, action) {
  switch (action.type) {
    case SET_SHOW_SEARCH_OVERLAY:
      if (new Date().getTime() - state.lastShowSearchChange < 300) {
        return state;
      }
      return {
        ...state,
        showSearchOverlay: action.payload,
        lastShowSearchChange: new Date().getTime(),
      };
    case GENERAL_SEARCH_CHANGE:
      return {
        ...state,
        generalResults: {
          ...state.generalResults,
          term: action.payload,
          searching: action.payload.trim() !== '',
        },
      };
    case GENERAL_SEARCH_RESULTS_DONE:
      return {
        ...state,
        generalResults: {
          ...state.generalResults,
          searching: false,
          results: action.payload,
        },
      };
    case GENERAL_SEARCH_CLEAR:
      return {
        ...state,
        generalResults: initialState.generalResults,
      };
    case SEARCH_CHANGE:
      return {
        ...state,
        results: initialState.results,
        term: action.payload,
        isSearching: action.payload.trim() !== '',
        isBuildingSearchResults: action.payload.trim() !== '',
      };
    case SEARCH_RESULTS_CHANGE:
      return { ...state, results: action.payload };
    case SEARCH_RESULTS_DONE:
      return { ...state, results: action.payload, isBuildingSearchResults: false };
    case SEARCH_CLEAR:
      return {
        ...state,
        ...initialState,
      };
    case SET_INDEX:
      return {
        ...state,
        hasIndex: true,
        index: action.payload,
      };
    default:
      return state;
  }
}
