import {
  race,
  put,
  call,
  take,
  takeLatest,
  select,
  all,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { categories } from '../../../content/commerce/itemCategories';
import { getItems, itemIsExpired, itemIsOutOfStock } from '../items';
import { itemReverseLookup } from '../../../content/commerce/itemLookup';
import getDefaultItemInformation from '../../../content/commerce/items';
import {
  GENERAL_SEARCH_CHANGE,
  SEARCH_CHANGE,
  doneWithSearchResults,
  SEARCH_CLEAR,
  setIndex,
  GENERAL_SEARCH_CLEAR,
  generalSearchResultsDone,
} from './actions';
import { getSearchState, hasIndex, getGeneralSearchState } from './selectors';
import { STARTUP_COMPLETE } from '../../actions';
import { ignoreWords } from '../../../content/search';
import { resultTypes } from './shared';
import getElementText from './getElementText';

const findAllIndexes = (t, s) => {
  const currentResults = [];
  for (let start = 0; start < s.length;) {
    const ss = s.substring(start);
    const nextIndex = ss.indexOf(t);
    if (nextIndex === -1) {
      break;
    }
    const completeNextIndex = nextIndex + start + 1;
    currentResults.push(nextIndex + start);
    start = completeNextIndex;
  }
  return currentResults;
};

const findIntersection = (a, b) => a.filter(i => b.includes(i));

const findTermSets = (allTerms) => {
  const termSets = [];
  for (let i = 0; i < allTerms.length; i += 1) {
    const innerTermsSet = [];
    for (let j = 0; j < allTerms.length - i; j += 1) {
      const currentTerms = [];
      for (let k = j; k <= j + i; k += 1) {
        currentTerms.push(allTerms[k]);
      }
      innerTermsSet.push(currentTerms.join(' '));
    }
    termSets.push(innerTermsSet);
  }
  return termSets;
};

async function searchItemsPrioritized(
  allTerms,
  itemIndex,
  allItems,
  currentLookupIndex = 0,
) {
  const {
    lookup,
    locations,
  } = itemIndex;
  if (currentLookupIndex >= lookup.length) {
    return allTerms.map(
      () => [],
    );
  }
  const hittinItems = {};
  const itemPriority = {};
  const allResults = [];
  for (let j = 0; j < allTerms.length; j += 1) {
    const t = allTerms[j];
    const allIndexes = findAllIndexes(t, lookup[currentLookupIndex]);
    const currentLocation = locations[currentLookupIndex];
    let currentIndexIterator = 0;
    const results = [];
    for (let i = 0; i < allIndexes.length; i += 1) {
      const currentIndex = allIndexes[i];
      while (currentIndex > (currentLocation[currentIndexIterator][1] + currentLocation[currentIndexIterator][2]) && currentIndexIterator < currentLocation.length - 1) {
        currentIndexIterator += 1;
      }
      const [varenr, start, length] = currentLocation[currentIndexIterator];
      const defaultItemInformation = getDefaultItemInformation(varenr);
      if (currentIndex >= start && currentIndex < start + length && (
        allItems[varenr] || (defaultItemInformation && defaultItemInformation.expiredMessage)
      )) {
        results.push(varenr);
      }
      while (allIndexes.length > i + 1 && allIndexes[i + 1] < start + length) {
        i += 1;
      }
      await new Promise(resolve => setTimeout(() => { resolve(); }, 0)); // eslint-disable-line
    }
    allResults.push(results);
  }
  const [firstResults, ...restResults] = allResults;
  let intersectingResults = firstResults;
  if (restResults.length > 0) {
    intersectingResults = restResults.reduce(
      (is, r) => findIntersection(is, r),
      intersectingResults,
    );
  }
  intersectingResults.forEach(
    (varenr) => {
      hittinItems[varenr] = true;
      itemPriority[varenr] = 0;
    },
  );
  const reallyAllResults = [intersectingResults];
  const termResults = [[]];
  const termSets = findTermSets(allTerms);
  for (let k = 1; k < termSets.length; k += 1) {
    const terms = termSets[k];
    const currentResults = [];
    termResults.push([]);
    for (let j = 0; j < terms.length; j += 1) {
      const t = terms[j];
      const allIndexes = findAllIndexes(t, lookup[currentLookupIndex]);
      const currentLocation = locations[currentLookupIndex];
      let currentIndexIterator = 0;
      for (let i = 0; i < allIndexes.length; i += 1) {
        const currentIndex = allIndexes[i];
        while (currentIndex > (currentLocation[currentIndexIterator][1] + currentLocation[currentIndexIterator][2]) && currentIndexIterator < currentLocation.length - 1) {
          currentIndexIterator += 1;
        }
        const [varenr, start, length] = currentLocation[currentIndexIterator];
        const defaultItemInformation = getDefaultItemInformation(varenr);
        if (currentIndex >= start && currentIndex < start + length && (
          allItems[varenr] || (defaultItemInformation && defaultItemInformation.expiredMessage)
        ) && hittinItems[varenr]) {
          itemPriority[varenr] = k;
          currentResults.push(varenr);
        }
        while (allIndexes.length > i + 1 && allIndexes[i + 1] < start + length) {
          i += 1;
        }
        await new Promise(resolve => setTimeout(() => { resolve(); }, 0)); // eslint-disable-line
      }
    }
    reallyAllResults.push(currentResults);
  }
  // Find equal results
  await new Promise(resolve => setTimeout(() => { resolve(); }, 0));
  const extraResults = await searchItemsPrioritized(allTerms, itemIndex, allItems, currentLookupIndex + 1);
  Object.keys(itemPriority).forEach(
    (varenr) => {
      const priority = itemPriority[varenr];
      termResults[priority].push(varenr);
    },
    [],
  );
  return termResults.reduce(
    (all, rs, idx) => [
      ...all,
      [
        ...rs,
        ...extraResults[idx],
      ],
    ],
    [],
  );
}

async function searchItems(
  allTerms,
  itemIndex,
  allItems,
  currentLookupIndex = 0,
) {
  const {
    lookup,
    locations,
  } = itemIndex;
  if (currentLookupIndex >= lookup.length) {
    return [];
  }
  const allResults = [];
  for (let j = 0; j < allTerms.length; j += 1) {
    const t = allTerms[j];
    const allIndexes = findAllIndexes(t, lookup[currentLookupIndex]);
    const currentLocation = locations[currentLookupIndex];
    let currentIndexIterator = 0;
    const results = [];
    for (let i = 0; i < allIndexes.length; i += 1) {
      const currentIndex = allIndexes[i];
      while (currentIndex > (currentLocation[currentIndexIterator][1] + currentLocation[currentIndexIterator][2]) && currentIndexIterator < currentLocation.length - 1) {
        currentIndexIterator += 1;
      }
      const [varenr, start, length] = currentLocation[currentIndexIterator];
      const defaultItemInformation = getDefaultItemInformation(varenr);
      if (currentIndex >= start && currentIndex < start + length && (
        allItems[varenr] || (defaultItemInformation && defaultItemInformation.expiredMessage)
      )) {
        results.push(varenr);
      }
      while (allIndexes.length > i + 1 && allIndexes[i + 1] < start + length) {
        i += 1;
      }
      await new Promise(resolve => setTimeout(() => { resolve(); }, 0)); // eslint-disable-line
    }
    allResults.push(results);
  }
  const [firstResults, ...restResults] = allResults;
  let intersectingResults = firstResults;
  if (restResults.length > 0) {
    intersectingResults = restResults.reduce(
      (is, r) => findIntersection(is, r),
      intersectingResults,
    );
  }
  // Find equal results
  await new Promise(resolve => setTimeout(() => { resolve(); }, 0));
  return [
    ...intersectingResults,
    ...(await searchItems(allTerms, itemIndex, allItems, currentLookupIndex + 1)),
  ];
}

const conciseTerm = (searchTerm) => {
  let replaced = (searchTerm || '').replace(/\s/g, ' ');
  while (replaced.indexOf('  ') !== -1) {
    replaced = replaced.replace(/\s\s/g, ' ');
  }
  return replaced.toLowerCase();
};

function validateSignificance(text, terms, keywords = [], searchSignificance = 0, generalSearchPriority = 0) {
  const conciseText = conciseTerm(text);
  const textParts = conciseText.split(' ');
  let significance = 0;
  for (let i = 0; i < terms.length; i += 1) {
    let biggestSignificance = 0;
    for (let j = 0; j < textParts.length; j += 1) {
      if (textParts[j] === terms[i]) {
        biggestSignificance = 2;
      } else if (biggestSignificance === 0 && textParts[j].indexOf(terms[i]) !== -1) {
        biggestSignificance = 1;
      }
    }
    significance += biggestSignificance;
  }
  if (terms.every(p => keywords.includes(p))) {
    return significance + 1000 + searchSignificance + generalSearchPriority;
  }
  return significance + generalSearchPriority;
}
function insertIntoPriorityArray(result, priorityArray, significance) {
  while (!priorityArray[significance]) {
    // eslint-disable-next-line no-param-reassign
    priorityArray[significance] = [];
  }
  priorityArray[significance].push(result);
}

function prioritizeItems(searchResults, searchTerm, state) {
  // eslint-disable-next-line no-use-before-define
  const conciseSearchTerm = conciseTerm(searchTerm);
  const searchTerms = conciseSearchTerm.split(' ');
  const priorityArray = {};
  const shouldBeInBottom = (state, obj, dii) => itemIsOutOfStock(state, obj) || dii.expiredMessage || itemIsExpired(state, obj);
  for (let i = 0; i < searchResults.length; i += 1) {
    const searchResult = searchResults[i];
    const [type, obj] = searchResult;
    /* if (type === resultTypes.ROUTE) {
      const [r, { title }] = obj;
      const significance = validateSignificance(getElementText(title), searchTerms);
      insertIntoPriorityArray(searchResult, priorityArray, significance);
    } else */
    if (type === resultTypes.ITEM) {
      const defaultItemInformation = getDefaultItemInformation(obj);
      if (
        defaultItemInformation
        && !shouldBeInBottom(state, obj, defaultItemInformation)

      ) {
        const name = (defaultItemInformation ? defaultItemInformation.name : '') || '';
        const keywords = (defaultItemInformation && defaultItemInformation.keywords ? defaultItemInformation.keywords.toLowerCase().split(' ') : []);
        const searchSignificance = ((defaultItemInformation && defaultItemInformation.searchSignificance) || 0);
        const generalSearchPriority = ((defaultItemInformation && defaultItemInformation.generalSearchPriority) || 0);
        const significance = validateSignificance(getElementText(getElementText(name)), searchTerms, keywords, searchSignificance, generalSearchPriority);
        insertIntoPriorityArray(searchResult, priorityArray, significance);
      }
    }
  }
  for (let i = 0; i < searchResults.length; i += 1) {
    const searchResult = searchResults[i];
    const [type] = searchResult;
    if (type === resultTypes.ROUTE) {
      insertIntoPriorityArray(searchResult, priorityArray, 0);
    }
  }
  for (let i = 0; i < searchResults.length; i += 1) {
    const searchResult = searchResults[i];
    const [type, obj] = searchResult;
    if (type === resultTypes.ITEM) {
      const defaultItemInformation = getDefaultItemInformation(obj);
      if (defaultItemInformation
        && shouldBeInBottom(state, obj, defaultItemInformation)) {
        insertIntoPriorityArray(searchResult, priorityArray, -100000);
      }
    }
  }
  const priorityArrayKeys = Object.keys(priorityArray).map(i => parseInt(i, 10));
  priorityArrayKeys.sort((a, b) => b - a);
  const flattenedResults = [];
  for (let i = 0; i < priorityArrayKeys.length; i += 1) {
    const paKey = priorityArrayKeys[i];
    for (let j = 0; j < priorityArray[paKey].length; j += 1) {
      flattenedResults.push(priorityArray[paKey][j]);
    }
  }
  return flattenedResults;
}
const convertToItems = varenrs => varenrs.map(v => [resultTypes.ITEM, v]);
const convertBackToVarenrs = items => items.map(([, v]) => v);
const findTerms = (searchTerm) => {
  const allTerms = searchTerm.split(' ');
  const allActualTerms = allTerms.filter(
    term => !ignoreWords.includes(term),
  );
  if (allActualTerms.length === 0) {
    return allTerms;
  }
  return allActualTerms;
};

function* findGeneralResultsSaga() {
  const { term: casedTerm } = yield select(getGeneralSearchState);
  const { index } = yield select(getSearchState);
  const term = casedTerm.toLowerCase().trim();
  const allTerms = findTerms(term);
  const termSets = findTermSets(allTerms);
  const results = {
    routes: [],
    items: [],
    routeNumber: 0,
    itemNumber: 0,
  };
  const tempRouteResults = {};
  const allRouteResults = [];
  termSets.forEach(
    (termSet, idx) => {
      allRouteResults.push([]);
      termSet.forEach(
        (t) => {
          const actualRoutes = index.routes.searchLookup.filter(r => r.indexOf(t) !== -1);
          if (actualRoutes.length > 0) {
            actualRoutes.forEach(
              (rr) => {
                const rs = index.routes.lookups[rr];
                rs.forEach(
                  (r) => {
                    tempRouteResults[r] = idx + 1;
                  },
                );
              },
            );
          }
        },
      );
    },
  );
  Object.keys(tempRouteResults).forEach(
    (r) => {
      const order = tempRouteResults[r] - 1;
      allRouteResults[order].push([r, index.routes.data[r]]);
      // results.routes.push([r, index.routes.data[r]]);
    },
  );
  results.routes = allRouteResults;
  const existingItems = yield select(getItems);
  results.items = yield call(searchItemsPrioritized, allTerms, index.items, existingItems);
  const flattenedResults = [];
  for (let i = results.routes.length - 1; i >= 0; i -= 1) {
    for (let j = 0; j < results.routes[i].length; j += 1) {
      const routeResult = results.routes[i][j];
      flattenedResults.push([resultTypes.ROUTE, routeResult]);
    }
    for (let j = 0; j < results.items[i].length; j += 1) {
      const itemResult = results.items[i][j];
      flattenedResults.push([resultTypes.ITEM, itemResult]);
    }
  }
  const state = yield select(s => s);
  const finalItemResults = prioritizeItems(flattenedResults, term, state);
  yield put(generalSearchResultsDone(finalItemResults));
}

function logSearch(searchTerm, searchPlace = 'GENERAL') {
  window.axios.post(`${window.location.origin}/api/searchLog`, { searchTerm, searchPlace }).then(() => { }).catch(() => { }); // eslint-disable-line
}

function* findResultsSaga() {
  const {
    term: casedTerm,
    isSearching,
    index,
  } = yield select(getSearchState);
  if (!isSearching) {
    return false;
  }
  const term = casedTerm.toLowerCase().trim();
  const allTerms = term.split(' ');
  let results = {
    amount: 0,
    categories: [],
    itemsInCategories: {},
    uncategorized: [
      [], [], [],
    ],
    expiredItems: [],
  };
  const addResults = (previousResults, extraResults) => extraResults.reduce(
    (rs, varenr) => {
      const { expired, expiredMessage, inStock } = getDefaultItemInformation(varenr) || {};
      if (expired || expiredMessage || inStock === '100') {
        return {
          ...rs,
          amount: rs.amount + 1,
          expiredItems: [...rs.expiredItems, varenr],
        };
      }
      const cat = itemReverseLookup[varenr];
      if (!cat) {
        const uncat = rs.uncategorized;
        return {
          ...rs,
          amount: rs.amount + 1,
          uncategorized: [
            [...uncat[0], varenr], uncat[1], uncat[2],
          ],
        };
      }
      const {
        dontShowCategoryInSearch,
      } = categories[cat];
      if (dontShowCategoryInSearch) {
        const uncat = rs.uncategorized;
        if (dontShowCategoryInSearch === 1) {
          return {
            ...rs,
            amount: rs.amount + 1,
            uncategorized: [
              uncat[0], [...uncat[1], varenr], uncat[2],
            ],
          };
        }
        return {
          ...rs,
          amount: rs.amount + 1,
          uncategorized: [
            uncat[0], uncat[1], [...uncat[2], varenr],
          ],
        };
      }
      let prelimRs = rs;
      if (!prelimRs.itemsInCategories[cat]) {
        prelimRs = {
          ...prelimRs,
          categories: [
            ...prelimRs.categories,
            cat,
          ],
          itemsInCategories: {
            ...prelimRs.itemsInCategories,
            [cat]: [],
          },
        };
      }
      return {
        ...prelimRs,
        amount: prelimRs.amount + 1,
        itemsInCategories: {
          ...prelimRs.itemsInCategories,
          [cat]: [
            ...prelimRs.itemsInCategories[cat],
            varenr,
          ],
        },
      };
    },
    previousResults,
  );
  const allItems = yield select(getItems);
  const allResults = yield call(searchItems, allTerms, index.items, allItems);
  results = addResults(results, allResults);

  // prioritizing the ordering
  const state = yield select(s => s);
  results.uncategorized = [
    convertBackToVarenrs(prioritizeItems(convertToItems(results.uncategorized[0]), term, state)),
    convertBackToVarenrs(prioritizeItems(convertToItems(results.uncategorized[1]), term, state)),
    convertBackToVarenrs(prioritizeItems(convertToItems(results.uncategorized[2]), term, state)),
  ];
  const resultCat = {};
  const allCatResults = [];
  results.categories.forEach((cat) => {
    results.itemsInCategories[cat].forEach((varenr) => {
      allCatResults.push(varenr);
      resultCat[varenr] = cat;
    });
  });
  const allCatResultsPrioritized = convertBackToVarenrs(prioritizeItems(convertToItems(allCatResults), term, state));
  results.categories = [];
  results.itemsInCategories = {};
  allCatResultsPrioritized.forEach((varenr) => {
    const cat = resultCat[varenr];
    if (!results.itemsInCategories[cat]) {
      results.categories.push(cat);
      results.itemsInCategories[cat] = [];
    }
    results.itemsInCategories[cat].push(varenr);
  });
  results.expiredItems = convertBackToVarenrs(prioritizeItems(convertToItems(results.expiredItems), term, state));
  // finished prioritizing the ordering
  yield put(doneWithSearchResults(results));
  return true;
}

const searchDebounce = 500;
function* innerSearchSaga({ payload: searchTerm }) {
  if (searchTerm.trim() === '') {
    return;
  }
  yield call(delay, searchDebounce);
  // Make log request
  logSearch(searchTerm, 'ITEMS');
  const hasSearchIndex = yield select(hasIndex);
  if (!hasSearchIndex) {
    const { default: index } = yield call(() => import('./indexValues'));
    yield put(setIndex(index));
  }
  yield race({
    results: call(findResultsSaga),
    cancelSearch: take(SEARCH_CLEAR),
  });
}
function* searchSaga() {
  yield take(STARTUP_COMPLETE);
  yield takeLatest(SEARCH_CHANGE, innerSearchSaga);
}

function* generalSearchSaga({ payload }) {
  if (payload.trim() === '') {
    return;
  }
  yield call(delay, searchDebounce);

  // Make log request
  logSearch(payload, 'GENERAL');
  const hasSearchIndex = yield select(hasIndex);
  if (!hasSearchIndex) {
    const { default: index } = yield call(() => import('./indexValues'));
    yield put(setIndex(index));
  }

  yield race({
    results: call(findGeneralResultsSaga),
    cancelSearch: take(GENERAL_SEARCH_CLEAR),
  });


  // Begin the search
}

export default all([
  searchSaga(),
  takeLatest(GENERAL_SEARCH_CHANGE, generalSearchSaga),
]);
