import { createStore, applyMiddleware, compose } from 'redux';
// import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import appReducer, { rootSaga } from './features';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line

export default function createAppStore(initialState = {}) {
  // const loggerMiddleware = createLogger();
  const sagaMiddleware = createSagaMiddleware();
  const store = (
    createStore(
      appReducer,
      initialState,
      composeEnhancers(applyMiddleware(
        sagaMiddleware,
        // loggerMiddleware,
      )),
    )
  );
  sagaMiddleware.run(rootSaga, store.dispatch);
  return store;
}
