import React from "react";
import styled from "styled-components/macro";
import { AiFillMinusCircle, AiFillPlusCircle } from "../../../Icons";

const StyledIcon = styled.span`
  display: inline-block;
  margin-top: 5px;
  & > svg {
    font-size: 24px;
    fill: ${({ theme }) => theme.colors.colorBlue};

    &:hover {
      fill: ${({ theme }) => theme.hoverColors.colorBlue};
      cursor: pointer;
    }
  }
`;

export const IconMinusCircle = ({ ...otherProps }) => (
  <StyledIcon {...otherProps}>
    <AiFillMinusCircle />
  </StyledIcon>
);

export const IconPlusCircle = ({ ...otherProps }) => (
  <StyledIcon {...otherProps}>
    <AiFillPlusCircle />
  </StyledIcon>
);
