import React from 'react';
import { Row, Col, Div } from '../../../grid';
import {
  Paragraph,
  Heading3,
  F12,
  Small,
  ClearFix,
  Span,
  Bold,
} from '../../../typography';
import BuyButtons from '../BuyButtons';
import PackImageSelector from '../PackImageSelector';
import { ScreenSizeDetector } from '../../../../lib/helpers';
import PriceText from './PriceText';
import KlapbarUdsolgt from '../../../UdsolgtTexts/KlapbarUdsolgt';
import AnlaegUdsolgt from '../../../UdsolgtTexts/AnlaegUdsolgt';

const RenderButtons = ({
  amount,
  actions,
  inStock,
}) => {
  if (inStock === '-1') {
    return null;
  }
  return (
    <BuyButtons medium amount={amount} actions={actions} />
  );
};

export default React.forwardRef(({
  varenr,
  name,
  amount,
  singleAmount,
  actions,
  description,
  smallPrint,
  lejeNameIndicator,
  packImages,
  highlighted,
  inStock,
  imageMaxHeight,
  imageDescription,
  IconBoxes,
  extraItemRef,
  price,
  isLeje,
  replaceStkWithPackageNoun,
  simpleAmountDescriptor,
  extendedAmountDescriptor,
  countPrColli,
  packageNoun,
  betingerFustager,
  inStockText,
  udsolgtKlapbar,
  udsolgtAnlaeg,
  dobbeltAnlaeg,
  betingerFustagerUdstyr,
}) => (
  <Div ref={extraItemRef}>
    <Row className={`vareView_${varenr}`} inStock={inStock} highlight={highlighted && !(amount > 0 || singleAmount > 0)} green={amount > 0 || singleAmount > 0} margin="0px" border="2px solid #D8D8DA">
      <Col xs={12} p0>
        <Row>
          <Col sm={4} md={4} textCenter>
            {packImages && packImages.length > 0 && (
              <PackImageSelector imageDescription={imageDescription} imageMaxHeight={imageMaxHeight} packImages={packImages} />
            )}
          </Col>
          <Col sm={8} md={8}>
            <Heading3 marginTop="0px" className="bucketAnimation">
              {name} {lejeNameIndicator && <Small>(Leje)</Small>}
            </Heading3>
            {React.isValidElement(description)
              ? description
              : (
                <Div>
                  {description()}
                </Div>
              )}
            <Row>
              <ScreenSizeDetector paddingBottom={[[767, '0px'], '15px']}>
                <Col sm={12} md={7}>
                  {inStock !== '-1' && (
                    <React.Fragment>
                      <Paragraph fontSize="18px" marginBottom={betingerFustager && '0px'}>
                        <Bold>
                          <PriceText
                            price={price}
                            isLeje={isLeje}
                            replaceStkWithPackageNoun={replaceStkWithPackageNoun}
                            simpleAmountDescriptor={simpleAmountDescriptor}
                            extendedAmountDescriptor={extendedAmountDescriptor}
                            packageNoun={packageNoun}
                            countPrColli={countPrColli}
                            momsIndication
                          />
                        </Bold>
                      </Paragraph>
                      {udsolgtAnlaeg && (
                        <AnlaegUdsolgt />
                      )}
                      {udsolgtKlapbar && (
                        <KlapbarUdsolgt />
                      )}
                      {betingerFustager && !dobbeltAnlaeg && (
                      <Paragraph fontSize="12px" marginBottom="0">
                        Lejeprisen betinger køb af fustager
                      </Paragraph>
                      )}
                      {betingerFustager && dobbeltAnlaeg && (
                      <Paragraph fontSize="12px" marginBottom="0">
                        Lejeprisen betinger køb af fustager til begge anlæg
                      </Paragraph>
                      )}
                      {betingerFustagerUdstyr && (
                      <Paragraph fontSize="12px" marginBottom="0">
                        Lejeprisen betinger køb af fustager, samt leje af udstyr
                      </Paragraph>
                      )}
                      <Paragraph>
                        <F12>
                          {smallPrint}
                        </F12>
                      </Paragraph>
                    </React.Fragment>
                  )}
                  {inStock === '-1' && !inStockText && (
                    <Paragraph>
                      Midlertidigt udsolgt fra producenten
                    </Paragraph>
                  )}
                  {inStock === '-1' && inStockText && (
                    <Paragraph red>
                      {inStockText()}
                    </Paragraph>
                  )}
                </Col>
              </ScreenSizeDetector>
              <ScreenSizeDetector paddingTop={[[767, '0px'], '20px']} textRight={[[767, false], true]}>
                <Col sm={12} md={5}>
                  <RenderButtons amount={amount} actions={actions} inStock={inStock} />
                  {inStock > 0 && (
                    <Span block textRight fontSize="13px" red paddingTop="5px">
                      {inStock} tilbage!
                    </Span>
                  )}
                </Col>
              </ScreenSizeDetector>
            </Row>
            {IconBoxes && (
              <Div>
                {IconBoxes()}
                <ClearFix />
              </Div>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  </Div>
));
