import React from 'react';
import styled from 'styled-components/macro';
import { Span } from '../typography';
import { BsCheckSquare, BsSquare } from '../Icons';
import Div from '../Div';

const MomsContainerNoStyle = ({
  children,
  burger,
  ...otherProps
}) => (
  <div {...otherProps}>
    {children}
  </div>
);
const MomsContainer = styled(MomsContainerNoStyle)`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 991px) {
    padding-top: 2px;
  }

  @media (max-width: 440px) {
    padding-top: 0px;
  }
`;
const Big = styled(Span)`
  display: inline-block;
  font-weight: 600;

  @media (max-width: 440px) {
    display: none;
  }
`;
const Small = styled(Span)`
  display: none;
  font-weight: 600;

  @media (max-width: 440px) {
    display: inline-block;
  }
`;
const BlockContainerNoStyle = ({
  children,
  ...otherProps
}) => (
  <div {...otherProps}>
    {children}
  </div>
);
const BlockContainer = styled(BlockContainerNoStyle)`
  display: inline-block;

  @media (max-width: 575px) {
    margin-left: 15px;
  }

  @media (max-width: 500px) {
    margin-left: 5px;
  }
`;
const HiddenContainerNoStyle = ({
  children,
  ...otherProps
}) => (
  <span {...otherProps}>
    {children}
  </span>
);
const HiddenContainer = styled(HiddenContainerNoStyle)`
  font-size: 14px;
  font-weight: 600;

  @media (max-width: 575px) {
    display: none;
  }
`;

const CheckBoxComponentNoStyle = ({
  checked,
  onClick,
  children,
  ...otherProps
}) => (
  <Div
    onClick={onClick}
    {...otherProps}
  >
    {checked ? <BsCheckSquare /> : <BsSquare />}
    {children}
  </Div>
);
const CheckBoxComponent = styled(CheckBoxComponentNoStyle)`
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  ${({ checked, theme }) => {
    if (checked) {
      return `
        color: ${theme.colors.colorGreen};
      `;
    }
    return `
      color: ${theme.colors.colorGreyDark};
    `;
  }}

  & > svg {
    position: relative;
    top: 1.5px;
    margin-right: 5px;
  }
`;


export default ({ on, change }) => (
  <MomsContainer>
    <HiddenContainer>
      Vis priser&nbsp;
    </HiddenContainer>
    <BlockContainer>
      <CheckBoxComponent checked={on} onClick={!on ? (() => change(true)) : undefined}>
        <Small>m.</Small><Big>med</Big> moms
      </CheckBoxComponent>
    </BlockContainer>
    <HiddenContainer>
      &nbsp;&nbsp;|&nbsp;&nbsp;
    </HiddenContainer>
    <BlockContainer>
      <CheckBoxComponent checked={!on} onClick={on ? (() => change(false)) : undefined}>
        <Small>u.</Small><Big>uden</Big> moms
      </CheckBoxComponent>
    </BlockContainer>
  </MomsContainer>
);
