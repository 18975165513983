import React from 'react';
import { Paragraph } from '../typography';

export default ({
  udsolgtTil,
}) => (
  <React.Fragment>
    <Paragraph red bold>
      Udsolgt til og med {udsolgtTil}.
    </Paragraph>
  </React.Fragment>
);
