import { connect } from 'react-redux';
import {
  setDeliveryType,
  hasFustageItem,
  getDeliveryType,
  deliveryTypeIsPossible,
  deliveryTypeError,
  hasLejeItem,
} from '../../features/commerce';
import {
  getDeliveryCost,
  getDeliveryCostWithoutFees,
  getDeliveryFeesCostWithoutExternalDelivery,
  getExternalDeliveryFeeCost,
  getEffectiveZip,
  hasOnlyPostnordItems,
} from '../../features/commerce/selectors';
import { getDate, dateTypes } from '../../features/commerce/delivery/dates';
import { getNormalDeliveryCost } from '../../features/commerce/items';

export const deliveryChooser = deliveryType => connect(
  state => ({
    error: deliveryTypeError(state, deliveryType),
    chosen: deliveryType === getDeliveryType(state),
    hasLeje: hasLejeItem(state),
    hasFustager: hasFustageItem(state),
    normalDeliveryCost: getNormalDeliveryCost(state),
    hasOnlyPostnordItems: hasOnlyPostnordItems(state),
  }),
  dispatch => ({
    onChoose: () => dispatch(setDeliveryType(deliveryType)),
  }),
);

export default connect(
  (state) => {
    const deliveryType = getDeliveryType(state);
    return {
      deliveryType,
      error: deliveryTypeError(state, deliveryType),
      isPossible: deliveryTypeIsPossible(state, deliveryType),
      hasLeje: hasLejeItem(state),
      deliveryCost: getDeliveryCost(state),
      deliveryCostWithoutFees: getDeliveryCostWithoutFees(state),
      deliveryFeesCost: getDeliveryFeesCostWithoutExternalDelivery(state),
      externalDeliveryFeeCost: getExternalDeliveryFeeCost(state),
      effectiveZip: getEffectiveZip(state),
      deliveryDate: getDate(dateTypes.delivery)(state),
    };
  },
);
