import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { ItemContextConsumer } from './ItemContext';
import getContextValues from '../../content/commerce/items';
import {
  isLeje as isLejeItem, getItemCost, getItem, getItemAmount, getItemSingleAmount, getItemEntry, getEntriesFromKeys, getWaterDefinitions, incrementItemAmount, decrementItemAmount,
} from '../../features/commerce';
import { getDateDiff } from '../../features/commerce/selectors';
import { isWaterItem } from '../../features/commerce/bucket';


const ConnectedInnerBucketEntry = connect(
  (state, { varenr }) => ({
    cost: getItemCost(state, { varenr }),
    itemValues: getItem(state, { varenr }),
    amount: getItemAmount(state, { varenr }),
    singleAmount: getItemSingleAmount(state, { varenr }),
    isLeje: isLejeItem(state.commerce.items, { varenr }),
    days: getDateDiff(state),
  }),
  (dispatch, { varenr }) => ({
    actions: {
      incrementItemAmount: () => dispatch(incrementItemAmount(varenr)),
      decrementItemAmount: () => dispatch(decrementItemAmount(varenr)),
    },
  }),
)(({
  display: Component,
  context,
  varenr,
  itemValues,
  ...otherProps
}) => {
  const contextValues = getContextValues(varenr, context);
  return (
    <Component context={context} varenr={varenr} {...itemValues.values} {...contextValues} {...otherProps} />
  );
});
const NormalItemEntry = props => (
  <ItemContextConsumer>
    {context => <ConnectedInnerBucketEntry context={context} {...props} />}
  </ItemContextConsumer>
);
const ConnectedWaterBucketEntryView = connect(
  () => createSelector(
    getItemAmount,
    getItemCost,
    getItemEntry,
    getWaterDefinitions,
    (amount, cost, itemEntry, waterDefinitions) => {
      const {
        size: {
          liter,
          count,
        },
      } = getEntriesFromKeys({ waterDefinitions, ...itemEntry });
      return ({
        amount,
        liter,
        amountPrPalle: count,
        cost,
      });
    },
  ),
  (dispatch, { varenr }) => ({
    actions: {
      incrementItemAmount: () => dispatch(incrementItemAmount(varenr)),
      decrementItemAmount: () => dispatch(decrementItemAmount(varenr)),
    },
  }),
)(({ waterDisplay: WaterDisplay, ...props }) => <WaterDisplay {...props} />);

const WaterSorter = ({ waterItem, ...props }) => (
  waterItem
    ? (<ConnectedWaterBucketEntryView {...props} />)
    : (
      <NormalItemEntry {...props} />
    )
);
export default connect(
  () => createSelector(
    isWaterItem,
    waterItem => ({
      waterItem,
    }),
  ),
)(WaterSorter);
