import React from 'react';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router-dom';
import { LinkStyled } from '../../components/typography';
import { itemReverseLookup, pageReverseLookup } from '../../content/commerce/itemLookup';
import { categories } from '../../content/commerce/itemCategories';

const LinkComponentStyled = styled(LinkStyled)`
  color: ${({ theme }) => theme.colors.colorBlue};
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.hoverColors.colorBlue};
  }
`;

export default withRouter(({
  varenr,
  children,
  LinkComponent = LinkComponentStyled,
  ...props
}) => {
  const category = itemReverseLookup[varenr];
  if (!category) {
    return children;
  }
  const objectCategory = categories[category];
  if (!objectCategory || !objectCategory.name) {
    return children;
  }
  const {
    name: categoryName,
  } = objectCategory;
  const page = pageReverseLookup[category];
  if (!page) {
    return children;
  }
  return (
    <LinkComponent
      {...props}
      to={`/bestil${page}/kategori/${categoryName}/vare/${varenr}`}
      noStyle
    >
      {children}
    </LinkComponent>
  );
});
