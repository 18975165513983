import React from 'react';
import styled from 'styled-components/macro';

const StyledLi = styled.li`
  white-space: nowrap;
  padding: 15px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.colorGreyDark};
`;

export default ({ title }) => (
  <StyledLi>
    {title}
  </StyledLi>
);
