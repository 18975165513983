import React from 'react';
import sizeMe from 'react-sizeme';

export default (Component) => {
  class ModifiedComponent extends React.Component {
    static displayName = `monitorSize(${Component.displayName})`;
    componentWillReceiveProps(nextProps) {
      const {
        size: { height },
        heightCallback,
      } = nextProps;
      heightCallback(height);
    }
    render() {
      const {
        heightCallback,
        size,
        ...props
      } = this.props;
      return <Component {...props} />;
    }
  }
  return sizeMe({ monitorHeight: true })(ModifiedComponent);
};
