import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { getItemAlcoholLevel } from '../../features/commerce/selectors';
import { Image } from '../../components/Image';

const Webbanner = styled(Image)`
  width: 100%;
  margin-top: 15px;
`;

const AlkoholWebbanner = ({
  alcoholLevel,
}) => {
  if (alcoholLevel !== 'NONE') {
    return <Webbanner width="100%" name="alkohol-webbanner.sst-alkohol-400x400.png" />;
  }
  return null;
};

export default connect(
  state => ({
    alcoholLevel: getItemAlcoholLevel(state),
  }),
)(AlkoholWebbanner);
