import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { Container } from '../components/grid';
import { FaCity, FaHome, FaTree, ImTruck } from '../components/Icons';

const CityAnimation = keyframes`
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-1510px);
  }
`;

const TruckAnimation = keyframes`
  0% { transform: translateX(0%); }
  15% { transform: translateX(-10%) rotate(-4deg); }
  30% { transform: translateX(10%) rotate(2deg); }
  45% { transform: translateX(-10%) rotate(-2deg); }
  60% { transform: translateX(4%) rotate(1.5deg); }
  75% { transform: translateX(-3%) rotate(-0.5deg); }
  100% { transform: translateX(0%); }
`;

const dotPulseBefore = keyframes`
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
`;
const dotPulse = keyframes`
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
`;
const dotPulseAfter = keyframes`
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
`;

const TruckAnimationWrapper = styled.div`
  background: ${({ theme }) => theme.colors.colorWhite};
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
`;

const Background = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 300px;
  animation: ${CityAnimation} 15s linear infinite;
`;
const BackgroundItem = styled.div`
  color: ${({ theme }) => theme.colors.colorGreyLight};
  position: absolute;

  left: ${({ left }) => left}px;
  bottom: ${({ bottom }) => bottom}px;
  font-size: ${({ iconSize }) => iconSize}px;
`;

const Truck = styled.div`
  position: absolute;
  bottom: 85px;
  left: calc(50% - 40px);
  transform: translateX(-50%);
  font-size: 80px;
  color: ${({ theme }) => theme.colors.colorGrey};
  z-index: 1;
  animation: ${TruckAnimation} 2s linear infinite;
`;

const Surface = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: ${({ theme }) => theme.colors.colorGreyLight};
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.colorGrey};
`;

const Dots = styled.div`
  & {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.colorGrey};
    color: ${({ theme }) => theme.colors.colorGrey};
    box-shadow: 9999px 0 0 -5px;
    animation: ${dotPulse} 1.5s infinite linear;
    animation-delay: 0.25s;
  }
  &::before, &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.colorGrey};
    color: ${({ theme }) => theme.colors.colorGrey};
  }
  &::before {
    box-shadow: 9984px 0 0 -5px;
    animation: ${dotPulseBefore} 1.5s infinite linear;
    animation-delay: 0s;
  }
  &::after {
    box-shadow: 10014px 0 0 -5px;
    animation: ${dotPulseAfter} 1.5s infinite linear;
    animation-delay: 0.5s;
  }
`;

export default class HenterRute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentWillMount() {
    setTimeout(() => {
      this.setState({ visible: true });
    }, 200);
  }

  render() {
    const { visible } = this.state;
    if (!visible) {
      return (
        <Container white />
      );
    }
    return (
      <Container>
        <TruckAnimationWrapper>
          <Truck><ImTruck /></Truck>
          <Background>
            <BackgroundItem left={10} bottom={53} iconSize={220}><FaCity /></BackgroundItem>
            <BackgroundItem left={220} bottom={87} iconSize={115}><FaTree /></BackgroundItem>
            <BackgroundItem left={315} bottom={90} iconSize={80}><FaTree /></BackgroundItem>
            <BackgroundItem left={390} bottom={70} iconSize={130}><FaHome /></BackgroundItem>
            <BackgroundItem left={537} bottom={40} iconSize={280}><FaCity /></BackgroundItem>
            <BackgroundItem left={820} bottom={87} iconSize={115}><FaTree /></BackgroundItem>
            <BackgroundItem left={900} bottom={77} iconSize={180}><FaTree /></BackgroundItem>
            <BackgroundItem left={1070} bottom={53} iconSize={220}><FaCity /></BackgroundItem>
            <BackgroundItem left={1310} bottom={59} iconSize={180}><FaHome /></BackgroundItem>

            <BackgroundItem left={1520} bottom={53} iconSize={220}><FaCity /></BackgroundItem>
            <BackgroundItem left={1730} bottom={87} iconSize={115}><FaTree /></BackgroundItem>
            <BackgroundItem left={1825} bottom={90} iconSize={80}><FaTree /></BackgroundItem>
            <BackgroundItem left={1900} bottom={70} iconSize={130}><FaHome /></BackgroundItem>
            <BackgroundItem left={2047} bottom={40} iconSize={280}><FaCity /></BackgroundItem>
            <BackgroundItem left={2330} bottom={87} iconSize={115}><FaTree /></BackgroundItem>
            <BackgroundItem left={2410} bottom={77} iconSize={180}><FaTree /></BackgroundItem>
            <BackgroundItem left={2580} bottom={53} iconSize={220}><FaCity /></BackgroundItem>
            <BackgroundItem left={2820} bottom={59} iconSize={180}><FaHome /></BackgroundItem>
          </Background>
          <Surface><div>Loader indhold</div><Dots /></Surface>
        </TruckAnimationWrapper>
      </Container>
    );
  }
}
