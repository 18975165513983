import React from 'react';
import styled from 'styled-components/macro';
import { Money, VareLink } from '../../../../containers/commerce';
import { Paragraph, Span } from '../../../typography';
import { LiterFormat } from '../../../Format';
import { Row, Col } from '../../../grid';
import { Image } from '../../../Image';
import { sanitizeMeasurement, getRentalText } from '../../../../lib/helpers';
import { FadKobling } from '../../parts';
import { IconMinusCircle, IconPlusCircle } from './entryComponents';

const EntryWrapNoStyle = ({
  children,
  ...otherProps
}) => (
  <div {...otherProps}>
    {children}
  </div>
);
const EntryWrap = styled(EntryWrapNoStyle)`
  border-bottom: 2px solid ${({ theme }) => theme.colors.colorGreyLight};
`;

export default (props) => {
  const {
    name,
    countPrColli,
    liter,
    amount,
    singleAmount,
    packageNoun,
    cannotBeManuallyBought,
    cost,
    imageSingle,
    bucketItemView: BucketItemView,
    actions,
    varenr,
    returPant,
    productTypePalle,
    productTypeReservedel,
    countPrPalle,
    simpleItem,
    isLeje,
    packImages,
    days,
    productTypePack,
    imagePlural,
    onlyRemove,
    royalUnibrewJulekittet,
    productTypeKulsyre,
    productTypeFadoel,
    fadKobling,
    productTypeIs,
  } = props;
  const {
    incrementItemAmount,
    decrementItemAmount,
  } = actions;
  if (BucketItemView) {
    return <BucketItemView {...props} />;
  }
  return (
    <EntryWrap>
      <Row>
        {!returPant && (
        <Col xs={3} padding="15px 5px" textCenter>
          {imageSingle && !imagePlural && (
            <Image maxWidth="100%" imageHeight="60px" name={imageSingle} left />
          )}
          {imagePlural && (
            <Image maxWidth="100%" imageHeight="60px" name={imagePlural} left />
          )}
          {packImages && packImages.length > 0 && !imagePlural && !imageSingle && (
            <Image maxWidth="100%" imageHeight="60px" name={packImages[0].name} left />
          )}
        </Col>
        )}
        <Col xs={returPant ? 12 : 9} padding={returPant ? '15px' : '15px 15px 15px 0px'}>
          <Paragraph lineHeight="1.2em" margin="0">
            {!returPant && (
              <VareLink varenr={varenr}>
                {name}
              </VareLink>
            )}
            {returPant && (
              <React.Fragment>{name}</React.Fragment>
            )}
            {!productTypePalle && !productTypeKulsyre && !productTypeIs && (
              <React.Fragment>
                {(sanitizeMeasurement(liter) > 0 && (sanitizeMeasurement(liter) / sanitizeMeasurement(countPrColli)) < 2) && (
                  <React.Fragment>
                    &nbsp;{countPrColli}x<LiterFormat liter={liter} cl divider={countPrColli} />
                  </React.Fragment>
                )}
                {(sanitizeMeasurement(liter) / sanitizeMeasurement(countPrColli)) >= 2 && (
                  <React.Fragment>
                    &nbsp;<LiterFormat liter={liter} divider={countPrColli} />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            {productTypePalle && (
              <Span block>
                {countPrPalle}x{countPrColli}x<LiterFormat liter={liter} cl divider={countPrColli} />
              </Span>
            )}
          </Paragraph>
          <Span block bold margin="5px 0 0 0">
            {!royalUnibrewJulekittet && amount}
            {royalUnibrewJulekittet && (
              <React.Fragment>
                2 x nissehuer<br />2 x knappehulsblinkere<br />1 x guirlande
              </React.Fragment>
            )}
            {!productTypePalle && !simpleItem && !productTypePack && !productTypeReservedel && (
              <React.Fragment>
                &nbsp;{packageNoun.toLowerCase()}{amount !== 1 && 'r'}{singleAmount > 0 && `, ${singleAmount * countPrColli} flasker`}
                {productTypeFadoel && (
                  <React.Fragment>
                    <Span normal> &nbsp;| <FadKobling value={fadKobling} noPrefix />-kobling</Span>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            {productTypePalle && (
              <React.Fragment>
                &nbsp;palle{amount !== 1 && 'r'}
              </React.Fragment>
            )}
            {(simpleItem || productTypePack || productTypeReservedel) && (
              <React.Fragment>
                &nbsp;stk. {isLeje && `(${getRentalText(days)})`}
              </React.Fragment>
            )}
          </Span>
          {!cannotBeManuallyBought && (
            <React.Fragment>
              <IconMinusCircle onClick={decrementItemAmount} />
              {!onlyRemove && (
                <IconPlusCircle onClick={incrementItemAmount} />
              )}
            </React.Fragment>
          )}
          <Paragraph floatRight margin="5px 0 0 0" bold>
            <Money money={cost} />
          </Paragraph>
        </Col>
      </Row>
    </EntryWrap>
  );
};
