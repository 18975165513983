import styled from 'styled-components/macro';
import {
  marginTheming,
  paddingTheming,
  fontTheming,
  elementTheming,
  removePaddingThemingProps,
  removeMarginThemingProps,
  removeFontThemingProps,
  removeElementThemingProps,
  removeProps,
  combine,
  createComponentWithModifiedProps,
} from '../../style/themes/main';


const removeCustomProps = removeProps('grey', 'blue');
const removeAllProps = combine(
  removePaddingThemingProps,
  removeMarginThemingProps,
  removeFontThemingProps,
  removeElementThemingProps,
  removeCustomProps,
);

const SpanNoProps = createComponentWithModifiedProps(removeAllProps, 'span', 'Skafte.com.typography.Span');
export const NoStyleSpan = styled(createComponentWithModifiedProps(removeAllProps, 'span', 'Skafte.com.typography.NoStyleSpan'))`
  ${({
    blue,
    green,
    red,
    theme,
  }) => {
    if (blue) {
      return `color: ${theme.colors.colorBlue};`;
    }
    if (green) {
      return `color: ${theme.colors.colorGreen};`;
    }
    if (red) {
      return `color: ${theme.colors.colorRed};`;
    }
    return '';
  }}
  ${paddingTheming}
  ${marginTheming}
  ${elementTheming}
  ${fontTheming}
`;

export default styled(SpanNoProps)`
  font-weight: normal;
  display: inline-block;
  line-height: 1.5em;
  ${({
    blue,
    green,
    red,
    yellow,
    inline,
    theme,
  }) => {
    if (blue) {
      return `color: ${theme.colors.colorBlue};`;
    }
    if (green) {
      return `color: ${theme.colors.colorGreen};`;
    }
    if (red) {
      return `color: ${theme.colors.colorRed};`;
    }
    if (yellow) {
      return `color: ${theme.colors.colorYellow};`;
    }
    if (inline) {
      return 'display: inline;';
    }
    return '';
  }}
  ${paddingTheming}
  ${marginTheming}
  ${elementTheming}
  ${fontTheming}
`;
