import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

const StyledLi = styled.li`
  white-space: nowrap;
`;
const StyledMenuDropdownLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.colorGreyDark};
  padding: 10px 15px;
  white-space: initial;
  font-size: 16px;
  &:hover {
    background: ${({ theme }) => theme.colors.colorGreyLight};
  }
`;

export default ({ to, title }) => (
  <StyledLi>
    <StyledMenuDropdownLink to={to}>
      {title}
    </StyledMenuDropdownLink>
  </StyledLi>
);
