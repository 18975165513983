import React from 'react';
import styled from 'styled-components/macro';
import { BucketEntry } from '../../../../containers/commerce';
import WaterBucketSimpleEntryView from '../entryViews/WaterBucketSimpleEntryView';
import BucketViewSimpleEntry from '../entryViews/BucketViewSimpleEntry';

const renderItems = items => items.map(
  varenr => (
    <BucketEntry
      display={BucketViewSimpleEntry}
      waterDisplay={WaterBucketSimpleEntryView}
      varenr={varenr}
      key={varenr}
    />
  ),
);
const ItemsWrapNoStyle = ({
  children,
  ...otherProps
}) => (
  <div {...otherProps}>
    {children}
  </div>
);
const ItemsWrap = styled(ItemsWrapNoStyle)`
`;

export default ({ items }) => (
  <ItemsWrap>
    {renderItems(items)}
  </ItemsWrap>
);
