import { setConfiguration } from 'react-grid-system';

const getDefaultScreenSize = (deviceType) => {
  switch (deviceType) {
    case 'MOBILE':
      return 'xs';
    case 'TABLET':
      return 'md';
    case 'COMPUTER':
    default:
      return 'xl';
  }
};

export default (deviceType) => {
  setConfiguration({ defaultScreenClass: getDefaultScreenSize(deviceType) });
};

export const getSizesFallback = (deviceType) => {
  switch (deviceType) {
    case 'MOBILE':
      return {
        fallbackWidth: 401,
        fallbackHeight: 640,
      };
    case 'TABLET':
      return {
        fallbackWidth: 768,
        fallbackHeight: 1024,
      };
    case 'COMPUTER':
    default:
      return {
        fallbackWidth: 1280,
        fallbackHeight: 700,
      };
  }
};

export const getActualDeviceType = () => {
  const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  // const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  if (width > 600) {
    if (width > 991) {
      return 'COMPUTER';
    }
    return 'TABLET';
  }
  return 'MOBILE';
};
