import { isValidElement } from 'react';

export default function getElementText(element) {
  if (isValidElement(element)) {
    const { props: { children } } = element;
    if (children) {
      if (Array.isArray(children)) {
        return children.reduce(
          (text, element) => text + getElementText(element),
          '',
        );
      }
      return getElementText(children);
    }
    return '';
  }
  if (typeof element === 'string') {
    return `${element.toLowerCase()}\n\n`;
  }
  return '';
}
