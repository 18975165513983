import React from 'react';
import { connect } from 'react-redux';
import { getItem } from '../../features/commerce';
import { sanitizePrice } from '../../lib/price';
import { sanitizeMeasurement } from '../../lib/helpers';
import Money from './Money';

export default connect((state, { vareA, vareB }) => ({
  itemA: getItem(state, { varenr: vareA }),
  itemB: getItem(state, { varenr: vareB }),
}))(
  ({ itemA, itemB, ...props }) => {
    const { price: aPrice, liter: aLiter } = itemA.values;
    const { price: bPrice, liter: bLiter } = itemB.values;
    const literPriceA = sanitizePrice(aPrice) / sanitizeMeasurement(aLiter);
    const literPriceB = sanitizePrice(bPrice) / sanitizeMeasurement(bLiter);
    const totalVal = (literPriceA - literPriceB);
    return <Money money={totalVal} {...props} noSuffix noDecimal />;
  },
);
