import React from 'react';
import { connect } from 'react-redux';
import { sanitizePrice } from '../../lib/price';
import { sanitizeMeasurement } from '../../lib/helpers';
import Money from './Money';
import { getItem } from '../../features/commerce';

export default connect((state, { varenr }) => ({
  item: getItem(state, { varenr }),
}))(({ item: { values: { liter, price } }, ...props }) => {
  const priceVal = sanitizePrice(price);
  const sanVal = sanitizeMeasurement(liter);
  return (
    <Money
      money={priceVal / sanVal}
      {...props}
    />
  );
});
