import React from 'react';
import styled from 'styled-components/macro';
import { BucketEntry, MomsChange } from '../../../../containers/commerce';
import BucketWrapNoStyle from '../BucketWrapNoStyle';
import RecieptEntryView from '../entryViews/RecieptEntryView';
import WaterRecieptEntryView from '../entryViews/WaterRecieptEntryView';
import { Div } from '../../../grid';
import { Span } from '../../../typography';
import GenericSlider from '../../../GenericSlider';
import { WeightFormat } from '../../../Format';
import { ScreenSizeDetector } from '../../../../lib/helpers';
import { FaChevronDown, FaChevronUp, GrCart } from '../../../Icons';

const renderItems = items => items.map(
  varenr => (
    <BucketEntry
      display={RecieptEntryView}
      waterDisplay={WaterRecieptEntryView}
      varenr={varenr}
      key={varenr}
    />
  ),
);

const BucketWrap = styled(BucketWrapNoStyle)`
  margin-top: 10px;
`;
const BucketHeaderNoStyle = ({
  children,
  ...otherProps
}) => (
  <div {...otherProps}>
    {children}
  </div>
);
const BucketHeader = styled(BucketHeaderNoStyle)`
  border-top: 10px solid ${({ theme }) => theme.colors.colorBlue};
  background: ${({ theme }) => theme.colors.colorGreyLight};
  font-size: 16px;
  font-weight: bold;
  padding: 10px 15px;
  position: relative;

  & > svg {
    font-size: 30px;
    margin-right: 5px;
    position: relative;
    top: -2px;
  }
`;
const BucketHeadingNoStyle = ({
  children,
  ...otherProps
}) => (
  <span {...otherProps}>
    {children}
  </span>
);
const BucketHeading = styled(BucketHeadingNoStyle)`
  display: block;
  position: absolute;
  left: 60px;
  top: 18px;
`;
const ItemsWrapNoStyle = ({
  open,
  closedHeight,
  children,
  ...otherProps
}) => (
  <div {...otherProps}>
    {children}
  </div>
);
const ItemsWrap = styled(ItemsWrapNoStyle)`
  border-right: 3px solid ${({ theme }) => theme.colors.colorGreyLight};
  border-left: 3px solid ${({ theme }) => theme.colors.colorGreyLight};
  padding: 5px;
  overflow: hidden;
  position: relative;
  
  ${({ open, closedHeight }) => {
    if (!open) {
      return `
        height: ${closedHeight};
        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 100px;
          position: absolute;
          bottom: 0px;
          margin-left: -5px;
          background: linear-gradient(0deg,rgba(255,255,255,1) 10%,rgba(0,0,0,0) 100%);
        }
      `;
    }
    return `
      height: 100%;
    `;
  }}
`;
const ButtomWrapNoStyle = ({
  children,
  openPossible,
  ...otherProps
}) => (
  <div {...otherProps}>
    {children}
  </div>
);
const ButtomWrap = styled(ButtomWrapNoStyle)`
  background: ${({ theme }) => theme.colors.colorGreyLight};
  padding: 10px;
  clear: both;
  text-align: center;
  position: relative;
  ${({ openPossible }) => openPossible && 'cursor: pointer;'}

  @media (max-width: 575px) {
    text-align: right;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.colorBlue};
  }
`;

const StyledIcon = styled.span`
  font-size: 20px;
`;

export default class RecieptView extends React.Component {
  constructor(props) {
    super(props);
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.state = {
      open: false,
    };
  }

  onOpen() {
    this.setState({ open: true });
  }

  onClose() {
    this.setState({ open: false });
  }

  render() {
    const { open: stateOpen } = this.state;
    const {
      totalCost,
      totalCostWithoutPant,
      totalPant,
      items,
      isDelivery,
      deliveryCost,
      totalWeight,
      ...props
    } = this.props;
    const open = items.length <= 4 || stateOpen;
    return (
      <BucketWrap {...props}>
        <BucketHeader>
          <GrCart /><BucketHeading>Min kurv:</BucketHeading>
        </BucketHeader>
        <GenericSlider>
          <ItemsWrap open={open} closedHeight="100px">
            <React.Fragment>
              {renderItems(items)}
              <Div clear absolute>&nbsp;</Div>
            </React.Fragment>
          </ItemsWrap>
        </GenericSlider>
        <ButtomWrap openPossible={items.length > 4} onClick={items.length > 4 && (open ? this.onClose : this.onOpen)}>
          {items.length > 4 && (
            <React.Fragment>
              <ScreenSizeDetector hide={[[575, true], false]} inlineBlock={[[575, false], true]}>
                <Div>
                  <Span bold relative top="-3px" marginRight="10px">
                    {open ? 'Vis mindre' : 'Vis mere'}
                  </Span>
                </Div>
              </ScreenSizeDetector>
              <StyledIcon>
                {open ? <FaChevronUp /> : <FaChevronDown />}
              </StyledIcon>
            </React.Fragment>
          )}
          <Div fontSize="12px" absolute left="15px" top="50%" marginTop="-10px">
            <ScreenSizeDetector hide={[[575, true], false]}>
              <Div inline>
                Ordrens totale vægt:
              </Div>
            </ScreenSizeDetector>
            <ScreenSizeDetector hide={[[575, false], true]} inlineBlock={[[575, true], false]}>
              <Div inline>
                Vægt i alt:
              </Div>
            </ScreenSizeDetector>
            &nbsp;<WeightFormat weight={totalWeight} /> kg
            &nbsp;|&nbsp;
            Priserne er <MomsChange />
          </Div>
        </ButtomWrap>
      </BucketWrap>
    );
  }
}
