import React from 'react';
import styled from 'styled-components/macro';
import { Div } from '../../../../components/grid';
import { Bold } from '../../../../components/typography';

const DivWrap = styled(Div)`
  background-color: ${({ theme }) => theme.colors.colorYellow};
  text-align: center;
  padding: 10px 15px;
`;

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.colorGreyDark};
  font-size: 16px;
  padding: 0 10px;

  @media (max-width: 500px) {
    display: block;
  }
`;

export default () => (
  <DivWrap>
    <StyledSpan>Juleåbningstider:</StyledSpan>
    <StyledSpan>Torsdag 22/12: <Bold>8-12</Bold></StyledSpan>
    <StyledSpan>Fredag 23/12: <Bold>8-12</Bold></StyledSpan>
  </DivWrap>
);
