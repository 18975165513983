import moment from 'moment';
import {
  getDate as datesGetDate,
  getTime as datesGetTime,
  getMode as datesGetMode,
  getActualTime as datesGetActualTime,
  isRequired as datesIsRequired,
  getPriceComposition as datesGetPriceComposition,
  validate as datesValidate,
  hasDateAsPossibility as dateHasDateAsPossibility,
} from './dates';
import { deliveryTypes } from './shared';
import { storeDateFormat, dateTypes } from './dates/shared';

export const getPreferredDeliveryType = state => state.commerce.delivery.preferredType;
export const getDeliveryType = (state) => {
  const deliveryType = state.commerce.delivery.type;
  if (deliveryType === null) {
    return state.commerce.delivery.preferredType;
  }
  return deliveryType;
};

export const getLocalState = state => state.commerce.delivery;
export const getDeliveryInfo = state => state.commerce.delivery.deliveryInfo;


export const getTime = dateType => (state) => {
  const deliveryType = getDeliveryType(state);
  return datesGetTime(deliveryType)(dateType)(state);
};

export const getMode = dateType => (state) => {
  const deliveryType = getDeliveryType(state);
  return datesGetMode(deliveryType)(dateType)(state);
};

export const isRequired = dateType => (state) => {
  const deliveryType = getDeliveryType(state);
  return datesIsRequired(deliveryType)(dateType)(state);
};

export const getActualTime = dateType => (state) => {
  const deliveryType = getDeliveryType(state);
  return datesGetActualTime(deliveryType)(dateType)(state);
};

export const validate = dateType => (state) => {
  const deliveryType = getDeliveryType(state);
  return datesValidate(deliveryType)(dateType)(state);
};

export const hasDateAsPossibility = dateType => (state) => {
  const deliveryType = getDeliveryType(state);
  return dateHasDateAsPossibility(deliveryType)(dateType)(state);
};


export const hasReturnDate = state => getDeliveryInfo(state).hasLeje;
export const getDeliveryTypeErrorLocalState = deliveryType => (state) => {
  const {
    hasAnySodaOrFadoel,
    hasLeje,
    numberOfItems,
  // eslint-disable-next-line react/destructuring-assignment
  } = state.deliveryInfo;
  if (numberOfItems === 0) {
    return null;
  }
  switch (deliveryType) {
    case deliveryTypes.no:
      return null;
    case deliveryTypes.delivery:
      if (hasLeje) {
        return 'IMPOSSIBLE_BECAUSE_LEJE';
      }
      return null;
    case deliveryTypes.deliveryAndPickup:
      if (!hasAnySodaOrFadoel) {
        return 'IMPOSSIBLE_BECAUSE_NO_FUSTAGER_AND_NO_LEJE_AND_NO_KASSE';
      }
      return null;
    default:
      return null;
  }
};
export const getDeliveryTypeError = deliveryType => state => getDeliveryTypeErrorLocalState(deliveryType)(getLocalState(state));

export const isInSpecialMode = state => getLocalState(state).deliveryInfo.specialMode;

export const getSpecialDeliveryType = state => getLocalState(state).specialDeliveryType;

export const isDelivery = (state) => {
  if (isInSpecialMode(state)) {
    return getSpecialDeliveryType(state) === deliveryTypes.delivery;
  }
  switch (getDeliveryType(state)) {
    case deliveryTypes.no:
      return false;
    default:
      return true;
  }
};


export const getDeliveryFees = (state) => {
  if (isInSpecialMode(state)) {
    if (isDelivery(state)) {
      return {
        636: {
          amount: 100,
          main: true,
        },
      };
    }
    return {};
  }
  const deliveryType = getDeliveryType(state);
  return datesGetPriceComposition(deliveryType)(state);
};
export const getSpecialDeliveryDate = (state) => {
  const d = getLocalState(state).specialDeliveryDate;
  return d && moment(d, storeDateFormat);
};

export const getSpecialNoDeliveryDate = (state) => {
  const d = getLocalState(state).specialNoDeliveryDate;
  return d && moment(d, storeDateFormat);
};


export const getDate = dateType => (state) => {
  if (isInSpecialMode(state)) {
    if (dateType === dateTypes.delivery) {
      if (isDelivery(state)) {
        return getSpecialDeliveryDate(state);
      }
      return getSpecialNoDeliveryDate(state);
    }
    return null;
  }
  const deliveryType = getDeliveryType(state);
  return datesGetDate(deliveryType)(dateType)(state);
};
