import {
  put, all, select, actionChannel, take
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { createSelector } from 'reselect';
import {
  DELIVERY_TYPE_SET,
  PREFERRED_DELIVERY_TYPE_SET,
  getDeliveryType,
  deliveryTypes,
  setPreferredDeliveryType,
  getPreferredDeliveryType,
  getDeliveryInfo,
  setDeliveryInfo,
  isInSpecialMode,
  setDeliveryType,
  SET_DELIVERY_INFO,
} from './delivery';
import {
  getSubDeliveryType,
  subDeliveryTypes,
} from './delivery/dates/delivery';
import { dateTypes } from './delivery/dates/shared';
import {
  ITEM_AMOUNT_SET,
  ITEM_SINGLE_AMOUNT_SET,
  ITEM_AMOUNT_INCREMENT,
  ITEM_AMOUNT_DECREMENT,
  setItemAmount,
  getItemEntry,
} from './bucket';
import {
  setPalleAmount, setHaneAmount, setWagonAmount, setDiscountAmount,
} from './bucket/actions';
import {
  getNumberOfPaller,
  hasBestilOrFadoelItem,
  hasOnlyFreeDeliveryItems,
  deliveryTypeError,
  hasWaterItems,
  hasLejeItem,
  getFreeDeliveryBasis,
  getAmountOfDraughtmaster,
  getTotalCost,
  hasFustageItem,
  getAmountOfAnlaeg,
  getAmountOfWagons,
  getDiscountAmount,
} from './selectors';
import {
  getAmountOfPaller,
  getAmountOfHaner,
  getAmountOfWagons as getAmountOfWagonsCached,
  getDiscountAmount as getDiscountAmountCached,
  getTotalNumberOfItems,
  hasDangerousGoods,
} from './bucket/selectors';
// import { isPalleItem } from './items';
import {
  setPaymentDelivery,
  getIsPaymentDelivery,
  getIsNegativeTotal,
  setPaymentNegativeTotal,
} from './payment';
// import { LiterPriceDifference } from '../../containers/commerce';
import { WATER_ADD_TO_BUCKET } from './actions';
import { STARTUP_COMPLETE } from '../actions';

const deliveryTriggerAll = [
  DELIVERY_TYPE_SET,
  PREFERRED_DELIVERY_TYPE_SET,
];

function* updatePaymentDelivery() {
  while (true) {
    yield take([...deliveryTriggerAll]);
    const isDelivery = (yield select(getDeliveryType)) !== deliveryTypes.no;
    const isPaymentDelivery = yield select(getIsPaymentDelivery);
    if (isDelivery !== isPaymentDelivery) {
      yield put(setPaymentDelivery(isDelivery));
    }
  }
}
const lejeTriggerAll = [
  STARTUP_COMPLETE,
  ITEM_AMOUNT_SET,
  ITEM_SINGLE_AMOUNT_SET,
  ITEM_AMOUNT_INCREMENT,
  ITEM_AMOUNT_DECREMENT,
];
function* updateLeje() {

  const deliveryInfoSelector = createSelector(
    isInSpecialMode,
    hasBestilOrFadoelItem,
    hasOnlyFreeDeliveryItems,
    hasWaterItems,
    hasLejeItem,
    getFreeDeliveryBasis,
    getAmountOfDraughtmaster,
    getTotalNumberOfItems,
    (specialMode, hasAnySodaOrFadoel, onlyFreeDeliveryItems, hasWater, hasLeje, freeDeliveryIndex, amountOfDraughtmaster, numberOfItems) => ({
      specialMode,
      hasAnySodaOrFadoel,
      hasWater,
      hasLeje,
      freeDeliveryIndex,
      amountOfDraughtmaster,
      numberOfItems,
      onlyFreeDeliveryItems,
    }),
  );
  while (true) {
    yield take([...lejeTriggerAll]);
    yield delay(1);
    const deliveryInfo = yield select(deliveryInfoSelector);
    const currentDeliveryInfo = yield select(getDeliveryInfo);
    if (deliveryInfo !== currentDeliveryInfo) {
      yield put(setDeliveryInfo(deliveryInfo));
      if (deliveryInfo.hasLeje && !currentDeliveryInfo.hasLeje) {
        const deliveryType = yield select(getDeliveryType);
        if (deliveryType === deliveryTypes.delivery) {
          yield put(setDeliveryType(deliveryTypes.deliveryAndPickup));
        }
      }
    }
    const isNegative = (yield select(getTotalCost)) < 0;
    const isAlreadyNegative = yield select(getIsNegativeTotal);
    if (isNegative !== isAlreadyNegative) {
      yield put(setPaymentNegativeTotal(isNegative));
    }
  }
}
const deliveryChoiceTrigger = [
  ITEM_AMOUNT_SET,
  ITEM_AMOUNT_INCREMENT,
  ITEM_AMOUNT_DECREMENT,
  WATER_ADD_TO_BUCKET,
];
function* updateDeliveryChoice() {
  const itemChangeChannel = yield actionChannel(deliveryChoiceTrigger);
  while (true) {
    yield take(itemChangeChannel);
    const hasBestilOrFadoel = yield select(hasBestilOrFadoelItem);
    const numberOfItems = yield select(getTotalNumberOfItems);
    const preferredDeliveryType = yield select(getPreferredDeliveryType);
    const hasWater = yield select(hasWaterItems);
    if (!hasBestilOrFadoel && !hasWater && numberOfItems.length > 0) {
      const deliveryError = yield select(state => deliveryTypeError(state, deliveryTypes.delivery));
      if (preferredDeliveryType !== deliveryTypes.delivery && !deliveryError) {
        yield put(setPreferredDeliveryType(deliveryTypes.delivery));
      }
    } else if (hasWater) {
      if (preferredDeliveryType !== deliveryTypes.delivery) {
        yield put(setPreferredDeliveryType(deliveryTypes.delivery));
      }
    } else if (numberOfItems.length === 0) {
      if (preferredDeliveryType !== deliveryTypes.no) {
        yield put(setPreferredDeliveryType(deliveryTypes.no));
      }
    }
  }
}
const palleTriggerAll = [
  ITEM_AMOUNT_SET,
  ITEM_AMOUNT_INCREMENT,
  ITEM_AMOUNT_DECREMENT,
  WATER_ADD_TO_BUCKET,
];

function* palleExtrapolationSaga() {
  const bucketChangeChannel = yield actionChannel(palleTriggerAll);
  while (true) {
    yield take(bucketChangeChannel);
    const {
      realPaller,
      cachedPaller,
      realAnlaeg,
      cachedAnlaeg,
      realWagon,
      cachedWagon,
      cachedDiscountAmount,
      realDiscountAmount,
    } = yield select((state) => {
      const hasFustage = hasFustageItem(state);
      return {
        realPaller: getNumberOfPaller(state),
        cachedPaller: getAmountOfPaller(state),
        realAnlaeg: hasFustage ? 0 : getAmountOfAnlaeg(state),
        cachedAnlaeg: getAmountOfHaner,
        realWagon: hasFustage ? 0 : getAmountOfWagons(state),
        cachedWagon: getAmountOfWagonsCached(state),
        cachedDiscountAmount: getDiscountAmountCached(state),
        realDiscountAmount: getDiscountAmount(state),
      };
    });
    if (realPaller !== cachedPaller) {
      yield put(setPalleAmount(realPaller));
    }
    if (realAnlaeg !== cachedAnlaeg) {
      yield put(setHaneAmount(realAnlaeg));
    }
    if (realWagon !== cachedWagon) {
      yield put(setWagonAmount(realWagon));
    }
    if (realDiscountAmount !== cachedDiscountAmount) {
      yield put(setDiscountAmount(realDiscountAmount));
    }
  }
}


const dangerousGoodTriggers = [
  ITEM_AMOUNT_SET,
  ITEM_AMOUNT_INCREMENT,
  ITEM_AMOUNT_DECREMENT,
  WATER_ADD_TO_BUCKET,
  DELIVERY_TYPE_SET,
  SET_DELIVERY_INFO,
  PREFERRED_DELIVERY_TYPE_SET,
];

function* dangerousGoodsSaga() {
  const bucketChangeChannel = yield actionChannel(dangerousGoodTriggers);
  while (true) {
    yield take(bucketChangeChannel);
    const {
      hasPostNord,
      dangerousGoods,
      alreadyHasFee,
    } = yield select((state) => {

      let hasPostNord = false;
      const deliveryType = getDeliveryType(state);
      if (deliveryType === deliveryTypes.delivery) {
        const subDeliveryType = getSubDeliveryType(dateTypes.delivery)(state);
        if (subDeliveryType === subDeliveryTypes.postNord) {
          hasPostNord = true;
        }
      }
      const itemEntry = getItemEntry(state, { varenr: '663' });
      return {
        hasPostNord,
        dangerousGoods: hasDangerousGoods(state),
        alreadyHasFee: itemEntry ? itemEntry.amount > 0 : false,
      };
    });
    if (hasPostNord && dangerousGoods) {
      if (!alreadyHasFee) {
        yield put(setItemAmount('663', 1));
      }
    } else {
      if (alreadyHasFee) {
        yield put(setItemAmount('663', 0));
      }
    }
  }
}
export default function* rootCommerceSaga() {
  yield all([
    updatePaymentDelivery(),
    updateLeje(),
    palleExtrapolationSaga(),
    updateDeliveryChoice(),
    dangerousGoodsSaga(),
  ]);
}
