import { getInitialState as getInitialItemsState } from './commerce/items';
import { getInitialState as getInitialSearchState } from './commerce/search';
import { getInitialState as getInitialWaterState } from './commerce/water';
import { getInitialState as getInitialPostnrState } from './postnr';
import { getInitialState as getInitialPlejerlisteState } from './plejerliste';
import { getInitialState as getInitialCurrencyState } from './currency';

export default state => ({
  ...state,
  plejerliste: getInitialPlejerlisteState(),
  postnr: getInitialPostnrState(),
  currency: getInitialCurrencyState(),
  commerce: {
    ...(state.commerce || {}),
    items: getInitialItemsState((state.commerce || {}).items),
    search: getInitialSearchState(),
    water: getInitialWaterState(),
  },
});
