import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { ActionLinkNegating } from '../../../../../../components/typography';
import { BsChevronRight } from '../../../../../../components/Icons';

const StyledMenuLi = styled.li`
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: block;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.colorGreyDark};
  &:active, &:hover, &:link, &:visited {
    color: ${({ theme }) => theme.colors.colorGreyDark};
  }
`;
const StyledActionLink = styled(ActionLinkNegating)`
  text-decoration: none;
  display: block;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.colorGreyDark};
  &:active, &:hover, &:link, &:visited {
    color: ${({ theme }) => theme.colors.colorGreyDark}
  }
`;
const StyledContentDiv = styled.div`
  position: relative;
  padding: 16px 14px 14px 14px;
  text-align: left;
  border-bottom: 1px solid ${({ theme }) => theme.colors.colorGreyLightAlt};
  &:hover {
    color: ${({ theme }) => theme.hoverColors.colorBlue};
  }
  ${StyledLink}:active &, ${StyledActionLink}:active & {
    color: ${({ theme }) => theme.colors.colorBlue};
  }
`;
const StyledIcon = styled.div`
  fill: ${({ theme }) => theme.colors.colorGreyDark};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  display: block;
  font-size: 20px;
  margin-top: 1px;
`;

export default ({
  title,
  expandable,
  onClick,
  to,
  bigScreens,
}) => {
  if (bigScreens) {
    return '';
  }
  if (expandable) {
    return (
      <StyledMenuLi>
        <StyledActionLink onClick={onClick}>
          <StyledContentDiv>
            {title} <StyledIcon><BsChevronRight /></StyledIcon>
          </StyledContentDiv>
        </StyledActionLink>
      </StyledMenuLi>
    );
  }
  return (
    <StyledMenuLi>
      <StyledLink to={to} onClick={onClick}>
        <StyledContentDiv>
          {title}
        </StyledContentDiv>
      </StyledLink>
    </StyledMenuLi>
  );
};
