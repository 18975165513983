
import getArg from './lib/args';

export default function getImageRequestName(name, size) {
  const [fileType, ...reverseFileName] = name.split('.').reverse();
  const fileName = reverseFileName.reverse().join('.');
  const version = 1;
  const origin = getArg('host') ? getArg('host') : (window ? window.location.origin : '');
  if (size) {
    return `${origin}/responsiveImage/${version}/${size}/${fileName}.${fileType}`;
  }
  return `${origin}/image/${version}/${fileName}.${fileType}`;
}

export const getImageRequestNameWebp = (name, size) => getImageRequestName(`${name}.webp`, size);
