import moment from "moment";
import { deliveryTypes } from "../shared";
import { dayFees, dayFormat } from "../../../../content/commerce/closedDates";
import { dhlDatoer } from "../../../../components/Datoer";
import { CHRISTMAS_FEES_ENABLED } from "../../../../settings";

export const storeDateFormat = "YYYYMMDD";
export const isSunday = (date) =>
  date && moment(date, storeDateFormat).isoWeekday() === 7;
export const isSaturday = (date) =>
  date && moment(date, storeDateFormat).isoWeekday() === 6;
export const isToday = (date) => moment().format(storeDateFormat) === date;
export const isTomorrow = (date) =>
  moment()
    .add(1, "days")
    .format(storeDateFormat) === date;
export const isBefore = (date, otherDate) =>
  moment(date, storeDateFormat).isBefore(moment(otherDate, storeDateFormat));
export const isChristmas = (date) => {
  if (!CHRISTMAS_FEES_ENABLED || !date) {
    return false;
  }
  const format = date.format("MMDD");
  return format >= 1122 && format <= 1224;
};
export const isDHL = (date) => {
  if (!date) {
    return false;
  }
  const format = date.format("MMDD");
  return format >= dhlDatoer.from.number && format <= dhlDatoer.to.number;
};

export const dateTypes = {
  delivery: "DELIVERY",
  pickup: "PICKUP",
};

export const priceFees = {
  sunday: "SUNDAY_FEE",
  christmas: "CHRISTMAS_FEE",
};
export const priceReasons = {
  [priceFees.sunday]: {
    varenr: 623,
    onlyOnce: false,
    validator: ({ deliveryType, date, type }) => {
      if (
        deliveryType === deliveryTypes.no ||
        date === null ||
        (deliveryType === deliveryTypes.delivery && type === dateTypes.pickup)
      ) {
        return false;
      }
      return isSunday(date);
    },
  },
  [priceFees.christmas]: {
    varenr: 622,
    onlyOnce: true,
    validator: ({ deliveryType, date, type }) => {
      if (
        deliveryType === deliveryTypes.no ||
        date === null ||
        (deliveryType === deliveryTypes.delivery && type === dateTypes.pickup)
      ) {
        return false;
      }
      return isChristmas(moment(date, storeDateFormat));
    },
  },
};

export const dateModes = {
  none: "NONE",
  sameDay: "SAME_DAY",
  night: "NIGHT",
  deliverySunday: "DELIVERY_SUNDAY",
  deliverySaturday: "DELIVERY_SATURDAY",
  noWeekend: "NO_WEEKEND",
  interval: "INTERVAL",
  noNight: "NO_NIGHT",
  noDelivery: "NO_DELIVERY",
};
export const sameDayChoices = {
  early: "SAME_DAY_EARLY",
  late: "SAME_DAY_LATE",
};
export const nightChoices = {
  early: "NIGHT_EARLY",
  medium: "NIGHT_MEDIUM",
  late: "NIGHT_LATE",
  afterMidnight: "AFTER_MIDNING",
  dhlDelivery: "NIGHT_DHLDel",
  dhl: "NIGHT_DHL",
};
export const noWeekendChoices = {
  morning: "NO_WEEKEND_MORNING",
  afternoon: "NO_WEEKEND_AFTERNOON",
  midday: "NO_WEEKEND_MIDDAY",
  evening: "NO_WEEKEND_EVENING",
  night: "NO_WEEKEND_NIGHT",
};

export const noWeekdayChoices = {
  regular: "NO_WEEKDAY_REGULAR",
  special: "NO_WEEKDAY_SPECIAL",
  // corona19
  corona1: "CORONA1",
  corona2: "CORONA2",
};

export const noNightChoices = {
  early: "NO_NIGHT_EARLY",
  medium: "NO_NIGHT_MEDIUM",
  late: "NO_NIGHT_LATE",
  afterMidnight: "NO_AFTER_MIDNIGHT",
};
export const deliverySaturdayChoices = {
  morning: "DELIVERY_SATURDAY_MORNING",
  morningNotHome: "DELIVERY_SATURDAY_MORNING_NOT_HOME",
  afternoon: "DELIVERY_SATURDAY_AFTERNOON",
  midday: "DELIVERY_SATURDAY_MIDDAY",
  evening: "DELIVERY_SATURDAY_EVENING",
  night: "DELIVERY_SATURDAY_NIGHT",
  afterMidnight: "DELIVERY_SATURDAY_AFTER_MIDNIGHT",
};

export const deliverySundayChoices = {
  afternoon: "DELIVERY_SUNDAY_AFTERNOON",
  afternoonNotHome: "DELIVERY_SUNDAY_AFTERNOON_NOT_HOME",
  midday: "DELIVERY_SUNDAY_MIDDAY",
  evening: "DELIVERY_SUNDAY_EVENING",
  night: "DELIVERY_SUNDAY_NIGHT",
  afterMidnight: "DELIVERY_SUNDAY_AFTER_MIDNIGHT",
};
export const timeTranslation = {
  [sameDayChoices.early]: {
    varenr: 624,
    amount: 1,
    night: false,
    time: "6:00 - 12:00",
    shortTime: "6:00 - 12:00",
    removeAfter: "12:00",
  },
  [sameDayChoices.late]: {
    varenr: 625,
    amount: 1,
    night: false,
    time: "12:00 - 16:00",
    shortTime: "12:00 - 16:00",
    removeAfter: "16:00",
  },
  [nightChoices.early]: {
    varenr: 626,
    amount: 1,
    night: true,
    time: "16:00 - 20:00",
    shortTime: "16:00 - 20:00",
    removeAfter: "20:00",
  },
  [nightChoices.medium]: {
    varenr: 627,
    amount: 1,
    night: true,
    time: "20:00 - 22:00",
    shortTime: "20:00 - 22:00",
    removeAfter: "22:00",
  },
  [nightChoices.late]: {
    varenr: 628,
    amount: 1,
    night: true,
    time: "22:00 - 24:00",
    shortTime: "22:00 - 24:00",
  },
  [nightChoices.afterMidnight]: {
    varenr: 629,
    amount: 1,
    time: "24:00 - 06:00",
    shortTime: "24:00 - 06:00",
  },
  [nightChoices.dhlDelivery]: {
    varenr: 636,
    amount: 1,
    night: true,
    time: "DHL 12 - 16",
    shortTime: "DHL 12:00 - 16:00",
    isPossible: (dateType, date) =>
      dateType === dateTypes.delivery && isDHL(moment(date, storeDateFormat)),
  },
  [nightChoices.dhl]: {
    varenr: 637,
    amount: 1,
    night: true,
    time: "DHL 22:30-00:30",
    shortTime: "DHL 22:30-00:30",
    isPossible: (dateType, date) =>
      dateType === dateTypes.pickup && isDHL(moment(date, storeDateFormat)),
  },
  [noWeekendChoices.morning]: {
    varenr: 650,
    amount: 1,
    time: "8:00 - 13:00",
    shortTime: "8:00 - 13:00",
    removeAfter: "13:00",
  },
  [noWeekendChoices.afternoon]: {
    varenr: 650,
    amount: 1,
    time: "13:00 - 16:00",
    shortTime: "13:00 - 16:00",
    removeAfter: "16:00",
  },
  [noWeekendChoices.midday]: {
    varenr: 651,
    amount: 1,
    time: "16:00 - 20:00",
    shortTime: "16:00 - 20:00",
    removeAfter: "20:00",
  },
  [noWeekendChoices.evening]: {
    varenr: 652,
    amount: 1,
    time: "20:00 - 22:00",
    shortTime: "20:00 - 22:00",
    removeAfter: "22:00",
  },
  [noWeekendChoices.night]: {
    varenr: 653,
    amount: 1,
    time: "22:00 - 24:00",
    shortTime: "22:00 - 24:00",
  },
  [noWeekdayChoices.regular]: {
    varenr: 654,
    amount: 0,
    removeFee: () => true,
    time: "7:00 - 16:00",
    shortTime: "7:00 - 16:00",
    removeAfter: "16:00",
  },
  // corona19
  [noWeekdayChoices.corona1]: {
    varenr: 654,
    amount: 0,
    removeFee: () => true,
    time: "7:00 - 9:00",
    shortTime: "7:00 - 9:00",
    removeAfter: "9:00",
  },
  [noWeekdayChoices.corona2]: {
    varenr: 654,
    amount: 0,
    removeFee: () => true,
    time: "13:00 - 16:00",
    shortTime: "13:00 - 16:00",
    removeAfter: "16:00",
  },
  [noWeekdayChoices.special]: {
    varenr: 654,
    amount: 0,
    removeFee: () => true,
    time: "8:00 - 13:00",
    shortTime: "8:00 - 13:00",
    special: true,
    removeAfter: "13:00",
  },
  [noNightChoices.early]: {
    varenr: 651,
    amount: 1,
    time: "16:00 - 20:00",
    shortTime: "16:00 - 20:00",
    removeAfter: "20:00",
  },
  [noNightChoices.medium]: {
    varenr: 652,
    amount: 1,
    time: "20:00 - 22:00",
    shortTime: "20:00 - 22:00",
    removeAfter: "22:00",
  },
  [noNightChoices.late]: {
    varenr: 653,
    amount: 1,
    time: "22:00 - 24:00",
    shortTime: "22:00 - 24:00",
  },
  [noNightChoices.afterMidnight]: {
    varenr: 654,
    amount: 1,
    time: "24:00 - 06:00",
    shortTime: "24:00 - 06:00",
  },
  [deliverySaturdayChoices.morning]: {
    varenr: 624,
    amount: 1,
    removeFee: (date) => date && !isToday(date.format(storeDateFormat)),
    time: "8:00 - 13:00",
    shortTime: "8:00 - 13:00",
    removeAfter: "13:00",
  },
  [deliverySaturdayChoices.morningNotHome]: {
    varenr: 624,
    amount: 1,
    removeFee: (date) => date && !isToday(date.format(storeDateFormat)),
    time: "Tag selv anlægget, jeg er ikke hjemme (hele dagen) ",
    shortTime: "Tag selv anlægget, jeg er ikke hjemme (hele dagen) ",
    removeAfter: "16:00",
  },
  [deliverySaturdayChoices.afternoon]: {
    varenr: 625,
    amount: 1,
    time: "13:00 - 16:00",
    shortTime: "13:00 - 16:00",
    removeAfter: "16:00",
  },
  [deliverySaturdayChoices.midday]: {
    varenr: 626,
    amount: 1,
    time: "16:00 - 20:00",
    shortTime: "16:00 - 20:00",
    removeAfter: "20:00",
  },
  [deliverySaturdayChoices.evening]: {
    varenr: 627,
    amount: 1,
    time: "20:00 - 22:00",
    shortTime: "20:00 - 22:00",
    removeAfter: "22:00",
  },
  [deliverySaturdayChoices.night]: {
    varenr: 628,
    amount: 1,
    time: "22:00 - 24:00",
    shortTime: "22:00 - 24:00",
  },
  [deliverySaturdayChoices.afterMidnight]: {
    varenr: 629,
    amount: 1,
    time: "24:00 - 06:00",
    shortTime: "24:00 - 06:00",
  },
  [deliverySundayChoices.afternoon]: {
    varenr: 623,
    amount: 1,
    time: "12:00 - 15:00",
    shortTime: "12:00 - 15:00",
    removeAfter: "15:00",
  },
  [deliverySundayChoices.afternoonNotHome]: {
    varenr: 623,
    amount: 1,
    time: "Tag selv anlægget, jeg er ikke hjemme (hele dagen) ",
    shortTime: "Tag selv anlægget, jeg er ikke hjemme (hele dagen) ",
    removeAfter: "16:00",
  },
  [deliverySundayChoices.midday]: {
    varenr: 626,
    amount: 1,
    time: "15:00 - 20:00",
    shortTime: "15:00 - 20:00",
    removeAfter: "20:00",
  },
  [deliverySundayChoices.evening]: {
    varenr: 627,
    amount: 1,
    time: "20:00 - 22:00",
    shortTime: "20:00 - 22:00",
    removeAfter: "22:00",
  },
  [deliverySundayChoices.night]: {
    varenr: 628,
    amount: 1,
    time: "22:00 - 24:00",
    shortTime: "22:00 - 24:00",
  },
  [deliverySundayChoices.afterMidnight]: {
    varenr: 629,
    amount: 1,
    time: "24:00 - 06:00",
    shortTime: "24:00 - 06:00",
  },
};
const actionPrefix = "SKAFTE/COMMERCE/DATES/";

export const RESET_DATE = `${actionPrefix}RESET_DATE`;
export const VALIDATE_STATE = `${actionPrefix}VALIDATE_STATE`;
export const SET_DATE = `${actionPrefix}SET_DATE`;
export const SET_MODE = `${actionPrefix}SET_MODE`;
export const SET_TIME = `${actionPrefix}SET_TIME`;

const specialDays = ["20191223", "20191227", "20191230"];
export const createGetAvailableTimes = (dateType, possibleTimes) => {
  const memoer = {};
  return (chosenDate, currentTime) => {
    const isTodayDate = isToday(chosenDate);
    const actuallyPossibleTimes = possibleTimes.filter((time) => {
      const { removeAfter, isPossible, special } = timeTranslation[time];
      if (isPossible && !isPossible(dateType, chosenDate)) {
        return false;
      }
      if (specialDays.includes(chosenDate)) {
        if (!special) {
          return false;
        }
      } else if (special) {
        return false;
      }

      if (isTodayDate && removeAfter) {
        return moment(currentTime).isBefore(
          moment(removeAfter, ["h:m a", "H:m"])
        );
      }
      return true;
    });
    if (
      !memoer.response ||
      memoer.response.length !== actuallyPossibleTimes.length
    ) {
      memoer.response = actuallyPossibleTimes;
    }
    return memoer.response;
  };
};

export const validateState = () => ({
  type: VALIDATE_STATE,
});
export const resetDate = (dateType) => () => ({
  type: RESET_DATE,
  payload: {
    dateType,
  },
});

export const setDate = (deliveryType) => (dateType) => (date) => ({
  type: SET_DATE,
  payload: {
    deliveryType,
    dateType,
    date: date.format(storeDateFormat),
  },
});

export const setTime = (deliveryType) => (dateType) => (time) => ({
  type: SET_TIME,
  payload: {
    deliveryType,
    dateType,
    time,
  },
});

export const setMode = (deliveryType) => (dateType) => (mode) => ({
  type: SET_MODE,
  payload: {
    deliveryType,
    dateType,
    mode,
  },
});

export const combineFees = (feesA, feesB) => {
  const combinedFees = Object.keys(feesA).reduce((fees, feeKey) => {
    let fee = feesA[feeKey];
    if (fees[feeKey]) {
      fee = {
        ...fees[feeKey],
        amount: fee.dontStack ? fee.amount : fee.amount + fees[feeKey].amount,
      };
    }
    return {
      ...fees,
      [feeKey]: fee,
    };
  }, feesB);
  const shouldBeRemovedFees = Object.keys(combinedFees).reduce(
    (shouldBeRemoved, key) => {
      if (combinedFees[key].overrides) {
        return [...shouldBeRemoved, ...combinedFees[key].overrides];
      }
      return shouldBeRemoved;
    },
    []
  );
  for (let i = 0; i < shouldBeRemovedFees.length; i += 1) {
    if (combinedFees[shouldBeRemovedFees[i]]) {
      delete combinedFees[shouldBeRemovedFees[i]];
    }
  }
  return combinedFees;
};

export const subtractFees = (feesA, feesB) => {
  const firstResult = Object.keys(feesA).reduce((fees, feeKey) => {
    let fee = feesA[feeKey];
    if (feesB[feeKey]) {
      fee = {
        ...fee,
        amount: fee.amount - feesB[feeKey].amount,
      };
    }
    return {
      ...fees,
      [feeKey]: fee,
    };
  }, {});
  const res = Object.keys(feesB).reduce((fees, feeKey) => {
    if (!fees[feeKey]) {
      return {
        ...fees,
        [feeKey]: {
          ...feesB[feeKey],
          amount: -feesB[feeKey].amount,
        },
      };
    }
    return fees;
  }, firstResult);
  return res;
};
export const getTimeFee = (time) => {
  if (!time) {
    return {};
  }
  const { varenr, amount, removeFee } = timeTranslation[time];
  return {
    [varenr]: {
      amount,
      removeFee,
    },
  };
};

export const getDateFee = (date) => {
  let fee = {};
  if (!date) {
    return fee;
  }
  const dayFee = dayFees[date.format(dayFormat)];
  if (dayFee) {
    fee = {
      ...fee,
      636: {
        amount: dayFee,
      },
    };
  }
  return fee;
};
