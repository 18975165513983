import React from 'react';
import { sanitizePrice } from '../../lib/price';
import { sanitizeMeasurement } from '../../lib/helpers';
import MoneyFormat from './MoneyFormat';

export default ({
  liter,
  price,
  moms,
  noSuffix,
  ...props
}) => {
  const priceVal = sanitizePrice(price);
  const sanVal = sanitizeMeasurement(liter);
  return (
    <MoneyFormat
      money={priceVal / sanVal}
      moms={moms}
      noSuffix={noSuffix}
      {...props}
    />
  );
};
