import { createSelector } from "reselect";
import orderBy from "lodash/orderBy";
import { getItemDefaultInformation } from "../../../content/commerce/items";

export const getLocalState = (state) => state.commerce.bucket;
export const getAmountOfPaller = (state) =>
  state.commerce.bucket.amountOfPaller;
export const getAmountOfHaner = (state) => state.commerce.bucket.amountOfHaner;
export const getAmountOfWagons = (state) =>
  state.commerce.bucket.amountOfWagons;
export const getDiscountAmount = (state) =>
  state.commerce.bucket.discountAmount;

export const getBucketDifferences = (oldBucketState, newBucketState) => {
  const oldItems = oldBucketState.items;
  const newItems = newBucketState.items;
  const differences = {
    ADDED_ITEMS: [],
    REMOVED_ITEMS: [],
  };
  Object.keys(newItems).forEach((key) => {
    if (oldItems[key] !== newItems[key]) {
      const { amount: newAmount } = newItems[key];
      const { amount: oldAmount = 0 } = oldItems[key] || {};
      const difference = newAmount - oldAmount;
      if (difference > 0) {
        differences.ADDED_ITEMS.push({
          varenr: key.substring(1),
          amount: difference,
        });
      } else if (difference < 0) {
        differences.REMOVED_ITEMS.push({
          varenr: key.substring(1),
          amount: Math.abs(difference),
        });
      }
    }
  });
  Object.keys(oldItems).forEach((key) => {
    if (!newItems[key]) {
      differences.REMOVED_ITEMS.push({
        varenr: key.substring(1),
        amount: oldItems[key].amount,
      });
    }
  });
  return differences;
};

const getItemAmountFromBucketItems = (bucketItems, varenr) =>
  bucketItems[`v${varenr}`] ? bucketItems[`v${varenr}`].amount : 0;
const getItemSingleAmountFromBucketItems = (bucketItems, varenr) =>
  bucketItems[`v${varenr}`] ? bucketItems[`v${varenr}`].singleAmount : 0;
export function getItemAmount(state, { varenr }) {
  if (varenr === "888") {
    return getAmountOfPaller(state);
  }
  /* if (varenr === '725') { bobli discount
    return getDiscountAmount(state);
  } */
  if (varenr === "603") {
    return getAmountOfHaner(state);
  }
  if (varenr === "605") {
    return getAmountOfWagons(state);
  }
  if (varenr == "706" || varenr == "712") {
    // eslint-disable-line
    const amountOfKulsyre = getItemAmount(state, { varenr: "8940" });
    if (varenr == "706") {
      // eslint-disable-line
      return amountOfKulsyre >= 6 && amountOfKulsyre <= 15
        ? amountOfKulsyre
        : 0;
    }
    if (varenr == "712") {
      // eslint-disable-line
      return amountOfKulsyre >= 16 ? amountOfKulsyre : 0;
    }
    return 0;
  }
  if (varenr == "705" || varenr == "711") {
    // eslint-disable-line
    const amountOfKulsyre = getItemAmount(state, { varenr: "8944" });
    if (varenr == "705") {
      // eslint-disable-line
      return amountOfKulsyre >= 6 && amountOfKulsyre <= 15
        ? amountOfKulsyre
        : 0;
    }
    if (varenr == "711") {
      // eslint-disable-line
      return amountOfKulsyre >= 16 ? amountOfKulsyre : 0;
    }
    return 0;
  }
  return getItemAmountFromBucketItems(state.commerce.bucket.items, varenr);
}
export const getKulsyreDiscountAmounts = createSelector(
  (state) => getItemAmount(state, { varenr: "706" }),
  (state) => getItemAmount(state, { varenr: "712" }),
  (state) => getItemAmount(state, { varenr: "705" }),
  (state) => getItemAmount(state, { varenr: "711" }),
  (a, b, c, d) => ({
    706: a,
    712: b,
    705: c,
    711: d,
  })
);
export const getItemSingleAmount = (state, { varenr }) =>
  getItemSingleAmountFromBucketItems(state.commerce.bucket.items, varenr);

export const getItemAmounts = createSelector(
  (state) => state.commerce.bucket.items,
  (state, { items }) => items,
  getAmountOfPaller,
  getAmountOfHaner,
  getAmountOfWagons,
  getDiscountAmount,
  getKulsyreDiscountAmounts,
  (
    bucketItems,
    items,
    amountOfPaller,
    amountOfHaner,
    amountOfWagons,
    discountAmount,
    kulsyreDiscountAmounts
  ) =>
    items.map((varenr) => {
      if (varenr === "888") {
        return {
          varenr,
          amount: amountOfPaller,
          singleAmount: 0,
        };
      }
      /* if (varenr === '725') { bobli discount
        return {
          varenr,
          amount: discountAmount,
          singleAmount: 0,
        };
      } */
      if (varenr === "603") {
        return {
          varenr,
          amount: amountOfHaner,
          singleAmount: 0,
        };
      }
      if (varenr === "605") {
        return {
          varenr,
          amount: amountOfWagons,
          singleAmount: 0,
        };
      }
      const kulsyreDiscountAmount = kulsyreDiscountAmounts[varenr];
      if (typeof kulsyreDiscountAmount !== "undefined") {
        return {
          varenr,
          amount: kulsyreDiscountAmount,
          singleAmount: 0,
        };
      }
      return {
        varenr,
        amount: getItemAmountFromBucketItems(bucketItems, varenr),
        singleAmount: getItemSingleAmountFromBucketItems(bucketItems, varenr),
      };
    })
);

export const kulsyreOrder = ["705", "706", "711", "712"];
export const getBucketItemEntries = createSelector(
  (state) => state.commerce.bucket.items,
  getAmountOfPaller,
  getAmountOfHaner,
  getAmountOfWagons,
  getDiscountAmount,
  getKulsyreDiscountAmounts,
  (
    bucketItems,
    amountOfPaller,
    amountOfHaner,
    amountOfWagons,
    discountAmount,
    kulsyreDiscountAmounts
  ) => {
    let bucketItemEntries = Object.keys(bucketItems).map((vVarenr) => {
      const bucketItem = bucketItems[vVarenr];
      const { amount, singleAmount, isWater } = bucketItem;
      if (isWater) {
        const { lid, size, flavour, uploadId: origUploadId } = bucketItem;
        const uploadId = origUploadId && origUploadId.uploadId;
        return {
          isWater: true,
          lid,
          size,
          flavour,
          uploadId,
          amount,
        };
      }
      const varenr = vVarenr.substring(1);
      return {
        varenr,
        amount,
        singleAmount,
      };
    });
    for (let i = 0; i < kulsyreOrder.length; i += 1) {
      const vn = kulsyreOrder[i];
      if (kulsyreDiscountAmounts[vn] !== 0) {
        bucketItemEntries = [
          ...bucketItemEntries,
          { varenr: vn, amount: kulsyreDiscountAmounts[vn], singleAmount: 0 },
        ];
      }
    }
    if (amountOfPaller > 0) {
      bucketItemEntries = [
        ...bucketItemEntries,
        { varenr: "888", amount: amountOfPaller, singleAmount: 0 },
      ];
    }
    if (amountOfHaner > 0) {
      bucketItemEntries = [
        ...bucketItemEntries,
        { varenr: "603", amount: amountOfHaner, singleAmount: 0 },
      ];
    }
    if (amountOfWagons > 0) {
      bucketItemEntries = [
        ...bucketItemEntries,
        { varenr: "605", amount: amountOfWagons, singleAmount: 0 },
      ];
    }
    /* if (discountAmount > 0) { bobli discount
      bucketItemEntries = [
        ...bucketItemEntries,
        { varenr: '725', amount: discountAmount, singleAmount: 0 },
      ];
    } */
    return bucketItemEntries;
  }
);

export const getTotalNumberOfItems = (state) =>
  Object.keys(state.commerce.bucket.items).reduce(
    (items, varenr) =>
      items + (state.commerce.bucket.items[varenr].amount || 1),
    0
  );

export const getBucketItems = createSelector(
  (state) => state.commerce.bucket.items,
  getAmountOfPaller,
  getAmountOfHaner,
  getAmountOfWagons,
  getDiscountAmount,
  getKulsyreDiscountAmounts,
  (
    bucketItems,
    amountOfPaller,
    amountOfHaner,
    amountOfWagons,
    discountAmount,
    kulsyreDiscountAmounts
  ) => {
    const items = Object.keys(bucketItems).map((varenr) => ({
      varenr: varenr.substring(1),
      ...bucketItems[varenr],
    }));
    let sortedItems = orderBy(items, ["order"], ["asc"]).map(
      ({ varenr }) => varenr
    );
    for (let i = 0; i < kulsyreOrder.length; i += 1) {
      const vn = kulsyreOrder[i];
      if (kulsyreDiscountAmounts[vn] !== 0) {
        sortedItems = [...sortedItems, vn];
      }
    }
    if (amountOfPaller > 0) {
      sortedItems = [...sortedItems, "888"];
    }
    if (amountOfHaner > 0) {
      sortedItems = [...sortedItems, "603"];
    }
    if (amountOfWagons > 0) {
      sortedItems = [...sortedItems, "605"];
    }
    /* if (discountAmount > 0) { bobli discount
      sortedItems = [...sortedItems, '725'];
    } */
    return sortedItems;
  }
);

export const getBucketItemsWithoutWater = createSelector(
  (state) => state.commerce.bucket.items,
  getAmountOfPaller,
  getAmountOfHaner,
  getAmountOfWagons,
  getDiscountAmount,
  getKulsyreDiscountAmounts,
  (
    bucketItems,
    amountOfPaller,
    amountOfHaner,
    amountOfWagons,
    discountAmount,
    kulsyreDiscountAmounts
  ) => {
    const items = Object.keys(bucketItems)
      .filter((varenr) => !bucketItems[varenr].isWater)
      .map((varenr) => ({
        varenr: varenr.substring(1),
        ...bucketItems[varenr],
      }));
    let sortedItems = orderBy(items, ["order"], ["asc"]).map(
      ({ varenr }) => varenr
    );
    for (let i = 0; i < kulsyreOrder.length; i += 1) {
      const vn = kulsyreOrder[i];
      if (kulsyreDiscountAmounts[vn] !== 0) {
        sortedItems = [...sortedItems, vn];
      }
    }
    if (amountOfPaller > 0) {
      sortedItems = [...sortedItems, "888"];
    }
    if (amountOfHaner > 0) {
      sortedItems = [...sortedItems, "603"];
    }
    if (amountOfWagons > 0) {
      sortedItems = [...sortedItems, "605"];
    }
    /* if (discountAmount > 0) { bobli discount
      sortedItems = [...sortedItems, '725'];
    } */
    return sortedItems;
  }
);
export const getWaterBucketItems = createSelector(
  (state) => state.commerce.bucket.items,
  (bucketItems) => {
    const items = Object.keys(bucketItems)
      .filter((varenr) => bucketItems[varenr].isWater)
      .map((varenr) => ({
        varenr: varenr.substring(1),
        ...bucketItems[varenr],
      }));
    return orderBy(items, ["order"], ["asc"]).map(({ varenr }) => varenr);
  }
);
export const getItemEntry = (state, { varenr }) => {
  if (varenr === "888") {
    return { amount: getAmountOfPaller(state), singleAmount: 0, order: 999997 };
  }
  /* if (varenr === '725') { bobli discount
    return { amount: getDiscountAmount(state), singleAmount: 0, order: 1000000 };
  } */
  if (varenr === "603") {
    return { amount: getAmountOfHaner(state), singleAmount: 0, order: 999998 };
  }
  if (varenr === "605") {
    return { amount: getAmountOfWagons(state), singleAmount: 0, order: 999999 };
  }
  if (kulsyreOrder.includes(varenr)) {
    return getItemAmount(state, { varenr });
  }
  return state.commerce.bucket.items[`v${varenr}`];
};

export const getItemEntries = createSelector(
  (state) => state.commerce.bucket.items,
  (bucketItems) => {
    const items = Object.keys(bucketItems).map((varenr) => ({
      varenr: varenr.substring(1),
      ...bucketItems[varenr],
    }));
    const sortedItems = orderBy(items, ["order"], ["asc"]).map(
      ({ order, ...props }) => props
    );
    return sortedItems;
  }
);
export const isWaterItem = createSelector(
  (state) => state.commerce.bucket.items,
  (state, { varenr }) => varenr,
  (bucketItems, varenr) =>
    Boolean(bucketItems[`v${varenr}`] && bucketItems[`v${varenr}`].isWater)
);

export const isOfferingRoyalUnibrewOffer = (state) =>
  state.commerce.bucket.offerRoyalUnibrewOffer;
export const isOfferingHeinekenOffer = (state) =>
  state.commerce.bucket.offerHeinekenOffer;
export const isOfferingHerslevOffer = (state) =>
  state.commerce.bucket.offerHerslevOffer;

export const hasDangerousGoods = createSelector(
  getItemEntries,
  (items) =>
    items.some(({ varenr }) => {
      const defaultInformation = getItemDefaultInformation(varenr);
      if (!defaultInformation) {
        return false;
      }
      if (defaultInformation.farligtGods) {
        return true;
      }
      return false;
    })
);

export const hasMoreThanTwoNoerrebroInBucket = (bucketItems) => {
  const noerrebroItems = Object.keys(bucketItems).reduce((acc, varenr) => {
    const actualVarenr = varenr.substring(1);
    const { amount } = bucketItems[varenr];
    const defaultInformation = getItemDefaultInformation(actualVarenr);
    if (!defaultInformation) {
      return acc;
    }
    if (defaultInformation.norrebro) {
      return acc + amount;
    }
    return acc;
  }, 0);
  return noerrebroItems > 1;
};
