export const dhlDatoer = {
  from: {
    text: "26. august",
    number: 826,
  },
  to: {
    text: "30. august",
    number: 830,
  },
};
export default ({ type }) => {
  switch (type) {
    case "julebrygLastYear":
      return "nov. 2024";
    case "julebryg":
      return "3. nov. 2025";
    case "julebrygNextYear":
      return "nov. 2025";
    case "carlsberg47":
      return "3. nov. 2024";
    case "draughtmaster":
      return "23. marts 2020";
    case "paaskebryg":
      return "1. marts 2024";
    case "roedtuborg":
      return "17. april 2024";
    case "oktoberfest":
      return "september 2025";
    case "thisYear":
      return new Date().getFullYear();
    case "experience":
      return new Date().getFullYear() - 1984;
    case "2020":
      return "2020";
    case "dhlYear":
      return "2024";
    case "dhlFrom":
      return dhlDatoer.from.text;
    case "dhlTo":
      return dhlDatoer.to.text;
    default:
      return "";
  }
};
