import React from 'react';
import { connect } from 'react-redux';
import { sanitizePrice } from '../../lib/price';
import { sanitizeMeasurement } from '../../lib/helpers';
import Money from './Money';
import { getItem } from '../../features/commerce';
import { getItemTypeIsPalle } from '../../features/commerce/items';

export default connect((state, { varenr }) => {
  let items = null;
  if (Array.isArray(varenr)) {
    items = varenr.map(
      (entry) => {
        if (Array.isArray(entry)) {
          const [varenr, amount] = entry;
          const item = getItem(state, { varenr });
          return {
            item,
            amount,
          };
        }
        return {
          item: getItem(state, { varenr: entry }),
          amount: 1,
        };
      },
    );
  } else {
    items = [{
      item: getItem(state, { varenr }),
      amount: 1,
    }];
  }
  return ({
    items,
    palle: getItem(state, { varenr: '888' }),
  });
})(({
  items,
  prStk,
  itemPrice,
  eurPalle,
  pant,
  embPrice,
  literPrice,
  add,
  numberOfPaller,
  palle: {
    values: {
      totalCasePrice: pallePrice,
    },
  },
  ...props
}) => {
  let priceVal = items.reduce(
    (acc, { item, amount }) => {
      const {
        values: {
          price,
          countPrColli,
          countPrPalle,
          itemType,
          liter,
          totalCasePrice,
          deposit,
        },
      } = item;
      let innerPriceVal = 0;
      let count = countPrColli;
      if (getItemTypeIsPalle(itemType)) {
        count *= countPrPalle;
      }
      if (itemPrice) {
        if (getItemTypeIsPalle(itemType)) {
          innerPriceVal += sanitizePrice(price) * countPrPalle * amount;
        } else {
          innerPriceVal += sanitizePrice(price) * amount;
        }
      }
      if (pant) {
        if (getItemTypeIsPalle(itemType)) {
          innerPriceVal += sanitizePrice(deposit) * countPrColli * countPrPalle * amount;
        } else {
          innerPriceVal += sanitizePrice(deposit) * countPrColli * amount;
        }
      }
      if (embPrice) {
        innerPriceVal += sanitizePrice(totalCasePrice) * amount;
      }
      if (prStk) {
        innerPriceVal /= sanitizeMeasurement(count);
      }
      if (literPrice) {
        innerPriceVal += sanitizePrice(price) * amount / liter;
      }

      return acc + innerPriceVal;
    },
    0,
  );
  if (numberOfPaller) {
    priceVal += numberOfPaller * sanitizePrice(pallePrice);
  }
  if (add) {
    priceVal += parseFloat(`${add}`);
  }
  return (
    <Money
      money={priceVal}
      {...props}
    />
  );
});
