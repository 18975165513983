import moment from "moment";

export const dateFormat = "YYYYMMDD";
export const isToday = (date) =>
  date && moment().format(dateFormat) === date.format(dateFormat);
export const isTomorrow = (date) =>
  date &&
  moment()
    .add(1, "days")
    .format(dateFormat) === date.format(dateFormat);

export const getSelfText =
  "Tag selv anlægget, jeg er ikke hjemme (hele dagen) ";
