import { combineReducers } from 'redux';
import { deliveryTypes } from '../shared';
import noReducer, {
  getDate as noGetDate,
  getTime as noGetTime,
  getMode as noGetMode,
  getDisabledWeekDays as noGetDisabledWeekdays,
  getDisabledDays as noGetDisabledDays,
  getAvailableTimes as noGetAvailableTimes,
  getMinDate as noGetMinDate,
  getIsDisabled as noGetIsDisabled,
  getActualTime as noGetActualTime,
  getTimeFee as noGetTimeFee,
  getDateFee as noGetDateFee,
  getFees as noGetFees,
  getPriceComposition as noGetPriceComposition,
  getNormalDateFees as noGetNormalDateFees,
  getDateExtraFee as noGetDateExtraFee,
  isRequired as noIsRequired,
  validate as noValidate,
  hasDateAsPossibility as noHasDateAsPossibility,
} from './no';
import deliveryReducer, {
  getDate as deliveryGetDate,
  getTime as deliveryGetTime,
  getMode as deliveryGetMode,
  getDisabledWeekdays as deliveryGetDisabledWeekdays,
  getDisabledDays as deliveryGetDisabledDays,
  getAvailableTimes as deliveryGetAvailableTimes,
  getMinDate as deliveryGetMinDate,
  getIsDisabled as deliveryGetIsDisabled,
  getActualTime as deliveryGetActualTime,
  getTimeFee as deliveryGetTimeFee,
  getDateFee as deliveryGetDateFee,
  getFees as deliveryGetFees,
  getPriceComposition as deliveryGetPriceComposition,
  getNormalDateFees as deliveryGetNormalDateFees,
  getDateExtraFee as deliveryGetDateExtraFee,
  isRequired as deliveryIsRequired,
  validate as deliveryValidate,
  hasDateAsPossibility as deliveryHasDateAsPossibility,
} from './delivery';
import deliveryAndPickupReducer, {
  getDate as deliveryAndPickupGetDate,
  getTime as deliveryAndPickupGetTime,
  getMode as deliveryAndPickupGetMode,
  getAvailableTimes as deliveryAndPickupGetAvailableTimes,
  getDisabledWeekdays as deliveryAndPickupGetDisabledWeekdays,
  getDisabledDays as deliveryAndPickupGetDisabledDays,
  getMinDate as deliveryAndPickupGetMinDate,
  getIsDisabled as deliveryAndPickupGetIsDisabled,
  getActualTime as deliveryAndPickupGetActualTime,
  getTimeFee as deliveryAndPickupGetTimeFee,
  getDateFee as deliveryAndPickupGetDateFee,
  getFees as deliveryAndPickupGetFees,
  getPriceComposition as deliveryAndPickupGetPriceComposition,
  getNormalDateFees as deliveryAndPickupGetNormalDateFees,
  getDateExtraFee as deliveryAndPickupGetDateExtraFee,
  isRequired as deliveryAndPickupIsRequired,
  validate as deliveryAndPickupValidate,
  hasDateAsPossibility as deliveryAndPickupHasDateAsPossibility,
} from './deliveryAndPickup';

export {
  storeDateFormat,
  isSunday,
  isSaturday,
  isToday,
  isBefore,
  dateTypes,
  priceFees,
  priceReasons,
  dateModes,
  sameDayChoices,
  nightChoices,
  timeTranslation,
  RESET_DATE,
  VALIDATE_STATE,
  SET_DATE,
  SET_MODE,
  SET_TIME,
  validateState,
  resetDate,
  setDate,
  setTime,
  setMode,
  combineFees,
} from './shared';

const createDeliveryTypeSelector = map => (deliveryType) => {
  if (map[deliveryType]) {
    return map[deliveryType];
  }
  return () => () => null;
};

export const getMode = createDeliveryTypeSelector({
  [deliveryTypes.no]: noGetMode,
  [deliveryTypes.delivery]: deliveryGetMode,
  [deliveryTypes.deliveryAndPickup]: deliveryAndPickupGetMode,
});

export const getDate = createDeliveryTypeSelector({
  [deliveryTypes.no]: noGetDate,
  [deliveryTypes.delivery]: deliveryGetDate,
  [deliveryTypes.deliveryAndPickup]: deliveryAndPickupGetDate,
});


export const getTime = createDeliveryTypeSelector({
  [deliveryTypes.no]: noGetTime,
  [deliveryTypes.delivery]: deliveryGetTime,
  [deliveryTypes.deliveryAndPickup]: deliveryAndPickupGetTime,
});

export const getDisabledWeekdays = createDeliveryTypeSelector({
  [deliveryTypes.no]: noGetDisabledWeekdays,
  [deliveryTypes.delivery]: deliveryGetDisabledWeekdays,
  [deliveryTypes.deliveryAndPickup]: deliveryAndPickupGetDisabledWeekdays,
});

export const getMinDate = createDeliveryTypeSelector({
  [deliveryTypes.no]: noGetMinDate,
  [deliveryTypes.delivery]: deliveryGetMinDate,
  [deliveryTypes.deliveryAndPickup]: deliveryAndPickupGetMinDate,
});

export const getIsDisabled = createDeliveryTypeSelector({
  [deliveryTypes.no]: noGetIsDisabled,
  [deliveryTypes.delivery]: deliveryGetIsDisabled,
  [deliveryTypes.deliveryAndPickup]: deliveryAndPickupGetIsDisabled,
});

export const getActualTime = createDeliveryTypeSelector({
  [deliveryTypes.no]: noGetActualTime,
  [deliveryTypes.delivery]: deliveryGetActualTime,
  [deliveryTypes.deliveryAndPickup]: deliveryAndPickupGetActualTime,
});


export const getDisabledDays = createDeliveryTypeSelector({
  [deliveryTypes.no]: noGetDisabledDays,
  [deliveryTypes.delivery]: deliveryGetDisabledDays,
  [deliveryTypes.deliveryAndPickup]: deliveryAndPickupGetDisabledDays,
});

export const getTimeFee = createDeliveryTypeSelector({
  [deliveryTypes.no]: noGetTimeFee,
  [deliveryTypes.delivery]: deliveryGetTimeFee,
  [deliveryTypes.deliveryAndPickup]: deliveryAndPickupGetTimeFee,
});

export const getDateFee = createDeliveryTypeSelector({
  [deliveryTypes.no]: noGetDateFee,
  [deliveryTypes.delivery]: deliveryGetDateFee,
  [deliveryTypes.deliveryAndPickup]: deliveryAndPickupGetDateFee,
});

export const getFees = createDeliveryTypeSelector({
  [deliveryTypes.no]: noGetFees,
  [deliveryTypes.delivery]: deliveryGetFees,
  [deliveryTypes.deliveryAndPickup]: deliveryAndPickupGetFees,
});

export const getPriceComposition = createDeliveryTypeSelector({
  [deliveryTypes.no]: noGetPriceComposition,
  [deliveryTypes.delivery]: deliveryGetPriceComposition,
  [deliveryTypes.deliveryAndPickup]: deliveryAndPickupGetPriceComposition,
});

export const getNormalDateFees = createDeliveryTypeSelector({
  [deliveryTypes.no]: noGetNormalDateFees,
  [deliveryTypes.delivery]: deliveryGetNormalDateFees,
  [deliveryTypes.deliveryAndPickup]: deliveryAndPickupGetNormalDateFees,
});


export const getDateExtraFee = createDeliveryTypeSelector({
  [deliveryTypes.no]: noGetDateExtraFee,
  [deliveryTypes.delivery]: deliveryGetDateExtraFee,
  [deliveryTypes.deliveryAndPickup]: deliveryAndPickupGetDateExtraFee,
});

export const isRequired = createDeliveryTypeSelector({
  [deliveryTypes.no]: noIsRequired,
  [deliveryTypes.delivery]: deliveryIsRequired,
  [deliveryTypes.deliveryAndPickup]: deliveryAndPickupIsRequired,
});

export const validate = createDeliveryTypeSelector({
  [deliveryTypes.no]: noValidate,
  [deliveryTypes.delivery]: deliveryValidate,
  [deliveryTypes.deliveryAndPickup]: deliveryAndPickupValidate,
});

export const hasDateAsPossibility = createDeliveryTypeSelector({
  [deliveryTypes.no]: noHasDateAsPossibility,
  [deliveryTypes.delivery]: deliveryHasDateAsPossibility,
  [deliveryTypes.deliveryAndPickup]: deliveryAndPickupHasDateAsPossibility,
});

export const getAvailableTimes = createDeliveryTypeSelector({
  [deliveryTypes.no]: noGetAvailableTimes,
  [deliveryTypes.delivery]: deliveryGetAvailableTimes,
  [deliveryTypes.deliveryAndPickup]: deliveryAndPickupGetAvailableTimes,
});

export default combineReducers({
  [deliveryTypes.no]: noReducer,
  [deliveryTypes.delivery]: deliveryReducer,
  [deliveryTypes.deliveryAndPickup]: deliveryAndPickupReducer,
});
