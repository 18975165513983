import React from 'react';
import { connect } from 'react-redux';
import { ItemContextConsumer } from './ItemContext';
import getContextValues from '../../content/commerce/items';
import { SmallView, SearchExpiredView } from '../../components/commerce/Item/list-views';
import {
  getItem,
  isLeje as isLejeItem,
} from '../../features/commerce/items';
import { getMoms } from '../../features/commerce/moms';
import {
  setItemAmount,
  setItemSingleAmount,
  incrementItemAmount,
  decrementItemAmount,
  getItemSingleAmount,
  getItemAmount,
} from '../../features/commerce/bucket';
import ItemRefContext from '../ItemAnimation/ItemRefContext';

class InnerItem extends React.Component {
  constructor(props) {
    super(props);
    this.itemRef = React.createRef();
  }

  render() {
    const {
      context,
      varenr,
      itemValues,
      display: chosenDisplay,
      useSearchDisplay,
      allowNotInState,
      allowExpiredKeepDisplay,
      ...otherProps
    } = this.props;
    const allContextValues = getContextValues(varenr, context);
    if (!itemValues && !allowNotInState) {
      if (!allContextValues || !allContextValues.showExpired) {
        return null;
      }
    }
    const actualItemValues = itemValues ? itemValues.values : { expired: true };
    if (!allContextValues) {
      return null;
    }
    const {
      ...contextValues
    } = allContextValues;
    let Display = ((useSearchDisplay || !chosenDisplay) && contextValues.searchDisplay) || chosenDisplay || SmallView;
    if (actualItemValues.expired && !allowExpiredKeepDisplay) {
      Display = SearchExpiredView;
    }
    return (
      <ItemRefContext.Provider value={this.itemRef}>
        <Display extraItemRef={this.itemRef} context={context} varenr={varenr} {...actualItemValues} {...contextValues} {...otherProps} />
      </ItemRefContext.Provider>
    );
  }
}
const ConnectedInnerItem = connect(
  (state, ownProps) => ({
    itemValues: getItem(state, ownProps),
    amount: getItemAmount(state, ownProps),
    singleAmount: getItemSingleAmount(state, ownProps),
    isLeje: isLejeItem(state.commerce.items, ownProps),
    hasMoms: getMoms(state),
  }),
  (dispatch, { varenr }) => ({
    actions: {
      setItemAmount: amount => dispatch(setItemAmount(varenr, amount)),
      setItemSingleAmount: singleAmount => dispatch(setItemSingleAmount(varenr, singleAmount)),
      incrementItemAmount: () => dispatch(incrementItemAmount(varenr)),
      decrementItemAmount: () => dispatch(decrementItemAmount(varenr)),
    },
  }),
)(InnerItem);
export default props => (
  <ItemContextConsumer>
    {context => <ConnectedInnerItem context={context} {...props} />}
  </ItemContextConsumer>
);
