import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';

// External = <LinkStyled external
// Action = <LinkStyled action
// Anchor = <LinkStyled anchor
// No Style = <LinkStyled nostyle

const TypeSwitch = ({ action, anchor, external, to, ...otherProps }) => {
  if (action) {
    return <span {...otherProps} />;
  }
  if (anchor) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a href={to} {...otherProps} />;
  }
  if (external) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content, react/jsx-no-target-blank
    return <a href={to} target="_blank" {...otherProps} />;
  }
  return <Link to={to} {...otherProps} />;
};

const LinkStyled = styled(TypeSwitch)(({ noStyle, hoverStyle, theme }) => {
  if (noStyle) {
    return `
      cursor: pointer;
    `;
  }
  if (hoverStyle) {
    return css`
      cursor: pointer;
      text-decoration: 'none';
      color: ${theme.colors.colorBlue};

      &:hover {
        color: ${theme.hoverColors.colorBlue};
      }

      & > svg {
        fill: ${theme.colors.colorBlue};

        &:hover {
          fill: ${theme.hoverColors.colorBlue};
        }
      }
    }`;
  }
  return css`
    cursor: pointer;
    position: relative;
    font-weight: 500;
    text-decoration: none;
    line-height: 1.5em;
    color: ${theme.colors.colorBlue};
    transition: all .15s linear;

    background: 
      linear-gradient(to bottom, ${theme.colors.colorBlue} 0%, ${theme.colors.colorBlue} 100%),
      linear-gradient(to bottom, ${theme.colors.colorBlue} 0%, ${theme.colors.colorBlue} 100%);
    background-size: 100% 1px, 0% 100%;
    background-repeat: no-repeat;
    background-position: right 100%, right 0%;
  
    &:hover {
      color: ${theme.colors.colorOffWhite};
      background-size: 0% 1px, 100% 100%;
      background-position: right 100%, right 100%;
    }
  }`;
});

export default LinkStyled;
