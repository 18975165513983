import React from 'react';
import styled from 'styled-components/macro';

const LogoBoblerNoStyle = ({
  ...otherProps
}) => (
  <svg width="35" height="46" viewBox="0 0 35 46" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
    <circle cx="10" cy="35.5" r="10" />
    <circle cx="27.5" cy="18" r="7.5" />
    <circle cx="15" cy="5.5" r="5" />
  </svg>
);

const LogoBobler = styled(LogoBoblerNoStyle)`
  fill: ${({ theme }) => theme.colors.colorBlue};
`;

export default LogoBobler;
