import { combineReducers } from 'redux';
import { persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  addWaterToBucket,
  WATER_ADD_TO_BUCKET,
} from './actions';
import {
  getTotalCost,
  getItemCost,
  getDeliveryCost,
  deliveryTypeIsPossible,
  deliveryTypeError,
  isDeliveryInConflict,
  hasLejeItem,
  hasFustageItem,
  getTotalPant,
  getFustagePant,
  getTotalCostWithoutPant,
  hasSomeNoneSTypeFustageButNoAnlaeg,
} from './selectors';
import commerceSaga from './sagas';
import momsReducer, { MOMS_SET, setMoms, getMoms } from './moms';
import skaftePickupDeliverySelf from './skaftePickupDeliverySelf';
import itemsReducer, {
  ITEMS_SET,
  setItems,
  getItem,
  getItems,
  getCost,
  getPant,
  isLeje,
  getProductVersion,
} from './items';
import bucketReducer, {
  ITEM_AMOUNT_SET,
  ITEM_SINGLE_AMOUNT_SET,
  ITEM_AMOUNT_INCREMENT,
  ITEM_AMOUNT_DECREMENT,
  setItemAmount,
  setItemSingleAmount,
  incrementItemAmount,
  decrementItemAmount,
  getItemAmount,
  getItemSingleAmount,
  getTotalNumberOfItems,
  getBucketItems,
  getItemAmounts,
  getItemEntry,
} from './bucket';
import detailsReducer, {
  HAS_DELIVERY_INFO_CHANGED,
  changeHasDeliveryInfo,
  hasDeliveryInfo,
} from './details';
import deliveryReducer, {
  DELIVERY_TYPE_SET,
  setDeliveryType,
  deliveryTypes,
  getDeliveryType,
  isDelivery,
  rootDeliverySaga,
} from './delivery';
import paymentReducer, {
  paymentTypes,
  PAYMENT_TYPE_SET,
  setPaymentType,
  getPaymentType,
  getPaymentTypeIsPossible,
} from './payment';
import waterReducer, {
  getCostFromEntries,
  getEntriesFromKeys,
  getWaterDefinitions,
  getWaterDelivery,
} from './water';
import searchReducer, {
  getIsSearching,
  getSearchResults,
  getSearchTerm,
  changeSearch,
  clearSearch,
  rootSearchSaga,
} from './search';

export {
  // MOMS
  MOMS_SET,
  setMoms,
  getMoms,
  // Items
  ITEMS_SET,
  setItems,
  getItems,
  getItem,
  getCost,
  getPant,
  isLeje,
  getProductVersion,
  // Bucket
  ITEM_AMOUNT_SET,
  ITEM_SINGLE_AMOUNT_SET,
  ITEM_AMOUNT_INCREMENT,
  ITEM_AMOUNT_DECREMENT,
  setItemAmount,
  setItemSingleAmount,
  incrementItemAmount,
  decrementItemAmount,
  getItemAmount,
  getItemSingleAmount,
  getTotalNumberOfItems,
  getBucketItems,
  getItemAmounts,
  getItemEntry,
  // Details
  HAS_DELIVERY_INFO_CHANGED,
  changeHasDeliveryInfo,
  hasDeliveryInfo,
  // Delivery
  deliveryTypes,
  DELIVERY_TYPE_SET,
  setDeliveryType,
  getDeliveryType,
  isDelivery,
  rootDeliverySaga,
  // Payment
  paymentTypes,
  PAYMENT_TYPE_SET,
  setPaymentType,
  getPaymentType,
  getPaymentTypeIsPossible,
  // Self
  getTotalCost,
  getTotalPant,
  getFustagePant,
  getTotalCostWithoutPant,
  getItemCost,
  getDeliveryCost,
  deliveryTypeIsPossible,
  deliveryTypeError,
  isDeliveryInConflict,
  hasLejeItem,
  hasFustageItem,
  commerceSaga,
  addWaterToBucket,
  WATER_ADD_TO_BUCKET,
  // water
  getCostFromEntries,
  getEntriesFromKeys,
  getWaterDefinitions,
  getWaterDelivery,

  // search
  getIsSearching,
  getSearchResults,
  getSearchTerm,
  changeSearch,
  clearSearch,
  rootSearchSaga,
  hasSomeNoneSTypeFustageButNoAnlaeg,
};

const bucketPersistConfig = {
  key: 'bucketV8',
  storage,
  transforms: [createTransform(
    undefined,
    (outboundState, key) => {
      if (window && key === 'items') { // eslint-disable-line
        const newItems = { ...outboundState };
        Object.keys(outboundState).forEach(
          (vNumber) => {
            const subs = vNumber.substring(1);
            if (!window.startItems[subs] && subs !== 'Water') { // eslint-disable-line
              delete newItems[vNumber];
            }
          },
        );
        return newItems;
      }

      return outboundState;
    },
  )],
};

const detailsPersistConfig = {
  key: 'details',
  storage,
};

const deliveryPersistConfig = {
  key: 'deliveryV19',
  storage,
};
const paymentPersistConfig = {
  key: 'paymentV3',
  storage,
};
const waterPersistConfig = {
  key: 'water3',
  storage,
  blacklist: ['waterDefinitions', 'waterDelivery'],
};
const momsPersistConfig = {
  key: 'moms',
  storage,
};

const skaftePickupDeliverySelfPersistConfig = {
  key: 'skaftePickupDeliverySelf',
  storage,
};

export default combineReducers({
  moms: persistReducer(momsPersistConfig, momsReducer),
  items: itemsReducer,
  details: persistReducer(detailsPersistConfig, detailsReducer),
  bucket: persistReducer(bucketPersistConfig, bucketReducer),
  delivery: persistReducer(deliveryPersistConfig, deliveryReducer),
  payment: persistReducer(paymentPersistConfig, paymentReducer),
  water: persistReducer(waterPersistConfig, waterReducer),
  skaftePickupDeliverySelf: persistReducer(skaftePickupDeliverySelfPersistConfig, skaftePickupDeliverySelf),
  search: searchReducer,
});
