import React from 'react';
import styled from 'styled-components/macro';
import MenuItem from './MenuItem';
import { LinkStyled } from '../../../../components/typography';
import Trustpilot from './Trustpilot';
import { FaPhoneAlt } from '../../../../components/Icons';
import Div from '../../../../components/Div';
import { Container } from '../../../../components/TheGrid';

const Wrap = styled(Div)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.colorGreyLight};
`;

const ContainerTop = styled(Container)`
`;

const Navigation = styled.ul`
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
`;

const SpecialMenuItem = styled(MenuItem)`
  float: none;
`;

const PhoneLink = styled(LinkStyled)`
  color: ${({ theme }) => theme.colors.colorBlue};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.hoverColors.colorBlue};
  }
`;

const PhoneIcon = styled.div`
  display: inline-block;
  position: relative;
  top: 2px;
  margin-right: 5px;
`;

const SpecialLi = styled.li`
  display: inline-block;
  float: left;
  font-weight: bold;
  padding: 11px 15px;
`;

export default () => (
  <Wrap>
    <ContainerTop>
      <Navigation>
        <SpecialLi>
          <Trustpilot />
        </SpecialLi>
        <SpecialLi>
          <PhoneLink noStyle anchor to="tel:+4543270000">
            <PhoneIcon><FaPhoneAlt /></PhoneIcon> 43 27 00 00
          </PhoneLink>
        </SpecialLi>
        <SpecialMenuItem title="Kontakt" to="/kontakt-os" topMenu />
        <SpecialMenuItem to="/om-peter-skafte-aps" title="Om Peter Skafte ApS" topMenu />
        {/* <SpecialMenuItem to="/job" title="Job" /> */}
      </Navigation>
    </ContainerTop>
  </Wrap>
);
