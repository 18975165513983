

const initialState = { value: false };

const actionPrefix = 'SKAFTE/COMMERCE/';

export const SET_SKAFTE_PICKUP_DELIVERY_SELF = `${actionPrefix}SET_SKAFTE_PICKUP_DELIVERY_SELF`;

export const setSkaftePickupDeliverySelf = payload => ({
  type: SET_SKAFTE_PICKUP_DELIVERY_SELF,
  payload,
});

export const getLocalState = state => state.commerce.skaftePickupDeliverySelf;

export const getSkaftePickupDeliverySelf = state => getLocalState(state).value;


export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SKAFTE_PICKUP_DELIVERY_SELF:
      return { value: action.payload };
    default:
      return state;
  }
}