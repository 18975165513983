import React from 'react';
import { Span } from '../../../../components/typography';

const ErrorText = ({ children }) => (
  <Span>
    {children}
  </Span>
);
export default ({ error }) => {
  switch (error) {
    case 'IMPOSSIBLE_BECAUSE_LEJE':
      return (
        <ErrorText>
          Denne leveringstype er ikke mulig, da du har valgt en vare der er til leje.
        </ErrorText>
      );
    case 'IMPOSSIBLE_BECAUSE_WATER':
      return (
        <ErrorText>
          Denne leveringstype er ikke mulig når du bestiller vand med logo.
        </ErrorText>
      );
    case 'IMPOSSIBLE_BECAUSE_NO_SODA_OR_FADOEL':
      return (
        <ErrorText>
          Denne leveringstype er ikke mulig, da du udelukkende bestiller reservedele eller Draughtmaster
        </ErrorText>
      );
    case 'IMPOSSIBLE_ONLY_RESERVEDELE_AND_DRAUGHTMASTER':
      return (
        <ErrorText>
          Denne leveringstype er kun mulig hvis du udelukkende køber reservedele eller Draughtmaster.
        </ErrorText>
      );
    case 'IMPOSSIBLE_BECAUSE_NO_FUSTAGER_AND_NO_LEJE_AND_NO_KASSE':
      return (
        <ErrorText>
          Denne leveringstype er kun mulig når du køber varer der skal retur.
        </ErrorText>
      );
    case 'IMPOSSIBLE_ONLY_POSSIBLE_WHEN_WATER_ORDER':
      return (
        <ErrorText>
          Denne leveringstype er kun mulig når du køber vand med logo.
        </ErrorText>
      );
    default:
      return null;
  }
};
