import React from 'react';
import { Span } from '../../components/typography';
import LiterPriceDifference from './LiterPriceDifference';

const LavLiterpris = ({
  lavLiterpris,
}) => {
  if (lavLiterpris) {
    return (
      <Span green uppercase fontSize="13px">
        <Span bold>Lav literpris!</Span><br />
        Spar <LiterPriceDifference vareA={lavLiterpris[0]} vareB={lavLiterpris[1]} multiplier={lavLiterpris[2]} />,- i forhold til {lavLiterpris[3]}<br />
        (Udregnet for {lavLiterpris[2]} liter øl)
      </Span>
    );
  }
  return (null);
};

// Export
export default LavLiterpris;
