import React from 'react';
import styled from 'styled-components/macro';
import {
  Logo,
  Menu,
  HeaderBucket,
  MenuTop,
  LeveringAfhentning,
  HeaderWrap,
  MomsContainer,
  // ClosedAnnouncement,
} from './components';
import GeneralSearchInput from '../../../components/Search/GeneralSearchInput';
import { Container } from '../../../components/TheGrid';
import Div from '../../../components/Div';

// import HideAtDate from '../../../components/HideAtDate';

const LogoContainer = styled(Div)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.colorGreyLight};
  padding: 8.5px 0;

  @media (max-width: 1199px) {
    padding: 14px 0;
  }
`;

const HeaderBig = ({
  noSearchBar,
  noSearchBarResults,
  disableBreadCrumbsSchema,
}) => (
  <HeaderWrap>
    <MenuTop />
    <LogoContainer>
      <Container>
        <Logo to="/" title="Skafte Øl &amp; Vand" text1="Skafte Øl &amp; Vand" text2="Leverandør af fadøl, vand, øl & sodavand" />
        <LeveringAfhentning />
        <HeaderBucket />
        {!noSearchBar && (
          <GeneralSearchInput noResults={noSearchBarResults} />
        )}
      </Container>
    </LogoContainer>
    <Container padding="0px">
      <Menu />
    </Container>
    {/*
    <HideAtDate date="23/12/2022 20:00">
      <ClosedAnnouncement />
    </HideAtDate>
    */}
    <MomsContainer disableBreadCrumbsSchema={disableBreadCrumbsSchema} />
  </HeaderWrap>
);

HeaderBig.displayName = 'layout.DefaultLayout.Header.HeaderBig';

export default HeaderBig;
