import React from 'react';
import Format from './Format';
import { sanitizeMeasurement } from '../../lib/helpers';

export default ({
  cl, value, space, noSuffix,
}) => {
  const sanVal = sanitizeMeasurement(value);
  if (cl) {
    return (
      <Format
        decimalScale={sanVal === 32.99999999999999 ? 0 : 1}
        suffix={!noSuffix && (space ? ' cl' : 'cl')}
        value={sanVal}
      />
    );
  }
  return (
    <Format
      decimalScale={3}
      suffix={!noSuffix && (space ? ' l' : 'l')}
      value={sanVal}
    />
  );
};
