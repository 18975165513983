
let args = null;

export function setArgs(newArgs) {
  args = newArgs;
}
export function getArgs() {
  return args;
}
export default function getArg(name) {
  if (args && args[name]) {
    return args[name];
  }
  return null;
}
