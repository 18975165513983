import React from 'react';
import styled from 'styled-components/macro';
import {
  LinkStyled,
  Heading2,
  Span,
  Paragraph,
} from '../../components/typography';
import Modal from '../../components/Modal';

import Item from './Item';
import { Row, Col, Div } from '../../components/grid';
import { Image } from '../../components/Image';
import { Table, Td } from '../../components/tables';
import { AlcoholPercentage, FadKobling } from '../../components/commerce/parts';
import {
  LiterFormat,
  PricePrLiterFormat,
  MoneyFormat,
  WeightFormat,
  LengthFormat,
} from '../../components/Format';
import { sanitizeMeasurement, ScreenSizeDetector } from '../../lib/helpers';
import { sanitizePrice } from '../../lib/price';
import { BuyButtons } from '../../components/commerce/Item';
import LavLiterpris from './LavLiterpris';
import { BsFillPlusCircleFill } from '../../components/Icons';

const StyledModal = styled(Modal)`
  overflow-y: visible;
  z-index: 99999999999;
  position: fixed;
  background: ${({ theme }) => theme.colors.colorWhite};
  border: 2px ${({ theme }) => theme.colors.colorGreyLightAlt} solid;
  box-shadow: 0 0 20px 0px rgba(0,0,0,0.5);
  outline: none;
  height: auto;
  width: 90%;
  max-width: 800px;

  @media (max-width: 991px) {
    top: 15px !important;
    transform: translate(-50%,20px) !important;
    max-height: calc(100% - 160px);
  }

  @media (max-height: 575px) {
    max-height: calc(100% - 120px);
  }
`;
const ScrollDiv = styled(Div)`
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 160px);
`;

const RenderBuyButtons = ({
  smallDescription,
  lavLiterpris,
  ...otherProps
}) => (
  <ScreenSizeDetector medium={[[1199, false], true]} fullWidthCenter={[[1199, true], false]}>
    <Div padding="10px 0">
      <Paragraph bold>Læg i kurven:</Paragraph>
      <BuyButtons {...otherProps} />
      {smallDescription && (
        <Paragraph>
          {smallDescription()}
        </Paragraph>
      )}
      {lavLiterpris && (
        <Paragraph>
          <LavLiterpris lavLiterpris={lavLiterpris} />
        </Paragraph>
      )}
    </Div>
  </ScreenSizeDetector>
);
const RenderButtons = ({
  amount,
  actions,
  dividableBy,
  inStock,
  inStockText,
}) => {
  if (inStock === '-1' && !inStockText) {
    return (
      <Paragraph grey>
        Midlertidigt udsolgt fra producenten
      </Paragraph>
    );
  }
  if (inStock === '100') {
    return (
      <Paragraph grey>
        Udgået fra producenten
      </Paragraph>
    );
  }
  if (inStock === '-1' && inStockText) {
    return (
      <Paragraph grey>
        {inStockText()}
      </Paragraph>
    );
  }
  return (
    <RenderBuyButtons amount={amount} actions={actions} dividableBy={dividableBy} />
  );
};

const RenderVareData = ({
  liter,
  price,
  varenr,
  countPrColli,
  alcoholPercentage,
  weight,
  width,
  depth,
  height,
  totalCasePrice,
  contentCharge,
  ean,
  packageNoun,
  deposit,
  caseDeposit,
  containerCharge,
  energy,
}) => (
  <React.Fragment>
    <Row padding="0">
      <Col xs={12} md={6} paddingTop="0px">
        <Table striped1 paddingTd="5px">
          <tr>
            <Td width="70%">Varenr.:</Td>
            <Td width="20%" textRight>{varenr}</Td>
            <Td width="10%">&nbsp;</Td>
          </tr>
          {Boolean(ean) && ean !== '0' && (
            <tr>
              <Td>EAN nr.:</Td>
              <Td textRight>{ean}</Td>
              <Td>&nbsp;</Td>
            </tr>
          )}
          {countPrColli > 1 && (
            <React.Fragment>
              <tr>
                <Td>Antal flasker:</Td>
                <Td textRight>{countPrColli}</Td>
                <Td>stk.</Td>
              </tr>
            </React.Fragment>
          )}
          {Boolean(liter) && sanitizeMeasurement(liter) > 0 && (
            <tr>
              <Td>Indhold liter i alt:</Td>
              <Td textRight><LiterFormat liter={liter} noSuffix /></Td>
              <Td>l</Td>
            </tr>
          )}
          {Boolean(alcoholPercentage) && sanitizeMeasurement(alcoholPercentage) > 0 && (
            <tr>
              <Td>Alkohol %:</Td>
              <Td textRight><AlcoholPercentage value={alcoholPercentage} noSuffix /></Td>
              <Td>%</Td>
            </tr>
          )}
          {packageNoun !== 'Fustage' && (
            <React.Fragment>
              <tr>
                <Td>Pris stk. uden moms:</Td>
                <Td textRight><MoneyFormat money={price} multiplier={1 / countPrColli} moms={false} noSuffix /></Td>
                <Td>kr.</Td>
              </tr>
              <tr>
                <Td>Pris stk. med moms:</Td>
                <Td textRight><MoneyFormat money={price} multiplier={1 / countPrColli} moms noSuffix /></Td>
                <Td>kr.</Td>
              </tr>
            </React.Fragment>
          )}
          <React.Fragment>
            <tr>
              <Td>Pris pr. {packageNoun.toLowerCase()} uden moms:</Td>
              <Td textRight><MoneyFormat money={price} moms={false} noSuffix /></Td>
              <Td>kr.</Td>
            </tr>
            <tr>
              <Td>Pris pr. {packageNoun.toLowerCase()} med moms:</Td>
              <Td textRight><MoneyFormat money={price} moms noSuffix /></Td>
              <Td>kr.</Td>
            </tr>
          </React.Fragment>
          {Boolean(liter) && sanitizeMeasurement(liter) > 0 && (
            <React.Fragment>
              <tr>
                <Td>Literpris uden moms:</Td>
                <Td textRight><PricePrLiterFormat liter={liter} price={price} moms={false} noSuffix /></Td>
                <Td>kr.</Td>
              </tr>
              <tr>
                <Td>Literpris med moms:</Td>
                <Td textRight><PricePrLiterFormat liter={liter} price={price} moms noSuffix /></Td>
                <Td>kr.</Td>
              </tr>
            </React.Fragment>
          )}
        </Table>
      </Col>
      <Col xs={12} md={6} paddingTop="0px">
        <Table striped1 paddingTd="5px">
          {Boolean(deposit) && sanitizePrice(deposit) > 0 && (
            <tr>
              <Td>Pant pr flaske med moms:</Td>
              <Td textRight><MoneyFormat money={deposit} multipler={1 / countPrColli} moms noSuffix /></Td>
              <Td>kr.</Td>
            </tr>
          )}
          {Boolean(caseDeposit) && sanitizePrice(caseDeposit) > 0 && (
            <tr>
              <Td>Pant kasse:</Td>
              <Td textRight><MoneyFormat money={caseDeposit} moms noSuffix /></Td>
              <Td>kr.</Td>
            </tr>
          )}
          {Boolean(totalCasePrice) && sanitizePrice(totalCasePrice) > 0 && (
            <tr>
              <Td>Pant i alt med moms:</Td>
              <Td textRight><MoneyFormat moms money={totalCasePrice} noSuffix /></Td>
              <Td>kr.</Td>
            </tr>
          )}
          {Boolean(contentCharge) && sanitizePrice(contentCharge) > 0 && (
            <tr>
              <Td>Indholdsafgift pr. {packageNoun.toLowerCase()} uden moms *:</Td>
              <Td textRight><MoneyFormat moms={false} money={contentCharge} noSuffix /></Td>
              <Td>kr.</Td>
            </tr>
          )}
          {Boolean(contentCharge) && sanitizePrice(contentCharge) > 0 && (
            <tr>
              <Td>Indholdsafgift pr. {packageNoun.toLowerCase()} med moms *:</Td>
              <Td textRight><MoneyFormat moms money={contentCharge} noSuffix /></Td>
              <Td>kr.</Td>
            </tr>
          )}
          {Boolean(containerCharge) && sanitizePrice(containerCharge) > 0 && (
            <tr>
              <Td>Emballageafgift pr. {packageNoun.toLowerCase()} *:</Td>
              <Td textRight>
                <MoneyFormat moms money={containerCharge} noSuffix />
              </Td>
              <Td>kr.</Td>
            </tr>
          )}
          {Boolean(weight) && sanitizeMeasurement(weight) > 0 && (
            <tr>
              <Td>Vægt {packageNoun.toLowerCase()}:</Td>
              <Td textRight><WeightFormat weight={weight} /></Td>
              <Td>kg.</Td>
            </tr>
          )}
          {Boolean(width) && sanitizeMeasurement(width) > 0 && (
            <tr>
              <Td>Bredde {packageNoun.toLowerCase()}</Td>
              <Td textRight><LengthFormat length={width} /></Td>
              <Td>cm.</Td>
            </tr>
          )}
          {Boolean(depth) && sanitizeMeasurement(depth) > 0 && (
            <tr>
              <Td>Længde {packageNoun.toLowerCase()}</Td>
              <Td textRight><LengthFormat length={depth} /></Td>
              <Td>cm.</Td>
            </tr>
          )}
          {Boolean(height) && sanitizeMeasurement(height) > 0 && (
            <tr>
              <Td>Højde {packageNoun.toLowerCase()}</Td>
              <Td textRight><LengthFormat length={height} /></Td>
              <Td>cm.</Td>
            </tr>
          )}
          {Boolean(energy) && sanitizeMeasurement(energy) > 0 && (
            <tr>
              <Td>Energi /100g:</Td>
              <Td textRight>{energy}</Td>
              <Td>kJ.</Td>
            </tr>
          )}
        </Table>
      </Col>
    </Row>
  </React.Fragment>
);

const RenderImages = ({
  imageSingle,
  imagePlural,
  productTypeFadoel,
  popupDescription,
  land,
  fadoelsType,
  fadKobling,
  gas,
  pressure,
}) => (
  <React.Fragment>
    {(productTypeFadoel || popupDescription) && (
      <React.Fragment>
        <Col xs={12} sm={6} padding="0px 15px">
          {popupDescription && (
            <React.Fragment>
              <Paragraph>
                {popupDescription()}
              </Paragraph>
            </React.Fragment>
          )}
          {productTypeFadoel && (
            <Table lines2 paddingTd="5px 0">
              <tbody>
                <tr><td>Fadkobling:</td><td><FadKobling value={fadKobling} /></td></tr>
                <tr><td>Type:</td><td>{fadoelsType}</td></tr>
                <tr><td>Land:</td><td>{land}</td></tr>
                <tr><td>Gas:</td><td>{gas}<br />Tryk: {pressure}</td></tr>
              </tbody>
            </Table>
          )}
        </Col>
      </React.Fragment>
    )}
    <Col xs={12} sm={(productTypeFadoel || popupDescription) ? 6 : 12} textCenter padding="0px 15px">
      {imageSingle && imagePlural && (
        <Row>
          <Col xs={6} textCenter padding="0px 15px">
            <Image name={imageSingle} maxHeight="200px" imageHeight="200px" />
          </Col>
          <Col xs={6} textCenter padding="0px 15px">
            <Image name={imagePlural} maxHeight="200px" imageHeight="200px" />
          </Col>
        </Row>
      )}
      {!imagePlural && imageSingle && (
        <Image name={imageSingle} maxHeight="200px" imageHeight="200px" />
      )}
      {!imageSingle && imagePlural && (
        <Image name={imagePlural} maxHeight="200px" imageHeight="200px" />
      )}
    </Col>
  </React.Fragment>
);


const VareDataModal = (props) => {
  const {
    regularName,
    imageSingle,
    imagePlural,
    productTypeFadoel,
    popupDescription,
    land,
    fadoelsType,
    fadKobling,
    varenr,
    gas,
    pressure,
    contentCharge,
    amount,
    actions,
    dividableBy,
    inStock,
    inStockText,
  } = props;
  return (
    <React.Fragment>
      <Row>
        <Col xs={12} textCenter={(productTypeFadoel || popupDescription) ? '' : true} padding="0px 15px">
          <Heading2>{regularName}</Heading2>
        </Col>
      </Row>
      <Row>
        <RenderImages
          imageSingle={imageSingle}
          imagePlural={imagePlural}
          productTypeFadoel={productTypeFadoel}
          popupDescription={popupDescription}
          land={land}
          fadoelsType={fadoelsType}
          fadKobling={fadKobling}
          varenr={varenr}
          gas={gas}
          pressure={pressure}
        />
        <Col xs={12} sm={(productTypeFadoel || popupDescription) ? 6 : 12} textCenter={!popupDescription} padding="0px 15px">
          <RenderButtons amount={amount} actions={actions} inStock={inStock} inStockText={inStockText} dividableBy={dividableBy} />
        </Col>
        <Col xs={12} padding="0px 15px">
          <RenderVareData {...props} />
        </Col>
        {Boolean(contentCharge) && sanitizePrice(contentCharge) > 0 && (
          <Col xs={12} padding="0px 15px" textRight>
            <Span fontSize="12px">
              * Statsafgiften er inkluderet
            </Span>
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};

const CloseButton = styled.div`
  color: ${({ theme }) => theme.colors.colorRed};
  border: 2px solid ${({ theme }) => theme.colors.colorWhite};
  position: absolute;
  display: flex;
  top: -7px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  background: white;
  rotate: 45deg;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.hoverColors.colorRed};
  }
`;

const LinkStyledName = styled(LinkStyled)`
  color: ${({ theme }) => theme.colors.colorBlue};

  &:hover {
    color: ${({ theme }) => theme.hoverColors.colorBlue};
    text-decoration: underline;
  }
`;

export default class VareDataModalLink extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onClose = this.onClose.bind(this);
    this.state = {
      open: false,
    };
  }

  onClick() {
    this.setState({ open: true });
  }

  onClose() {
    this.setState({ open: false });
  }

  render() {
    const { children, varenr, ...otherProps } = this.props;
    const link = (
      <LinkStyledName action noStyle onClick={this.onClick}>
        {children}
      </LinkStyledName>
    );
    const { open } = this.state;
    if (open) {
      return (
        <React.Fragment>
          {link}
          <StyledModal
            isOpen
            onRequestClose={this.onClose}
          >
            <ScrollDiv>
              <Item varenr={varenr} display={VareDataModal} {...otherProps} />
            </ScrollDiv>
            <CloseButton onClick={this.onClose}><BsFillPlusCircleFill /></CloseButton>
          </StyledModal>
        </React.Fragment>
      );
    }
    return link;
  }
}
