import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { getBucketState, closeBucket } from '../../../../features/burgermenus';
import { BucketPreFinish } from '../../../../containers/commerce';
import { BucketView } from '../../../../components/commerce/Bucket';
import { GrClose } from '../../../../components/Icons';

const BucketWrapper = styled.div`
  opacity: 0;
  position: fixed;
  top: 0;
  right: -70px;
  width: 370px;
  padding-left: 50px;
  height: 100%;
  z-index: 100;
  overflow: auto;
  box-sizing: border-box;
  transform: translateX(300px);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  overflow: hidden;

  @media (max-width: 991px) {
    height: calc(100% - 70px);
  }

  @media (max-width: 370px) {
    padding-left: 40px;
    width: 320px;
  }
  
  ${({ isOpen }) => {
    if (isOpen) {
      return `
        opacity: 1;
        right: 0;
        overflow-y: auto;
        overflow-x: hidden;
        transform: translateX(0);
      `;
    }
    return '';
  }}
`;

const CloseBucket = styled.div`
  background: ${({ theme }) => theme.colors.colorRed};
  cursor: pointer;
  position: fixed;
  top: 50%;
  left: 0;
  width: 50px;
  height: 50px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);

  & > svg {
    width: 25px;
    height: 25px;

    & > path {
      stroke: ${({ theme }) => theme.colors.colorWhite};
    }
  }

  @media (max-width: 360px) {
    width: 40px;
    height: 40px;

    & > svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

const BucketInnerScroll = styled.div`
  height: 100%;
  overflow-y: auto;
`;

class SkafteBucketMenu extends React.Component {
  static displayName = 'layout.DefaultLayout.Header.BucketMenu';

  componentDidMount() {
    const { history } = this.props;
    history.listen(this.handleHistoryChange);
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props;
    if (prevProps.open !== open) {
      if (open) {
        document.body.classList.add('bucket-open');
      } else {
        document.body.classList.remove('bucket-open');
      }
    }
  }

  componentWillUnmount() {
    const { history } = this.props;
    history.listen(this.handleHistoryChange);
    const { open, closeMenu } = this.props;
    if (open) {
      closeMenu();
    }
  }

  handleHistoryChange = () => {
    const { open, closeMenu } = this.props;
    if (open) {
      closeMenu();
    }
  };

  render() {
    const { open, onStateChange, closeMenu } = this.props;
    return (
      <React.Fragment>
        <BucketWrapper className="bucket-menu" isOpen={open} onStateChange={onStateChange}>
          <BucketInnerScroll>
            <BucketPreFinish display={BucketView} />
          </BucketInnerScroll>
          <CloseBucket isOpen={open} onClick={closeMenu}><GrClose /></CloseBucket>
        </BucketWrapper>
        {open && <Overlay isOpen={open} onClick={closeMenu} />}
      </React.Fragment>
    );
  }
}

export default withRouter(connect(
  state => ({ open: getBucketState(state) }),
  dispatch => ({
    closeMenu: () => dispatch(closeBucket()),
    onStateChange: state => !state.isOpen && dispatch(closeBucket()),
  }),
)(SkafteBucketMenu));
