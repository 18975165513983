import React from 'react';
import styled from 'styled-components/macro';
import { LinkStyled } from '../../typography';

const FlaskeSelectNoStyle = ({
  children,
  error,
  ...otherProps
}) => (
  <select {...otherProps}>
    {children}
  </select>
);
const FlaskeSelect = styled(FlaskeSelectNoStyle)`
  border: 1px solid ${({ theme }) => theme.colors.colorGreyLightAlt};
  color: ${({ theme }) => theme.colors.colorGreyDark};
  display: inline-block;
  width: 75px;
  outline: none;
  border-radius: 0px;
  padding: 7px 10px;
  height: auto;
  margin-top: -6px;

  @media (max-width: 1199px) {
  }
`;

const compositions = {
  24: [
    0,
    6,
    12,
    18,
  ],
  30: [
    0,
    5,
    10,
    15,
    20,
    25,
  ],
};
export default ({
  singleAmount,
  countPrColli,
  actions: {
    setItemSingleAmount,
  },
}) => {
  const composition = compositions[countPrColli];
  if (!composition) {
    return null;
  }
  if (singleAmount === 0) {
    return (
      <LinkStyled action blue onClick={() => setItemSingleAmount(0.5)}>
        Bland selv
      </LinkStyled>
    );
  }
  return (
    <FlaskeSelect value={singleAmount} onChange={evt => setItemSingleAmount(evt.target.value)}>
      {composition.map(
        value => <option value={value / countPrColli} key={value}>{value} stk</option>,
      )}
    </FlaskeSelect>
  );
};
