import getArg from '../../lib/args';

const prefix = 'SKAFTE/CURRENCY/';

const CURRENCIES_SET = `${prefix}CURRENCIES_SET`;

export const setCurrencies = currencies => ({
  type: CURRENCIES_SET,
  payload: currencies,
});

export const getInitialState = () => getArg('currencies');

export default (state = {}, action) => {
  switch (action.type) {
    case CURRENCIES_SET:
      return { ...action.payload };
    default:
      return state;
  }
};

export const getCurrencyRate = (state, { currency }) => (currency === 'DKK' ? 1 : state.currency[currency]);
