import { createSelector } from 'reselect';
import {
  // formValueSelector,
  getKundenr,
} from '../../form';
import { isInSpecialMode } from '../delivery/selectors';

const actionPrefix = 'SKAFTE/COMMERCE/PAYMENT/';

export const paymentTypes = {
  invoice: 'INVOICE',
  eanInvoice: 'EAN_INVOICE',
  card: 'CARD',
  cash: 'CASH',
};

export const PAYMENT_TYPE_SET = `${actionPrefix}PAYMENT_TYPE_SET`;
export const PAYMENT_DELIVERY_SET = `${actionPrefix}PAYMENT_DELIVERY_SET`;
export const PAYMENT_SET_NEGATIVE_TOTAL = `${actionPrefix}PAYMENT_SET_NEGATIVE_TOTAL`;
export const setPaymentType = paymentType => ({
  type: PAYMENT_TYPE_SET,
  payload: paymentType,
});
export const setPaymentDelivery = isDelivery => ({
  type: PAYMENT_DELIVERY_SET,
  payload: isDelivery,
});
export const setPaymentNegativeTotal = negativeTotal => ({
  type: PAYMENT_SET_NEGATIVE_TOTAL,
  payload: negativeTotal,
});


export const possiblePaymentTypes = {
  whenDelivery: [
    paymentTypes.card,
    paymentTypes.invoice,
    paymentTypes.eanInvoice,
  ],
  whenDeliveryNegative: [
    paymentTypes.invoice,
    paymentTypes.eanInvoice,
  ],
  whenPickup: [
    paymentTypes.card,
    paymentTypes.invoice,
    paymentTypes.eanInvoice,
    paymentTypes.cash,
  ],
  whenPickupNegative: [
    paymentTypes.invoice,
    paymentTypes.eanInvoice,
    paymentTypes.cash,
  ],
};
const initialState = {
  negativeTotal: false,
  isDelivery: false,
  paymentType: null,
};
export default function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case PAYMENT_TYPE_SET:
      return { ...state, paymentType: action.payload };
    case PAYMENT_DELIVERY_SET:
      return { ...state, isDelivery: action.payload };
    case PAYMENT_SET_NEGATIVE_TOTAL:
      return { ...state, negativeTotal: action.payload };
    default:
      return state;
  }
}
export const getIsPaymentDelivery = state => state.commerce.payment.isDelivery;
export const getIsNegativeTotal = state => state.commerce.payment.negativeTotal;
export const getPossiblePaymentTypes = (state) => {
  const isDelivery = getIsPaymentDelivery(state);
  const negativeTotal = getIsNegativeTotal(state);
  if (isDelivery) {
    if (negativeTotal) {
      return possiblePaymentTypes.whenDeliveryNegative;
    }
    return possiblePaymentTypes.whenDelivery;
  }
  if (negativeTotal) {
    return possiblePaymentTypes.whenPickupNegative;
  }
  return possiblePaymentTypes.whenPickup;
};

export const getPaymentTypeIsPossible = createSelector(
  (state, { paymentType }) => paymentType,
  getPossiblePaymentTypes,
  (paymentType, possiblePaymentTypes) => possiblePaymentTypes.reduce(
    (acc, pType) => pType === paymentType || acc,
    false,
  ),
);

export const getPaymentType = (state) => {
  if (isInSpecialMode(state)) {
    return paymentTypes.card;
  }
  const kundenr = getKundenr(state);
  const hasKundenr = kundenr;
  const chosenPaymentType = state.commerce.payment.paymentType;
  if (getPaymentTypeIsPossible(state, { paymentType: chosenPaymentType })) {
    return chosenPaymentType;
  }
  if (hasKundenr && getPaymentTypeIsPossible(state, { paymentType: paymentTypes.invoice })) {
    return paymentTypes.invoice;
  }
  return getPossiblePaymentTypes(state)[0];
};

export const getPaymentTypeText = (paymentType) => {
  switch (paymentType) {
    case paymentTypes.invoice:
      return 'Faktura';
    case paymentTypes.eanInvoice:
      return 'EAN faktura';
    case paymentTypes.cash:
      return 'Betaler på lageret';
    case paymentTypes.card:
      return 'Kort';
    default:
      return 'Ukendt';
  }
};
