const prefix = 'SKAFTE/FORM-ERROR/';
export const VALIDATE = `${prefix}VALIDATE`;
export const VALIDATE_FIELD = `${prefix}VALIDATE_FIELD`;

export const validateRequest = () => ({
  type: `${VALIDATE}_REQUEST`,
});

export const validateFail = payload => ({
  type: `${VALIDATE}_FAIL`,
  payload,
});

export const validateSuccess = payload => ({
  type: `${VALIDATE}_SUCCESS`,
  payload,
});

export const validateFieldRequest = payload => ({
  type: `${VALIDATE_FIELD}_REQUEST`,
  payload,
});

export const validateFieldFail = payload => ({
  type: `${VALIDATE_FIELD}_FAIL`,
  payload,
});

export const validateFieldSuccess = payload => ({
  type: `${VALIDATE_FIELD}_SUCCESS`,
  payload,
});
