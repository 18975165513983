
const actionPrefix = 'SKAFTE/COMMERCE/DETAILS/';

export const HAS_DELIVERY_INFO_CHANGED = `${actionPrefix}/HAS_DELIVERY_INFO_CHANGED`;

export const changeHasDeliveryInfo = hasDeliveryInfo => ({
  type: HAS_DELIVERY_INFO_CHANGED,
  payload: hasDeliveryInfo,
});
export const hasDeliveryInfo = state => state.commerce.details.hasDeliveryInfo;

const initialState = {
  hasDeliveryInfo: false,
};
export default function detailsReducer(state = initialState, action) {
  switch (action.type) {
    case HAS_DELIVERY_INFO_CHANGED:
      return { ...state, hasDeliveryInfo: action.payload };
    default:
      return state;
  }
}
