import {
  put, take, actionChannel, all, select, call, takeEvery,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { formValueSelector, CHANGE } from '../form';
import {
  VALIDATE_FIELD, VALIDATE, validateFieldRequest, validateFieldSuccess, validateSuccess,
} from './actions';
import { getItemEntries } from '../commerce/bucket/selectors';
import { hasDeliveryInfo } from '../commerce/details';
import { isDeliveryInConflict } from '../commerce/selectors';
import { dateTypes, validateState } from '../commerce/delivery/dates/shared';
import {
  isRequired,
  getDate,
  isInSpecialMode,
} from '../commerce/delivery';
import {
  getPaymentType,
  paymentTypes,
} from '../commerce/payment';
import { getTotalNumberOfItems } from '../commerce';

/* const email = message => value => (
  (value ? !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) : true)
    ? message
    : undefined
); */
const required = message => (v) => {
  if (v) {
    if (v.trim && !v.trim()) {
      return message;
    }
    return undefined;
  }
  return message;
};

const validators = {
  invoice: {
    name: required('Påkrævet'),
    address: required('Påkrævet'),
    city: required('Påkrævet'),
    zipCode: required('Påkrævet'),
    phonenumber: required('Påkrævet'),
    email: required('Skal være en email'),
  },
  delivery: {
  },
  ean: {
    ean: required('Påkrævet'),
    cvr: required('Påkrævet'),
  },
};
const formSelectors = {
  invoice: formValueSelector('invoice-details'),
  delivery: formValueSelector('delivery-details'),
  ean: formValueSelector('ean-form'),
};
function validateFormField(state, form, fieldName) {
  if (validators[form][fieldName]) {
    const value = formSelectors[form](state, fieldName);
    return validators[form][fieldName](value);
  }
  return undefined;
}
function validateInvoiceForm(state) {
  return Object.keys(validators.invoice).reduce(
    (acc, key) => ({
      ...acc,
      [key]: validateFormField(state, 'invoice', key),
    }),
    {},
  );
}
function validateDeliveryForm(state) {
  return Object.keys(validators.delivery).reduce(
    (acc, key) => ({
      ...acc,
      [key]: validateFormField(state, 'delivery', key),
    }),
    {},
  );
}
function validateEanForm(state) {
  return Object.keys(validators.ean).reduce(
    (acc, key) => ({
      ...acc,
      [key]: validateFormField(state, 'ean', key),
    }),
    {},
  );
}
function hasAnyErrors({ forms, delivery }) {
  return Object.keys(forms).reduce(
    (acc, key) => Object.keys(forms[key]).reduce(
      (acc2, key2) => acc2 || Boolean(forms[key][key2]),
      acc,
    ),
    false,
  )
  || Object.keys(delivery).reduce(
    (acc, key) => acc || Boolean(delivery[key]),
    false,
  );
}

function validateDeliveryField(state, dateType) {
  const required = isRequired(dateType)(state);
  if (required) {
    const date = getDate(dateType)(state);
    return {
      [dateType]: date ? undefined : 'Påkrævet',
    };
  }
  return {};
}
function* validateFieldSaga() {
  const channel = yield actionChannel(`${VALIDATE_FIELD}_REQUEST`);
  while (true) {
    const { payload } = yield take(channel);
    const state = yield select(state => state);
    const { container } = payload;
    if (container === 'forms') {
      const { form, field } = payload;
      const message = validateFormField(state, form, field);
      yield put(validateFieldSuccess({ ...payload, message }));
    } else if (container === 'delivery') {
      const { dateType } = payload;
      const message = validateDeliveryField(state, dateType);
      yield put(validateFieldSuccess({ ...payload, message }));
    }
  }
}

function* validateSaga() {
  const channel = yield actionChannel(`${VALIDATE}_REQUEST`);
  while (true) {
    yield take(channel);
    yield put(validateState());
    const state = yield select(state => state);
    const itemEntries = getItemEntries(state);
    if (isInSpecialMode(state)) {
      const totalNumberOfItems = getTotalNumberOfItems(state);
      if (totalNumberOfItems < 3) {
        yield put(validateSuccess({
          message: 'Du skal bestille mindst 3 rammer',
        }));
        continue; // eslint-disable-line
      }
      const forms = {
        invoice: validateInvoiceForm(state),
        delivery: {},
        ean: {},
      };
      const payload = {
        forms,
        delivery: {},
      };
      if (hasAnyErrors(payload)) {
        yield put(validateSuccess({
          ...payload,
          show: true,
          message: 'Du mangler at indtaste nogle oplysninger - se felter med rød',
        }));
      } else {
        yield put(validateSuccess({
          ...payload,
          message: null,
        }));
      }
      continue; // eslint-disable-line
    }
    if (itemEntries.length === 0) {
      yield put(validateSuccess({
        message: 'Du skal have mindst en vare i kurven for at kunne lave en ordre',
      }));
      continue; // eslint-disable-line
    }
    if (isDeliveryInConflict(state)) {
      yield put(validateSuccess({
        message: 'Den valgte leveringsmetode er ikke mulig',
      }));
      continue; // eslint-disable-line
    }
    const forms = {
      invoice: validateInvoiceForm(state),
    };
    if (hasDeliveryInfo(state)) {
      forms.delivery = validateDeliveryForm(state);
    } else {
      forms.delivery = {};
    }
    const paymentType = getPaymentType(state);
    if (paymentTypes.eanInvoice === paymentType) {
      forms.ean = validateEanForm(state);
    } else {
      forms.ean = {};
    }
    const delivery = {
      ...validateDeliveryField(state, dateTypes.delivery),
      ...validateDeliveryField(state, dateTypes.pickup),
    };
    const payload = {
      forms,
      delivery,
    };
    if (hasAnyErrors(payload)) {
      yield put(validateSuccess({
        ...payload,
        show: true,
        message: 'Du mangler at indtaste nogle oplysninger - se felter med rød',
      }));
    } else {
      yield put(validateSuccess({
        ...payload,
        message: null,
      }));
    }
  }
}
const translateForm = { 'invoice-details': 'invoice', 'delivery-details': 'delivery', 'ean-form': 'ean' };
function* listenToFormsChange({ payload: { form: reduxForm, key: field } }) {
  const form = translateForm[reduxForm];
  if (form) {
    yield call(delay, 0);
    yield put(
      validateFieldRequest(
        {
          container: 'forms',
          form,
          field,
        },
      ),
    );
  }
}
export default function* formErrorSaga() {
  yield all([
    validateFieldSaga(),
    validateSaga(),
    takeEvery(CHANGE, listenToFormsChange),
  ]);
}
