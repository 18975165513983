import React from 'react';
import styled from 'styled-components/macro';
import TabButton from '../TabButton';

const TabButtonStyled = styled(TabButton)`
`;

export default ({ tabs }) => tabs.map(
  ({
    to,
    smallTitle,
    showSmall,
    id,
  }) => showSmall && <TabButtonStyled id={id} to={to}>{smallTitle}</TabButtonStyled>,
);
