import React from 'react';
import styled from 'styled-components/macro';
import { Paragraph, ClearFix, Bold } from '../typography';
import { Image } from '../Image';
import {
  MomsChange,
  Money,
} from '../../containers/commerce';
import { sanitizePrice } from '../../lib/price';
import { LiterFormat } from '../Format';
import { sanitizeMeasurement, ScreenSizeDetector } from '../../lib/helpers';
import { Row, Col, Div } from '../grid';
import PriceText from '../commerce/Item/list-views/PriceText';
import Fjernlager from '../Fjernlager';
import LavLiterpris from '../../containers/commerce/LavLiterpris';
import { FadKobling } from '../commerce/parts';

const Container = styled.div`
  position: relative;
  border-bottom: 2px ${({ theme }) => theme.colors.colorGreyLightAlt} solid;
  padding: 10px;
  color: ${({ theme }) => theme.colors.colorGreyDark} !important;

  &:hover {
    background: ${({ theme }) => theme.colors.colorBlueHighlight};
  }

  @media (max-width: 991px) {
    padding: 5px;
  }
`;
const ImageContainer = styled.div`
  display: block;
  min-height: 40px;
  height: 100%;
  float: left;
  width: 120px;
  text-align: center;

  @media (max-width: 400px) {
    width: 90px;
  }
`;
const DescriptionContainer = styled.div`
  float: right;
  width: calc(100% - 130px);

  @media (max-width: 400px) {
    width: calc(100% - 100px);
  }
`;
const Title = styled(Paragraph)`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;

  @media (max-width: 991px) {
    font-size: 14px;
    margin-bottom: 5px;
  }
`;
const MoneyStyled = styled(Money)`
  color:  ${({ theme }) => theme.colors.colorBlue};
  font-weight: bold;
`;
const PriceTextWrap = styled(Div)`
  margin-top: 20px;
  & span {
    color:  ${({ theme }) => theme.colors.colorBlue};
    font-weight: bold;
  }
`;

export default ({
  name,
  imageSingle,
  imagePlural,
  image,
  price,
  packageNoun,
  description,
  countPrColli,
  liter,
  inStock,
  inStockText,
  totalCasePrice,
  returPant,
  productTypePack,
  replaceStkWithPackageNoun,
  simpleAmountDescriptor,
  extendedAmountDescriptor,
  isLeje,
  expiredMessage,
  expiredSize,
  smallDescription,
  title,
  expired,
  productTypeReservedel,
  glass,
  lavLiterpris,
  productTypeKulsyre,
  productTypeIs,
  productTypeFadoel,
  fadKobling,
}) => (
  <Container className="resultView">
    <ImageContainer>
      <Row margin="0px">
        {imageSingle && (
          <Col xs={imagePlural ? 4 : 12} padding="0px" textCenter>
            <ScreenSizeDetector imageHeight={[[991, '40px'], '60px']} maxHeight={[[991, '40px'], '60px']}>
              <Image name={imageSingle} />
            </ScreenSizeDetector>
          </Col>
        )}
        {imagePlural && (
          <Col xs={imageSingle ? 8 : 12} padding="0px" textCenter>
            <ScreenSizeDetector imageHeight={[[991, '40px'], '60px']} maxHeight={[[991, '40px'], '60px']}>
              <Image name={imagePlural} />
            </ScreenSizeDetector>
          </Col>
        )}
        {image && (
          <Col xs={12} padding="0px" textCenter>
            <ScreenSizeDetector imageHeight={[[991, '40px'], '60px']} maxHeight={[[991, '40px'], '60px']}>
              <Image name={image} />
            </ScreenSizeDetector>
          </Col>
        )}
      </Row>
    </ImageContainer>
    <DescriptionContainer>
      {inStock && (
        <React.Fragment>
          <Title>
            {name}
            {productTypeFadoel && fadKobling && !productTypePack && !productTypeReservedel && (
              <React.Fragment>
                ,&nbsp;<FadKobling value={fadKobling} noPrefix />-kobling
              </React.Fragment>
            )}
            {(sanitizeMeasurement(liter) > 0 && (sanitizeMeasurement(liter) / sanitizeMeasurement(countPrColli)) < 2) && !expiredSize && !productTypeKulsyre && !productTypeIs && (
              <React.Fragment>
                ,&nbsp;{countPrColli}x<LiterFormat liter={liter} cl divider={countPrColli} />
              </React.Fragment>
            )}
            {(sanitizeMeasurement(liter) / sanitizeMeasurement(countPrColli)) >= 2 && !expiredSize && !productTypeKulsyre && !productTypeIs && (
              <React.Fragment>
                ,&nbsp;<LiterFormat liter={liter} divider={countPrColli} />
              </React.Fragment>
            )}
            {expiredSize && !productTypeKulsyre && (
              <React.Fragment>
                ,&nbsp;{expiredSize}
              </React.Fragment>
            )}
            {glass && (
              <React.Fragment>
                ,&nbsp;{countPrColli} stk.
              </React.Fragment>
            )}
          </Title>
          {price && (
            <React.Fragment>
              {!returPant && (
                <React.Fragment>
                  <MoneyStyled money={sanitizePrice(price)} /> {packageNoun && !productTypeReservedel && `(${packageNoun.toLowerCase()})`} <MomsChange />
                </React.Fragment>
              )}
              {returPant && (
                <MoneyStyled money={totalCasePrice} multiplier={-1} />
              )}
            </React.Fragment>
          )}
          {description && (
            <Paragraph marginTop="-10px">
              {description}
            </Paragraph>
          )}
          {productTypePack && (
            <PriceTextWrap>
              <PriceText
                price={price}
                isLeje={isLeje}
                replaceStkWithPackageNoun={replaceStkWithPackageNoun}
                simpleAmountDescriptor={simpleAmountDescriptor}
                extendedAmountDescriptor={extendedAmountDescriptor}
                packageNoun={packageNoun}
                countPrColli={countPrColli}
              />
            </PriceTextWrap>
          )}
          {inStock === '-1' && !inStockText && (
            <Paragraph red paddingTop="10px">
              Midlertidigt udsolgt fra producenten
            </Paragraph>
          )}
          {inStock === '-1' && inStockText && (
            <Paragraph red paddingTop="10px">
              {inStockText()}
            </Paragraph>
          )}
          {expiredMessage && (
            <Paragraph red paddingTop="10px">
              {expiredMessage()}
            </Paragraph>
          )}
          {inStock === '0' && smallDescription && (
            <React.Fragment>
              <Paragraph blue paddingTop="10px">
                {smallDescription()}
              </Paragraph>
            </React.Fragment>
          )}
          {inStock === '0' && lavLiterpris && (
            <React.Fragment>
              <Paragraph paddingTop="10px">
                <LavLiterpris lavLiterpris={lavLiterpris} />
              </Paragraph>
            </React.Fragment>
          )}
          {inStock > 0 && inStock !== '100' && (
            <Paragraph red paddingTop="10px">
              {inStock} tilbage på lager!
            </Paragraph>
          )}
          {inStock <= -2 && (
            <Paragraph blue marginBottom="0" paddingTop="10px">
              <Fjernlager inStock={inStock} />
            </Paragraph>
          )}
          {inStock === '100' && !inStockText && (
            <Paragraph red paddingTop="10px">
              Udgået fra producenten
            </Paragraph>
          )}
        </React.Fragment>
      )}
      {!inStock && productTypePack && (
        <React.Fragment>
          <Title>{name}</Title>
          <Bold>
            <PriceText
              price={price}
              isLeje={isLeje}
              replaceStkWithPackageNoun={replaceStkWithPackageNoun}
              simpleAmountDescriptor={simpleAmountDescriptor}
              extendedAmountDescriptor={extendedAmountDescriptor}
              packageNoun={packageNoun}
              countPrColli={countPrColli}
            />
          </Bold>
        </React.Fragment>
      )}
      {!inStock && (
        <React.Fragment>
          <Title>{title}</Title>
          {description && (
            <Paragraph marginTop="-10px">
              {description}
            </Paragraph>
          )}
        </React.Fragment>
      )}
      {expired && (
        <React.Fragment>
          <Title>
            {name} {expiredSize && (<React.Fragment>{expiredSize}</React.Fragment>)}
          </Title>
          <Paragraph red>
            {expiredMessage && (<React.Fragment>{expiredMessage()}</React.Fragment>)}
          </Paragraph>
        </React.Fragment>
      )}
    </DescriptionContainer>
    <ClearFix />
  </Container>
);
