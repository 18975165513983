import styled from 'styled-components/macro';
import {
  marginTheming,
  paddingTheming,
  elementTheming,
  fontTheming,
  borderTheming,
  removePaddingThemingProps,
  removeMarginThemingProps,
  removeElementThemingProps,
  removeFontThemingProps,
  removeBorderThemingProps,
  removeProps,
  combine,
  createComponentWithModifiedProps,
} from '../../style/themes/main';


const removeCustomProps = removeProps();
const removeAllProps = combine(
  removePaddingThemingProps,
  removeMarginThemingProps,
  removeElementThemingProps,
  removeFontThemingProps,
  removeBorderThemingProps,
  removeCustomProps,
);

const DivNoProps = createComponentWithModifiedProps(removeAllProps, 'div', 'Skafte.com.grid.Div');

export default styled(DivNoProps)`
  ${({
    theme,
    grey,
    grey2,
    white,
    green,
  }) => {
    if (grey) {
      return `background-color: ${theme.colors.colorGreyLight};`;
    }
    if (grey2) {
      return `background-color: ${theme.colors.colorGreyLightAlt};`;
    }
    if (white) {
      return `background-color: ${theme.colors.colorWhite};`;
    }
    if (green) {
      return `background-color: ${theme.colors.colorGreenHighlight};`;
    }
    return '';
  }}
  ${({ infoBox }) => {
    if (infoBox) {
      return `
        & {
          border-radius: 15px;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
      `;
    }
    return '';
  }}
  ${marginTheming}
  ${paddingTheming}
  ${elementTheming}
  ${fontTheming}
  ${borderTheming}
`;
