
const prefix = 'SKAFTE/FORM¨/';

export const CHANGE = `${prefix}CHANGE`;
export const change = (form, key, value) => ({
  type: CHANGE,
  payload: {
    form,
    key,
    value,
  },
});

export const LOAD_TEST_DATA = `${prefix}LOAD_TEST_DATA`;
export const loadTestData = () => ({
  type: LOAD_TEST_DATA,
});
