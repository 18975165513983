import styled from 'styled-components/macro';
import { media } from '../../style/utils';

export const Bold = styled.span`
  font-weight: bold;
`;

export const F11 = styled.span`
  font-size: 11px;
`;

export const F12 = styled.span`
  font-size: 12px;
`;

export const F13 = styled.span`
  font-size: 13px;
`;

export const Left = styled.span`
  float: left;
`;

export const Right = styled.span`
  float: right;
`;

export const ClearFix = styled.span`
  clear: both;
  display: block;
`;

export const HiddenXS = styled.span`
  ${media.xs`
    display:none;
  `}
`;export const HiddenSM = styled.span`
${media.sm`
  display:none;
`}
`;
export const HiddenMD = styled.span`
  ${media.md`
    display:none;
  `}
`;
export const HiddenLG = styled.span`
  ${media.lg`
    display:none;
  `}
`;
export const HiddenXL = styled.span`
  ${media.xl`
    display:none;
  `}
`;
