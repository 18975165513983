import React from 'react';
import { Money } from '../../../containers/commerce';
import { AlcoholPercentage, FadKobling } from '../parts';
import { sanitizeMeasurement, ScreenSizeDetector } from '../../../lib/helpers';
import { sanitizePrice } from '../../../lib/price';
import BuyButtonsFadoel from './BuyButtonsFadoel';
import {
  Col,
  Row,
  Container,
  Div,
} from '../../grid';
import {
  Paragraph, Bold, Heading3, LinkButton,
} from '../../typography';
import { Image } from '../../Image';


const RenderButtons = ({
  amount,
  actions,
  notice,
  inStock,
  inStockText,
}) => {
  if (inStock === '-1' && !inStockText) {
    return (
      <React.Fragment>
        <Paragraph red>
          Midlertidigt udsolgt fra producenten
        </Paragraph>
        <LinkButton small textCenter to="/bestil/fadoel-og-anlaeg/fadoel" title="Fadølstyper">
          Se vores store sortiment af fadøl
        </LinkButton>
      </React.Fragment>
    );
  }
  if (inStock === '-1' && inStockText) {
    return (
      <React.Fragment>
        <Paragraph red>
          {inStockText()}
        </Paragraph>
        <LinkButton small textCenter to="/bestil/fadoel-og-anlaeg/fadoel" title="Fadølstyper">
          Se vores store sortiment af fadøl
        </LinkButton>
      </React.Fragment>
    );
  }
  if (notice) {
    return (
      <React.Fragment>
        <Paragraph>
          {notice()}
        </Paragraph>
        <Paragraph fontWeight="bold">
          Læg i kurv:
        </Paragraph>
        <Paragraph>
          <BuyButtonsFadoel medium amount={amount} actions={actions} />
        </Paragraph>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Paragraph fontWeight="bold">
        Læg i kurv:
      </Paragraph>
      <Paragraph>
        <BuyButtonsFadoel medium amount={amount} actions={actions} />
      </Paragraph>
    </React.Fragment>
  );
};

const defaultSingleWidth = [[425, '100px'], '100px'];
const RenderSingleImage = ({ imageSingle, singleWidth = defaultSingleWidth }) => {
  if (!imageSingle) {
    return null;
  }
  return (
    <ScreenSizeDetector maxWidth={singleWidth} imageWidth={singleWidth}>
      <Image name={imageSingle} width="100%" className="bucketAnimation" />
    </ScreenSizeDetector>
  );
};

export default React.forwardRef((props) => {
  const {
    price,
    liter,
    name,
    imageSingle,
    amount,
    actions,
    fadKobling,
    alcoholPercentage,
    singleWidth,
    notice,
    customText,
    medium,
    extraItemRef,
    inStock,
    inStockText,
  } = props;
  return (
    <Div ref={extraItemRef}>
      <Container grey={amount === 0} green={amount > 0} height="100%">
        <Row>
          <Col xs={12} lg={4} textCenter>
            <RenderSingleImage imageSingle={imageSingle} singleWidth={singleWidth} />
          </Col>
          <Col xs={12} lg={8}>
            <Heading3 className="bucketAnimation">{name}</Heading3>
            <Paragraph>{customText}</Paragraph>
            <Paragraph>
              Anstikker: <FadKobling value={fadKobling} />
            </Paragraph>
            <Paragraph>
              <Bold>Pris pr. {sanitizeMeasurement(liter)}l fustage <AlcoholPercentage value={alcoholPercentage} />: <Money money={sanitizePrice(price)} /></Bold>
            </Paragraph>
            <Paragraph blue>
              <Bold>Literpris: <Money money={sanitizePrice(price) / sanitizeMeasurement(liter)} /></Bold>
            </Paragraph>
            <RenderButtons medium={medium} amount={amount} actions={actions} inStock={inStock} inStockText={inStockText} notice={notice} />
          </Col>
        </Row>
      </Container>
    </Div>
  );
});
